.product-tooltip{
  pointer-events: visible!important;
  width:100%;

  .tooltipster-content{
    font-family: @hallon-frutiger-55-roman;
    color: @hallon-black;
    font-size: @ftsize-hallon-14;
    line-height: 16px;
    padding: 20px 20px 10px 20px;
    overflow: hidden;
    background: @hallon-light-grey;
    position: relative;
    width:360px;

    .product-content__cmscontent{
      width:285px;
    }

    h1{
      text-transform: none;
      font-size: @ftsize-hallon-24;
    }

    p{
      margin-bottom:1em;
    }

    .hallon-btn-close{
      position: absolute;
      right:15px;
      top:15px;
    }

  }
}