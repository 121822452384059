/// <reference path="../framework.less" />

.component-button {
    color: @color-2018-white;
    background: @color-2018-green;
    display: inline-block;
    text-align: center;
    font-family: @font-2018-bold;
    font-size: 21px;
    border-radius: 50px;
    padding: 20px 62px;
    line-height: 24px;
    transition: all 0.2s linear;
    border: none;
    cursor: pointer;
    position: relative;
    outline: none;

    @media @breakpointquery-2018-phablet {
        padding: 20px 50px;
    }

    &:hover {
        color: @color-2018-white;
        background: @color-2018-green-hover;
    }

    &--secondary {
        background: @color-2018-hallon-red;
        color: @color-2018-white;
        border: 1px solid @color-2018-white;

        &:hover {
            background: @color-2018-white;
            color: @color-2018-hallon-red;
            border: 1px solid @color-2018-hallon-red;
        }
    }

    &--loading {

        &:disabled, &:disabled:hover {
            background: @color-2018-green;
        }

        &::after {
            content: '';
            position: absolute;
            border-radius: 100%;
            right: 0;
            top: 50%;
            width: 0;
            height: 0;
            border: 2px solid rgba(255, 255, 255, 0.5);
            border-left-color: #FFFFFF;
            border-top-color: #FFFFFF;
            animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
        }
    }
}