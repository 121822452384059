/// <reference path="../framework.less" />

.category-usp {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media @breakpointquery-2018-tablet {
		flex-direction: column;
	}

	&__item {
		font-size: 16px;
		font-family: @font-2018-bold;
		color: @color-2018-hallon-red;
		flex-basis: 20%;
		display: flex;
		align-items: center;
		align-self: center;

		@media @breakpointquery-2018-tablet {
			padding: 0 0 @margins-2018-small 0;
			align-self: flex-start;
			flex: 1 1 auto;

			&:last-child {
				padding: 0;
			}
		}
	}

	&__icon {
		margin-right: 15px;
		width: 20px;
		height: 20px;
		min-width: 20px;
		min-height: 20px;
	}

	&__icon-background {
		fill: @color-2018-hallon-red;
	}

	&__tick-color {
		fill: none;
		stroke: @color-2018-white;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-width: 2px;
	}
}
