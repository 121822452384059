.login-area{

  background-color: @hallon-white;
  width: 100%;
  margin: 0 auto;
  padding: 20px;

  @media only screen and (min-width: @phone-barrier) {
    width: 375px;
    padding: 25px 50px;
    margin: 170px 0px 170px 0 auto;
  }

  h1 {
    color: @hallon-dark-red;
    font-family: @hallon-frutiger-55-roman;
    font-size: @ftsize-hallon-24;
    text-align: center;
    margin-bottom: 30px;
    text-transform: none;
  }

  form{
    div, label{
      margin-bottom:1.5em;
    }
  }

  label {
    font-size: @ftsize-hallon-14;
    font-family: @hallon-frutiger-55-roman;
    display: inline-block;
  }

  .hallon-label{
    font-size: @ftsize-hallon-12;
    margin-bottom: 0.1em;
    margin-left: 0;
    opacity: 0;
  }

  .mobile-subscription__error{
    display: inline-block;
    float:right;
    margin-bottom:0;
    padding-bottom:0.5em;
    font-size: @ftsize-hallon-12;
    margin-block-end:0em;
    margin-block-start:0em;

    p{
      display: inline-block;
      margin-block-end:0em;
      margin-block-start:0em;
      float:right;
      margin:0;
      font-size: @ftsize-hallon-12;
      color: @hallon-light-red;
    }
  }

  .link-right-arrow {
    margin-top: 10px;
    margin-bottom:30px;
    color: @hallon-dark-red;
    text-align: center;
  }

    input[type="submit"] {
      width: 100%;
      margin-bottom:20px;
    }

    p {
      margin: 0 0 20px 0px;
      font-size: @ftsize-hallon-14;
      color: @hallon-dark-grey;
    }
    span.whiteEnvlp {
      .background(-115px, -53px);
      width: 114px;
      height: 80px;
      display: block;
      margin: auto;
      margin-bottom: 20px;
    }


  .errorMsg {

    @paddingLeft: unit( 2/12*100, %);

    position: relative;
    color: #fff;
    margin-bottom: @margin-M;

    &[data-area] {

      padding-left: @paddingLeft; // Stronger selector
    }

    i[class^="icon-"] {

      @fontSize : 36px;

      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      font-size : @fontSize;
      margin-top: @fontSize/-2;
      margin-right: 0;

      width: @paddingLeft;
      text-align: center;
    }
  }


}