/// <reference path="../framework.less" />

.expandable-product-card {
    position: relative;
    width: 20%;
    left: 0;
    transition: left 0.2s linear, z-index 0.2s linear, height 0.2s linear;
    cursor: default;
    height: 0;

    @media @breakpointquery-2018-tablet {
        transition: padding 0.2s linear, z-index 0.2s linear;
        width: 100%;
        height: auto;
        padding: 0 20px;
    }

    &--summary-end {
        display: none;

        @media @breakpointquery-2018-tablet {
            width: 100%;
            height: 1px;
            display: block;
        }
    }

    + .expandable-product-card {
        .expandable-product-card__upsale-wrapper--clone {
            @media @breakpointquery-2018-tablet {
                transition-delay: 0s;
                transform: scale(1, 1) translate3d(-40px, -5px, 0);
                transform-origin: 15px center;
                opacity: 0;
                transition: opacity 0.2s linear, transform 0.2s linear;
            }
        }
    }

    &__upsale-wrapper {
        position: absolute;
        left: -60%;
        top: 0;
        transform: scale(0, 0) translateY(-70%);
        z-index: 2;
        transform-origin: top;
        pointer-events: none;
        transition: transform 0.2s linear;
        transition-delay: 0s;

        @media @breakpointquery-2018-tablet {
            display: none;
        }

        &--show {
            transform: scale(1, 1) translateY(-70%);
        }

        &--clone {
            display: none;
            transform: scale(0, 0);
            left: unset;
            top: -70%;
            right: 0;

            @media @breakpointquery-2018-tablet {
                display: block;
            }
        }
    }

    &__upsale-text-wrapper {
        height: 100px;
        position: absolute;
        width: 100px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media @breakpointquery-2018-tablet {
            left: 0;
            right: auto;
            top: 0;
            z-index: 1;
        }
    }

    &__upsale-text {
        font-family: @font-2018-light;
        color: @color-2018-white;

        &--b2b {
            color: @color-2018-white;
        }

        font-size: 12px;
        line-height: 16px;

        &--bold {
            font-family: @font-2018-bold;
        }
    }

    &__upsale-background {

        &--desktop {
            display: block;

            @media @breakpointquery-2018-tablet {
                display: none;
            }
        }

        &--mobile {
            display: none;

            @media @breakpointquery-2018-tablet {
                display: block;
            }
        }
    }

    &__upsale-background-circle {
        fill: @color-2018-hallon-red;
    }

    &__upsale-background-mobile-circle {
        stroke: @color-2018-hallon-red;
        fill: @color-2018-hallon-red;

        &--b2b {
            stroke: @color-2018-white;
        }
    }

    &__upsale-background-mobile-arrow {
        fill: none;
        stroke: @color-2018-white;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    &__upsale-background-arrow {
        fill: none;
        stroke: @color-2018-white;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    &__wrapper {
        background: @color-2018-white;

        @media @breakpointquery-2018-tablet {
            background: @color-2018-white;
        }

        &--b2b {
            background: @color-2018-hallon-red;

            @media @breakpointquery-2018-tablet {
                background: @color-2018-hallon-red;
            }
        }

        box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.75);
        overflow: hidden;
        border-radius: 25px;
        padding: 0;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        transform: none;
        transition: all 0.2s linear;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);

        @media @breakpointquery-2018-tablet {
            box-shadow: none;
            filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.40));
            -webkit-filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.40));
            border-radius: 25px 25px 0 0;
            position: relative;
            left: 0;
            top: 0;
            transform: none;
            max-height: 1000px;
        }

        &::before {
            content: "";
            background: #000;
            width: 100%;
            height: 100%;
            position: absolute;
            opacity: 0.05;
            left: 0;
            top: 0;
            z-index: 2;
            border-radius: 25px;
            transition: opacity 0.2s linear;
            pointer-events: none;

            @media @breakpointquery-2018-tablet {
                border-radius: 25px 25px 0 0;
            }
        }

        &--b2b {
            &::before {
                opacity: 0.01;
            }
        }

        &--overlay::before {
            opacity: 0;
        }
    }

    &__price-wrapper {
        flex: 0 0 auto;
        align-self: center;
        position: relative;
        z-index: 1;
        width: 100%;
        padding: 0;
        transition: all 0.4s linear;
        transform: none;
        border-radius: 25px 25px 0 0;
        background-color: @color-2018-white;
        justify-content: center;
        display: flex;
        flex-direction: column;

        @media @breakpointquery-2018-tablet {
            display: flex;
            border-radius: 0;
            padding: 20px 15px 20px 15px;
            overflow: hidden;
            flex-direction: row;
            background-color: @color-2018-yellow;
        }

        &::after {
            background-color: @color-2018-yellow;
            width: 100%;
            height: 100%;
            content: "";
            border-radius: 25px 25px 0 0;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transform: scale(2,1);
            transition: border-radius 0.2s linear, transform 0.2s linear;

            @media @breakpointquery-2018-tablet {
                content: none;
            }
        }

        &--b2b {
            background-color: @color-2018-hallon-red;

            @media @breakpointquery-2018-tablet {
                background-color: @color-2018-white;
            }

            &::after {
                background-color: @color-2018-white;
            }
        }
    }


    &__product-title {
        font-size: 45px;
        font-family: @font-2018-black;
        color: @color-2018-hallon-red;
        text-transform: uppercase;
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
        transition: all 0.2s linear;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-top: 20px;

        &--b2b {
            color: @color-2018-hallon-red;
        }

        span:first-child {
            padding-right: 4px;
        }

        &--doubleline {
            span {
                flex-basis: 100%;

                &:first-child {
                    padding-right: 0;
                }
            }
        }

        @media @breakpointquery-2018-tablet {
            font-size: 32px;
            margin: 0;
            height: auto;
            order: 3;
            flex: 1 0 auto;
            justify-content: flex-end;
            align-items: flex-start;
            position: absolute;
            right: 15px;
            top: 15px;
            transition: right 0.2s linear;
            padding: 0;

            &--doubleline {
                span {
                    flex-basis: auto;
                }
            }
        }
    }


    &__main-price {
        font-size: 30px;
        font-family: @font-2018-black;
        color: @color-2018-text-black;
        padding: 0 5px;
        margin-left: auto;
        margin-right: auto;
        transition: all 0.2s linear;

        &--b2b {
            color: @color-2018-text-black;
        }

        @media @breakpointquery-2018-tablet {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            flex: 1 1 auto;
            font-size: 22px;
            line-height: 31px;
            padding: 0;
        }
    }

    &__main-price-price {
        display: block;
        transform: none;
        font-size: 30px;
        transition: all 0.2s linear;

        @media @breakpointquery-2018-tablet {
            padding-right: 10px;
            font-size: 22px;
        }
    }

    &__main-price-info {
        display: block;
        padding: 0 0 20px 0;
        margin: auto;

        @media @breakpointquery-2018-tablet {
            flex: 1 1 auto;
            text-align: left;
            transform: none;
            margin: 0 0 10px 0;
            padding: 0;

            &:first-child {
                margin: 0;
            }
        }

        &:first-child {
            margin-top: 5px;
        }

        span {
            display: block;
            font-size: 12px;
            line-height: 16px;
            min-height: 16px;
            font-family: @font-2018-roman;

            @media @breakpointquery-2018-tablet {
                font-size: 12px;
                line-height: 21px;
                max-height: none;
            }
        }
    }

    &__cta-wrapper {
        flex: 1 1 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
        transition: transform 0.2s linear, padding 0.2s linear;
        transform: none;

        @media @breakpointquery-2018-tablet {
            padding-bottom: 130px;
        }

        &--multi {
            transition: all 0.2s linear;
            padding: 0;
            margin-top: 0;
            top: 0;
            position: relative;

            @media @breakpointquery-2018-tablet {
                padding: 0 0 0 0;
                margin-top: 0;
                top: 0;
            }
        }
    }

    &__compare-wrapper {
        margin-bottom: 50px;
        margin-top: 15px;
        transition: margin 0.2s linear;

        @media @breakpointquery-2018-tablet {
            margin-bottom: 0px;
        }
    }

    &__compare-price {
        font-size: 14px;
        font-family: @font-2018-bold;
        margin-bottom: 5px;
        color: @color-2018-hallon-red;
        display: block;
        transition: transform 0.2s linear;
        transform: none;

        &--b2b {
            color: @color-2018-white;
        }


        @media @breakpointquery-2018-tablet {
            font-size: 24px;
        }
    }

    &__compare-title {
        font-size: 14px;
        line-height: 21px;
        font-family: @font-2018-roman;
        display: block;
        position: relative;
        transform: scale(0,0);
        transform-origin: center;
        transition: transform 0.2s linear;
        white-space: nowrap;
        overflow: hidden;

        &--b2b {
            color: @color-2018-white;
        }

        @media @breakpointquery-2018-tablet {
            transform: none;
        }
    }


    &__usp-list {
        padding-top: 25px;
        display: inline-block;
        transition: all 0.2s linear;

        @media @breakpointquery-2018-tablet {
            padding-top: 34px
        }
    }

    &__usp-list-item {
        padding-bottom: 10px;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transition: all 0.2s linear;

        &::after {
            content: "";
            width: calc(~"100% + 20px");
            position: absolute;
            height: 1px;
            left: 0;
            transform: translate3d(0, 14px,0);
            border-top: 1px solid #000;
            border-color: rgba(0, 0, 0, 0.1);

            @media @breakpointquery-2018-tablet {
                width: calc(~"100% - 40px");
                left: 20px;
            }
        }

        &--b2b {
            &::after {
                border-top: 1px solid #E5E5E5;
                border-color: rgba(229, 229, 229, 0.25);
            }
        }
    }

    &__usp-list-item-text {
        font-size: 14px;
        line-height: 21px;
        position: relative;
        width: 0;
        flex: 0 1 0;
        overflow: hidden;
        padding: 0;
        transition: all 0.2s linear;
        transition-delay: 0s;
        text-align: left;

        &--b2b {
            color: @color-2018-white;
        }

        &--cross {
            color: #d3d3d3;
        }

        @media @breakpointquery-2018-tablet {
            font-size: 18px;
            width: 100%;
            flex: 1 1 auto;
            padding-left: 10px;
        }
    }

    &__usp-list-item-icon {
        font-size: 14px;
        flex: 0 0 auto;
        display: flex;
        min-width: 15px;

        .tick {
            path {
                fill: none;
                stroke: @color-2018-green;
                stroke-width: 2px;
            }

            &--b2b {
                path {
                    stroke: @color-2018-white;
                }
            }
        }

        .cross {
            path {
                fill: none;
                stroke: #d3d3d3;
                stroke-width: 2px;
            }

            &--b2b {
                path {
                    stroke: #d3d3d3;
                }
            }
        }
    }

    &__cta-button-wrapper {
        display: block;
        bottom: 20px;
        left: 0;
        right: 0;
        position: absolute;
        transform: translate3d(0%, 150%,0);
        transform-origin: center;
        transition-delay: 0s;
        transition: transform 0.2s linear, opacity 0.2s linear;

        @media @breakpointquery-2018-tablet {
            position: relative;
            transform: translate3d(0%, 30%,0);
        }

        &--multi {
            position: relative;
            transform: none;
            bottom: 0;
            transition: all 0.2s linear;

            @media @breakpointquery-2018-tablet {
                transform: none;
                position: relative;
                padding-bottom: 60px;
                padding-top: 40px;
                transition: transform 0.2s linear, opacity 0.2s linear;
            }
        }

        &--multi-with-upsale {
            @media @breakpointquery-2018-tablet {
                padding-bottom: 130px;
            }
        }
    }

    &__cta-button {
        padding: 13px 20px;
        white-space: nowrap;
        margin: 0 50px 0 50px;
        display: block;

        @media @breakpointquery-2018-large-tablet {
            margin: 20px 15px 0 15px;
        }
    }

    &__cta-subtext {
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 12px;
        white-space: nowrap;
        transform: translateY(-100%);
        opacity: 0;
        transition: transform 0.2s linear opacity 0.2s linear;

        &--b2b {
            color: @color-2018-white;
        }
    }

    &__multi-subscription-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__decrease-subscription {
        background-color: @color-2018-white;
        color: @color-2018-text-black;
        font-size: 16px;
        line-height: 18px;
        font-family: @font-2018-roman;
        border-radius: 8px 0 0 8px;
        height: 32px;
        width: 25px;
        border: 0;
        outline: none;
        -webkit-appearance: none;
        cursor: pointer;
        transition: 0.1s background-color linear;
        display: flex;
        align-items: center;
        justify-content: center;

        @media @breakpointquery-2018-tablet {
            height: 68px;
            width: 60px;
            font-size: 30px;
            line-height: 36px;
            border-radius: 20px 0 0 20px;
        }

        &:active {
            background-color: #BEBEBE;
        }

        &:disabled {
            background-color: #D8D8D8;
        }

        &--bling {
            background-color: #D8D8D8;
        }

        svg {
            height: 2px;

            @media @breakpointquery-2018-tablet {
                height: 20px;
            }

            path {
                fill: none;
                stroke: #000;
                stroke-linecap: round;
                stroke-width: 3px;
            }
        }
    }

    &__amount-subscription {
        background-color: #f0f0f0;
        color: @color-2018-text-black;
        font-size: 16px;
        line-height: 17px;
        font-family: @font-2018-roman;
        width: 28px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media @breakpointquery-2018-tablet {
            height: 68px;
            width: 60px;
            font-size: 30px;
            line-height: 36px;
        }
    }

    &__increase-subscription {
        background-color: @color-2018-white;
        color: @color-2018-text-black;
        font-size: 16px;
        line-height: 18px;
        font-family: @font-2018-roman;
        border-radius: 0 8px 8px 0;
        height: 32px;
        width: 25px;
        border: 0;
        outline: none;
        -webkit-appearance: none;
        cursor: pointer;
        transition: 0.1s background-color linear;
        display: flex;
        align-items: center;
        justify-content: center;

        @media @breakpointquery-2018-tablet {
            height: 68px;
            width: 60px;
            font-size: 30px;
            line-height: 36px;
            border-radius: 0 20px 20px 0;
        }

        &:active {
            background-color: #BEBEBE;
        }

        &:disabled {
            background-color: #D8D8D8;

            svg {
                path {
                    stroke: #b3b3b3;
                }
            }
        }

        &--bling {
            background-color: #D8D8D8;
        }

        svg {
            height: 12px;

            @media @breakpointquery-2018-tablet {
                height: 40px;
            }

            path {
                fill: none;
                stroke: @color-2018-text-black;
                stroke-linecap: round;
                stroke-width: 3px;
            }
        }
    }

    &--active {

        @media @breakpointquery-2018-tablet {
            height: auto;
        }

        + .expandable-product-card {
            .expandable-product-card__upsale-wrapper--clone {
                @media @breakpointquery-2018-tablet {
                    transition-delay: 0.2s;
                    opacity: 1;
                }
            }
        }

        &.expandable-product-card--upsale {
            .expandable-product-card__upsale-wrapper {
                @media @breakpointquery-2018-tablet {
                    top: unset;
                    left: unset;
                    bottom: 0;
                    right: 0;
                }
            }
        }

        .expandable-product-card {

            &__wrapper {
                @media @breakpointquery-2018-tablet {
                    width: 100%;
                    border-radius: 25px 25px 0 0;
                }
            }

            &__price-wrapper {

                &::after {
                    border-radius: 25px 25px 50% 50%;
                }

                @media @breakpointquery-2018-tablet {
                    padding: 20px 15px 20px 15px;
                    border-radius: 0 0 400% 400% ~"/" 100%;
                    transition: border-radius 2s linear;
                }

                &--multi {
                    padding: 0;

                    @media @breakpointquery-2018-tablet {
                        padding: 20px 15px 20px 15px;
                    }
                }
            }

            &__product-title {
                font-size: 60px;
                transition: all 0.2s linear;

                @media @breakpointquery-2018-tablet {
                    font-size: 32px;
                }
            }


            &__main-price-price {
                font-size: 40px;
                transition: all 0.2s linear;

                @media @breakpointquery-2018-tablet {
                    font-size: 22px;
                }
            }

            &__cta-wrapper {
                padding-bottom: 124px;

                @media @breakpointquery-2018-tablet {
                    padding-bottom: 130px;
                }

                &--multi {
                    padding-bottom: 0px;
                    padding: 20px 0;
                    margin-bottom: 0;
                    top: -17px;
                    position: relative;

                    @media @breakpointquery-2018-tablet {
                        padding: 0 0 0 0;
                        margin-top: 0;
                        top: 0;
                    }
                }
            }

            &__compare-wrapper {
                margin-bottom: -5px;

                @media @breakpointquery-2018-tablet {
                    margin-bottom: 0px;
                }

                &--multi {
                    @media @breakpointquery-2018-tablet {
                        margin-bottom: 50px;
                    }
                }
            }

            &__compare-price {
                transform: scale(1.2, 1.2);

                @media @breakpointquery-2018-tablet {
                    transform: none;
                }
            }

            &__compare-title {
                transform: scale(1, 1);

                @media @breakpointquery-2018-tablet {
                    transform: none;
                }
            }


            &__usp-list-item-text {
                width: 100%;
                flex: 1 1 auto;
                padding-left: 10px;
                transition-delay: 0.2s;
            }

            &__cta-button-wrapper {
                transition-delay: 0.2s;
                transform: translate3d(0%, 0%, 0);

                @media @breakpointquery-2018-tablet {
                    transform: translate3d(0%, 30%, 0);
                    transition-delay: 0.2s;
                }

                &--multi {
                    position: relative;
                    transform: none;
                    padding-top: 41px;
                    bottom: -14px;
                    transition-delay: 0s;

                    @media @breakpointquery-2018-tablet {
                        position: relative;
                        padding-top: 40px;
                        bottom: 0;
                        transform: none;
                        transition-delay: 0.2s;
                    }
                }
            }

            &__cta-subtext {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    &--no-upsale {
        .expandable-product-card {
            &__cta-wrapper {
                @media @breakpointquery-2018-tablet {
                    padding-bottom: 60px;
                }

                &--multi {
                    @media @breakpointquery-2018-tablet {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    &--multi {
        &:nth-last-child(2) {
            .expandable-product-card {
                &__wrapper {
                    @media @breakpointquery-2018-tablet {
                        border-radius: 25px;
                    }
                }

                &__cta-wrapper {
                    @media @breakpointquery-2018-tablet {
                        padding-bottom: 40px;
                    }

                    &--multi {
                        @media @breakpointquery-2018-tablet {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    &:last-child {
        .expandable-product-card {
            &__wrapper {
                @media @breakpointquery-2018-tablet {
                    border-radius: 25px;
                }
            }

            &__cta-wrapper {
                @media @breakpointquery-2018-tablet {
                    padding-bottom: 40px;
                }

                &--multi {
                    @media @breakpointquery-2018-tablet {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}