.hardware-company-product{
  z-index: 2;
  position: absolute;


  .hardware-company-product__container{
    display: inline-block;
    vertical-align: middle;
    float:left;

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      float:none;
      display: block;
    }

    @media only screen and (min-width: @desktop-special-breakpoint) {
      display: inline-block;
      vertical-align: middle;
      float:left;
    }

  }

  .hardware-company-product__img-container{
    width:100%;
    display: block;
    margin-top:1em;
    margin-bottom:1em;

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      width:250px;
      margin:0;
      vertical-align: top;
      display: inline-block;
    }

    img{
      margin:0 auto;
      display: block;
      @media only screen and (min-width: @tablet-breakpoint-hallon) {
        margin:0;
      }
    }
  }

  .hardware-company-product__product-picker{

    display: block;
    float:left;
    width:100%;

    a{
      margin:0 auto;
    }

    @media only screen and (min-width: @tablet-breakpoint-hallon){
      margin-left:250px;
      margin-top:0px;
      width:auto;
      display: block;
    }

    @media only screen and (min-width: @desktop-special-breakpoint) {
      margin-left:20px;
      margin-top:34px;
      width:auto;
      float:left;
      display: inline-block;
    }
  }

  .hardware-company-product__content  {

    display: block;
    padding-left:15px;
    padding-right:15px;
    margin-bottom:2em;

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      display: inline-block;
      width:300px;
      padding:0;
      margin-bottom:0;
    }


    h1{
      font-family: @hallon-frutiger-77-bc;
      font-size: @ftsize-hallon-24;
      text-transform: none;
    }
    p, li{
      font-family: @hallon-frutiger-55-roman;
      font-size: @ftsize-hallon-14;
      margin-bottom:1em;
      color: @hallon-heavy-grey;
    }
    h2{
      text-transform: none;
      margin-top: 1em;
      margin-bottom: 1em;
      font-family: @hallon-frutiger-77-bc;
      font-size: @ftsize-hallon-24;
    }
    .link-down-arrow{
      color:@hallon-black;
      cursor: pointer;
      &:hover, &:visited, &:focus{
        color:@hallon-black;
      }
    }

    ul{
      li{
        margin:0;
      }
    }

  }

  .hardware__more-info{
    display: none;

    li{
      font-family: @hallon-frutiger-55-roman;
      font-size: @ftsize-hallon-14;
      color: @hallon-heavy-grey;
    }
  }

  [data-product-instock="true"]{
    color: @hallon-light-green;
  }

  [data-product-instock="false"]{
    color: @hallon-light-red;
  }

  .hardware-company-product__stock{
    margin-top:1em;
  }


  .hardware-company-product__subscription-picker{
    padding-top:2em;

    h1{
      font-family: @hallon-frutiger-77-bc;
      font-size: @ftsize-hallon-48;
      text-transform: uppercase;
      color: @hallon-white;
      margin-bottom:0;

      span{
        font-family: @hallon-frutiger-55-roman;
        font-size: @ftsize-hallon-20;
        color: @hallon-white;
        margin-bottom:0;
        text-transform: none;
        line-height: 1;
        display: block;
      }

    }

    display: block;
    padding-bottom:120px;

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      display: none;
    }
  }


  .hardware-company-product__buy-link{
    padding:15px;
    border-left: 1px solid @hallon-dark-grey;;
    border-bottom: 1px solid @hallon-dark-grey;
    border-right: 1px solid @hallon-dark-grey;
    cursor: pointer;
    display: block;
    color:@hallon-dark-red;
    background: @hallon-medium-grey;
    i{
      margin-right:5px;
      display: none;
    }

    &:first-child{
      border-top:1px solid @hallon-dark-grey;
    }
  }

  .hardware-company-product__buy-link--selected{
    color:@hallon-white;
    border-left: 1px solid @hallon-dark-red;;
    border-bottom: 1px solid @hallon-dark-red;
    border-right: 1px solid @hallon-dark-red;
    background:@hallon-dark-red;
    i{
      margin-right:5px;
      display: inline-block;
    }
  }

  .hardware-company-product__buy-link--nostock{
    text-decoration: line-through;
    cursor: default;
  }


}