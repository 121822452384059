/// <reference path="../framework.less" />

.account-switcher {
	&__wrapper {
		display: flex;
		flex-direction: column;
		flex-direction: column;
		width: 100%;
		padding: 20px 0;
	}

	&__item-title {
		font-family: @font-2018-roman;
		text-transform: none;
		color: @color-2018-text-black;
		font-size: 14px;
		line-height: 21px;
	}

	&__item {
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;
		text-align: left;
		cursor: pointer;
		position: relative;
	}

	&__email-wrapper {
		background-color: @color-2018-hallon-red;
		font-family: @font-2018-bold;
		color: @color-2018-white;
		font-size: 16px;
		line-height: 21px;
		padding: 15px 20px 10px 20px;
		border-radius: 25px 25px 0 0;
		display: flex;
		justify-self: center;
		align-items: center;
		z-index: 0;
		position: relative;
		box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16);

		&--no-number {
			border-radius: 25px;
		}

		&--active {
			background-color: #3E3A37;
		}
	}

	&__email-balls {
		position: absolute;
		width: 100px;
		height: 100px;
		z-index: -1;
		left: -30px;
		top: -6px;
		pointer-events:none;
	}

	&__email {
		flex: 1 1 auto;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0 20px 0 0;
	}

	&__email-arrow {
		width: 8px;
		height: 15px;
		flex: 0 0 auto;
	}

	&__number {
		background-color: #e2e2e2;
		padding: 0 20px;
		font-size: 14px;
		line-height: 19px;
		color: #808080;

		&--first {
			padding-top: 5px;
		}

		&:nth-child(even) {
			background-color: #f0f0f0;
		}

		&:last-child {
			border-radius: 0 0 25px 25px;
			padding-bottom: 5px;
		}

		&--helptext {
			padding: 5px 20px;
		}
	}

	@media @breakpointquery-2018-tablet {
	}
}
