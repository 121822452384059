.mobile-subscription-list{

  width: 100%;
  position: relative;
  background: @hallon-light-blue;
  margin-bottom:1em;
  margin-top:1em;
  padding:1em;
  text-align: center;

  @media only screen and (min-width: @phone-breakpoint-hallon) {
    width:500px;
    margin:0 auto;
  }


  ul{
    display:-moz-inline-stack;
    display:inline-block;
    zoom:1;
    *display:inline;
    text-align: left;
  }

  li{
    color: @hallon-white;
    line-height: 20px;
    padding-left:3em;
    position: relative;

    p{
        font-size: 1.6rem;
    }
  }
  .mobile-subscription-list__check-box{
    margin-top: 1em;
    &:before {
      padding-left: 0em;
      left:0;
      position: absolute;
      display: inline-block;
      color: @hallon-white;
      font-family: "hallon";
      content: "\e914";
    }
  }
  li:first-child{
    margin-top:0em;
  }
  li:last-child{
    margin-top:2em;
  }

  .hallon-btn-transparent{
    margin-top: 1em;
  }

}

.mobile-subscription-list--arrow{
  position: relative;

  &:after{
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: @hallon-light-blue;
    border-width: 15px;
    margin-left: -15px;
  }

  li:last-child{
    margin-top:0em;
  }
}

.mobile-subscription-list--ajax-arrow{
  position: relative;
  background: @hallon-lighter-blue;
  padding-bottom: 40px;
  padding-top: 40px;
  display: none;

  &:after{
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: @hallon-lighter-blue;
    border-width: 15px;
    margin-left: -15px;
  }

  li:last-child{
    margin-top:0em;
  }
}

.mobile-subscription-list--ajax-error{
  position: relative;
  background: @hallon-lighter-red;
  padding-bottom: 40px;
  padding-top: 40px;
  display: none;

  &:after{
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: @hallon-lighter-red;
    border-width: 15px;
    margin-left: -15px;
  }



  li{
    color: @hallon-light-red;
  }
  .mobile-subscription-list__check-box{
    &:before {
      color: @hallon-light-red;
      font-family: "hallon";
      font-size: 22px;
      content: "\e915";
    }
  }

  li:last-child{
    margin-top:0em;
  }
}