.content-container {
    
    max-width: 960px;
    margin: 0 auto;
    .clearfix();
    
    & h1:first-child,
    .top-rubrik h1 {
        margin-top: 1em;
    }
    
    .top-rubrik {
        padding: 0 0 @margin-M;
    }

    @media only screen and ( max-width: 960px) {
        @side-padding: 100/12 * 3/4; // one column width minus gutter
        padding-left: unit( @side-padding, %);
        padding-right: unit( @side-padding, %);
    }
    
    @media only screen and ( max-width: @hw-laptop ) {
        @side-padding: 100/12 * 1/4; // one column width minus gutter
        padding-left: unit( @side-padding, %);
        padding-right: unit( @side-padding, %);
    }
    
    @media only screen and (max-width: @hw-tablet) {
        @side-padding: 100/12/3; // one gutter width
        padding-left: unit( @side-padding, %);
        padding-right: unit( @side-padding, %);
    }
}
