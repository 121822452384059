/// <reference path="../framework.less" />

.profile-nav {

	&__item {
		position: relative;
		flex: 1 1 auto;

		&:first-child {
			margin-right: 15px;
		}
	}

	&__button {
		border-radius: 50px;
		padding: 10px 0;
		background: @color-2018-white;
		color: @color-2018-hallon-red;
		border: 1px solid @color-2018-hallon-red;
		box-sizing: border-box;
		font-size: 18px;
		font-family: @font-2018-bold;
		display: block;
		text-align: center;
		width: 135px;
		transition: all 0.2s linear;
		cursor: pointer;

		&:hover {
			background: @color-2018-hallon-red;
			color: @color-2018-white;
		}

		&--hallon-red {
			background: @color-2018-hallon-red;
			color: @color-2018-white;
			border: 1px solid @color-2018-white;

			&:hover {
				background: @color-2018-white;
				color: @color-2018-hallon-red;
				border: 1px solid @color-2018-hallon-red;
			}
		}
	}

	&__sublist {
		position: absolute;
		visibility: hidden;
		opacity: 0;
		top: 0;
		z-index: 0;
		transition: all .3s;
		backface-visibility: hidden;
		padding: 5px 20px 20px 20px;
		background-repeat: no-repeat;
		right: 50%;
		transform: translateX(50%);
		background: @color-2018-white;
		border-radius: 0 0 10px 10px;
		margin-top: 25px;
		width: 192px;
		-webkit-filter: drop-shadow( 0px 3px 3px rgba(0, 0, 0, .3));
		filter: drop-shadow( 0px 3px 3px rgba(0, 0, 0, .3));

		&.show {
			visibility: visible;
			opacity: 1;
		}

		&::before {
			content: "";
			display: block;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 195 25.274'%3E%3Cpath d='M0 25.273v-4a10 10 0 0 1 10-10h79.6L97.676 0l8.384 11.273H185a10 10 0 0 1 10 10v4z' fill='%23fff'/%3E%3C/svg%3E");
			height: 25px;
			width: 100%;
			background-repeat: no-repeat;
			position: absolute;
			top: -24px;
			right: 50%;
			transform: translateX(50%);
		}
	}

	&__sublist-item {
		&--margin-bottom {
			margin-bottom: 15px;
		}
	}

	&__sublist-item-link {
		font-size: 16px;
		display: block;
		text-align: center;
		white-space: nowrap;
		font-family: @font-2018-bold;
		color: @color-2018-hallon-red;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}

		&--button {
			border-radius: 50px;
			padding: 10px 0;
			background: @color-2018-hallon-red;
			color: @color-2018-white;
			border: 1px solid @color-2018-hallon-red;
			box-sizing: border-box;
			font-size: 16px;
			font-family: "Frutiger-LT-W01-65-Bold";
			display: block;
			text-align: center;
			width: 155px;
			transition: all 0.2s linear;

			&:hover {
				color: @color-2018-hallon-red;
				background: @color-2018-white;
				border: 1px solid @color-2018-hallon-red;
				text-decoration: none;
			}
		}
	}
}