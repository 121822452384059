.bubble-hardware {
    background: @hallon-white;
    height: 130px;
    width: 130px;
    border-radius: 50%;
    display: table;

    ul {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    .bubble-hardware__price {
        font-size: @ftsize-hallon-48;
        font-family: @hallon-frutiger-77-bc;
    }

    li {
        font-family: @hallon-frutiger-55-roman;
        font-size: @ftsize-hallon-14;
        line-height: 1;
    }
}

.bubble-campaign {
    background: #f4cf23;
    font-family: @font-2018-bold;
    font-size: 18px;
    height: 130px;
    width: 130px;
    border-radius: 50%;
    z-index: 4;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(40%);
    //display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    color: @hallon-dark-red;
    font-family: @tre-standard-font;
    font-size: @ftsize-hallon-14;

    span {
        font-family: @hallon-frutiger-77-bc;
        font-size: @ftsize-hallon-36;
        margin-top: 18px;
        display: block;
    }


    @media @breakpointquery-2018-tablet {
        //transform: translate(55%, -300%)
    }
}