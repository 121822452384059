//  LAYOUT ALIGNMENTS
//  ___________________

.clearfix {

	*zoom: 1;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}
}

.left {
	float: left;
}
.right {
	float: right;
}
.center {
	text-align: center;
}
.align-left {
	text-align: left;
}

.valign-top,
[data-column].valign-top {

	vertical-align: top;
}
.valign-middle,
[data-column].valign-middle {

	vertical-align: middle;
}
.valign-bottom,
[data-column].valign-bottom {
	
	vertical-align: bottom;
}

// .valign-bottom:not( [data-column="equalheight"] > [data-column] ){
.valign-bottom{

	&:after {

			content: "";
			.inline-block();
			vertical-align: bottom;
			height: 100%;
			margin-left: -0.25em; /* To offset spacing. May vary by font */
		}
		& > * {

			.inline-block();
			vertical-align: bottom;
			max-width: 97.3%; // varies according to context
		}
}

[data-hlayout~="leftcenterright"] {

}
[data-hlayout~="leftright"] {

}
[data-hlayout~="weight-left"] {}
[data-hlayout~="weight-center"] {}
[data-hlayout~="keeplayout"] {}

[data-hlayout~="left"] {
	float: left;
	> * {
		text-align: left;
	}
}
[data-hlayout~="center"] {
	> * {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
}
[data-hlayout~="right"] {

	float: right;
	text-align: right;

	> * {

		text-align: right;
		// float: right;
	}
	> div,
	> p {
		clear: both;
	}
}

[data-vlayout~="last-bottom"] {

	position: relative;
	padding-bottom: 6.8em;

	& > :last-child {

		position: absolute;
		bottom: 0;
	}	
}

//  ATOMIC ADJUSTMENTS/ Helper classes
//  ___________________

.baselinetoH2 {
	&:before {
		content: "M";
		.font-size( @fontsize-MS/10 );
		display: inline-block;
		width: 0;
		visibility: hidden;
		opacity: 0;
	}
}

.no-break {
	white-space: nowrap;
}
//TODO: clean avd remove in favour of the .inline-block() mixin
.inlineBlock {

	display: inline-block;
	*display: inline;
	zoom:1;
}
.inline-block {

	display: inline-block;
	*display: inline;
	zoom:1;
}

/*
 * Hide visually and from screen readers:
 * http://juicystudio.com/article/screen-readers-display-none.php
 */

.hidden {
    display: none !important;
    visibility: hidden;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
    visibility: hidden;
}