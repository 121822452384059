.popover-menu{
  width:320px;
  position: absolute;
  z-index: 9;
  margin-top:60px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  text-align: left;


  &:after, &:before {
    z-index:999;
    top: -18px;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    transform: translate(0, -12.5%) scale(1, 1.25);
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: @hallon-white;
    border-width: 10px;
    margin-left: -10px;
  }
  &:before {
    top: -20px;
    border-color: rgba(217, 218, 218, 0);
    border-bottom-color: @hallon-light-grey;
    border-width: 11px;
    margin-left: -11px;
  }

  .popover-menu__content{
    position:relative;
    background: @hallon-white;
    border:1px solid @hallon-light-grey;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  }

  .popover-menu__link{
    position: relative;
    padding:20px;
    display: block;
    width:100%;
    font-family: @hallon-frutiger-55-roman;
    color: @hallon-dark-red;
    font-size: 14px;

    &:hover{
      color: @hallon-red-hover;
    }

    i{
      vertical-align: middle;
      padding-left:10px;
    }
  }
}
