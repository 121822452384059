.HardwareCompanyPage{

  .hardwarepage__content{

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      height: -moz-calc(~"100vh - 140px");
      height: -webkit-calc(~"100vh - 140px");
      height: -o-calc(~"100vh - 140px");
      height: calc(~"100vh - 140px");
      //footer 80px + header 60px = 140px
      position: relative;
      overflow: hidden;
    }

    .red-circle{
      display: none;
      @media only screen and (min-width: @tablet-breakpoint-hallon) {
        display: block;
        margin-bottom:60px;
      }
    }

    .red-circle--left{
      float:left;
      max-width: 30%;
    }

    .hardware-company-product{
      width:100%;
      position: relative;
      display: block;

      @media only screen and (min-width: @tablet-breakpoint-hallon) {
        display: block;
        height: 85%;
        left: 380px;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
      }

      @media only screen and (min-width: @desktop-special-breakpoint) {
        display: block;
        height: 50%;
        left: 380px;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
      }

    }

    .red-circle--right{
      float:right;
      max-width: 30%;
    }


  }


  .hardwarepage__footer{

    position: relative;
    display: block;

    -webkit-box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.1);
    box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.1);


    background: @hallon-white;
    width:100%;
    z-index: 9998;
    float:left;

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      height:80px;
      display: block;
      float:none;
      bottom:0px;

    }

  }

  .mobile-subscription, .footer-presection{
    display: none;
  }



}