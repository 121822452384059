/// <reference path="../framework.less" />

.generic-content {
	min-height: 630px;
	display: flex;
	flex-direction: row-reverse;

	@media @breakpointquery-2018-phablet {
		min-height: 550px;
	}

	&__content-wrapper {
		align-self: center;
		max-width: 400px;
		width: 100%;

		@media @breakpointquery-2018-phablet {
			max-width: none;
			padding: 0;
			display: flex;
			flex-direction: column;
			flex: 1 0 auto;
		}
	}

	&__title {
		font-family: @font-2018-black;
		text-transform: none;
		color: @color-2018-white;
		margin: 0 0 20px 0;
		padding: 0;
		font-size: @font-2018-component-header;
		line-height: @font-2018-component-header-line-height;

		@media @breakpointquery-2018-tablet {
			padding: 0;
			text-align: center;
			font-size: @font-2018-component-header-mobile;
			line-height: @font-2018-component-header-line-height-mobile;
		}

		&--enhanced {
			font-size: @font-2018-component-header-enhanced;
			line-height: @font-2018-component-header-line-height-enhanced;

			@media @breakpointquery-2018-tablet {
				font-size: @font-2018-component-header-enhanced-mobile;
				line-height: @font-2018-component-header-line-height-enhanced-mobile;
			}
		}
	}

	&__text {
		color: @color-2018-white;
		font-size: 18px;
		line-height: 27px;
		margin: 0;
		padding: 0;
		word-wrap: break-word;
		overflow-wrap: break-word;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		hyphens: auto;

		@media @breakpointquery-2018-phablet {
			font-size: 18px;
			line-height: 27px;
			padding: 0;
			flex: 1 1 auto;
		}

		p {
			font-size: 18px;
			line-height: 27px;
			font-family: @font-2018-roman;

			@media @breakpointquery-2018-phablet {
				font-size: 18px;
				line-height: 27px;
			}
		}
	}

	&__button {
		margin-top: 40px;

		@media @breakpointquery-2018-phablet {
			align-self: center;
		}
	}
}
