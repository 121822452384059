﻿.popup {
    background: url(/UI/build/images/title_semitransparent.png);
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .popup-container {
        padding: 20px;
        .border-radius(4px);
        background: white;
        position: fixed;
        top: 50%;
        left: 50%;
        width: 300px;
        margin-left: -150px;

        h2, h3 {
            margin-top: 0;
        }
    }

    .popup-close {
        margin-top: 20px;
        width: auto;
        display: block;
    }
}
