.overlay {
    display:none;
    position:static;
    -ms-overflow-style: scrollbar;
    top:0;
    height:100%;
    width:100%;
    bottom: 0;
    background: #fff;
    // overflow:auto;
    z-index:99;
}

.overlay-active {
    display: block;
}

    
.overlay__top-part {
    position: absolute;
    text-align: center;
    top: 0;
    height: 80px;
    width: 100%;
    background-color: #FFFFFF;	
    box-shadow: 0 0 6px 1px rgba(161,161,161,0.5);
    // margin-bottom: 40px;
}
    
.overlay__top-part-text {
    display: inline-block;
    color: #8F1840;
    text-align: center;
    margin-top: 20px;
    vertical-align: baseline;
    cursor: pointer;

    @media @breakpointquery-2018-small-desktop {
        display: none;
    }
}


.overlay__top-part-toggle-icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    cursor: pointer;
    pointer-events: none;

    @media @breakpointquery-2018-small-desktop {
        display: inline-block;
    }

    &.active {
        z-index: 2000;
        margin-top: -14px;
        @media @breakpointquery-2018-small-desktop {
            display: inline-block;
        }
        .header__mobile-toggle-line:nth-of-type(1) {
            transform: rotate(-45deg) translateY(0) translateX(0);
        }

        .header__mobile-toggle-line:nth-of-type(2) {
            opacity: 0;
        }

        .header__mobile-toggle-line:nth-of-type(3) {
            transform: rotate(45deg) translateY(0em) translateX(0em);
        }
    }
}

.overlay__top-part-close {
    margin-left: 20px;
    display: inline-block;
    color: @color-2018-hallon-red;
    text-align: center;
    margin-top: 30px;
    vertical-align: baseline;
    cursor: pointer;

    @media @breakpointquery-2018-small-desktop {
        display: none;
    }
}

.overlay__top-part-mobile {
    flex-wrap: wrap;
	max-width: 1300px;
	display: flex;
	width: 100%;
    
    @media @breakpointquery-2018-desktop-full {
		width: auto;
	}	
}

.overlay__top-part-close-text {
    font-size: 14px;
    color: black;
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    white-space: nowrap;
}

.overlay__top-part-mobile--visible {
	display: flex;
	width: 100%;
	box-shadow: 0 2px 4px 0 rgba(175, 175, 175, 0.5);

	@media @breakpointquery-2018-desktop-full {
		display: none;
	}
}

.overlay__top-part-icon {
    vertical-align: middle;
    padding-left: 10px;
}

.overlay__wrap {
	color:#110a0a;
	text-align:center;
    margin: 0 auto;
    height: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    right: 0;
    left: 0;
    margin-top: 20px;
}

.overlay__nav {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 406px;
    margin-top: 10px;
    
    @media @breakpointquery-2018-small-desktop {
        max-width: 352px;
    }
}

.overlay__nav:last-child {
    margin-top: 50px;
}

.overlay__nav li {
    display: block; 
}

.overlay__menu-list {
    overflow: hidden;
    display: none;
    line-height: 40px;
}

.overlay__menu-list-item:not(:last-child) {
    border-bottom: 1px solid @color-2018-text-grey;
}

.overlay__menu-list-item:first-child {
    border-top: 1px solid @color-2018-text-grey;
}

.overlay__menu-list-item-link {
    font-family: @font-2018-light;
    margin-left: 22px;
}

.overlay__menu-item {
    text-align: left;
	vertical-align:top;
    position:relative;
    line-height: 30px;
}

.overlay__nav div:not(:last-child) {
    border-bottom: 1px solid @color-2018-text-grey;
}

.overlay__menu-toggle-link {
    width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
	display: inline-block;
    padding: 10px 0 0;
    cursor: pointer;
    text-decoration: none;
	transition-property:all .2s linear 0s;
	-moz-transition:all .2s linear 0s;
	-webkit-transition:all .2s linear 0s;
	-o-transition:all .2s linear 0s;
}

.overlay__menu-toggle-link,
.overlay__menu-list-item-link {
    font-size: @font-2018-header-overlay;
}


.js-toggle {
    border-radius: 0.15em;
    transition: background .2s ease;  
}

.overlay__main-menu-item {
    font-size: 12px;
    color: black;
}

.overlay__menu-toggle-icon {
    position: absolute;
    padding: 10px;
    right: 0;
    top: 4px;

    &.visible {
        padding: 10px;
        top: 15px; 
    }
}

.overlay__wrap ul.overlay__nav ul li {
	display:block;
	font-size:13px;
	width:100%;
	color:#e9e9e9;
}

.overlay__menu-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay__menu-buttons-wrapper {
    margin-right: 10px;
}

.overlay__menu-button {
    border-radius: 50px;
    padding: 10px 0;
    box-sizing: border-box;
    font-size: @font-2018-normal;
    font-family: @font-2018-bold;
    display: block;
    text-align: center;
    width: 135px;
    transition: all .2s linear;
    cursor: pointer;

}

.overlay__menu-button {
    color: black;
    background-color: #F6EBEF;
    display: block;
}

.overlay__menu-button:active {
    background-color: #891845;
    color: #fff;
}

.overlay__menu-button--active {
    background-color: #891845;
    color: #fff; 
    text-decoration: underline;
    text-underline-position: under; 
}

.overlay__menu-button:hover {
    background-color: #891845;
    color: #fff; 
    text-decoration: underline;
    text-underline-position: under; 
}

.overlay__menu-nav-list {
    margin-bottom: 30px;

    @media @breakpointquery-2018-small-desktop {
        padding: 10px 0 0 0;
    }
}

.overlay__menu-nav-list--company {
    margin-left: 50px;

    li {
        margin-left: 10px;
    }
}

.megamenu__main-menu-nav-list-item {
    margin-right: 40px;
}

.overlay__menu-toggle-link {
   font-family: @font-2018-roman;
}

.megamenu__main-menu-nav-list-item-link,
.overlay__menu-nav-list-item-link-right {
    color: #8F1840;
    font-size: 18px;
    position: relative;
    text-align: center;
}

.overlay__menu-nav-list-item-link-right {
    display: block;
}

.megamenu__main-menu-nav-list-item-link:hover {
    border-bottom: 1px solid #8F1840;
    padding-bottom: 4px;
    text-underline-position: under;  
}
