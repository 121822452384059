/// <reference path="../framework.less" />


.always-at-hallon {

	&__header {
		flex-basis: 100%;
		font-size: @font-2018-component-header;
		line-height: @font-2018-component-header-line-height;
		font-family: @font-2018-black;
		color: @color-2018-hallon-red;
		text-align: center;
		margin-bottom: 50px;
		text-transform: none;

		@media @breakpointquery-2018-tablet {
			font-size: @font-2018-component-header-mobile;
			line-height: @font-2018-component-header-line-height-mobile;
			margin: 0 40px 40px 40px;
		}

		&--enhanced {
			font-size: @font-2018-component-header-enhanced;
			line-height: @font-2018-component-header-line-height-enhanced;

			@media @breakpointquery-2018-tablet {
				font-size: @font-2018-component-header-enhanced-mobile;
				line-height: @font-2018-component-header-line-height-enhanced-mobile;
			}
		}
	}

	&__column-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		margin-left: -50px;
		width: calc(~"100% + 50px");
		justify-content: center;

		@media @breakpointquery-2018-tablet {
			width: 100%;
			flex-wrap: wrap;
			margin-left: 0;
		}
	}


	&__column {
		flex: 0 1 25%;
		max-width: 262px;
		margin-bottom: 50px;
		text-align: center;
		flex-direction: column;
		overflow: hidden;
		padding-left: 50px;

		@media @breakpointquery-2018-tablet {
			flex: 1 1 100%;
			max-width: 100%;
			padding-left: 0;
		}
	}

	&__column-header {
		font-family: @font-2018-bold;
		font-size: 22px;
		line-height: 26px;
		color: @color-2018-hallon-red;
		text-transform: none;
	}

	&__column-text {
		font-family: @font-2018-light;
		font-size: 18px;
		line-height: 27px;
		color: @color-2018-hallon-red;
		flex: auto;

		a {
			color: @color-2018-hallon-red;
			text-decoration: underline;

			&:hover {
				color: @color-2018-hallon-red;
			}
		}

		p {
			font-family: @font-2018-light;
		}
	}

	&__link {
		color: @color-2018-linkblue;
		font-size: 22px;
		font-family: @font-2018-bold;
		margin-top: 20px;
		display: block;

		&:hover {
			color: @color-2018-linkblue;
			text-decoration: underline;
		}
	}
}