.press-article{

  .main-container__grid{
    background:@hallon-white;
  }

  article{
    h1{
      font-family: @hallon-frutiger-77-bc;
      font-size: @ftsize-hallon-48;
      color: @hallon-heavy-grey;
      text-align:left!important;
      text-transform: none;
    }

    .press-article__time{
      margin-bottom:1em;
      font-size: @ftsize-hallon-12;
    }
    .press-article__preamble{
      margin-bottom:1em;
      color:@hallon-heavy-grey;
      font-size: @ftsize-hallon-18;
      line-height: 1.5em;
    }

    p{
      font-size: @ftsize-hallon-14;
      font-family: @hallon-frutiger-55-roman;
      color: @hallon-heavy-grey;
      margin-bottom:1em;
    }

    p:last-of-type{
      margin-bottom:2em;
    }

  }

}