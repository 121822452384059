.module-link{
  display: table;
  cursor: pointer;
  h2{
    display: table-cell;
    vertical-align: middle;
    text-align:center;
    font-family: @hallon-frutiger-65-bold;
    font-size: @ftsize-hallon-24;
    text-transform: none;

    span{
      display: block;
    }

    @media only screen and (min-width: @phone-breakpoint-hallon){
      font-size: @ftsize-hallon-48;
    }
  }
}

.module-link--senior{
  background-color: @hallon-white;

  &:hover{
    background: @hallon-light-grey;
  }

  h2{
    color: @hallon-light-red;
  }
}

.module-link--student{
  background: @hallon-light-red;
  background: -webkit-linear-gradient(@linear-gradient-pink);
  background: -o-linear-gradient(@linear-gradient-pink);
  background: -moz-linear-gradient(@linear-gradient-pink);
  background: linear-gradient(@linear-gradient-pink);

  &:hover{
    background: @hallon-dark-red;
    background: -webkit-linear-gradient(@linear-gradient-red);
    background: -o-linear-gradient(@linear-gradient-red);
    background: -moz-linear-gradient(@linear-gradient-red);
    background: linear-gradient(@linear-gradient-red);
  }
  h2{
    color: @hallon-white;
  }
}
