﻿//  _____________________________________________________________________________
//
//  This is a collection of compound, atomic and molecular level UI elements
//  _____________________________________________________________________________
//

//
// Special H1 and H2 with different sizes on span
//
.tophead {

	p {

		text-align: center;
		.font-size(2.4);
		line-height: @lineheight-M;
		font-family: @tre-black-font;
		font-weight: lighter;
		color:#fff;

		@media only screen and (min-width: 700px) and (max-width: 1250px) { .font-size(4); }
		@media only screen and (min-width: 1250px) { .font-size(5); }

	}

	span {

		display: block;
		margin-bottom: 0.2em;
		padding: 0 3%;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);


		@media only screen and (min-width: 700px) {
			margin-bottom: 0;
		}

	}

	span + span {

		display:block;
		font-size: 1.4em; // The proportion of the bottom line compared to the top line 70/50
		// .hyphens();

	}

	@media only screen and (max-width: @hw-mobile-L) {

		p {

			.font-size(2);
		}
	}
}

//  COMPOUND ELEMENTS
//  _____________________________________________________________________________

[data-moduleblock] {

	position: relative;
	height:auto; // Defined according to context

}
// TODO: remove this 
[data-moduleblock~="imagearea"] {

	.content{

		height: 100%;
		background-color:transparent;
		background-size: cover;
	}

	h2 {

		position: relative;
		top: 15%;
		text-align: center;
		color: @tre-white-color;
		.font-size(4);

		@media only screen and (min-width: @phone-barrier) and (max-width: 1050px) {
			.font-size(2.9);
		}
	}
}

//
// TODO: Replace this with something more modular.
//
.boxes {

	.module {

		//background-color:#d3cbc8; //ie8 fallback color (makes border-color visible)
		//background-color:rgba(177,168,162,0.6);

		// .border-radius(0 0 4px 4px);
		// // padding-bottom:8px;
		// padding: 1em;
		// margin-bottom: @margin-M;

		h2 {
			margin:0 0 20px;
			text-align: center;
			color: #fff;
			.font-size(3.5);
		}

		h3 {
			display:none;
		}

		table.details {
			width:100%;
			margin-bottom:20px;
			border-spacing:0;

			td {
				.font-size(1.6);
				font-weight:bold;
				color:@tre-darkgray-color;
				padding:5px;
				border-bottom: 1px solid @tre-lightgrey-color;
				+ td {
					font-weight:normal;
				}
			}
		}
	}

}

.pricetag {

	position: relative;
	line-height: 1;
	color: #FFF;
	visibility: hidden;
	opacity: 0;


	&[aria-hidden="false"] {

		visibility: visible;
		opacity: 1;
	}

	@media only screen and (max-width: @hw-palm) {

		letter-spacing: 0;
	}

	.price-table-container {

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		// background-image: is defined in icon-svg/png64/png spritesheet
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: 100%;
	}
	.price-table {

		display: table;
		width: 100%;
		height: 100%;
		//TODO: remove below as background images are placed on the price-table-container node.gulp
		// background-image: is defined in icon-svg/png64/png spritesheet
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: 100%;
	}
	.price-container {

		display: table-cell;
		position: relative;
		// position: absolute;
		width: 100%;
		// height: 100%;
		height: auto;
		// top: 0;
		// left: 0;
		// padding-top: 15%;
		padding-top: 0;
		// padding-right: 6%;
		text-align: center;
		vertical-align: middle;
		background-size: 100% auto;


		// & > *:first-child {

		// 	margin-top: 15%;
		// }
	}

	// scales the width according to the containing element
	&[data-pricetag~="resize-to-width"]{

		width: 100%;
		height: auto;
	}

	// Size according to given values
	// The proportiions of the font sizes vary according to size category
	//
	&[data-pricetag~="size-L"]{

		// base font-size which is the same as the font-size for .originalprice
		// scaling other elements relative to this.

		@pt-basefont-size: @pricetag-size-L; //2.4

		font-size: unit( @pt-basefont-size, rem );
		font-family: "FrutigerLTW01-87ExtraBk";

		.originalprice{

		}
		.price {

			font-size: unit( 14/@pt-basefont-size, em );
			line-height: 0.9em;
			margin-top: -0.1em;
		}
		.unit{
			font-size: unit( 2.9/@pt-basefont-size, em );
            margin-left: 0.2em;
		}
	}
	&[data-pricetag~="size-M"]{

		// base font-size which is the same as the font-size for .originalprice
		// scaling other elements relative to this.

		@pt-basefont-size: @pricetag-size-M; //1.6

		font-size: unit( @pt-basefont-size, rem );
		font-family: @tre-black-font;

		.originalprice {

			font-family: @tre-standard-font;
		}
		.price {

			font-size: unit( 9.8/@pt-basefont-size, em );
			margin-top: -0.1em; // visual compensation
		}
		.unit {

			font-size: unit( 3.0/@pt-basefont-size, em );
			margin-bottom: @margin-XXXS;
		}
		.description {

			font-family: @tre-bold-font;
		}
		.duration {
			font-family: @tre-standard-font;
		}

	}
	&[data-pricetag~="size-MS"]{

		// base font-size which is the same as the font-size for .originalprice
		// scaling other elements relative to this.

		@pt-basefont-size: @pricetag-size-M; //1.6

		font-size: unit( @pt-basefont-size, rem );
		font-family: "FrutigerLTW01-87ExtraBk";
		letter-spacing: 0;

		.originalprice,
		.description {
			font-family: "FrutigerLTW01-55Roman"
		}
		.price {

			font-size: unit( 6.6/@pt-basefont-size, em );
		}
		.unit{

			font-size: unit( 2.7/@pt-basefont-size, em );
			font-family: @tre-standard-font;
			text-transform: uppercase;
			margin-bottom: @margin-XXXS;
		}

		.price-container {

			padding-top: 12%;
		}

		// visual adjustments according to context

		&:not( [data-pricetag~="bg-pricebubble-white"] ) {

			.price-container {

				padding-bottom: 1.1em;
			}

		}

	}

	&[data-pricetag~="size-S"]{

		// base font-size which is the same as the font-size for .originalprice
		// scaling other elements relative to this.

		@pt-basefont-size: @pricetag-size-XXXXS; // 8pt
		// base width 144px

		font-size: unit( @pt-basefont-size, rem );
		font-family: @tre-standard-font;
		letter-spacing: 1;

		.originalprice,
		.description {
			//8pt
			letter-spacing: 1;
		}

		.price {

			font-family: @tre-black-font;
			font-size: unit( 4.8/@pt-basefont-size, em );
		}
		.unit,
		.duration {

			font-size: unit( 1.8/@pt-basefont-size, em );
			letter-spacing: 1;
			// margin-bottom: @margin-XXXS;
		}

		.price-container {

			// padding-top: 15%;

			.description {
				margin-bottom: 0.2em;
				letter-spacing: 1;
			}
		}

	}

	.icon-pricebubble-white {

		visibility: hidden; // Used to keep the proportions of the shape
		width: 100%;
		height: auto;
		// width="446" height="480"
	}
	.nosvg-fallback-pricebubble {

		display: none;
	}
	&[data-pricetag~="bg-pricebubble-white"]{

		.price-container {

			padding-right: 0;
		}

		.icon-pricebubble-white {
            // no longer used, should be cleaned out
			visibility: hidden;
		}
	}

	&[data-pricetag~="color-black"]{
		color: #000;
	}
	&[data-pricetag~="color-dark"]{
		color: @tre-darkgray-color;
	}
	&[data-pricetag~="color-red"]{
		color: @tre-standard-color;
	}
    &[data-pricetag~="color-yellow"]{
		color: @color-yellow;

		.unit{
			color:@tre-white-color;
		}
		.originalprice{
			span{
				color:@tre-white-color;
			}
		}
		.description{
			color: @tre-white-color;
			span{
				color:@tre-white-color;
			}
		}
		.duration{
			color:@tre-white-color;
		}

	}
	.originalprice {

		display: inline-block;
		position: relative;
		top: -0.7em; // weighting this element more to the top instead of pushing the whole price-container with assigning a margin-bottom to this.

	}
	.description {
		// The description might be place above or below the other elements
		display: block;

		&:first-child {
			margin-top: -0.4em;
			margin-bottom: @margin-XXXS;
		}
	}
	.price {

		display: block;
		margin-top: -0.2em;
	}
	.description ~ .price {
		margin-top: 0;
	}
	.unit {

		display: block;
		letter-spacing: -1px;
	}
	.duration {

		display: block;
	}

	&.campaignhero-upfront {

		& > *:first-child {
			margin-top: 25%;
		}

	}

}

.surroundBox {

	.surroundimg {
		text-align: center;
		padding: 1em 0;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 0;
	}

	.pricetag {

		max-width: 140px;
		margin-left: auto;
		margin-right: auto;
	}
}

//  MOLECULAR ELEMENTS
//  _____________________________________________________________________________



.spotlightarea {

	padding: @margin-M unit(20/960*100, %);
}

[data-sidelabel] {

	position: absolute;
	top: unit( @buttonBorderWidth * -1, px );
	bottom: unit( @buttonBorderWidth * -1, px );
	// padding: inherit;
	// border: inherit;
	.border-radius( inherit );

	> * {

		position: absolute;
		top: 50%;
		left: 1em;
		margin-top: -0.5em;
	}

	&[data-sidelabel~="green"] {

		color: white;
		background-color: @color-olivegreen3;
		border-color: @color-olivegreen3;
	}

	&[data-sidelabel~="align-right"] {

		right: unit( @buttonBorderWidth * -1, px );
		.border-left-radius(0);
		border-left: 0;
		padding-right: 0;
	}
}


[data-listlayout] {
	
	@sidepadding: unit( @gutter-width/@gridsystem-width*100, %);

	li {

		margin: 0 @sidepadding @margin-S 0;
		text-align: left;

		&:last-child {
			margin-right: 0;
		}

	}

	&[data-listlayout~="weight-center"] {


		@media only screen and (max-width: @phone-barrier) {

			text-align: center;
	
			ul {

				.inline-block();
			}
			li {

				display: block;
				text-align: left;
				white-space: nowrap; //iOS was breaking lines even though it wasn't necessary.
				margin-bottom: @margin-XXXS;
			}

		}
	}
}

.checkicon( @c:@tre-purple-color ) {

	&:before {

		content: @ico-var-check;
		.ico-icon();
		position: relative;
		top: 0.15em;
		font-size: 2.3em;
		margin-right: 0.2em;
		color: @c;
	}
}

.checklistitem( @c-text: @tre-darkgray-color , @c-icon: @tre-purple-color ) {

	.font-size( 1.6 );
	font-family: @tre-black-font;
	text-transform: uppercase;
	color: @c-text;
	margin-bottom: @margin-XXXS;
	.checkicon( @c-icon );
	line-height: 1.6em;
}
.checklist-h( @c-text: @tre-darkgray-color , @c-icon: @tre-purple-color  ) {

	li {
		.checklistitem( @c-text, @c-icon );
		.inline-block();
	}
}
.checklist-v( @c-text: @tre-darkgray-color , @c-icon: @tre-purple-color  ) {

	li {

		.checklistitem( @c-text, @c-icon );
		display: block;
		margin-bottom: 0;
		
		&:before {

			margin-right: 0.3em;
		}
	}
}

.checklist-red--h {

	.checklist-h( @tre-darkgray-color , @tre-purple-color );
}
.checklist-red--v {

	.checklist-v( @tre-darkgray-color , @tre-purple-color );
}
.checklist-blue--h {

	.checklist-h( @tre-darkgray-color , @hw-check-blue );
}
.checklist-blue--v {

	.checklist-v( @tre-darkgray-color , @hw-check-blue );
}
.checklist-white--h {

	.checklist-h( #FFF , #FFF );
}
.checklist-white--v {

	.checklist-v( #FFF , #FFF );
}
// Context specific styles
.top-rubrik,
.whitebox {
	
	.checklist-red--h,
	.checklist-red--v,
	.checklist-blue--h,
	.checklist-blue--v,
	.checklist-white--h,
	.checklist-white--v {

		margin-bottom: @margin-M;
	}
}


//  ATOMIC ELEMENTS
//  _____________________________________________________________________________

[data-area] {
	
	overflow: hidden;

}

[data-area~="style-white"] {

	background-color: white;
}

[data-area~="style-gray"] {

	background-color: @color-gray95;
}

[data-area~="style-brown"] {

	color: white;
	background-color: @tre-darkgray-color;
	.headerparagraphColor(white);

	a {
		color: @color-violetred;
	}
}

[data-area~="style-red"] {

	background-color: @tre-error-color;
}

[data-area~="style-raspberry"] {

		background-color: @color-raspberry-dark;
}

[data-area~="style-rounded"],
[data-area~="style-rounded-M"] {

	.border-radius();
}
[data-area~="style-rounded-bottom"],
[data-area~="style-rounded-bottom-M"] {

	.border-bottom-radius();
}
[data-area~="transparent"] {

	position: relative;

	&[data-area~="style-raspberry"] {

		background-color: transparent;
		&:before {
		
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			.opacity(0.8);

			background-color: @color-raspberry-dark;
		}
		& > * {
			position: relative;
		}
	}
}
[data-area~="blurred"] {

	overflow: visible;

	&:before{
		.blur(1);
	}
}

[data-row~="equalheight"] {

	// [data-box],
	// [data-area] {
		
	// 	&[data-column] {

	// 		border-right: 10px solid transparent;
	// 		border-left: 10px solid transparent;
	// 		background-clip: padding-box;

	// 		padding-left: 0;
	// 		padding-right: 0;
	// 	}
	// }

	[data-column][data-area~="style-rounded"] {

		@radius: 4px;
		.border-radius-2( unit(@gutter-width/2 + @radius, px); @radius; );
	}
	[data-column][data-area~="style-rounded-bottom"],
	[data-column][data-area~="style-rounded-bottom-M"] {

		@radius: 4px;
		.border-bottom-radius-2( unit(@gutter-width/2 + @radius, px); @radius; );
	}
	[data-column][data-area~="style-rounded-top"],
	[data-column][data-area~="style-rounded-top-M"] {

		@radius: 4px;
		.border-top-radius-2( unit(@gutter-width/2 + @radius, px); @radius; );
	}

	[data-area~="type-image"] {

		// Absolutely positioning an element within a table-cell doesn't work in IE as they are following the W3C
		// recomendation not to do so. 
		// See related SO discussion: http://stackoverflow.com/questions/10902743/positioning-context-on-table-cell-element-in-firefox?lq=1
		// And: http://stackoverflow.com/questions/10900433/absolute-positioning-inside-a-table-cell-in-ie

		&[data-column~="1/2"] {

			background-repeat: no-repeat;
			// background-size: 160% auto; Specific for the image. Place in seperate place with specific class
			background-position: center top;

		}
		// TODO: continue for other column sizes
	}
}

[data-area~="padded"],
[data-box~="padded"]
{

	@vBoxpadding: 20;
	@hBoxpadding: @gutter-width; // 20
	padding: unit(@vBoxpadding, px) unit( @hBoxpadding/960*100, %);
	overflow: hidden;

	@media only screen and (max-width: @hw-palm) {
		@hBoxpadding: @column-width - @gutter-width; // 60 - 20
		padding: unit(@vBoxpadding, px) unit( @hBoxpadding/960*100, %);
	}

	@media only screen and (max-width: @hw-mobile-L) {
		@hBoxpadding: @column-width; // 60
		padding: unit(@vBoxpadding, px) unit( @hBoxpadding/960*100, %);
	}

}
[data-column~="1/2"] {

	&[data-box~="padded"],
	>[data-box~="padded"]
	{

		@vBoxpadding: 20;
		@hBoxpadding: @gutter-width; // 20
		padding: unit(@vBoxpadding, px) unit( @hBoxpadding/480*100, %);

		// half-width column becomes 100% wide. Reset padding to 
		@media only screen and (max-width: @hw-tablet) {
			@hBoxpadding: @gutter-width; // 20
			padding: unit(@vBoxpadding, px) unit( @hBoxpadding/960*100, %);
		}

		@media only screen and (max-width: @hw-palm) {
			@hBoxpadding: @column-width - @gutter-width; // 60 - 20
			padding: unit(@vBoxpadding, px) unit( @hBoxpadding/960*100, %);
		}

		@media only screen and (max-width: @hw-mobile-L) {
			@hBoxpadding: @column-width; // 60
			padding: unit(@vBoxpadding, px) unit( @hBoxpadding/960*100, %);
		}
	}
}

[data-box] {

	.border-radius(4px);
	.box-shadow();
}
[data-row~="regular"] {

	[data-box] {

		.border-radius(4px);
		.box-shadow(); // default: 0 1px 3px rgba(0,0,0,.3))
	}
}
[data-row~="equalheight"] {

	[data-column][data-box] {

		// background-image: url('/UI/build/images/dropshadow.png');
		// background-size: 104.2553% 100%;
		// background-position: -10px 0;
		// background-color: transparent;
		// border-bottom: 10px solid transparent;
		// background-repeat: no-repeat;
		// background-clip: inherit;
		// Create faux dropshadow at the bottom of the element
		// overflow-y: visible;
		// .vertical-three-colors(@startColor: #FFFFFF, @midColor: #FFFFFF, @colorStop: 99%, @endColor: #000000)
		#gradient.vertical-four-colors(#FFFFFF, #FFFFFF, 99%, rgba(0, 0, 0, 0.15), 99%, rgba(0, 0, 0, 0));
		box-shadow: none; // This will otherwise flow over the transparent border
		background-color: transparent;

		
		// So far it isn't possible to have rounded corners on this element

		// &:after {

		// 	content: "";
		// 	display: block;
		// 	width: 100%;
		// 	height: 6px;
		// 	#gradient.vertical(#000000, #ffffff);
		// }

		// > * {
			
		// 	// the box attribute can't be placed on columns which are in an equalheight row.
		// 	.border-radius(4px);
		// 	.box-shadow(); // default: 0 1px 3px rgba(0,0,0,.3))
		// }
	}
	[data-column][data-box~="style-rounded"] {

		@radius: 4px;
		.border-radius-2( unit(@gutter-width/2 + @radius, px); @radius; );
	}
	[data-column][data-box~="style-rounded-bottom"],
	[data-column][data-box~="style-rounded-bottom-M"] {

		@radius: 4px;
		.border-bottom-radius-2( unit(@gutter-width/2 + @radius, px); @radius; );
	}
	[data-column][data-box~="style-rounded-top"],
	[data-column][data-box~="style-rounded-top-M"] {

		@radius: 4px;
		.border-top-radius-2( unit(@gutter-width/2 + @radius, px); @radius; );
	}
}
.lte-IE9 {

	[data-row~="equalheight"] {

		[data-column][data-box~="style-white"] {

			filter: none; // IE downwards don't support gradients with stops and don't respect background-clip.
			background-color: white;
		}
		[data-column][data-box~="style-gray"] {

			filter: none; // IE downwards don't support gradients with stops and don't respect background-clip.
			background-color: @color-gray95;
		}
	}
}

// [data-box~="style-shadow"] {

// }
// [data-box~="style-round"] {

// }
[data-box~="style-white"] {

	background-color: white;
}
[data-box~="style-gray"] {

	background-color: @color-gray95;
}
[data-box] {
	
	.titlearea {
		
		padding-top: 1.5em;
		padding-bottom: 1.5em;
		text-align: center;
  		overflow: auto;

  		&:after,
		h2 {
			 display: inline-block;
  			vertical-align: middle;
		}
		&:after {
			content: "";
			height: 100%;
			margin-left: -0.25em;
		}
		h2 {
			max-width: 99%;
		}
	}

	.contentarea {

		.buttonarea {
			
			position: absolute;
			bottom: 0;
			left:0;
			width: 100%;
			text-align: center;

			> * {
				margin-right: auto;
				margin-left: auto;
			}
		}
	}
}
[data-box~="feat-button-M"] {

	position: relative;
	padding-bottom: 6.4em; // This doesn't work in IE for equalheight rows

	.buttonarea {

		padding-top: 2em;
		padding-bottom: 2em;
	}
	
}

i[class^="icon-"] {
	.ico-icon();

	&:first-child {

		margin-right: 0.4em;
	}
	&.right {

		margin-right: 0;
		margin-left: 0.4em;
	}
}

.horizontal-rule {

	// border-bottom: 1px solid @color-gray91;
	color: @color-gray91;
	margin: 0 0 @margin-M;

}

















