.hallon-slider {
    display: block;
    width: 100%;
    position: relative;
    margin: 20px 0;


    .hallon-slider__bubble-container {
        display: block;
        height: 30px;
        list-style: outside none none;
        margin: 30px 30px;
        padding: 0;
        position: relative;
    }

    .hallon-slider__bubble {
        position: absolute;
        top: -70px;
        margin-right: -50%;
        transition: all 200ms;
    }


    .hallon-slider__background {
        display: block;
        width: 100%;
        height: 60px;
        position: relative;
        border: 2px solid #fff;
        border-radius: 30px;
        box-sizing: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .hallon-slider__scrubber {
        display: block;
        width: 60px;
        height: 60px;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        box-sizing: border-box;
        -ms-touch-action: none;
    }

    .hallon-slider__scrubber-graphic {
        display: block;
        width: 50px;
        height: 50px;
        position: relative;
        top: 3px;
        left: 4px;
        background-color: #fff;
        border-radius: 30px;
        box-sizing: border-box;
    }

    .hallon-slider__labels {
        display: block;
        height: 30px;
        position: relative;
        margin: 5px 30px;
        padding: 0;
        list-style: none;
    }

    .hallon-slider__label {
        display: block;
        width: 100px;
        color: @hallon-white;
        position: absolute;
        left: 0;
        font-family: sans-serif;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
    }

    .hallon-slider__label--campaign {
        color: @hallon-yellow;
    }

    .hallon-slider__label--selected {
        font-weight: 800;
    }


    .hallon-slider__line {
        display: block;
        width: 2px;
        height: 150%;
        position: absolute;
        top: -25%;
        left: 51%;
        background-color: #fff;
        margin-right: -50%;
    }
}

.hallon-slider.hallon-slider--product {

    .hallon-slider__labels {
        height: 20px;


        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            height: 30px;
        }
    }
}
/*
.hallon-slider.hallon-slider--list-desktop{
  margin:0px;
  margin-top:70px;

  .hallon-slider__background{
    height:30px;
  }
  .hallon-slider__scrubber{
    height:30px;
    width:30px;
  }

  .hallon-slider__scrubber-graphic{
    height:25px;
    width:25px;
    top:1px;
    left:1px;
  }
  .hallon-slider__bubble-container{
    margin:15px;
    margin-bottom: 20px;
  }
}
*/
.hallon-slider.hallon-slider--hw {

    .hallon-slider__bubble-container {
        display: none;
    }
}
