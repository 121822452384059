/// <reference path="../framework.less" />

.product-filter {
  &__header {
    margin-bottom: 35px;
    font-size: 48px;
    line-height: 57px;
    color: @color-2018-hallon-red;
    text-transform: none;
    font-family: @font-2018-black;
    max-width: 660px;

    @media @breakpointquery-2018-phablet {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__subheader {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 28px;
    color: @color-2018-hallon-red;
    text-transform: none;
    font-family: @font-2018-bold;
    width: 100%;
    max-width: 660px;

    @media @breakpointquery-2018-phablet {
      font-size: 21px;
      line-height: 24px;
    }
  }

  &__subheader-text {
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 21px;
    font-family: @font-2018-light;

    @media @breakpointquery-2018-phablet {
      margin-bottom: 20px;
    }
  }

  &__options-wrapper {
    margin-top: 25px;

    @media @breakpointquery-2018-phablet {
      display: flex;
    }
  }

  &__wysiwyg {
    width: 100%;
    max-width: 660px;
    display: inline-block;
  }

  &__filter-option {
    background: @color-2018-white;
    color: @color-2018-hallon-red;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50px;
    outline: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 auto 0 auto;
    padding: 15px 55px;
    z-index: 1;
    position: relative;
    transition: all 0.2s linear;

    &:first-child {
      margin-right: 15px;

      @media @breakpointquery-2018-phablet {
        margin-right: 10px;
      }
    }

    &:hover,
    &.active {
      color: @color-2018-white;
      background: @color-2018-hallon-red;
    }

    @media @breakpointquery-2018-phablet {
      font-size: 14px;
      padding: 15px 0;
      flex: 1;
      margin-top: 0;
    }
  }

  &__tabs-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 20px 0;

    input {
      display: none;
    }

    label {
      display: inline-block;
      padding: 5px 20px 10px 10px;
      font-weight: 600;
      text-align: center;
      color: @color-2018-text-black;
      border: none;
      background: #dcdcdc;
      width: 100%;
      margin:0;
      transition: background 0.1s linear, color 0.1s linear;

      &:hover{
          background: @color-2018-hallon-red;
          color: @color-2018-white;
      }
    }

    .hallon-checkbox__box {
      display: none;
    }

    .hallon-checkbox--checked {
      background: @color-2018-hallon-red;
      color: @color-2018-white;
    }
  }

  &__student-wrapper,
  &__senior-wrapper,
  &__youth-wrapper,
  &__ordinary-wrapper {
    margin-top: 0;
    max-width: 25%;
    flex:1 1 25%;
    display:flex;
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transition: 0.2s transform linear;
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;

    @media @breakpointquery-2018-phablet {
      margin-top: 0;
      max-width: 50%;
      flex:1 1 50%;
    }

    &.hide {
      transform: scale3d(1, 0, 0);
    }
  }
}
