﻿.select2-container {
	margin: 0;
	position: relative;
	display: inline-block;
	vertical-align: middle;
}

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
	box-sizing: border-box;
}

.select2-container .select2-choice {
	display: block;
	height: 26px;
	padding: 0 0 0 8px;
	overflow: hidden;
	position: relative;
	border: 1px solid #aaa;
	white-space: nowrap;
	line-height: 26px;
	color: #444;
	text-decoration: none;
	border-radius: 4px;
	background-clip: padding-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: #fff;
	background-image: -moz-linear-gradient(center bottom, #eee 0, #fff 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#eeeeee", GradientType=0);
	background-image: linear-gradient(0deg, #eee, #fff 50%);
}

html[dir=rtl] .select2-container .select2-choice {
	padding: 0 8px 0 0;
}

.select2-container.select2-drop-above .select2-choice {
	border-bottom-color: #aaa;
	border-radius: 0 0 4px 4px;
	background-image: -moz-linear-gradient(center bottom, #eee 0, #fff 90%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#eeeeee", GradientType=0);
	background-image: linear-gradient(180deg, #eee, #fff 90%);
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
	margin-right: 42px;
}

.select2-container .select2-choice > .select2-chosen {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	float: none;
	width: auto;
}

html[dir=rtl] .select2-container .select2-choice > .select2-chosen {
	margin-left: 26px;
	margin-right: 0;
}

.select2-container .select2-choice abbr {
	display: none;
	width: 12px;
	height: 12px;
	position: absolute;
	right: 24px;
	top: 8px;
	font-size: 1px;
	text-decoration: none;
	border: 0;
	background: url(select2.png) 100% 0 no-repeat;
	cursor: pointer;
	outline: 0;
}

.select2-container.select2-allowclear .select2-choice abbr {
	display: inline-block;
}

.select2-container .select2-choice abbr:hover {
	background-position: right -11px;
	cursor: pointer;
}

.select2-drop-mask {
	border: 0;
	margin: 0;
	padding: 0;
	position: fixed;
	left: 0;
	top: 0;
	min-height: 100%;
	min-width: 100%;
	height: auto;
	width: auto;
	opacity: 0;
	z-index: 1;
	background-color: #fff;
	filter: alpha(opacity=0);
}

.select2-drop {
	width: 100%;
	margin-top: -1px;
	position: absolute;
	z-index: 2;
	top: 100%;
	background: #fff;
	color: #000;
	border: 1px solid #aaa;
	border-top: 0;
	border-radius: 0 0 4px 4px;
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}

.select2-drop.select2-drop-above {
	margin-top: 1px;
	border-top: 1px solid #aaa;
	border-bottom: 0;
	border-radius: 4px 4px 0 0;
	box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
}

.select2-drop-active {
	border: 1px solid #5897fb;
	border-top: none;
}

.select2-drop.select2-drop-above.select2-drop-active {
	border-top: 1px solid #5897fb;
}

.select2-drop-auto-width {
	border-top: 1px solid #aaa;
	width: auto;
}

.select2-container .select2-choice .select2-arrow {
	display: inline-block;
	width: 18px;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	border-left: 1px solid #aaa;
	border-radius: 0 4px 4px 0;
	background-clip: padding-box;
	background: #ccc;
	background-image: -moz-linear-gradient(center bottom, #ccc 0, #eee 60%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eeeeee", endColorstr="#cccccc", GradientType=0);
	background-image: linear-gradient(0deg, #ccc, #eee 60%);
}

html[dir=rtl] .select2-container .select2-choice .select2-arrow {
	left: 0;
	right: auto;
	border-left: none;
	border-right: 1px solid #aaa;
	border-radius: 4px 0 0 4px;
}

.select2-container .select2-choice .select2-arrow b {
	display: block;
	width: 100%;
	height: 100%;
	background: url(select2.png) no-repeat 0 1px;
}

html[dir=rtl] .select2-container .select2-choice .select2-arrow b {
	background-position: 2px 1px;
}

.select2-search {
	display: inline-block;
	width: 100%;
	min-height: 26px;
	margin: 0;
	padding: 4px 4px 0;
	position: relative;
	z-index: 3;
	white-space: nowrap;
}

.select2-search input {
	width: 100%;
	height: auto !important;
	min-height: 26px;
	padding: 4px 20px 4px 5px;
	margin: 0;
	outline: 0;
	font-family: sans-serif;
	font-size: 1em;
	border: 1px solid #aaa;
	border-radius: 0;
	box-shadow: none;
	background: #fff url(select2.png) no-repeat 100% -22px;
	background: url(select2.png) no-repeat 100% -22px, linear-gradient(180deg, #fff 85%, #eee 99%) 0 0;
}

html[dir=rtl] .select2-search input {
	padding: 4px 5px 4px 20px;
	background: #fff url(select2.png) no-repeat -37px -22px;
	background: url(select2.png) no-repeat -37px -22px, linear-gradient(180deg, #fff 85%, #eee 99%) 0 0;
}

.select2-search input.select2-active {
	background: #fff url(select2-spinner.gif) no-repeat 100%;
	background: url(select2-spinner.gif) no-repeat 100%, linear-gradient(180deg, #fff 85%, #eee 99%) 0 0;
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
	border: 1px solid #5897fb;
	outline: none;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.select2-dropdown-open .select2-choice {
	border-bottom-color: transparent;
	box-shadow: inset 0 1px 0 #fff;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	background-color: #eee;
	background-image: -moz-linear-gradient(center bottom, #fff 0, #eee 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eeeeee", endColorstr="#ffffff", GradientType=0);
	background-image: linear-gradient(0deg, #fff, #eee 50%);
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
	border: 1px solid #5897fb;
	border-top-color: transparent;
	background-image: -moz-linear-gradient(center top, #fff 0, #eee 50%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eeeeee", endColorstr="#ffffff", GradientType=0);
	background-image: linear-gradient(180deg, #fff, #eee 50%);
}

.select2-dropdown-open .select2-choice .select2-arrow {
	background: transparent;
	border-left: none;
	filter: none;
}

html[dir=rtl] .select2-dropdown-open .select2-choice .select2-arrow {
	border-right: none;
}

.select2-dropdown-open .select2-choice .select2-arrow b {
	background-position: -18px 1px;
}

html[dir=rtl] .select2-dropdown-open .select2-choice .select2-arrow b {
	background-position: -16px 1px;
}

.select2-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.select2-results {
	max-height: 200px;
	padding: 0 0 0 4px;
	margin: 4px 4px 4px 0;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html[dir=rtl] .select2-results {
	padding: 0 4px 0 0;
	margin: 4px 0 4px 4px;
}

.select2-results ul.select2-result-sub {
	margin: 0;
	padding-left: 0;
}

.select2-results li {
	list-style: none;
	display: list-item;
	background-image: none;
}

.select2-results li.select2-result-with-children > .select2-result-label {
	font-weight: 700;
}

.select2-results .select2-result-label {
	padding: 3px 7px 4px;
	margin: 0;
	cursor: pointer;
	min-height: 1em;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.select2-results-dept-1 .select2-result-label {
	padding-left: 20px;
}

.select2-results-dept-2 .select2-result-label {
	padding-left: 40px;
}

.select2-results-dept-3 .select2-result-label {
	padding-left: 60px;
}

.select2-results-dept-4 .select2-result-label {
	padding-left: 80px;
}

.select2-results-dept-5 .select2-result-label {
	padding-left: 100px;
}

.select2-results-dept-6 .select2-result-label {
	padding-left: 110px;
}

.select2-results-dept-7 .select2-result-label {
	padding-left: 120px;
}

.select2-results .select2-highlighted {
	background: #3875d7;
	color: #fff;
}

.select2-results li em {
	background: #feffde;
	font-style: normal;
}

.select2-results .select2-highlighted em {
	background: transparent;
}

.select2-results .select2-highlighted ul {
	background: #fff;
	color: #000;
}

.select2-results .select2-ajax-error,
.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
	background: #f4f4f4;
	display: list-item;
	padding-left: 5px;
}

.select2-results .select2-disabled.select2-highlighted {
	color: #666;
	background: #f4f4f4;
	display: list-item;
	cursor: default;
}

.select2-results .select2-disabled {
	background: #f4f4f4;
	display: list-item;
	cursor: default;
}

.select2-results .select2-selected {
	display: none;
}

.select2-more-results.select2-active {
	background: #f4f4f4 url(select2-spinner.gif) no-repeat 100%;
}

.select2-results .select2-ajax-error {
	background: rgba(255, 50, 50, 0.2);
}

.select2-more-results {
	background: #f4f4f4;
	display: list-item;
}

.select2-container.select2-container-disabled .select2-choice {
	background-color: #f4f4f4;
	background-image: none;
	border: 1px solid #ddd;
	cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
	background-color: #f4f4f4;
	background-image: none;
	border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
	display: none;
}

.select2-container-multi .select2-choices {
	height: auto !important;
	height: 1%;
	margin: 0;
	padding: 0 5px 0 0;
	position: relative;
	border: 1px solid #aaa;
	cursor: text;
	overflow: hidden;
	background-color: #fff;
	background-image: linear-gradient(180deg, #eee 1%, #fff 15%);
}

html[dir=rtl] .select2-container-multi .select2-choices {
	padding: 0 0 0 5px;
}

.select2-locked {
	padding: 3px 5px !important;
}

.select2-container-multi .select2-choices {
	min-height: 26px;
}

.select2-container-multi.select2-container-active .select2-choices {
	border: 1px solid #5897fb;
	outline: none;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.select2-container-multi .select2-choices li {
	float: left;
	list-style: none;
}

html[dir=rtl] .select2-container-multi .select2-choices li {
	float: right;
}

.select2-container-multi .select2-choices .select2-search-field {
	margin: 0;
	padding: 0;
	white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
	padding: 5px;
	margin: 1px 0;
	font-family: sans-serif;
	font-size: 100%;
	color: #666;
	outline: 0;
	border: 0;
	box-shadow: none;
	background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
	background: #fff url(select2-spinner.gif) no-repeat 100% !important;
}

.select2-default {
	color: #999 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
	padding: 3px 5px 3px 18px;
	margin: 3px 0 3px 5px;
	position: relative;
	line-height: 13px;
	color: #333;
	cursor: default;
	border: 1px solid #aaa;
	border-radius: 3px;
	box-shadow: inset 0 0 2px #fff, 0 1px 0 rgba(0, 0, 0, 0.05);
	background-clip: padding-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: #e4e4e4;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eeeeee", endColorstr="#f4f4f4", GradientType=0);
	background-image: linear-gradient(180deg, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee);
}

html[dir=rtl] .select2-container-multi .select2-choices .select2-search-choice {
	margin: 3px 5px 3px 0;
	padding: 3px 18px 3px 5px;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
	cursor: default;
}

.select2-container-multi .select2-choices .select2-search-choice-focus {
	background: #d4d4d4;
}

.select2-search-choice-close {
	display: block;
	width: 12px;
	height: 13px;
	position: absolute;
	right: 3px;
	top: 4px;
	font-size: 1px;
	outline: none;
	background: url(select2.png) 100% 0 no-repeat;
}

html[dir=rtl] .select2-search-choice-close {
	right: auto;
	left: 3px;
}

.select2-container-multi .select2-search-choice-close {
	left: 3px;
}

html[dir=rtl] .select2-container-multi .select2-search-choice-close {
	left: auto;
	right: 2px;
}

.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close,
.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
	background-position: right -11px;
}

.select2-container-multi.select2-container-disabled .select2-choices {
	background-color: #f4f4f4;
	background-image: none;
	border: 1px solid #ddd;
	cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
	padding: 3px 5px;
	border: 1px solid #ddd;
	background-image: none;
	background-color: #f4f4f4;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
	display: none;
	background: none;
}

.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
	text-decoration: underline;
}

.select2-offscreen,
.select2-offscreen:focus {
	clip: rect(0 0 0 0) !important;
	width: 1px !important;
	height: 1px !important;
	border: 0 !important;
	margin: 0 !important;
	padding: 0 !important;
	overflow: hidden !important;
	position: absolute !important;
	outline: 0 !important;
	left: 0 !important;
	top: 0 !important;
}

.select2-display-none {
	display: none;
}

.select2-measure-scrollbar {
	position: absolute;
	top: -10000px;
	left: -10000px;
	width: 100px;
	height: 100px;
	overflow: scroll;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 2dppx) {
	.select2-container .select2-choice .select2-arrow b,
	.select2-container .select2-choice abbr,
	.select2-search-choice-close,
	.select2-search input {
		background-image: url(select2x2.png) !important;
		background-repeat: no-repeat !important;
		background-size: 60px 40px !important;
	}

	.select2-search input {
		background-position: 100% -21px !important;
	}
}

.form-control .select2-choice {
	border: 0;
	border-radius: 2px;
}

.form-control .select2-choice .select2-arrow {
	border-radius: 0 2px 2px 0;
}

.form-control.select2-container {
	height: auto !important;
	padding: 0;
}

.form-control.select2-container.select2-dropdown-open {
	border-color: #5897fb;
	border-radius: 3px 3px 0 0;
}

.form-control .select2-container.select2-dropdown-open .select2-choices {
	border-radius: 3px 3px 0 0;
}

.form-control.select2-container .select2-choices {
	border: 0 !important;
	border-radius: 3px;
}

.control-group.warning .select2-container-active .select2-choice,
.control-group.warning .select2-container-active .select2-choices,
.control-group.warning .select2-container-multi.select2-container-active .select2-choices,
.control-group.warning .select2-container .select2-choice,
.control-group.warning .select2-container .select2-choices,
.control-group.warning .select2-dropdown-open.select2-drop-above .select2-choice,
.control-group.warning .select2-dropdown-open.select2-drop-above .select2-choices {
	border: 1px solid #c09853 !important;
}

.control-group.warning .select2-container .select2-choice div {
	border-left: 1px solid #c09853 !important;
	background: #fcf8e3 !important;
}

.control-group.error .select2-container-active .select2-choice,
.control-group.error .select2-container-active .select2-choices,
.control-group.error .select2-container-multi.select2-container-active .select2-choices,
.control-group.error .select2-container .select2-choice,
.control-group.error .select2-container .select2-choices,
.control-group.error .select2-dropdown-open.select2-drop-above .select2-choice,
.control-group.error .select2-dropdown-open.select2-drop-above .select2-choices {
	border: 1px solid #b94a48 !important;
}

.control-group.error .select2-container .select2-choice div {
	border-left: 1px solid #b94a48 !important;
	background: #f2dede !important;
}

.control-group.info .select2-container-active .select2-choice,
.control-group.info .select2-container-active .select2-choices,
.control-group.info .select2-container-multi.select2-container-active .select2-choices,
.control-group.info .select2-container .select2-choice,
.control-group.info .select2-container .select2-choices,
.control-group.info .select2-dropdown-open.select2-drop-above .select2-choice,
.control-group.info .select2-dropdown-open.select2-drop-above .select2-choices {
	border: 1px solid #3a87ad !important;
}

.control-group.info .select2-container .select2-choice div {
	border-left: 1px solid #3a87ad !important;
	background: #d9edf7 !important;
}

.control-group.success .select2-container-active .select2-choice,
.control-group.success .select2-container-active .select2-choices,
.control-group.success .select2-container-multi.select2-container-active .select2-choices,
.control-group.success .select2-container .select2-choice,
.control-group.success .select2-container .select2-choices,
.control-group.success .select2-dropdown-open.select2-drop-above .select2-choice,
.control-group.success .select2-dropdown-open.select2-drop-above .select2-choices {
	border: 1px solid #468847 !important;
}

.control-group.success .select2-container .select2-choice div {
	border-left: 1px solid #468847 !important;
	background: #dff0d8 !important;
}
/*# sourceMappingURL=maps/vendor.css.map */

/*# sourceMappingURL=maps/vendor.css.map */
