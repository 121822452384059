/// <reference path="../framework.less" />

.notification-center {

	&.notification-center--hidden {
		display: none;
	}

	&__wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
	}

	&__content {
		padding: 0 100px 0 0;
		flex: 1 1 auto;
		font-size: 14px;
		line-height: 21px;
		color: @color-2018-white;

		@media @breakpointquery-2018-phablet {
			padding: 0 50px 0 0;
		}

		a {
			color: @color-2018-white;
			font-family: @font-2018-bold;
			text-decoration: underline;

			&:hover {
				color: @color-2018-white;
				text-decoration: underline;
			}
		}
	}

	&__icon {
		width: 25px;
		height: 25px;
		flex: 0 0 auto;

		&--close {
			cursor: pointer;
		}
	}


	&__info-background {
		fill: @color-2018-white;
	}

	&__icon-color {
		fill: @color-2018-linkblue;
	}
}