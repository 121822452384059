﻿// ww-expect "mixin"
// ww-expect "variables"

.visible-phone {
  display: none !important;
}
//
// @phone-barrier: 700px
//
@media only screen and (max-width: @phone-barrier) {


	.contactForm {
		margin-top: 20px;
	}

	.visible-phone {display: inherit !important;}
	.hidden-phone {display: none !important;}

	.baselinetoH2 {
		&:before {

			.font-size(1.8);
		}
	}	

	//Questionbox for FAq and Contact Page
	.whitebox.contactForm{
		.col-1-2{width:100%; margin-bottom:20px;
			select, input{width:100%;}
		}
		&.col-1-1{
			padding-right: 20px !important;
			textarea{width:100%!important;}
		}
	}


	//h2.tophead {margin-top:40px;}

	.module a.back-link {.font-size(2.1);}


	.MobilePackageOrderPage {
		h1 {
			padding-top:20px;
			padding-left:10px;
		}
		.summary-footer{
			div.right{width:100%;}
		}
		.next{
			div.right {
				// width:70% !important;
				.button-link {width:100%;}
			}
		}

		.conditions > div {width:88% !important;}
		.col-1-2.informationarea .module {

			.button-link {width:42%;}
			input{width:100%;}
		}
		.col-1-1.summary-footer h2 {width:100%;}
		.col-1-1.summary-footer .module {
			height:auto;
			p { float:none; }
		}
		.col-1-1.summary-footer div.right {
			float:none;
			margin-top:40px;
			* {float:none;}
		}

		.col-1-2.rightimagearea {
			img {
				.border-radius(0);
				.box-shadow(none);
			}
			.module {
				height:145px;margin-bottom:20px;
				.imgContainer {
					h2 {
						.font-size(2.9);
						line-height:38px;
					}
				}
			}
		}

	}


	.col-1-2.boxes, .col-2-3 {width:100%;padding-right:0;}
	.wrapper.faq .col-1-3 {display:none;}
	.wrapper.faq .col-2-3 {width:100%;padding-right:0;}

	.module.push-item {
		min-heigth:0;
		height:300px;
	}

		a.home {
		position:relative;
		z-index:10;
		display:inline-block;
		float:left;
		padding:22px 0 0 13px;
	}

	.top-pane {
		overflow:hidden;
		cursor:pointer;
		text-decoration:none;
		display:block;
		background-color: @tre-standard-color;
		color:#fff;
		height:80px;
		position:relative;
		z-index:10;

		a.mobilemenu {
			float:right;
			padding-top:32px;
			padding-right:20px;
		}
	}
	.module.push-item.noImg {height:auto;}
	.module.push-item{
		min-height:210px;
		height:210px;
		a.button-link {margin-top:20px;}
	}

	.module.push-item.noImg{
		p {
			.font-size(1.7);
		}
		h2.tophead, h1.tophead {
			margin-top:20px;
			span {
				.font-size(2.2);
				letter-spacing:-1px;
				text-align: center;
				color: @tre-darkgray-color;
			}
			span + span {
				.font-size(2.5);
			}
		}
	}

	.ProductLandingPage, .ProductLandingPage2, .ProductLandingPageCampaign, .ForeignPriceCountryPage, .ForeignPriceListPage {
		.grid-pad {
			div.shadowbox {
				margin:0 10px;
			}
			div.product-category-description {
				margin:0 20px 20px;
			}
			h1 {
				padding-left:20px;
				padding-top:20px;
				padding-bottom:10px;
			}
			h2 {
				//text-align:center;
				margin-left:20px;
				&.viewarea {margin:0;}
			}
		}
	}
	
	.alertbox {

		padding:0px 10px 0!important;

		a.button-link {
			margin:auto;
			background-size:20%;
		}
	}

	.MobileCoverageMapPage{
		h1{margin:10px 0;}
		.grid-pad{margin:0 10px;}
	}

	.whitebox.contactForm{
		.comment-vote-actions {
			margin-right: 0px;
		}
	}

	.col-1-1.confirm-price-box {
		.module {
			margin:0 10px;
		}
		p.large {
			.font-size(5.0);
		}
		div.right {
			margin-top:20px;
			float:left;
			width:100% !important;
		}
	}

	.col-1-2.toggle-box {
		&.right {width:100% !important;}
		.module {
			margin:0 10px;
			span {right:17px;}
		}
	}

}

@media only screen and (max-width: 770px){
	.SelfServicePage{
		.col-1-3, .col-2-3{width:100%!important;}
		.col-1-3{margin-bottom:20px;}
	}

	//fix ios8 bug
	header.main-header {
		.navigation__inner {
			height: auto !important;
		}
		nav[role='navigation']{
			height: auto !important;
		}
		ul#navigation {
			li {
				a {
					height: auto !important;
				}
			}
		}
	}

}