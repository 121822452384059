.mobile-subscription {
    width: 100%;
    background: @hallon-white;
    border-top: 1px solid @hallon-light-grey;
    float: left;
    padding-bottom: 50px;

    @media only screen and (min-width: @phone-breakpoint-hallon) {
        float: none;
        max-width: 960px;
        margin: 0 auto;
        border-top: 0;
    }

    .js-hw-connect, .js-hw-connect-selectlabel {
        display: none;
    }

    .js-email, .js-ssn {
        display: block;
    }

    .js-order-error {
        color: #8F1840;
        font-size: 0.8em;
        margin-top: 1em;
        padding: 0 1em;
        display: none;
        text-align: center;
    }

    form {
        padding-left: 20px;
        padding-right: 20px;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            padding-left: 230px;
            padding-right: 230px;
        }

        div {
            margin-bottom: 1em;
        }

        .checkout__summary-gdpr, .hallon-checkbox {
            margin-bottom: 0.2em;
        }

        .checkout-gdpr-old-margin-top {
            margin-top: 2em;
            margin-bottom: 1.5em;
        }
    }


    h2 {
        text-transform: uppercase;
        font-family: @hallon-frutiger-65-bold;
        text-align: center;
        color: @hallon-light-blue;
        width: 100%;
        display: block;
        margin: 0;
        font-size: @ftsize-hallon-24;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 1em;


        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            padding-left: 230px;
            padding-right: 230px;
        }
    }

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
        #prohibitedDiv {
            h2 {
                padding-left: 30px;
                padding-right: 30px;
            }

            .mobile-subscription__field {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }

    .mobile-subscription__field {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 1.5em;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            padding-left: 230px;
            padding-right: 230px;
        }
    }

    .mobile-subscription__cost-summary {
        padding-left: 20px;
        padding-right: 20px;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            padding-left: 230px;
            padding-right: 230px;
        }

        div {
            margin-bottom: 1em;
        }

        .mobile-subscription__cost-summary__download {
            display: table;
            margin: 0;
            margin-top: 2em;

            a {
                display: inline-block;
            }
        }
    }

    .mobile-subscription-list {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 2em;
        padding-bottom: 2em;
        margin-bottom: 1em;
    }



    .mobile-subscription__gift-code {
        display: none;
    }


    .mobile-subscription__totals {

        p:nth-child(odd) {
            font-family: @hallon-frutiger-55-roman;
            text-align: center;
            font-size: @ftsize-hallon-14;
            color: @hallon-dark-grey;
            margin-bottom: 0.5em;
        }

        p:nth-child(even) {
            text-align: center;
            text-transform: uppercase;
            color: @hallon-light-blue;
            font-family: @hallon-frutiger-65-bold;
            font-size: @ftsize-hallon-24;
            margin-bottom: 1em;
        }


        a {
            text-align: center;
            margin-bottom: 3em;
            cursor: pointer;
        }
    }

    .mobile-subscription__spinner-section[hidden] {
        display: none;
    }

    .mobile-subscription__spinner-section {
        margin-bottom: 0;
    }

    .mobile-subscription__spinner-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 0;

        div {
            margin-bottom: 0;
        }

        span {
            flex: 1 0;
        }

        .mobile-subscription__spinner {
            line-height: 16px;
            margin-left: 0.5em;
            flex: 0;
        }
    }

    .link-down-arrow {
        text-align: center;
        margin-bottom: 1em;
    }

    .link-right-arrow {
        display: block;
        margin-top: 0.5em;
        font-size: @ftsize-hallon-14;
        font-family: @hallon-frutiger-55-roman;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            display: inline-block;
            margin-left: 2em;
        }
    }

    p {
        display: block;
        margin-bottom: 1em;
        font-size: @ftsize-hallon-14;
        font-family: @hallon-frutiger-55-roman;
    }

    input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="tel"] {
        display: block;
        margin-bottom: 1em;
        .placeholder-color;
    }

    label:not(".hallon-checkbox__label") {
        font-size: @ftsize-hallon-14;
        font-family: @hallon-frutiger-55-roman;
        display: inline-block;
        margin-bottom: 0.5em;
    }

    .label-radio {
        display: block;
    }

    .hallon-select {
        margin-bottom: 1em;
    }

    .hallon-btn {
        margin: 0 auto;
        display: table;
    }

    .mobile-subscription__error {
        display: inline-block;
        float: right;
        margin-bottom: 0;
        font-size: @ftsize-hallon-12;
        margin-block-end: 0em;
        margin-block-start: 0em;

        p {
            display: inline-block;
            margin-block-end: 0em;
            margin-block-start: 0em;
            float: right;
            margin: 0;
            font-size: @ftsize-hallon-12;
            color: @hallon-light-red;
        }
    }

    .mobile-subscription__confirm-text {
        padding-left: 20px;
        padding-right: 20px;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            padding-left: 230px;
            padding-right: 230px;
        }

        p {
            text-align: left;
        }
    }


    .hallon-label {
        margin-left: 0;
        font-size: @ftsize-hallon-12;
        opacity: 0;
        margin-bottom: 0.1em;
    }

    .js-hallon-select, .js-hallon-selectlabel {
        display: none;
    }

    .mobile-subscription__payment-info-wrapper {
        padding-left: 20px;
        padding-right: 20px;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            padding-left: 230px;
            padding-right: 230px;
        }
    }

    .mobile-subscription__payment-info {
        text-align: center;
        margin-bottom: 1em;

        p {
            text-align: center;
        }
    }

    .js-hallon-btn-next {
        display: block;
    }
}
//MODIFIERS
.mobile-subscription--order {
    display: none;
}

.mobile-subscription--hw-order {

    .label-radio, .js-current-number, .js-hw-connect, .js-hw-connect-selectlabel, .js-hallon-selectnumber, .js-hallon-selectnumberlabel {
        display: none;
    }

    .js-email, .js-ssn {
        display: block;
    }
}

.mobile-subscription--hw-monthly {

    .label-radio, .js-hallon-selectnumber, .js-hallon-selectnumberlabel, .js-current-number, .hallon-password, .js-email, .js-ssn {
        display: none;
    }

    .js-hw-connect, .js-hw-connect-selectlabel {
        display: block;
    }
}

.mobile-subscription--hw-monthly.mobile-subscription--logged-out {
    .js-hallon-btn-next {
        display: none;
    }
}

.mobile-subscription--hw-monthly.mobile-subscription--hw-monthly-not-available {
    .js-hallon-btn-next {
        display: none;
    }
}
