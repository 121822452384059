.SelfServicePage {

    .viewWrapper {
        position: relative;
        z-index: 0;
    }

    .js-number-selectbox {
        display: none;
    }

    .accountInformationView {
        display: none;
    }

    .content-container {
        position: relative;
        padding-top: @font-size-h1 * 10px; // H1 equivalent of 1em
        @media only screen and (max-width: @phone-barrier) {
            padding-top: @font-size-h1-phone * 10px;
        }

        h1 {
            .inline-block();
            margin-right: .5em;
        }
    }

    .field-row {
        margin-bottom: @margin-M;
    }

    .button-row {
        padding-top: @margin-M;
    }

    .accountInformationHeadWrapper {
        display: inline-block;
    }

    .accountInformation {
        .inlineBlock();
        .font-size( @fontsize-S/10 );
        margin-bottom: @margin-M;
        font-family: @tre-bold-font;

        @media only screen and (max-width: @phone-barrier) {
            margin-bottom: @margin-S;
        }

        &--head {
            margin-bottom: @margin-M;
            display: flex;
            align-items: center;
            cursor: pointer;

            @media only screen and (max-width: @phone-barrier) {
                margin-bottom: @margin-S;
            }

            .icon {
                width: 0.85em;
            }
        }

        &__account-switcher {
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        &__account-switcher-icon {
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }

        &__account-switcher-icon-path {
            fill: @color-2018-hallon-red;
        }

        &__account-switcher-text {
            font-family: @font-2018-bold;
            font-size: 12px;
            line-height: 16px;
        }

        &__svg-icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            fill: #8F1840;

            @media only screen and (max-width: @phone-barrier) {
                width: 15px;
                height: 15px;
            }
        }
    }

    .gdpr-summary {
        display: block;
        margin-top: 0.8em;
        font-size: 0.8em;
        font-style: italic;
    }

    .selfservice-change-margin-top {
        margin-top: 0.8em;
        display: inline-block;
    }

    .changelink {
        text-align: right;

        a {
            float: none;
            margin: 0;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    .myPott {
        .usage {
            font-family: @tre-black-font;
            line-height: 1;
        }

        .amountused {
            .font-size(5);
        }

        [data-button] {
            margin-bottom: @margin-M;
        }

        .transparentarea {
            text-align: center;
        }
    }

    .usage-item {
        @headerfontsize: 18;

        color: #3e3a37;
        font-family: @tre-black-font;
        position: relative;

        &.grayarea {
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: "";
                display: block;
                background-color: rgba(255, 255, 255, 0.75);
                transition: opacity 0.2s linear;
                opacity: 0;
                visibility: hidden;
                background-size: 50px;
                background-repeat: no-repeat;
                background-position: center right 25px;
                background-image: url("data:image/svg+xml, %3Csvg class='remodal__icon' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'%3E%3Ctitle%3ELaddar%3C/title%3E%3Cpath fill='%238F1840' class='remodal__icon-background' d='M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
            }

            &--loader {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
            }

            &--allow-when-inactive {
                z-index: 1000;
            }
        }

        h3 {
            .font-size( @headerfontsize/10 );
            display: inline;
            color: @color-gray50;
            margin-right: 1em;
        }

        p {
            font-family: @tre-standard-font;
            color: @color-gray50;
            margin-top: 1em;

            &:first-child {
                margin-bottom: 1em;
            }
        }

        [data-hlayout~="right"] {
            .baselineTo( @headerfontsize );
        }

        [data-button] {
            margin-bottom: 0;
        }

        .packagename {
            .font-size( @fontsize-M/10 );
            .hyphens();
            display: block;
            -webkit-hyphens: none; // Safari hyphenates when a linebreak would be desireable.
            font-family: @tre-black-font;
            line-height: @lineheight-M;
            margin-bottom: @margin-XS;
            color: black;
        }

        .packagepriceinfo {
            .font-size(1.8);
            font-family: @tre-standard-font;
            margin-bottom: @margin-M;

            .originalprice {
                color: @color-gray68;
                text-decoration: line-through;
            }
        }

        @media only screen and (max-width: @hw-mobile-L) {
            .packagename {
                .font-size( 2.4 );
            }

            .packagepriceinfo {
                .font-size(1.4);
            }
        }

        .usage-list {
            display: none;
            // padding-top: @margin-M;
            // padding-bottom: @margin-M;
            h3 {
                // An exception to the rule that the H3 should be placed in it's own data-column
                display: block;
                margin-bottom: @margin-XS;
            }

            @media screen and (max-width: 960px) {
                [data-column="1/2"] {
                    width: initial !important;
                }

                [data-hlayout="right"] {
                    float: right !important;
                }
            }

            .optionlist,
            .datacontent {
                margin-bottom: @margin-ML;
            }


            .optionlist {
                li {
                    position: relative;
                    margin-bottom: 1em;
                    padding-left: 2em;

                    input[type="radio"] {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 1em;
                        height: 1em;
                        margin: -.5em 0 0 .1em;
                    }
                }

                li.optionlist-item-with-toggle-switch {
                    padding-left: 0;
                }

                &.option-layout-row {
                    li {
                        display: inline-block;
                        margin-right: @margin-L;
                    }
                }
            }

            a {
                cursor: pointer;
            }

            .optionlist-item-info-question-mark {
                width: 1em;
                height: 1em;
                margin-left: 5px;
                top: 3px;
                position: relative;
            }

            .optionlist-item-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;

                .toggle-switch {
                    flex: 1 0 auto;
                    margin-right: 20px;
                }
            }

            .optionlist-item-info {
                display: block;
                margin: 0;
            }

            .optionlist-item-info-why-preselected {
                margin-top: 1em;
                display: block;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            .new-password-infotext {
                .font-size( 1);
                color: @tre-standard-color;
                margin-bottom: 1em;
                line-height: 18px;
            }

            li {
                margin-bottom: @margin-XXS;

                label:not(.toggle-switch) {
                    display: inline;
                    color: @color-gray50;
                    .font-size( 1.4 );
                }
            }

            label:not(.toggle-switch):not(.hallon-checkbox__label--gdpr-my-pages) {
                display: block;
                margin-bottom: @margin-XXS;
                font-family: @tre-bold-font;
            }

            &--gdpr label {
                display: flex;
                margin-top: 1em;
                margin-bottom: @margin-XXS;
                font-family: @tre-bold-font;
            }

            input[type="text"],
            input[type="password"],
            input[type="email"],
            input[type="tel"] {
                display: block;
                margin-bottom: @margin-M;
            }

            p {
                margin-bottom: @margin-M;
            }
        }

        .ajaxloader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 100%;
            text-align: center;

            img {
                position: absolute;
                right: 20px;
                top: 25px;
            }
        }

        .error {
            margin-top: @margin-S;
            color: @tre-error-color;
        }

        .success {
            margin-top: @margin-S;
            color: #63a044;
        }

        .instruction {
            .font-size( 1.4 );
            font-style: italic;
            font-family: @tre-standard-font;
            color: @color-gray50;

            p {
                .font-size( 1.4 );
            }
        }
    }

    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"] {
        &.fullwidth {
            width: 100%;
        }
    }

    .usage-overview {
        .font-size(1.6);
        text-align: center;
        margin-bottom: @margin-M;

        .usage-refills {
            font-family: @tre-bold-font;
            color: #000;
        }
    }

    .myPott {
        .usage-daysleft {
            text-align: center;
        }
    }

    .tellafriend {
        .vouchercode {
            background-color: @color-gray95;
            border: 2px dashed @color-gray76;
            text-align: center;
            font-family: @tre-bold-font;
            .font-size(3);
            padding: 18px 0;
            letter-spacing: .1em;
        }

        .voucherdescription {
            p {
                .font-size(1.8);
            }
        }
    }

    .tellafriend__inner-wrapper {
        margin: 50px 0 25px 0;
    }

    .is-inactive {
        .blocker {
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            background: #fff;
            opacity: 0.7;
        }
    }

    .numberDetails {
        display: none;

        &.active {
            display: block;
        }

        .is-inactive {
            position: relative;

            .blocker {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                min-height: 100%;
                background: transparent url(/UI/build/images/blocker.png) repeat top left;
                z-index: 999;
            }
        }

        .messagebox {
            @media screen and (min-width: 360px) and (max-width: 500px) {
                .messagebody {
                    h2 {
                        font-size: 2.5rem;
                        margin-left: -1.5em;
                        margin-right: -1.5em;
                    }
                }
            }
        }
    }

    .messagebox__close-icon {
        width: 25px;
        height: 25px;
        flex: 0 0 auto;
        cursor: pointer;
        position: absolute;
        top: 1em;
        right: 1em;

        @media screen and (max-width: 768px) {
            top: 0.5em;
            right: 0.5em;
        }

        &-background {
            fill: #3e3a37;
        }

        &-color {
            fill: @tre-message-color;
        }
    }

    .selfservicebox {
        .button-selection {
            margin-bottom: @margin-M;
        }
    }

    .voucher-offer-overview {
        margin: 0 0 2em 0;
    }

    .myoffers {
        padding: 4em 0 1em 0;

        h2 {
            .font-size( 4 );
            font-family: @tre-black-font;
        }
    }

    .gotquestions {
        text-align: center;

        h2 {
            .font-size( 4 );
            font-family: @tre-black-font;
        }

        p {
            .font-size( 1.8 );
            font-family: @tre-black-font;
            text-transform: uppercase;

            a {
                text-decoration: underline;
            }
        }
    }

    .simpleformpage {
        input[type="text"] {
            width: 45%;
        }

        .product {
            font-family: @tre-black-font;
            text-align: center;

            .productname {
                .font-size( 2.4 );
            }

            .price-unit {
                .font-size( 2 );
            }
        }

        .info-checkout {
            .font-size( 1.6 );
            font-family: @tre-black-font;
            text-align: center;
            margin-bottom: @margin-M;
        }

        > p {
            margin-bottom: @margin-M;
        }
    }

    .myNumbers {
        [data-column] {
            position: relative;
        }

        [data-column] {
            position: relative;
        }

        &__list-item {
            border-bottom: 1px solid @color-gray95;

            &:last-child {
                border-bottom: none;
            }

            &--active {
                .myNumbers__list-item-title-arrow {
                    transform: rotate(180deg);
                }
            }

            &--dropdown {
                border-bottom: none;
            }
        }

        &__list-item-title-header {
            padding-bottom: 10px;
        }

        &__list-item-title-wrapper {
            font-size: 50px;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: 1%;
        }

        &__list-item-title-innerwrapper {
            flex-basis: 100%;
        }

        &__list-item-title-arrow {
            transition: 0.2s linear all;
            transform: rotate(0);
            font-size: 56px;
            color: #808080;

            @media screen and (max-width: 500px) {
                font-size: 34px;
            }
        }

        &__list-item-title-number {
            font-size: 50px;
            padding-top: 10px;
            font-family: @tre-bold-font;
            text-transform: uppercase;
            color: @hw-bg-dark;
            display: block;

            @media screen and (max-width: 500px) {
                font-size: 24px;
            }
        }

        &__list-item-title-text {
            font-size: 50px;
            font-family: @tre-bold-font;
            text-transform: uppercase;
            color: @hw-bg-dark;
            display: block;

            @media screen and (max-width: 500px) {
                font-size: 24px;
            }
        }

        &__list-item-title-subscription-name-wrapper {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
        }

        &__list-item-title-subscription-name {
            font-size: 22px;
            display: block;
            font-family: @tre-black-font;
            font-weight: normal;
            text-transform: uppercase;
            color: @tre-gray-color;

            @media screen and (max-width: 500px) {
                font-size: 14px;
            }
        }

        &__list-item-title-subscription-name-alias {
            margin-left: 20px;
            display: flex;
            align-items: center;

            @media screen and (max-width: 500px) {
                display: none;
            }

            &--mobile {
                display: none;

                @media screen and (max-width: 500px) {
                    display: flex;
                    margin-left: 0;
                    padding-top: 10px;
                }
            }
        }

        &__list-item-title-subscription-name-alias-icon {
            width: 21px;
            height: 21px;
            margin-right: 10px;
        }

        &__list-item-content {
            display: none;
            padding-top: 20px;

            &--active {
                display: block;
            }
        }

        &__list-item-content-toggle-wrapper {
            display: flex;
            align-content: center;
            justify-content: flex-end;
        }

        &__list-item-content-toggle {
            padding: 1em 0;
            cursor: pointer;
            align-items: center;
            display: flex;

            i {
                padding: 0 10px 0 0;
                font-size: 25px;
                margin: 0;
            }
        }
    }

    .selectbox {
        margin-bottom: @margin-L;

        &.selectbox-single {
            cursor: default;
        }

        &.selected {
            [data-value~="-1"] {
                display: none !important;
            }
        }

        .listitem {
            &:hover {
                .phonenumber,
                .instruction {
                    color: #fff;
                }
            }

            &.selected,
            &.default {
                &:hover {
                    .phonenumber,
                    .instruction {
                        color: @hw-bg-dark;
                    }
                }
            }

            h4 {
                display: none;
                margin: 0 0 0 .1em;
                color: @tre-gray-color;
            }

            &.selected {
                h4 {
                    display: block;
                }

                .phonenumber {
                    .font-size(6);
                }
            }

            .phonenumber {
                .font-size(5);
                font-family: @tre-bold-font;
                text-transform: uppercase;
                color: @hw-bg-dark;
                display: block;
            }

            .packagename {
                padding-bottom: 10px;
                display: block;
                line-height: @lineheight-M;
                font-family: @tre-black-font;
                font-weight: normal;
                text-transform: uppercase;
                color: @tre-gray-color;
            }
        }
    }

    .myNumber {
        h4 {
            color: @tre-gray-color;
            font-weight: normal;
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    .bigfont {
        .font-size(4.5);
        color: #000;
        font-weight: bold;
        text-transform: uppercase;
    }

    .mediumfont {
        .font-size(2.7);
        margin-left: 20px;
        color: @tre-gray-color;
        font-weight: bold;
        text-transform: uppercase;
    }

    .tellafriend {

        .facebook-share {
            @iconsize: 4.5;
            @basefontsize: 1.8;

            @iconsize_incontext: @iconsize/@basefontsize;

            .font-size( @basefontsize );
            text-align: center;

            [data-button] {
                position: relative;
                padding-left: unit(@iconsize_incontext + .8, em);
            }

            [class^="icon-"] {
                position: absolute;
                font-size: unit(@iconsize_incontext, em);
                top: 50%;
                left: 0;
                margin-left: unit(.7/@iconsize, em);
                margin-top: unit(@iconsize_incontext/-2/@iconsize_incontext, em);
            }
        }
    }
    // Page specific breakpoints
    @media only screen and (max-width: 960px) {
        [data-column~="1/2"] {
            > [data-button~="fit-auto"] {
                &[data-button~="size-L"] {
                    display: block;
                    max-width: unit(@gridsystem-width/2, px);
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .button-row {
            margin-top: 0;

            [data-column~="1/2"] + [data-column~="1/2"] {
                padding-top: @margin-M;
            }
        }

        [data-column~="1/2"]:first-child {
            margin-bottom: @margin-M;
        }

        .selectbox {
            .listitem {
                .phonenumber {
                    display: block;
                }

                .packagename {
                    display: block;
                }

                &:hover,
                &:active,
                &:focus {
                    .phonenumber {
                        color: #000;
                    }
                }

                &.selected {
                    &:hover,
                    &:active,
                    &:focus {
                        .phonenumber {
                            color: #000;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: @phone-barrier) {
        .myPott .amountused {
            .font-size( 5 * 0.75 );
        }
    }
    // 480px
    @media only screen and (max-width: @hw-palm) {
        .accountInformation {
            .font-size( @fontsize-XS/10 );
        }

        .selectbox {
            .listitem {
                &.selected {
                    .phonenumber {
                        .font-size(2.7);
                    }
                }

                .phonenumber {
                    .font-size(2.5);
                    display: block;
                }

                .packagename {
                    display: block;
                    .font-size(1.5);
                }

                &:hover,
                &:active,
                &:focus {
                    .phonenumber {
                        color: #000;
                    }
                }

                &.selected {
                    &:hover,
                    &:active,
                    &:focus {
                        .phonenumber {
                            color: #000;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: @hw-laptop) {
        .usage-item .packagename {
            .font-size( @fontsize-M/10 * 0.75 );
        }
    }
    /* Detaljspecifikation */
    &.betalningshistorik,
    &.betalkort {
        h1 {
            margin-bottom: 0;
        }
    }
}

.top-rubrik__header {
	margin-bottom: 10px;

	@media @breakpointquery-2018-phablet {
		margin-bottom: 5px;
	}
}

.top-rubrik__current-username {
	font-size: 18px;
	line-height: 27px;
	color: #646464;
	text-align: center;
	font-family: "FrutigerLTW01-55Roman";
	text-transform: none;
	margin-bottom: 30px;

	@media @breakpointquery-2018-phablet {
		font-size: 14px;
		line-height: 21px;
	}
}
