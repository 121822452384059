.sprite-star-special {

	position: relative;
	top: 9px;
	margin-top: -9px;

	.inline-block();
	font-size: 0; // kill white-space
	margin-right: 16px;

	.count {

		.font-size(1.6);
		color: white;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 19px;
		margin: auto;
		text-align: center;
	}
}