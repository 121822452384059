.module-hardware {
    display: table;

    .module-hardware__container {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    .module-hardware__img-container {
        position: relative;
        padding-bottom: 30px;

        img {
            z-index: 2;
        }

        .bubble-hardware {
            z-index: 3;
            position: absolute;
            bottom: 5px;
            left: 50%;
            margin-right: -50%;
            -ms-transform: translate(25%, 0%); /* IE 9 */
            -webkit-transform: translate(25%, 0%); /* Safari */
            transform: translate(25%, 0%);
        }
    }

    .module-hardware__img-link {
        text-decoration: none;
        color: @hallon-black;

        &:hover {
            color: @hallon-black;
        }
    }

    h2 {
        margin-bottom: 1em;
        text-transform: none;
        font-family: @hallon-frutiger-77-bc;
        font-size: @ftsize-hallon-24;
        margin-top: -20px;

        span {
            display: block;
        }
    }
}
