/// <reference path="../framework.less" />

.header {
	position: fixed;
	transform: translate3d(0, -80px, 0);
	display: block;
	width: 100vw;
	background: transparent;
	z-index: 999;
	transition: background 0.3s linear;

	&__content-wrapper {
		width: 100%;
		max-width: @breakpoint-2018-desktop;
		padding: @margins-2018-small @margins-2018-large @margins-2018-small @margins-2018-large;
		box-sizing: border-box;
		margin: auto;
		display: flex;
		flex-direction: row;
		align-items: center;

		@media @breakpointquery-2018-small-desktop {
			justify-content: space-between;
			padding: 10px;
			flex-wrap: wrap;
		}

		&-mobile-icons {
			margin-left: auto;
		}
	}

	&--not-sticky {
		position: initial;
	}

	&--sticky-show, &--white-header {
		background-color: @color-2018-white;
		transform: translate3d(0, 0, 0);
		// transition: padding 300ms ease;
		// transition: all 0.4s ease;

		.header__link {
			color: @color-2018-text-black;

			&:hover {
				color: @color-2018-text-black;

				&::after {
					background: @color-2018-text-black;
				}
			}

			&--active {
				color: @color-2018-text-black;

				&::after {
					background: @color-2018-text-black;
				}
			}
		}


		.header__logotype-image {
			fill: @color-2018-hallon-red;
		}
	}

	&--sticky-ios {
		position: absolute;
	}

	&--active-mobile-header {
		@media @breakpointquery-2018-small-desktop {
			.header__link {
				color: @color-2018-hallon-red;

				&:hover {
					color: @color-2018-hallon-red;

					&::after {
						background: @color-2018-hallon-red;
					}
				}

				&--active {
					color: @color-2018-hallon-red;

					&::after {
						background: @color-2018-hallon-red;
					}
				}
			}

			.header__logotype-image {
				fill: @color-2018-hallon-red;
			}
		}
	}

	&__logotype {
		align-self: flex-end;
		margin-bottom: -5px;
		flex-basis: 25%;

		@media @breakpointquery-2018-small-desktop {
			margin: 0 @margins-2018-small 0 @margins-2018-small;
			align-self: center;
			z-index: 2000;
			flex: 0 0 auto;
			height: 50px;
			display: flex;
		}
	}

	&__logotype-image {
		fill: @color-2018-white;
		width: 218px;
		height: 45px;

		@media @breakpointquery-2018-small-desktop {
			width: 111px;
			height: 23px;
			align-self: center;
		}

		&--company {
			height: 55px;

			@media @breakpointquery-2018-small-desktop {
				height: 40px;
				align-self: center;
			}
		}
	}

	&__nav {
		flex: 1;
		margin-bottom: -4px;

		@media @breakpointquery-2018-small-desktop {
			display: none;
		}
	}

	&__nav-list {
		display: flex;
		flex-direction: row;
		flex: 1 1 auto;
	}

	&__nav-list-item {
		margin-right: 40px;
		align-self: flex-end;
	}

	&__link {
		font-size: 18px;
		color: @color-2018-white;
		font-family: @font-2018-bold;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			left: 0;
			bottom: -5px;
			background: transparent;
			transition: background 0.2s ease;
			pointer-events: none;
		}

		&:hover {
			color: @color-2018-white;

			&::after {
				background: @color-2018-white;
			}
		}

		&--active {
			color: @color-2018-white;

			&::after {
				background: @color-2018-white;
			}
		}
	}

	&__profile-nav {
		display: flex;
		
		@media @breakpointquery-2018-small-desktop {
			display: none;
		}
	}

	&__profile-nav--hide {
		display: none;
	}

	&__mobile-toggle {
		display: none;

		@media @breakpointquery-2018-small-desktop {
			align-self: center;
			display: inline-block;
			outline: none;
			background: none;
			border: 0;
		}
	}

	&__mobile-toggle--first:not(:last-child) {
		margin-right: 20px;
		
		@media @breakpointquery-2018-mobile {
			margin-right: 6px;
		}
		
		@media @breakpointquery-2018-small-desktop {
			
		}
		
		&.active {
			display: none;
		}
	}


	&__mobile-toggle-icon {
		display: none;
		width: 50px;
		height: 50px;
		cursor: pointer;
		pointer-events: none;

		@media @breakpointquery-2018-small-desktop {
			margin-top: -10px;
    		margin-bottom: 4px;
			display: inline-block;
		}

		&.active {
			z-index: 2000;
			// margin-top: -14px;
			@media @breakpointquery-2018-small-desktop {
				display: inline-block;
			}
			.header__mobile-toggle-line:nth-of-type(1) {
				transform: rotate(-45deg) translateY(0) translateX(0);
			}

			.header__mobile-toggle-line:nth-of-type(2) {
				opacity: 0;
			}

			.header__mobile-toggle-line:nth-of-type(3) {
				transform: rotate(45deg) translateY(0em) translateX(0em);
			}
		}
	}

	&__mobile-toggle-background {
		fill: @color-2018-white;
	}

	&__mobile-toggle-line {
		fill: none;
		stroke: @color-2018-text-black;
		stroke-linecap: round;
		stroke-miterlimit: 10;
		stroke-width: 3px;
		transform: rotate(0) translateY(0) translateX(0);
		opacity: 1;
		transform-origin: 20px 10px;
		transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;

		&:nth-of-type(1) {
			transform-origin: 35px 21px;
		}

		&:nth-of-type(3) {
			transform-origin: 34px 29px;
		}
	}

	&__mobile-menu {
			display: none;
			display: block;
			flex-basis: 100%;
			height: 100vh;
			width: 100%;
			background: @color-2018-white;
			position: absolute;
			visibility: hidden;
			opacity: 0;
			top: 0;
			left: 0;
			padding-top: 86px;
			z-index: -1;
			transition: all .3s;
			backface-visibility: hidden;
			overflow-y: scroll;



			&.show {
				visibility: visible;
				opacity: 1;
			}
	}

	&__mobile-menu-overlay {
		content: "";
		display: none;
		background: @color-2018-white;
		width: 100vw;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 0;
	}

	&__mobile-nav-list {
		padding-bottom: 65px;
	}

	&__mobile-nav-list-item {
		text-align: center;
	}

	&__mobile-nav-list-item--top {
		margin-top: 60px;
	}

	&__mobile-nav-list-item-link {
		display: inline-block;
		padding: 15px;
		text-align: center;
		font-size: 18px;
		color: @color-2018-hallon-red;
		font-family: @font-2018-bold;

		&--active::after {
			margin-bottom: -1px;
			content: "";
			height: 1px;
			display: block;
			background: @color-2018-hallon-red;
		}
	}

	&__mobile-nav-list-item-button {
		border-radius: 50px;
		padding: 10px 0;
		background: @color-2018-white;
		color: @color-2018-hallon-red;
		border: 1px solid @color-2018-hallon-red;
		box-sizing: border-box;
		font-size: 18px;
		font-family: @font-2018-bold;
		display: block;
		text-align: center;
		width: calc(~"100% - 100px");
		max-width: 260px;
		transition: all 0.2s linear;
		margin: 0 auto 15px auto;


		&:hover {
			background: @color-2018-hallon-red;
			color: @color-2018-white;
		}

		&--hallon-red {
			background: @color-2018-hallon-red;
			color: @color-2018-white;
			border: 1px solid @color-2018-white;

			&:hover {
				background: @color-2018-white;
				color: @color-2018-hallon-red;
				border: 1px solid @color-2018-hallon-red;
			}
		}
	}
}

.header__mobile-toggle-close {
	display: none;
	margin-left: -44px;
	font-size: 12px;
}

.header__mobile-toggle--left {
	margin-left: auto;
	z-index: 2000;
}

.header__mobile-toggle-close--visible {
	display: none;
	@media @breakpointquery-2018-small-desktop {
		z-index: 2000;
		display: inline-block;
	}
	
}

// .header__mobile-toggle-user {
// 	display: none;
// }

.header__mobile-toggle-user--hidden {
	display: none;
}



