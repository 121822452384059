.informationarea {

	h2 {

		// display: none !important; // Leave and set up for debate. -LR

		@media only screen and (max-width: @phone-barrier) { 

			display: block !important;

		}
	}
	p.lead {

		margin-bottom: @margin-M;
	}
	.field { 

		margin-bottom: @margin-M;
	}
	.instructiontext {

		margin-bottom: @margin-XS;
	}
	
	label {

		display:block;
		padding-bottom:5px;
	}


	.loginbutton {
		margin-bottom: @margin-M;
	}

	// @media only screen and (max-width: @phone-barrier) { .persnumberarea {width:100%!important;} }
	// @media only screen and (min-width: @phone-barrier) and (max-width: 1024px) { input[type="text"], .persnumberarea {width:85%!important;}	}

	//input[type*="checkbox"] {padding:0;border:none;}
	.persnumberarea {

		position: relative; // To contain the absolute positioned spinner
		// width:60%;
		margin-bottom: @margin-ML;
		padding: 10px;
		min-height:90px;
		
		.font-size(1.6);
		background-color: @tre-lightblue-color;
		border:1px solid @tre-specialborder-color;
		.border-radius(4px);
		
		* {
			padding:3px;
		}
	}
	#floatingCirclesG {

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left:0;
		margin: auto;
	}
	.button-link {

		float:none;
		margin:0;
		padding: 6px 10px;
		// width:30%;
		.font-size(1.4);
		margin-bottom: 10px;
	}

	.conditions {
		
		>div {width:70%;}
		
		@media only screen and (min-width: @phone-barrier) and (max-width: 950px) {	>div {width:88%;} }
		
		.font-size(1.6);
		margin-top: 10px;
		overflow: hidden;
		
		.checkb {
			margin-right:5px;
			margin-bottom:0;
			width:20px;
		}
		
		* { float:left; }
		
		label {
		
			margin-right:5px;
		
			+a {
				text-decoration:none;
				color: @tre-standard-color;
			}
		}
	}
	
	input {

		margin-bottom: @margin-XS;
		width:100%; // actual width taken care of containing grid-based node

		&[type="radio"],
		&[type="checkbox"] {

			width: auto;
			margin-right: 0.6em;
		}
	}

	@right-outside: -35px;
	@right-inside: 6px;
	@padding-inside: 2.2em;

	.field-valid {

		@iconheight: 26px;
		position: relative;
		right: @right-outside;
	}
	
	@media only screen and (min-width: @phone-barrier) and (max-width: @hw-tablet) {

		.field-valid {
			right: @right-inside; // place inside input field
		}

		input[type="text"],
		input[type="password"] {
			padding-right: @padding-inside;
		}
	}

	@media only screen and (max-width: @hw-mobile-L) {

		.field-valid {
			right: @right-inside; // place inside input field
		}

		input[type="text"],
		input[type="password"] {
			padding-right: @padding-inside;
		}
	}

}
