/// <reference path="../framework.less" />


.hallon-2019-input {
    &__group {
        position: relative;
        margin: 0 0 35px 0;
        padding: 0;
        display: inline-block;
        width: 100%;
        text-align: left;
    }

    &__input {
        display: block;
        width: 100%;
        font-size: 14px;
        line-height: 55px;
        padding: 0 50px 0 0;
        outline: none;
        -webkit-appearance: none;
        border-radius: 0;
        margin: 0;
        border: none;
        border-bottom: 1px solid #393939;
        background: transparent;
        color: #525252;
        font-family: @font-2018-roman;
        transition: border-color 0.2s linear;

        &:focus {
            border-color: #393939;
        }

        &.valid {
            border-color: @color-2018-green;
            color: @color-2018-green;
        }

        &.error {
            border-color: @color-2018-error-red;
        }

        &::-ms-expand, &::-ms-reveal, &::-ms-clear {
            display: none;
        }
    }

    &__label {
        font-size: 16px;
        line-height: 21px;
        color: @color-2018-text-black;
        font-family: @font-2018-bold;
        display: block;
    }

    &__input-error-message {
        position: absolute;
        margin-top: 5px;
        width: 100%;
        font-size: 11px;
        color: @color-2018-error-red;
    }


    &__form-error {
        margin-top: 5px;
        width: 100%;
        font-size: 11px;
        line-height: 14px;
        min-height: 14px;
        color: @color-2018-error-red;
        display: none;
    }

    &__label-tooltip {
        position: relative;
        cursor: pointer;
        display: inline-flex;
        align-items: center;

        &--questionmark {
            background-color: #363636;
            font-size: 12px;
            line-height: 14px;
            font-family: @font-2018-roman;
            color: @color-2018-white;
            padding: 2px;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
        }
    }

    &__input-select {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #393939;
        padding: 20px 0;
        cursor: pointer;
        outline: none;
        -webkit-appearance: none;
        background-image: url("data:image/svg+xml, %3Csvg width='16px' height='9px' viewBox='0 0 16 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Mobile-Steg-1' transform='translate(-208.000000, -156.000000)' stroke='%23393939' stroke-width='2'%3E%3Cpolyline id='Path-2' points='209 157 215.726834 163.726834 222.453668 157'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center right;
    }
}