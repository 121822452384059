.ProductLandingPageCampaign{

  .productlandingpagecampaign__header{
    text-align: center;
    font-size: 40px;
    background: @body-background;
    padding-top:60px;
    padding-bottom: 60px;
    display: block;
    color: @hallon-light-black;
    margin: 0;
  }

}