.main-container{
  width:100%;

  .main-container__grid{
    width:100%;
    padding: 20px 30px;

    h1, h2{
      text-align: center;
    }

    @media only screen and (min-width: @phone-breakpoint-hallon) {
      max-width: 960px;
      margin: 0 auto;
      padding: 30px 50px;
    }
  }

}