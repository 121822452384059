/// <reference path="../framework.less" />

.three-part-blurb {
	display: flex;

	@media @breakpointquery-2018-tablet {
		flex-wrap: wrap;
	}

	&__block {
		flex: 0 1 33.333%;
		height: 200px;
		padding: @paddings-2018-small;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		background-size: cover;
		background-position: center;
		position: relative;

		&::before, &::after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transition: opacity 0.2s linear;
			background: linear-gradient(0deg, rgba(0,0,0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
			z-index: 0;
		}

		&::after {
			opacity:0;
			background: linear-gradient(0deg, rgba(0,0,0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
		}


		&:hover {
			&::before {
				opacity: 0;
			}

			&::after {
				opacity: 1;
			}
		}

		@media @breakpointquery-2018-tablet {
			flex: 1 1 100%;
		}
	}

	&__title {
		font-size: 36px;
		line-height: 25px;
		color: @color-2018-white;
		font-family: @font-2018-bold;
		text-transform: none;
		z-index: 1;

		@media @breakpointquery-2018-tablet {
			font-size: 22px;
			line-height: 24px;
		}
	}

	&__subheader {
		font-size: 22px;
		line-height: 24px;
		color: @color-2018-white;
		text-transform: none;
		font-family: @font-2018-light;
		margin-bottom: 0;
		z-index: 1;

		@media @breakpointquery-2018-tablet {
			font-size: 16px;
			line-height: 19px;
		}
	}
}
