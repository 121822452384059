//	HEADER MIXINS
//  ___________________

.fontsize-h1() {

    .font-size( @font-size-h1 );

    @media only screen and (max-width: @phone-barrier) {
        .font-size( @font-size-h1-phone );
    }
}
.fontsize-h2() {

    .font-size( @font-size-h2 );

    @media only screen and (max-width: @phone-barrier) {
        .font-size( @font-size-h1-phone );
    }
}
.fontsize-h3() {

    .font-size(@font-size-h3);

    // @media only screen and (max-width: @phone-barrier) {
    // 	.font-size(1.6);
    // }
}
.fontsize-h4() {
    .font-size( @font-size-h4 );
}
.fontsize-h5() {
    .font-size( @font-size-h5 );
}
.fontsize-h6() {
    .font-size( @font-size-h6 );
}

//	BOXES
//  ___________________

.box-sizing(@bb) {
    -webkit-box-sizing: @bb;
    -moz-box-sizing: @bb;
    box-sizing: @bb;
}

.bgsize(@prop) {
    -webkit-background-size: @prop;
    -moz-background-size: @prop;
    -o-background-size: @prop;
    background-size: @prop;
}
.background-clip( @c: padding-box ) {
    -webkit-background-clip: @c; // Safari 4/5
    -mox-background-clip: @c; // FF 3.6
    background-clip: @c;
}

.box-shadow(@shadow: 0 1px 3px rgba(0,0,0,.3)) {
    -webkit-box-shadow: @shadow;
    -moz-box-shadow: @shadow;
    box-shadow: @shadow;
}

.border-radius(@radius: 4px) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}

.border-bottom-radius-2( @radiush: 4px, @radiusv: 4px ) {
    -webkit-border-bottom-right-radius: @radiush @radiusv;
    -moz-border-bottom-right-radius: @radiush @radiusv;
    border-bottom-right-radius: @radiush @radiusv;
    -webkit-border-bottom-left-radius: @radiush @radiusv;
    -moz-border-bottom-left-radius: @radiush @radiusv;
    border-bottom-left-radius: @radiush @radiusv;
}
.border-top-radius-2( @radiush: 4px, @radiusv: 4px ) {
    -webkit-border-top-left-radius: @radiush @radiusv;
    -moz-border-top-left-radius: @radiush @radiusv;
    border-top-left-radius: @radiush @radiusv;
    -webkit-border-top-right-radius: @radiush @radiusv;
    -mozborder-top-right-radius: @radiush @radiusv;
    border-top-right-radius: @radiush @radiusv;
}

.border-radius-2(@radiush: 4px, @radiusv:4px) {

    .border-bottom-radius-2(@radiush; @radiusv);
    .border-top-radius-2(@radiush; @radiusv);
}

// Single side border-radius
//TODO: fill in the rest with browser prefixes.

.border-top-radius(@radius) {
    border-top-right-radius: @radius;
    border-top-left-radius: @radius;
}

.border-right-radius(@radius) {
    -webkit-border-bottom-right-radius: @radius;
    -moz-border-bottom-right-radius: @radius;
    border-bottom-right-radius: @radius;
    -webkit-border-top-right-radius: @radius;
    -moz-border-top-right-radius: @radius;
    border-top-right-radius: @radius;
}

.border-bottom-radius( @radius: 4px ) {
    border-bottom-right-radius: @radius;
    border-bottom-left-radius: @radius;
}

.border-left-radius(@radius) {
    -webkit-border-bottom-left-radius: @radius;
    -moz-border-bottom-left-radius: @radius;
    border-bottom-left-radius: @radius;
    -webkit-border-top-left-radius: @radius;
    -moz-border-top-left-radius: @radius;
    border-top-left-radius: @radius;
}

.font-size(@sizeValue) {
    @remValue: @sizeValue;
    @pxValue: (@sizeValue * 10);

    font-size: ~"@{pxValue}px";
    font-size: ~"@{remValue}rem";
}

.width(@sizeValue) {
    @remValue: @sizeValue;
    @pxValue: (@sizeValue * 10);

    width: ~"@{pxValue}px";
    width: ~"@{remValue}rem";
}

.height(@sizeValue) {
    @remValue: @sizeValue;
    @pxValue: (@sizeValue * 10);

    height: ~"@{pxValue}px";
    height: ~"@{remValue}rem";
}

.background( @x:auto, @y:auto ) {
    background-image: @spriteUrl;
    background-repeat: no-repeat;
    background-position: @x @y;
}
.spriteBackground( @x:auto, @y:auto ) {
    background-image: @spriteUrl;
    background-repeat: no-repeat;
    background-position: @x @y;
}

// utility to assert a baseline alignment to a given pixel font-size
.baselineTo( @fontsizepx:@fontsize-XS ) {
    &::before {
        content: "M";
        .font-size( @fontsizepx/10 );

        display: inline-block;
        width: 0;
        visibility: hidden;
        opacity: 0;
    }
}

// CSS3 Content Columns
.content-columns(@column-count; @column-gap: @gutter-width) {
    -webkit-column-count: @column-count;
    -moz-column-count: @column-count;
    column-count: @column-count;
    -webkit-column-gap: @column-gap;
    -moz-column-gap: @column-gap;
    column-gap: @column-gap;
}
// How the column breaks, @param {avoid} auto, avoid, avoid-column, avoid-page, inherit, initial
.break-inside( @avoid:auto ) {
    -webkit-column-break-inside: @avoid;
    page-break-inside: @avoid;
    break-inside: @avoid;
}

.hyphens( @h:auto ) {
    -webkit-hyphens: @h;
    -moz-hyphens: @h;
    -ms-hyphens: @h;
    hyphens: @h;
}

.headerparagraphColor (@c) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: @c;
    }
}

.opacity(@opacity: 0.5) {
    -moz-opacity: @opacity;
    -khtml-opacity: @opacity;
    -webkit-opacity: @opacity;
    opacity: @opacity;
    @opperc: @opacity * 100;

    -ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})";
    filter: ~"alpha(opacity=@{opperc})";
}
.blur( @b: 1 ) {
    // TODO:investigate the SVG blur option.
    // filter: url(blur.svg#blur);
    -webkit-filter: blur(unit(@b, px));
    filter: blur(unit(@b, px));
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='@b px');
}

// Gradients
#gradient {
    .horizontal(@startColor: #555, @endColor: #333) {
        background-color: @endColor;
        background-image: -moz-linear-gradient(left, @startColor, @endColor); // FF 3.6+
        background-image: -ms-linear-gradient(left, @startColor, @endColor); // IE10
        background-image: -webkit-gradient(linear, 0 0, 100% 0, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
        background-image: -webkit-linear-gradient(left, @startColor, @endColor); // Safari 5.1+, Chrome 10+
        background-image: -o-linear-gradient(left, @startColor, @endColor); // Opera 11.10
        background-image: linear-gradient(left, @startColor, @endColor); // Le standard
        background-repeat: repeat-x;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)", @startColor, @endColor)); // IE9 and down
    }
    .vertical(@startColor: #555, @endColor: #333) {
        background-color: mix(@startColor, @endColor, 60%);
        background-image: -moz-linear-gradient(top, @startColor, @endColor); // FF 3.6+
        background-image: -ms-linear-gradient(top, @startColor, @endColor); // IE10
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
        background-image: -webkit-linear-gradient(top, @startColor, @endColor); // Safari 5.1+, Chrome 10+
        background-image: -o-linear-gradient(top, @startColor, @endColor); // Opera 11.10
        background-image: linear-gradient(top, @startColor, @endColor); // The standard
        background-repeat: repeat-x;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)", @startColor, @endColor)); // IE9 and down
    }
    .directional(@startColor: #555, @endColor: #333, @deg: 45deg) {
        background-color: @endColor;
        background-repeat: repeat-x;
        background-image: -moz-linear-gradient(@deg, @startColor, @endColor); // FF 3.6+
        background-image: -ms-linear-gradient(@deg, @startColor, @endColor); // IE10
        background-image: -webkit-linear-gradient(@deg, @startColor, @endColor); // Safari 5.1+, Chrome 10+
        background-image: -o-linear-gradient(@deg, @startColor, @endColor); // Opera 11.10
        background-image: linear-gradient(@deg, @startColor, @endColor); // The standard
    }
    .vertical-three-colors(@startColor: #00b3ee, @midColor: #7a43b6, @colorStop: 50%, @endColor: #c3325f) {
        background-color: mix(@midColor, @endColor, 80%);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), color-stop(@colorStop, @midColor), to(@endColor));
        background-image: -webkit-linear-gradient(@startColor, @midColor @colorStop, @endColor);
        background-image: -moz-linear-gradient(top, @startColor, @midColor @colorStop, @endColor);
        background-image: -ms-linear-gradient(@startColor, @midColor @colorStop, @endColor);
        background-image: -o-linear-gradient(@startColor, @midColor @colorStop, @endColor);
        background-image: linear-gradient(@startColor, @midColor @colorStop, @endColor);
        background-repeat: no-repeat;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)", @startColor, @endColor)); // IE9 and down, gets no color-stop at all for proper fallback
    }
    .vertical-four-colors(@startColor: #00b3ee, @midColor1: #7a43b6, @colorStop1: 50%, @midColor2: #7a43b6, @colorStop2: 50%, @endColor: #c3325f) {
        background-color: mix(@midColor1, @endColor, 80%);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), color-stop(@colorStop1, @midColor1), color-stop(@colorStop2, @midColor2), to(@endColor));
        background-image: -webkit-linear-gradient(@startColor, @midColor1 @colorStop1, @midColor2 @colorStop2, @endColor);
        background-image: -moz-linear-gradient(top, @startColor, @midColor1 @colorStop1, @midColor2 @colorStop2, @endColor);
        background-image: -ms-linear-gradient(@startColor, @midColor1 @colorStop1, @midColor2 @colorStop2, @endColor);
        background-image: -o-linear-gradient(@startColor, @midColor1 @colorStop1, @midColor2 @colorStop2, @endColor);
        background-image: linear-gradient(@startColor, @midColor1 @colorStop1, @midColor2 @colorStop2, @endColor);
        background-repeat: no-repeat;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)", @startColor, @endColor)); // IE9 and down, gets no color-stop at all for proper fallback
    }
    .radial(@innerColor: #555, @outerColor: #333) {
        background-color: mix(@innerColor, @outerColor, 50%);
        background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(@innerColor), to(@outerColor));
        background-image: -webkit-radial-gradient(circle, @innerColor, @outerColor);
        background-image: -moz-radial-gradient(circle, @innerColor, @outerColor);
        background-image: -ms-radial-gradient(circle, @innerColor, @outerColor);
        background-repeat: no-repeat;
        // Opera cannot do radial gradients yet
    }
    .striped(@color, @angle: -45deg) {
        background-color: @color;
        background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255, 255, 255, .15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255, 255, 255, .15)), color-stop(.75, rgba(255, 255, 255, .15)), color-stop(.75, transparent), to(transparent));
        background-image: -webkit-linear-gradient(@angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
        background-image: -moz-linear-gradient(@angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
        background-image: -ms-linear-gradient(@angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
        background-image: -o-linear-gradient(@angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
        background-image: linear-gradient(@angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    }
}

// Transformations
.scale(@ratio) {
    -webkit-transform: scale(@ratio);
    -ms-transform: scale(@ratio); // IE9 only
    -o-transform: scale(@ratio);
    transform: scale(@ratio);
}
.scale(@ratioX; @ratioY) {
    -webkit-transform: scale(@ratioX, @ratioY);
    -ms-transform: scale(@ratioX, @ratioY); // IE9 only
    -o-transform: scale(@ratioX, @ratioY);
    transform: scale(@ratioX, @ratioY);
}
.scaleX(@ratio) {
    -webkit-transform: scaleX(@ratio);
    -ms-transform: scaleX(@ratio); // IE9 only
    -o-transform: scaleX(@ratio);
    transform: scaleX(@ratio);
}
.scaleY(@ratio) {
    -webkit-transform: scaleY(@ratio);
    -ms-transform: scaleY(@ratio); // IE9 only
    -o-transform: scaleY(@ratio);
    transform: scaleY(@ratio);
}
.skew(@x; @y) {
    -webkit-transform: skewX(@x) skewY(@y);
    -ms-transform: skewX(@x) skewY(@y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
    -o-transform: skewX(@x) skewY(@y);
    transform: skewX(@x) skewY(@y);
}
.translate(@x; @y) {
    -webkit-transform: translate(@x, @y);
    -ms-transform: translate(@x, @y); // IE9 only
    -o-transform: translate(@x, @y);
    transform: translate(@x, @y);
}
.translate3d(@x; @y; @z) {
    -webkit-transform: translate3d(@x, @y, @z);
    transform: translate3d(@x, @y, @z);
}
.rotate(@degrees) {
    -webkit-transform: rotate(@degrees);
    -ms-transform: rotate(@degrees); // IE9 only
    -o-transform: rotate(@degrees);
    transform: rotate(@degrees);
}
.rotateX(@degrees) {
    -webkit-transform: rotateX(@degrees);
    -ms-transform: rotateX(@degrees); // IE9 only
    -o-transform: rotateX(@degrees);
    transform: rotateX(@degrees);
}
.rotateY(@degrees) {
    -webkit-transform: rotateY(@degrees);
    -ms-transform: rotateY(@degrees); // IE9 only
    -o-transform: rotateY(@degrees);
    transform: rotateY(@degrees);
}
.perspective(@perspective) {
    -webkit-perspective: @perspective;
    -moz-perspective: @perspective;
    perspective: @perspective;
}
.perspective-origin(@perspective) {
    -webkit-perspective-origin: @perspective;
    -moz-perspective-origin: @perspective;
    perspective-origin: @perspective;
}
.transform-origin(@origin) {
    -webkit-transform-origin: @origin;
    -moz-transform-origin: @origin;
    -ms-transform-origin: @origin; // IE9 only
    transform-origin: @origin;
}

// Transitions
.transition(@transition) {
    -webkit-transition: @transition;
    transition: @transition;
}
.transition-property(@transition-property) {
    -webkit-transition-property: @transition-property;
    transition-property: @transition-property;
}
.transition-delay(@transition-delay) {
    -webkit-transition-delay: @transition-delay;
    transition-delay: @transition-delay;
}
.transition-duration(@transition-duration) {
    -webkit-transition-duration: @transition-duration;
    transition-duration: @transition-duration;
}
.transition-transform(@transition) {
    -webkit-transition: -webkit-transform @transition;
    -moz-transition: -moz-transform @transition;
    -o-transition: -o-transform @transition;
    transition: transform @transition;
}

.sim-card-bg(@width: 30%, @height: 450%, @top: -10em, @right: 5%, @zIndex: 0, @bgSize: 100%) {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: @width;
        height: @height;
        top: @top;
        right: @right;
        z-index: @zIndex;
        background: url(/UI/build/images/sim-card-illustration.svg) 0 0 no-repeat;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        background-size: @bgSize;
    }
}

//  _____________________________________________________________________________
//
//  UTILITY-LIKE MIXINS
//  _____________________________________________________________________________

// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.

.clearfix() {
    *zoom: 1; //For IE 6/7 only. Include this rule to trigger hasLayout and contain floats.

    &::before,
    &::after {
        content: " "; // 1
        display: table; // 2
    }

    &::after {
        clear: both;
    }
}
