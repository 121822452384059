.module-listproduct {
    display: table;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .module-listproduct__container {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    .module-listproduct__btncontainer {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 1em;
    }

    .module-listproduct__btn {
        position: relative;
        z-index: 1;
    }

    .module-listproduct__uspcontainer {

        a {
            display: inline-block;
            margin-left: 3px;
            margin-right: 3px;
        }
    }

    .module-listproduct__hallon-slidercontainer {
        padding-top: 40px;

        .hallon-slider {
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, 0%);
            width: 286px;
        }
    }

    .bubble {
        z-index: 2;
        position: relative;
    }

    h2 {
        font-family: @hallon-frutiger-77-bc;
        font-size: @ftsize-hallon-77;
        text-transform: uppercase;
        color: @hallon-white;
        margin-bottom: 0;

        span {
            font-family: @hallon-frutiger-55-roman;
            font-size: @ftsize-hallon-20;
            color: @hallon-white;
            margin-bottom: 0;
            text-transform: none;
            display: block;
            line-height: 1;
        }
    }

    .link-right-arrow {
        margin-top: 2em;
        text-align: center;
        color: @hallon-white;
    }
}
