﻿/* FONT PATH
 * -------------------------- */

@font-face {
  // font-family: 'icomoon';
  // src: url('@{ico-font-path}/icomoon.eot?v=@{ico-version}');
  // src: url('@{ico-font-path}/icomoon.eot?#iefix&v=@{ico-version}') format('embedded-opentype'),
  //   url('@{ico-font-path}/icomoon.woff?v=@{ico-version}') format('woff'),
  //   url('@{ico-font-path}/icomoon.ttf?v=@{ico-version}') format('truetype'),
  //   url('@{ico-font-path}/icomoon.svg?v=@{ico-version}#icomoonregular') format('svg');

	font-family: 'icomoon';
	src:url('@{ico-font-path}/icomoon.eot?ta5tmh');
	src:url('@{ico-font-path}/icomoon.eot?#iefixta5tmh') format('embedded-opentype'),
		url('@{ico-font-path}/icomoon.woff?ta5tmh') format('woff'),
		url('@{ico-font-path}/icomoon.ttf?ta5tmh') format('truetype'),
		url('@{ico-font-path}/icomoon.svg?ta5tmh#icomoon') format('svg');
		
 // src: url('@{ico-font-path}/icomoon.otf') format('opentype'); // used when developing fonts
// src: url('@{ico-font-path}/icomoon.eot');
//   src: url('@{ico-font-path}/icomoon.eot') format('embedded-opentype'),
//     url('@{ico-font-path}/icomoon.woff') format('woff'),
//     url('@{ico-font-path}/icomoon.ttf') format('truetype'),
//     url('@{ico-font-path}/icomoon.svg#icomoonregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
