/// <reference path="../framework.less" />

.remodal {

    &--login {
        max-width: 375px;
        margin: 0;
        padding: 0;
        width: 375px;
        height: 554px;
        max-height: 80vh;
        display: none;

        &.remodal-is-initialized {
            display: inline-block;
        }

        @media @breakpointquery-2018-tablet {
            height: 100%;
            width: 100vw;
            max-height: none;
            max-width: none;
            position: fixed;
            left: 0;
            top: 0;
            border-radius: 0;
        }
    }


    &--no-height {
        height: auto;
        max-height: none;
    }

    &__info-text {
        font-size: @ftsize-hallon-14;
    }

    &__icon {
        width: 40px;
        height: 40px;

        &-background {
            fill: @color-2018-hallon-red;
        }

        &-icon {
            fill: @color-2018-white;
        }
    }

    &__icon-cancelation {
        width: 40px;
        height: 40px;
        margin: 0 auto;
        display: inherit;
    }

    &__background {
        position: absolute;
        top: 40px;
        z-index: 2000;
        right: 0;
        left: 0;
        background: @hallon-light-grey;
        border: 1px solid @hallon-black;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        padding: 20px;
        margin-bottom: 20px;
        margin-top: 30px;
        width: 600px;
        text-align: center;
        margin-left: auto;

        @media @breakpointquery-2018-tablet {
            width: 100vw;
            max-height: none;
            max-width: none;
            position: fixed;
            left: 0;
            top: 0;
            border-radius: 0;
        }
    }

    &__inner-wrapper {
        height: 100%;
        overflow: auto;
        padding: 0 30px 30px 30px;
        margin: 0;
    }

    &__header-container {
        &--center {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__header {
        margin-top: 20px;
        font-size: 27px;
        line-height: 36px;
        text-transform: none;
        font-family: @font-2018-bold;
        color: @color-2018-hallon-red;

        @media @breakpointquery-2018-tablet {
            font-size: 24px;
            line-height: 28px;
        }

        &--small-header {
            font-size: 18px;
            line-height: 24px;
        }

        &--header-left {
            text-align: left;
        }

        &--dark {
            color: @hallon-light-black;
            font-size: @ftsize-hallon-20;
        }
    }



    &__message {
        margin-top: 25px;
        font-size: 16px;
        line-height: 26px;
        font-family: @font-2018-roman;

        @media @breakpointquery-2018-tablet {
            font-size: 14px;
            line-height: 24px;
        }

        &--text-left {
            text-align: left;
        }

        &--small-text {
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__button-wrapper {
        margin-top: 30px;
        display: flex;
        justify-content: center;

        &--aligned {
            align-items: center;
        }
    }

    &__button {
        font-size: 16px;
        line-height: 18px;
        font-family: @font-2018-bold;
        border-radius: 50px;
        transition: all 0.2s linear;
        text-transform: none;
        padding: 12px 35px;
        display: flex;
        justify-content: center;
        cursor: pointer;

        @media @breakpointquery-2018-tablet {
            font-size: 14px;
            line-height: 17px;
            padding: 10px 15px;
        }

        &--confirm {
            color: @color-2018-white;
            background: @color-2018-green;
            border: 2px solid @color-2018-green;

            &:hover {
                background: @color-2018-green-hover;
                border: 2px solid @color-2018-green-hover;
            }
        }

        &--decline {
            color: @color-2018-hallon-red;
            background: @color-2018-white;
            border: 2px solid @color-2018-hallon-red;

            &:hover {
                background: @color-2018-hallon-red;
                border: 2px solid @color-2018-hallon-red;
                color: @color-2018-white;
            }
        }

        &--margin-left {
            margin-left: 10px;
        }

        &--margin-right {
            margin-right: 10px;
        }
    }

    &__small-text {
        font-size: 12px;
        line-height: 14px;
        margin-top: 20px;
    }

    &__close-toggle {
        position: absolute;
        right: -20px;
        top: -20px;
        display: block;
        outline: none;
        background: none;
        border: 0;
        margin: 0;
        padding: 0;
        height: 43px;
        width: 43px;
        z-index: 10;
        display: flex;
        justify-content: center;
        cursor: pointer;

        @media @breakpointquery-2018-tablet {
            display: none;
        }
    }

    &__close-modal {
        height: 43px;
        width: 43px;
        -webkit-filter: drop-shadow( 0px 3px 3px rgba(0, 0, 0, .3));
        filter: drop-shadow( 0px 3px 3px rgba(0, 0, 0, .3));
        border-radius: 50px;
    }

    &__close-toggle-icon {
        width: 19px;
        pointer-events: all;
    }

    &__close-toggle-line {
        fill: none;
        stroke: @color-2018-white;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-width: 2px;
        transform: rotate(0) translateY(0) translateX(0);
        opacity: 1;
        transform-origin: 20px 10px;
        transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;

        &:nth-of-type(1) {
            transform: rotate(-45deg) translateY(0) translateX(0);
            transform-origin: 35px 21px;
        }

        &:nth-of-type(2) {
            transform: rotate(45deg) translateY(0em) translateX(0em);
            transform-origin: 34px 29px;
        }
    }

    &__close-toggle-mobile {
        display: block;
        outline: none;
        background: none;
        border: 0;
        margin: 0;
        height: 50px;
        width: 50px;
        flex: 0 0 71px;
    }

    &__close-toggle-mobile-icon {
        width: 50px;
        height: 50px;
        cursor: pointer;
        pointer-events: all;
    }

    &__close-toggle-mobile-line {
        fill: none;
        stroke: @color-2018-hallon-red;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-width: 2px;
        transform: rotate(0) translateY(0) translateX(0);
        opacity: 1;
        transform-origin: 20px 10px;
        transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;

        &:nth-of-type(1) {
            transform: rotate(-45deg) translateY(0) translateX(0);
            transform-origin: 35px 21px;
        }

        &:nth-of-type(2) {
            transform: rotate(45deg) translateY(0em) translateX(0em);
            transform-origin: 34px 29px;
        }
    }

    &__close-link-botton {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__list {
        margin: 0 !important;
    }

    &__list-item {
        margin-bottom: 25px;
        font-family: @font-2018-roman;
    }

    ol {
        margin: 0 !important;

        li {
            font-family: @font-2018-roman;
        }
    }
}
