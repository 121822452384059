/// <reference path="../framework.less" />


//This is old colors for myPages before 2018
@color-grey-mypages-old: @color-gray50;
@font-blackCondensed-mypages-old: @hallon-frutiger-77-bc;

/* The switch - the box around the slider */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 29px;

    &__checkbox {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .toggle-switch__slider {
            background-color: @color-2018-linkblue;

            &:before {
                -webkit-transform: translateX(21px);
                -ms-transform: translateX(21px);
                transform: translateX(21px);
            }
        }

        &:focus + .toggle-switch__slider {
            box-shadow: 0 0 1px @color-2018-linkblue;
        }
    }

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;

        &:before {
            position: absolute;
            content: "";
            height: 21px;
            width: 21px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        &--round {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
            }
        }
    }
}
