.hardware {
	max-width: calc(@desktop-special-breakpoint + 180px);
	margin: 0 auto;

	@media only screen and (min-width: @tablet-breakpoint-hallon) {
		padding: 0 2em;
	}

	@media only screen and (max-width: @tablet-breakpoint-hallon) {
		padding: 0 .5em;
	}

	.hardware__container {
		@media only screen and (min-width: @tablet-breakpoint-hallon) {
			&:not(.hardware__container-noflex) {
				.flex-display(flex);
				.flex-direction(row);
			}
		}
	}

	.hardware__img-container {
		width: ~"calc(100% - 2em)";
		margin-top: 1em;
		margin-bottom: 2em;

		@media only screen and (min-width: @tablet-breakpoint-hallon) {
			.flex(0 1 50%);
		}

		img {
			margin: 0 auto;
			display: block;
			max-width: 100%;
		}

		@media only screen and (max-width: @tablet-breakpoint-hallon) {
			.carousel-container {
				margin: 0 -1em 0 1em;
			}
		}
	}

	.hardwareProduct {
		color: @hallon-darkish-red;
		text-transform: none;

		@media only screen and (max-width: @tablet-breakpoint-hallon) {
			font-family: @hallon-frutiger-77-bc;
			margin: 1em 1em 0;

			&--desktop {
				display: none;
			}
		}

		@media only screen and (min-width: @tablet-breakpoint-hallon) {
			font-family: @hallon-frutiger-65-bold;
			line-height: @lineheight-XL;
			margin: .5em 0;

			&--mobile {
				display: none;
			}
		}
	}

	h1.hardwareProduct {
		@media only screen and (max-width: @tablet-breakpoint-hallon) {
			font-size: @ftsize-hallon-24;
		}

		@media only screen and (min-width: @tablet-breakpoint-hallon) {
			font-size: @ftsize-hallon-36;
		}
	}

	h2.hardwareProduct {
		margin: 1em 0;
		width: 100%;
	}

	.hardware__container-noflex {
		padding: 0 1em;


		h1 {
			margin: 1em 0;
		}

		> p,
		> div {
			margin: 1em 0 0;
			font-size: 1.6rem;

			@media only screen and (min-width: @tablet-breakpoint-hallon) {
				width: ~"calc(100% + 1em)";
			}

			fieldset {
				margin-left: -.25em;
			}

			label {
				color: @hallon-darkish-red;
				cursor: pointer;
				font-family: @hallon-frutiger-77-bc;
			}
		}

		@media only screen and (max-width: @tablet-breakpoint-hallon) {
			> p {
				margin: 1em 1em 0;
			}
		}
	}

	.hardware__subscription-description {
		p {
			max-width: 80ex;
		}

		div {
			margin: 1em 0;
		}
	}

	.hardware__subscriptions {
		&--organism {
			.flex-display();
			.flex-wrap(wrap);
			.justify-content(flex-start);
			.align-content(space-between);

			@media only screen and (max-width: @tablet-breakpoint-hallon) {
				.flex-direction(column);
			}

			h2,
			h3,
			p,
			strike,
			li {
				text-transform: none;
				color: @hallon-darkish-red;
			}

			&-summary {
				z-index: 1;
				position: fixed;
				bottom: -100%;

				@media only screen and (max-width: @tablet-breakpoint-hallon) {
					position: relative;
					bottom: 0;
				}

				.hardware__subscriptions--molecule {
					margin: 0;
					.flex(33% 0 0);
					max-width: none;

					&.dimmed {
						opacity: 1;
					}
				}

				.hardware__subscriptions--atom {
					min-height: inherit;
					background: @hallon-white;
					padding: 1em 2em;
					.justify-content(center);
					.flex-display();
					.flex-direction(column);

					p:nth-child(3) {
						font-family: @hallon-frutiger-65-bold;
					}

					&-summary-left {
						border-right: 2px solid @hallon-medium-grey;
						border-radius: 8px 0 0 8px;
					}

					&-summary-left,
					&-summary-right {
						color: @hallon-black;
					}

					&-summary-right {
						background: @hallon-darkish-red;
						border-radius: 0 8px 8px 0;

						h3,
						p,
						span {
							color: @hallon-white;
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale;
						}

						p {
							&:first-child {
								font-family: @hallon-frutiger-77-bc;
							}

							&:nth-child(2) {
								font-size: @ftsize-hallon-12;
							}
						}

						h3 {
							font-size: @ftsize-hallon-36;
							font-family: @hallon-frutiger-65-bold;
							line-height: .15em;
							letter-spacing: -1px;
						}

						.hardware__subscriptions--final-price {
							position: absolute;
							right: 2em;
							bottom: 1em;
							text-align: right;
							.flex-display();
							.flex-direction(column);
							.justify-content(flex-end)
						}
					}

					@media only screen and (max-width: @tablet-breakpoint-hallon) {
						min-height: 6em;
						padding: 1em;

						&-summary-left {
							border-right: 0;
							border-bottom: 2px solid @hallon-medium-grey;
							border-radius: 8px 8px 0 0;
						}

						&-summary-right {
							border-radius: 0 0 8px 8px;
						}

						.hardware__subscriptions--final-price {
							right: 1.5em;
							bottom: 1.25em;
						}
					}
				}
			}
		}

		&--molecule {
			.flex(33.3333% 0 0);
			flex-basis: ~"calc(33.3333% - 1em)";
			max-width: ~"calc(33.3333% - 1em)";
			margin: 0 1em 1em 0;
			overflow: hidden;
			position: relative;
			transition: .4s opacity;

			@media only screen and (max-width: 1300px) and (min-width: @tablet-breakpoint-hallon) {
				margin: 0 1em 1em 0;
			}

			@media only screen and (max-width: @tablet-breakpoint-hallon) {
				.flex(100% 1 1);
				flex-basis: 100%;
				margin: 0 0 1em;
				max-width: none;
			}

			label {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 2;
			}

			input[type="radio"] {
				visibility: hidden;
			}

			.hardware__subscriptions--expandable {
				.flex(100% 1 1);
				flex-basis: 100%;
				margin: 0 0 1em;
				max-width: none;
				position: relative;


				&.dimmed {
					opacity: .5;
				}

				&.number-sub {
					z-index: 2;
				}
			}
		}

		&--molecule.dimmed {
			opacity: .5;
		}

		&--atom {
			position: relative;
			height: 100%;
			min-height: 200px;

			li:not(:empty) {
				line-height: 1.6;

				&::before {
					content: '';
					width: .4em;
					height: .4em;
					background: @hallon-white;
					margin: 0 .5em -.1em 0;
					display: inline-block;
					border-radius: 50%;
					border: 4px solid #891845;
				}
			}

			&:not(.hardware__subscriptions--atom-offer) {
				.hardware__subscriptions--price {
					border-left: 2px solid @hallon-light-grey;
					padding-left: 2em;
				}
			}

			.hardware__subscriptions--price {
				p {
					text-align: center;
				}
			}

			.subscription-offer {
				display: none;
			}

			&-offer {
				&::after {
					content: '';
					display: block;
					position: absolute;
					bottom: -5em;
					right: -3.25em;
					height: 14em;
					width: 14em;
					background: @hallon-yellow;
					z-index: 0;
					border-radius: 50%;
				}

				.subscription-offer {
					display: block;
				}
			}

			&-phonenumber {
				.flex-display();
				.align-items(center);
				font-family: @hallon-frutiger-65-bold;
				font-size: @ftsize-hallon-31;
				color: @hallon-darkish-red;
				margin-top: -.1em;
				margin-bottom: -.1em;

				&::before {
					content: '';
					height: 56px;
					width: 56px;
					background: url(/UI/build/images/sim-card.svg) bottom center;
					background-size: 80%;
					background-repeat: no-repeat;
					margin-right: .5em;
				}
			}
		}

		&--discount {
			text-align: left;
			position: absolute;
			left: 0;
			bottom: 0;
			padding-bottom: 0.6em;
			padding-left: 1.4em;
			color: @hallon-darkish-red;

			p {
				line-height: 1.6;
				font-family: @hallon-frutiger-65-bold;
				font-size: 1.4rem;
			}
		}

		&--price {
			text-align: right;
			z-index: 1;
			position: absolute;
			right: 0;
			bottom: 0;
			display: inline-block;
			padding-top: .5em;

			h3 {
				font-size: @ftsize-hallon-60;
				font-family: @hallon-frutiger-65-bold;
				line-height: .5em;
				letter-spacing: -3px;
			}
		}
	}

	#buyButton {
		right: 0;
		position: absolute;
		bottom: 0;
		transform: translateX(-14px) translateY(100%) translateY(1em);

		@media only screen and (max-width: @tablet-breakpoint-hallon) {
			width: 100%;
			display: block;
			position: relative;
			margin: 1em auto 4em;
			transform: none;
		}
	}

	.hardware__content {
		display: block;

		&::after {
			content: '';
			clear: both;
			display: block;
			height: 1em;
		}

		@media only screen and (min-width: @tablet-breakpoint-hallon) {
			margin: 1em 1em 2em;
			.flex(1 0 50%);
		}

		@media only screen and (max-width: @tablet-breakpoint-hallon) {
			margin: 1em 1em 2em;
		}

		p,
		li {
			font-family: @hallon-frutiger-55-roman;
			font-size: @ftsize-hallon-14;
		}

		.hardware__product-description {
			p,
			li {
				margin-bottom: 1em;
			}
		}

		.hardware__product-description {
			p,
			li {
				// overwrite some cases of inline styling
				background-color: transparent !important;
				margin: 0 0 1em !important;
				font: @ftsize-hallon-14 @hallon-frutiger-55-roman !important;
				color: @hallon-darkish-red !important;
				line-height: 1.4285 !important;
			}
		}

		h2 {
			text-transform: none;
			font-family: @hallon-frutiger-65-bold;

			@media only screen and (min-width: @tablet-breakpoint-hallon) {
				font-size: @ftsize-hallon-24;
			}

			@media only screen and (max-width: @tablet-breakpoint-hallon) {
				font-size: @ftsize-hallon-18;
			}
		}

		p,
		li,
		h2,
		.link-down-arrow {
			color: @hallon-darkish-red;
		}

		.link-down-arrow {
			cursor: pointer;
			font-family: @hallon-frutiger-77-bc;
			display: block;

			@media only screen and (min-width: @tablet-breakpoint-hallon) {
				display: inline-block;
			}

			&:hover,
			&:visited,
			&:focus {
				color: @hallon-wine-red;
			}
		}

		ul li {
			margin: 0 0 1em;
		}
	}

	.hardware__more-info {
		display: none;

		li {
			color: @hallon-heavy-grey;
		}
	}

	[data-product-instock="true"] {
		color: @hallon-light-green;
	}

	[data-product-instock="false"] {
		color: @hallon-light-red;
	}

	.hardware__stock {
		margin: 1em 0 0;
	}

	.hardware__color-picker,
	.hardware__become-customer,
	.hardware__payment-method {
		margin: 1.5em 0;
	}

	.hardware__become-customer {
		ul {
			margin: .5em 0;
		}

		li {
			line-height: @lineheight-XXL;
			color: @hallon-heavy-grey;
			letter-spacing: -.5px;
		}

		input {
			display: none;
		}

		label::before {
			content: '';
			width: .5em;
			height: .5em;
			background: @hallon-white;
			margin: -.35em .5em;
			display: inline-block;
			border-radius: 50%;
			border: 6px solid @hallon-light-grey;
		}

		input:checked + label::before {
			border-color: @hallon-light-blue;
		}

		input[disabled="disabled"] + label span {
			text-decoration: line-through;
		}

		p {
			margin-left: 2.85em;
		}
	}

	.hardware__payment-method p {
		color: @hallon-heavy-grey;
	}

	.hardware__buy-hwlink {
		cursor: pointer;
		clear: both;
		border: 1px solid transparent;
		border-bottom-left-radius: 7px;
		border-bottom-right-radius: 7px;
		display: block;
		padding: 1em;
		margin: 6em -1em -1em;

		@media only screen and (min-width: @tablet-breakpoint-hallon) {
			float: left;
			margin: 2em -1em -1em;
			padding: 1em;
		}

		&--no-stock {
			text-decoration: line-through;
			cursor: default;
			visibility: hidden;
			height: 0;

			@media only screen and (min-width: @tablet-breakpoint-hallon) {
				visibility: visible;
				height: auto;
			}
		}

		&--selected {
			background: @hallon-darkish-red;
			border: 1px solid @hallon-darkish-red;
			color: @hallon-white;
		}
	}

	.hardware__hw-price {
		color: @hallon-dark-grey;
		font-family: @hallon-frutiger-65-bold;

		span {
			font-size: @ftsize-hallon-31;
			display: block;
			text-align: right;
		}

		&--selected {
			color: @hallon-light-black;
		}

		@media only screen and (max-width: @tablet-breakpoint-hallon) {
			float: none;
			clear: both;
			margin: 4em 0 -5em;

			span {
				text-align: left;
			}
		}
	}

	&[data-purchase-flow-step="1"] {
	}

	&[data-purchase-flow-step="2"] {
		display: none;
		margin-bottom: -3.75em;
		padding-top: .5em;

		@media only screen and (max-width: @tablet-breakpoint-hallon) {
			padding-bottom: 3.75em;
		}
	}
}

.hardware-company-product__more-info {
	display: none;
}

.hardware-company-product__content {
	margin-left: 80px;
}

label[for="student-55plus"] {
	&.hallon-btn--loading::after {
		border: 2px solid rgba(0, 0, 0, .5);
		border-left-color: #000;
		border-top-color: #000;
	}
}
