.wysiwyg{

  width: auto;

  @media only screen and (min-width: @tablet-breakpoint-hallon) {
    width: 390px;
  }

  h2{
    font-family: @hallon-frutiger-65-bold;
    font-size: 16px;
    text-transform: none;
    padding-bottom: 20px;
    margin:0;
  }

  h3{
    text-transform: uppercase;
    font-family: @hallon-frutiger-77-bc;
    font-size: 27px;
    color: @hallon-dark-grey;
    padding-bottom: 20px;
    margin:0;
  }

  p{
    font-family: @hallon-frutiger-55-roman;
    font-size: 16px;
    padding-bottom: 20px;
  }

  ul{
    font-family: @hallon-frutiger-55-roman;
    font-size: 16px;
    padding-bottom: 20px;

    li{
      &:not(:last-child){
        margin-bottom: 20px;
      }
    }
  }


}

.wysiwyg--point{

  ul{
    list-style: disc;
    padding-left: 20px;
    width: auto;


    li{
      padding-left:15px;
    }
  }

}

.wysiwyg--check{

  ul{
    margin-left: 20px;


    li{
      text-indent: -35px;
      margin-left: 20px;

      &:before{
        font-family: hallon;
        content: "\e914";
        color: @hallon-dark-red;
        margin-right: 13px;
        vertical-align: bottom;
      }
    }

  }
}
