.messagebody{

  .messagebody__cancel-link{
    font-family: @hallon-frutiger-77-bc;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    margin-top:18px;
    display: block;
  }

}