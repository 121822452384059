[data-multistepform] {

	fieldset {

		display: none;
		background-color: white;

		&.is-active {

			display: block;
		}

	}

	.csstransforms3d & {

		@duration: 400ms;
		
		.perspective( 2000px );
		.transition(height @duration ease-in); // Height given by JS.
		position: relative;

		fieldset {

			.transition(all @duration ease-in);
			.transform-origin( bottom );

			&.out {

				.rotateX(0deg);
				.opacity(1);

				&.animate{
					
					.rotateX(90deg);
					.opacity(0);					
				}
			}

			&.in {

				position: absolute;
				bottom: 0;
				.rotateX(-90deg);
				.opacity(0);

				&.animate{
					
					.rotateX(0deg);					
					.opacity(1);
				}
			}

		}

	}
}