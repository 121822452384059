/// <reference path="../framework.less" />

.heading-h1 {
	font-size: 48px;
	color: @color-2018-hallon-red;
	text-transform: none;
	font-family: @font-2018-black;
	line-height: 57px;

	@media @breakpointquery-2018-mobile {
		font-size: 38px;
		line-height: 45px;
	}
}

.heading-h2 {
	font-size: 24px;
	color: @color-2018-hallon-red;
	text-transform: none;
	font-family: @font-2018-bold;
	line-height: 28px;

	@media @breakpointquery-2018-mobile {
		font-size: 18px;
		line-height: 21px;
	}
}

.heading-h3 {
	font-size: 48px;
	color: @color-2018-hallon-red;
	text-transform: none;
	font-family: @font-2018-bold;
	line-height: 1.22em;

	@media @breakpointquery-2018-mobile {
		font-size: 38px;
		line-height: 45px;
	}
}

.heading-h4 {
	font-size: 48px;
	color: @color-2018-hallon-red;
	text-transform: none;
	font-family: @font-2018-bold;
	line-height: 1.22em;

	@media @breakpointquery-2018-mobile {
		font-size: 38px;
		line-height: 45px;
	}
}