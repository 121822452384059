﻿// ww-expect "mixin"
// ww-expect "variables"
.SelfServicePage {

    .pHistory-pagination-prev {
        float: left;
        display: none;
    }

    .pHistory-pagination-next {
        float: right;
        display: none;
    }

    .pHistory-Loader {
        display: none;
        text-align: center;
        margin: 20px;
    }

    .pHistory-Loader-icon {
        width: 65px;
        height: 65px;
    }


    #pHistory-showMore-container {
        padding: 10px;
        display: none;
    }

    #pHistory-emptyMessage {
        display: none;
    }

    .purchase-history {


        .showhide {
            display: none;
            margin: 20px 0;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            display: none;
        }

        table thead tr {
            display: table-row;
        }

        table thead tr th {
            font-weight: bold;
            text-transform: uppercase;
        }

        table td {
        }

        table tbody tr {
            border-bottom: 1px solid #eee;
        }

        th {
            font-weight: bold;
        }

        td, th, li {
            padding: 0.4em;
            text-align: left;
            .font-size(1.5);
        }

        a {
            vertical-align: middle;
        }

        img.invoice {
            padding: 2px 20px 2px 0px;
            vertical-align: middle;
        }

        img.specification {
            padding: 2px 22px 2px 2px;
            vertical-align: middle;
        }

        // @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
        @media only screen and ( max-width: @hw-tablet ) {

            table, tbody, th, td, tr, ul, li {
                display: block;
            }
            // thead tr {
            // 	position: absolute;
            // 	top: -9999px;
            // 	left: -9999px;
            // }
            thead {
                display: none;
            }

            tr,
            ul {
                border-bottom: 1px solid #eee;
                margin: 1em 0;
                padding-bottom: 1em;
            }

            td,
            li {
                /* Behave  like a "row" */
                border: none;
                position: relative;
                padding-left: 50%;
                .font-size(1.5);
                vertical-align: bottom;
            }

            li:last-child {
            }

            td:before,
            li:before {
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 6px;
                left: 6px;
                width: 45%;
                padding-right: 10px;
                white-space: nowrap;
                font-weight: bold;
                .font-size(1.7);
            }

            td,
            li {

                &:before {
                    content: attr(data-title);
                }
            }

            .no-result {
                border: none;

                td {
                    padding: 0;
                    border: none;
                }

                td:before {
                    display: none;
                }
            }
        }
    }

    .selfservicepart {

        .description {

            p {
                margin-bottom: @margin-ML;
            }
        }
    }
    // #usageList {
    // 	display: none;
    // 	margin-top: -2em;
    // 	padding-bottom: 2em;
    // 	h2 {
    // 		margin-top: 1em;
    // 	}
    // }
    // .usage-list {
    // 	.usage-count {
    // 		margin: -10px 0 10px 0;
    // 		font-style: italic;
    // 	}
    // }
    // .slider-handle {
    // 	cursor: pointer;
    // 	color: #a8133e;
    // 	margin-top: 10px;
    // 	margin-bottom: 10px;
    // 	display: inline-block;
    // }
    .pinBox {
        min-height: inherit !important;

        .see-pin-code-handler {
            margin-top: 20px;
            display: inline-block;
        }

        .see-pin-code {
            display: none;
        }

        .pin-item {
            h3 {
                margin-bottom: 0;
            }

            .pin-item-info {
                font-style: italic;
                margin-top: 5px;
            }

            border-bottom: 1px solid #efeeec;
            padding-bottom: 10px;
            margin-bottom: 10px;

            &.last {
                border-bottom: 0;
            }
        }
    }

    .summaryBox {
        // h2 {
        // 	margin-bottom: 10px;
        // }
        // h3 {
        // 	margin: 0 0 6px 0;
        // }
        // p {
        // 	margin: 0 0 10px;
        // }
        // .usage-item span {
        // 	font-style: normal;
        // }
        .summaryBox-packageinfo {
            .button-link {
                margin-top: 10px;
            }

            margin-bottom: 20px;
        }
    }

    .change-cost-control-ajax-loader {
        position: relative;
        top: 3px;
        margin-left: 10px;
        display: none;
    }

    .packageSummaryBox {
        .summaryText {
            margin-top: 20px;
        }
    }
    // .alertbox {
    // 	width: 100% !important;
    // 	padding-left: 0 !important;
    // }
    .button-link {
        margin: 0 auto @margin-M auto;
    }

    .module.transparent .right .button-link {
        margin-top: 0;
    }

    .module.transparent {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    &.losenord, &.anvandarnamn, &.abonnemang, &.data, &.balance, &.tanka {
        .grid-pad {
            max-width: 600px;
        }
    }
    // vvvv remove vvvv
    // &.dataremove .col-1-1.simpleformpage .module {
    // 	p {
    // 		margin-bottom: 20px;
    // 	}
    // }
    // .col-1-1.simpleformpage .module, .col-1-1.selfservicepart .module {
    // 	.border-radius(4px);
    // 	.box-shadow();
    // 	h2 {
    // 		margin-top: 0;
    // 	}
    // 	.field-validation-error {
    // 		margin-bottom: 20px;
    // 	}
    // }
    // ^^^^ remove ^^^^
    .passwordinstructions, .editor-field, .field-validation-error {
        margin-bottom: @margin-M;
    }
    // h1 {
    // 	-webkit-margin-before: 0;
    // 	-webkit-margin-after: 0;
    // 	margin-top: 20px;
    // 	margin-bottom: 30px;
    // 	line-height: 37px;
    // }
    // TODO: remove when phased out
    p.bottomborder {
        margin-bottom: 20px;
    }

    .surroundIt {
        overflow: hidden;
        margin-bottom: 20px;

        img {
            float: left;
            margin-right: 10px;
            margin-bottom: 10px;
        }

        .infobox {
            float: left;
            display: inline-block;
            margin: 0;

            h3 {
                margin: 0 0 5px 0;
            }
        }

        @media only screen and (max-width: 700px) {
            .infobox {
                float: none;
                display: block;
            }
        }

        .button-link {
            float: right;

            &.disabled {
                &:hover {
                    color: #fff;
                }
            }
        }

        .right {
            width: 40%;
        }
    }

    .readmore {
        margin-top: 20px;
    }
    //.abort {.font-size(1.6);}
    .secondStep {
        display: none;
    }

    .areyousure span {
        font-weight: bold;
    }

    .areyousurecash {
        margin-top: 20px;
        padding-top: 20px;
        border-top-color: #efeeec;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    // TODO: remove when phased out
    h2.bottomborder, p.bottomborder, .surroundIt.bottomborder {
        margin-top: 0;
        padding-bottom: 20px;
        border-bottom-color: #efeeec;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }
    // input[type="submit"] {
    // 	border: none;
    // }
    .ajax-partial-form-container {
        select {
            width: 100%;
        }

        .col-1-1, .col-1-2 {
            width: 100%;
            margin-top: 10px !important;
            padding-right: 0;
        }

        .ajaxCallElement {
            float: left;
            margin-top: 20px !important;

            &.button-link {
                float: none;
                clear: both;
                margin: 20px auto 0 auto !important;
            }
        }

        textarea {
            width: 100%;
        }

        input[type="text"] {
            width: 100%;
        }
    }

    .changelink {
        overflow: hidden;

        h3 {
            float: left;
        }

        a {
            float: right;
            margin-top: 18px;
        }
    }

    .selfserv-header {
        position: relative;
        //padding-left:20px;
        .icon-mobile {
            background: url(/UI/build/images/sprite.png) -222px -351px;
            width: 33px;
            height: 33px;
            float: right;
            margin-top: -2px;
        }

        .icon-broadband {
            background: url(/UI/build/images/sprite.png) -302px -351px;
            width: 32px;
            height: 32px;
            float: right;
            margin-top: -1px;
        }
        // TODO: Remove when user pages are redesigned and these icons are defunkt.
        .icon-mobile, .icon-broadband {
            position: absolute;
            right: 20px;
            top: 20px;
        }
    }

    .header-container {
        margin-bottom: @margin-M;

        .module {
            background-color: @tre-darkgray-color;
            .border-radius(4px);
            .box-shadow();
        }

        h2 {
            display: inline;
            margin: 0;
            color: #fff;
        }
    }

    .isoClick {
        &.isoClick {
            cursor: pointer;
        }
    }
    // .col-1-3 {
    // 	float: left;
    // 	//overflow:hidden;
    // 	.surround {
    // 		.box-shadow(0 0 1px 1px rgba(0, 0, 0, .1));
    // 		.border-radius(4px);
    // 		overflow: hidden;
    // 	}
    // 	.surround > div {
    // 		float: left;
    // 		width: 100%;
    // 		/*
    // 			border-right:1px solid #000;
    // 			border-left:1px solid #000;
    // 			border-bottom:1px solid #000;
    // 			&:first-child {border-top:1px solid #000;}
    // 			*/
    // 		.module {
    // 			cursor: pointer;
    // 			height: 52px;
    // 			padding-top: 17px;
    // 			/*
    // 				p {font-size:1.2rem;}
    // 				*/
    // 			p {
    // 				text-transform: uppercase;
    // 				font-weight: bold;
    // 				span {
    // 					text-transform: normal;
    // 					font-weight: normal;
    // 				}
    // 				+span {
    // 					width: 13px;
    // 					height: 22px;
    // 					.background(-61px, 0);
    // 					float: right;
    // 					position: relative;
    // 					top: -21px;
    // 					right: -4px;
    // 				}
    // 			}
    // 			&.active {
    // 				background-color: @tre-gray-color;
    // 				p {
    // 					color: #fff;
    // 					+span {
    // 						display: none;
    // 					}
    // 				}
    // 			}
    // 			.fade {
    // 				color: #666;
    // 				font-style: italic;
    // 				font-size: 90%;
    // 				margin-bottom: 5px;
    // 			}
    // 		}
    // 	}
    // }
    /*.oneSubscription .numberview article .module{
		min-height:272px;
	}*/
    // .moreSubscriptions .numberview article .module {
    //     min-height: 327px;
    //     margin: 0 10px 20px 10px;
    // }
    // .oneSubscription .numberview article .module {
    //     margin: 0 10px 20px 10px;
    // }
    .col-1-1 {
        .module {
            .border-radius(4px);
            .box-shadow();

            &.numberview {
                .box-shadow(none);
                .border-radius(0);
            }
        }
    }

    .number-menu, .details-column {
        margin-bottom: @margin-L;
    }

    .details-column {
        // float: left;
        &.oneSubscription {
            width: 100%;

            .selfserv-header .module {
                h2 {
                    display: inline-block;
                    color: #fff;
                }

                p {
                    display: inline-block;
                    margin-left: 20px;
                    .font-size(2.6);
                    color: #fff !important;

                    a {
                        color: #fff;
                    }
                }

                .service-type {
                    display: inline-block;
                }
            }
        }

        .isotope {
            .isotope-item {
                margin: 0 1%; // TODO: invest some time in figuring out why the semantic grid system doesn't work with isotope masonry.
            }
        }

        .module {
            .border-radius(4px);
            .box-shadow();

            &.numberview {
                .box-shadow(none);
                .border-radius(0);
                // margin: 0 -10px;
                h3 {
                    margin-top: 0;
                }
                // overflow: visible !important;
                padding: 0;
                background-color: transparent;
            }

            .button-link {
                display: block;
                margin-top: 20px;
            }
        }

        .account-information, .numberview {
            .module {
                margin-bottom: @margin-M;
            }
        }

        .module.numbercontainer {
            background-color: #ffd146;
            min-height: inherit !important;

            p {
                font-style: italic;
            }

            &.error {
                background-color: @tre-error-color;

                * {
                    color: #fff;
                }
            }
        }
        // .module.numberview article {
        //     padding-right: 0;
        // }
        // .module.numberview article {
        //     width: 49.89%;
        //     &.left {
        //         float:left;
        //         padding-right: 10px;
        //     }
        //     &.right {
        //         float: right;
        //         padding-left: 10px;
        //     }
        /*
			> .module {margin:10px;}
			*/
        /*
			&:not(.isotope-hidden):nth-child(2n+1) > .module {margin-right:5px}
			&:not(.isotope-hidden):nth-child(2n) > .module {margin-left:5px}
			*/
    }
    // .module:first-child {
    //     margin-bottom: 20px;
    // }
    .module.account-heading {
        clear: both;
        background-color: @tre-darkgray-color;
        //.border-radius(4px);
        //.box-shadow();
        h2 {
            color: #fff;
            margin: 0;
        }
    }

    .grid.account-information {
        .col-1-2 {
            margin-top: 20px;
        }

        .left {
            padding-right: 10px;
        }

        .right {
            padding-right: 0;
            padding-left: 10px;
        }

        .bonus {
            input[type="text"] {
                min-height: 37px;
                margin-bottom: 20px;
                padding-left: 10px;
                width: 100%;
            }
        }

        .neworder {
            .button-link + p {
                margin-top: 20px;
                font-style: italic;
            }
        }
    }
    //.module.numberview article {padding-right:0px !important;}
    // TODO: remove when phased out
    .editor-field.bottomborder {
        padding-bottom: 40px;
        border-bottom: 1px solid #efeeec;
    }
    // .usage-overview {
    // 	float: right;
    // 	margin-top: -63px;
    // 	color: #3e3a37;
    // 	font-style: italic;
    // }
    .module .errorcontainer {
        background: #c72100;
        min-height: inherit !important;
        color: white;

        h2, p {
            color: white;
        }

        p {
            font-style: italic;

            a {
                font-weight: bold;
                text-decoration: underline;
                color: white;
            }
        }
        // TODO: remove when phased out
        h2.bottomborder {
            border-color: #d24b30 !important;

            span {
                display: block;
                float: left;
                height: 24px;
                width: 24px;
                background: url(/UI/build/images/sprite.png);
                background-position: -8px -342px;
                margin: 3px 10px 0 0;
            }
        }

        .button-link {
            background: white !important;
            color: #3e3a37;
            margin-top: 20px;
            width: 215px !important;
        }
    }

    .numberview {
    }

    .alertbox .button-link.terminated {
        background-image: none;
        margin: 0px 10px 40px 0 !important;
        width: 250px;
    }
    // .myPott {
    // 	h2 {
    // 		position: relative;
    // 		.help-icon {
    // 			position: absolute;
    // 			right: -5px;
    // 			top: 4px;
    // 		}
    // 	}
    // 	.slider-handle {
    // 		margin-bottom: 20px;
    // 	}
    // }
    .help-icon {
        width: 22px;
        height: 22px;
        display: inline-block;
        background: url(/UI/build/images/sprite.png) -19px -172px;
        cursor: pointer;
    }

    .module.is-inactive {
        color: #b1a8a2;

        p, a, h1, h2, h3, span, div {
            color: #b1a8a2 !important;
        }

        a {
            cursor: default;
        }

        .button-link {
            background: #b1a8a2;
            color: white !important;
        }
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .module .errorcontainer h2.bottomborder span {
            background-size: 200px 200px;
            background-position: -23px -165px;
        }

        .help-icon {
            background-size: 200px 200px;
            background-position: -22px -80px;
        }
    }
}