.press-list{



  .main-container__grid{
    background:@hallon-white;
  }

  h1{
    font-family: @hallon-frutiger-77-bc;
    font-size: @ftsize-hallon-48;
    color: @hallon-heavy-grey;
  }

  article{
    padding-bottom:2em;
    border-bottom:1px solid @hallon-light-grey;
    padding-top:2em;

    h2{
      font-family: @hallon-frutiger-77-bc;
      font-size: @ftsize-hallon-24;
      text-align: left!important;
      color: @hallon-heavy-grey;
      text-transform: none;
    }
    p{
      margin-bottom:1em;
    }


    .press-list__time{
      margin-bottom:1em;
      font-size: @ftsize-hallon-12;
    }

    p{
      font-size: @ftsize-hallon-14;
      font-family: @hallon-frutiger-55-roman;
      color: @hallon-dark-grey;
    }
  }

  article:last-of-type{
    border:0;
  }

}