.text-section{
  background: @hallon-white;
  float:left;
  width:100%;

  h1{
    font-size: @ftsize-hallon-24;

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      font-size: @ftsize-hallon-48;
    }
  }
  h2{
    font-size: @ftsize-hallon-24;
  }

  p{
    font-size: @ftsize-hallon-14;
  }

  article{
    width:100%;
    padding: 20px 30px;

    h1, h2{
      margin:0.5em;
      text-align: center;
      font-family: @hallon-frutiger-77-bc;
      text-transform: none;
    }

    @media only screen and (min-width: @phone-breakpoint-hallon) {
      max-width: 960px;
      margin: 0 auto;
      padding: 10px 50px;
      padding-bottom:20px;
    }
  }

}