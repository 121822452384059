/// <reference path="../framework.less" />

.RegisterNumberPage {
    background: @color-2018-white;
}

.oval-content-page {
    display: flex;
    flex-direction: row;
    height: 1050px;
    max-height: 1050px;
    background: @color-2018-white;

    @media @breakpointquery-2018-tablet {
        height: auto;
        max-height: none;
        flex-direction: column;
    }

    &__red-wrapper {
        flex: 0 0 50%;
        max-width: 50%;
        width: 100%;
        z-index: 1;
        background-color: @color-2018-white;
        background-position: center right;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("data:image/svg+xml,%3Csvg width='755px' height='1024px' viewBox='0 0 755 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 53.2 (72643) - https://sketchapp.com --%3E%3Ctitle%3E5615A504-07E2-43A1-9F7A-1112DE01F047%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cdefs%3E%3Cellipse id='path-1' cx='182' cy='511.5' rx='563' ry='579.5'%3E%3C/ellipse%3E%3Cfilter x='-1.3%25' y='-1.3%25' width='102.7%25' height='102.6%25' filterUnits='objectBoundingBox' id='filter-2'%3E%3CfeOffset dx='0' dy='0' in='SourceAlpha' result='shadowOffsetOuter1'%3E%3C/feOffset%3E%3CfeGaussianBlur stdDeviation='5' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3E%3C/feGaussianBlur%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0' type='matrix' in='shadowBlurOuter1'%3E%3C/feColorMatrix%3E%3C/filter%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Steg-1'%3E%3Cg id='Oval'%3E%3Cuse fill='black' fill-opacity='1' filter='url(%23filter-2)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill='%238F1840' fill-rule='evenodd' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), linear-gradient(-90deg, #ffffff00 0, #ffffff00 500px, rgb(143, 24, 64) 500px, rgb(143, 24, 64) 100%);

        @media @breakpointquery-2018-tablet {
            background-color: @color-2018-white;
            background-position: center bottom;
            background-repeat: no-repeat;
            background-size: cover;
            flex: 1 1 auto;
            max-width: 100%;
            background-image: url("data:image/svg+xml,%3Csvg width='320px' height='535px' viewBox='0 0 320 535' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 53.2 (72643) - https://sketchapp.com --%3E%3Ctitle%3EFF199C71-437D-47C4-8B57-139AF303A99B%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Mobile-Steg-1.1' fill='%238F1840'%3E%3Cpath d='M320,258.407565 C383.204412,286.539 424,330.985133 424,381 C424,466.051851 306.027032,535 160.5,535 C14.9729684,535 -103,466.051851 -103,381 C-103,331.249402 -62.6343854,287.008845 2.84217094e-14,258.854853 L0,0 L320,0 L320,258.407565 Z' id='Combined-Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
    }

    &__red-container {
        max-width: 585px;
        width: 100%;
        padding: 90px 130px 50px 0;
        margin: 0 0 0 auto;
        color: @color-2018-white;


        @media @breakpointquery-2018-small-desktop {
            padding: 90px 130px 50px 20px;
        }

        @media @breakpointquery-2018-tablet {
            padding: 20px 20px 50px 20px;
            max-width: 100%;
        }
    }

    &__red-title {
        text-transform: none;
        color: @color-2018-white;
        font-size: 64px;
        line-height: 70px;
        font-family: @font-2018-black;

        @media @breakpointquery-2018-large-tablet {
            font-size: 50px;
        }

        @media @breakpointquery-2018-mobile {
            font-size: 32px;
        }
    }

    &__red-text {
        font-size: 16px;
        line-height: 24px;
        color: @color-2018-white;
        margin-bottom: 20px;
        display: block;

        @media @breakpointquery-2018-tablet {
            display: none;
        }

        &--mobile {
            display: none;

            @media @breakpointquery-2018-tablet {
                display: block;
            }
        }
    }

    &__red-list {
        margin-bottom: 20px;

        @media @breakpointquery-2018-tablet {
            height: auto;
            overflow: hidden;
            position: relative;
            max-height: 0;
            transition: max-height 0.6s linear;
            margin-bottom: 0;
        }

        &--show {
            @media @breakpointquery-2018-tablet {
                max-height: 2000px;
            }
        }
    }

    &__red-list-item {
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;
    }

    &__red-list-item-checkmark {
        margin-right: 10px;
        margin-top: 3px;
        flex: 0 0 25px;
    }

    &__red-list-item-text {
        font-size: 16px;
        line-height: 24px;
        color: @color-2018-white;
    }

    &__red-list-toggle {
        color: @color-2018-white;
        text-align: center;
        font-size: 16px;
        line-height: 40px;
        cursor: pointer;
        display: none;

        &--open {
            .oval-content-page {
                &__red-list-toggle-text {
                    display: none;

                    &--open {
                        display: inline;
                    }
                }

                &__red-list-toggle-icon {
                    transform: rotate(180deg);
                }
            }
        }

        @media @breakpointquery-2018-tablet {
            display: block;
        }
    }

    &__red-list-toggle-text {
        color: @color-2018-white;
        text-decoration: underline;

        &--open {
            display: none;
        }
    }

    &__red-list-toggle-icon {
        transform: rotate(0);
        transition: transform 0.2s linear;
    }

    &__wrapper {
        flex: 0 0 50%;
        max-width: 50%;
        width: 100%;
        padding: 0;
        margin: 0 auto;
        z-index: 0;

        @media @breakpointquery-2018-tablet {
            flex: 1 1 auto;
            max-width: 100%;
        }
    }

    &__container {
        max-width: 610px;
        width: 100%;
        padding: 50px 0 50px 30px;
        margin: 0;
        display: flex;
        flex-direction: row;
        overflow: hidden;


        @media @breakpointquery-2018-tablet {
            max-width: none;
            padding: 20px 20px 50px 20px;
        }
    }
}