.bubble{
  position: relative;

  ul{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -ms-transform: translate(-50%, -50%); /* IE 9 */
    -webkit-transform: translate(-50%, -50%); /* Safari */
    transform: translate(-50%, -50%);
  }

  li{
    font-size: @ftsize-hallon-14;
    font-family: @hallon-frutiger-55-roman;
  }

  .bubble__campaign-style{
    color:@hallon-yellow;
    line-height:1;
    font-size: @ftsize-hallon-14;
    font-family: @hallon-frutiger-55-roman;
  }

   .bubble__ogprice{
     text-decoration: line-through;
     line-height:1;
     color: @hallon-white;
     font-size: @ftsize-hallon-14;
     font-family: @hallon-frutiger-55-roman;
   }

  .bubble__price{
    font-size: @ftsize-hallon-48;
    font-family: @hallon-frutiger-77-bc;
  }

  .bubble__unit{
    line-height:1;
    font-size: @ftsize-hallon-14;
    font-family: @hallon-frutiger-55-roman;
  }

}

.bubble--campaign{
  background: url(/UI/build/images/icon-marker-soft-red.png) no-repeat center center;
  background-size: contain;
  height:170px;

  li{
    color:@hallon-white;
  }

  .bubble__ogprice{
    color:@hallon-white;
  }

  .bubble__price{
    color: @hallon-yellow;
  }

  .bubble__unit{
    color: @hallon-yellow;
  }

}

.bubble--original{
  background: url(/UI/build/images/icon-marker-soft-white-2.png) no-repeat center center;
  background-size: contain;
  height:170px;

  li{
    color: @hallon-black;
    font-size: @ftsize-hallon-14;
    font-family: @hallon-frutiger-55-roman;
  }

  .bubble__price{
    color: @hallon-black;
  }

  .bubble__unit{
    color:@hallon-black;
  }
}

.bubble--news{
  background: url(/UI/build/images/icon-marker-soft-yellow-2.png) no-repeat center center;
  background-size: contain;
  height:170px;

  li{
    color:@hallon-light-red;
  }

  .bubble__ogprice{
    color:@hallon-light-red;
  }

  .bubble__price{
    color: @hallon-light-red;
  }

  .bubble__unit{
    color: @hallon-light-red;
  }
}

.bubble--news-campaign{
  background: url(/UI/build/images/icon-marker-soft-yellow-2.png) no-repeat center center;
  background-size: contain;
  height:170px;

  li{
    color:@hallon-light-red;
  }

  .bubble__ogprice{
    color:@hallon-light-red;
  }

  .bubble__price{
    color: @hallon-light-red;
  }

  .bubble__unit{
    color: @hallon-light-red;
  }
}

.bubble--small{
  height:130px;

  li{
    line-height: 1;
    font-family: @hallon-frutiger-55-roman;
    font-size: @ftsize-hallon-14;
  }
  .bubble__price{
    line-height: 1;
    font-family: @hallon-frutiger-77-bc;
    font-size: @ftsize-hallon-31;
  }
}

.bubble--pink-offer{
  background: url(/UI/build/images/icon-marker-soft-pink.png) no-repeat center center;
  background-size: contain;
  height:95px;

  li{
    color:@hallon-white;

    &:first-child{
      font-family: @hallon-frutiger-77-bc;
      font-size:16px;
    }
    &:last-child{
      font-family: @hallon-frutiger-55-roman;
      font-size:11px;
    }
  }
}