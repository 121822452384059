.ErrorPage{
  padding-top:0px;
  display: table;
  height:100%;

  margin: 0px auto;

  h1{
    font-family: @hallon-frutiger-77-bc;
    font-size: @ftsize-hallon-24;
    text-align: center;
    color: @hallon-dark-red;
    @media only screen and (min-width: @phone-breakpoint-hallon) {
      font-size: @ftsize-hallon-90;
    }
  }

  .ErrorPage__container{
    display: table-cell;
    vertical-align: middle;
    width:750px;
  }

  a{
    margin-top: 1em;
  }

  p{
    font-family: @hallon-frutiger-55-roman;
    font-size: @ftsize-hallon-14;
    padding-left:20px;
    padding-right:20px;
    padding-top:20em;

    @media only screen and (min-width: @phone-breakpoint-hallon) {
      width:510px;
      padding:0;
      margin: 80px auto 0;
    }
  }




}