/// <reference path="../framework.less" />

.campaign-banner {
	background: @color-2018-background-dark-grey;
	color: @color-2018-white;
	font-family: @font-2018-bold;
	font-size: 18px;
	line-height: 21px;
	text-align: center;
	padding: 15px 20px;
	border-radius: 15px;
	margin:0 0 40px 0;
	width:100%;
	@media @breakpointquery-2018-tablet {
		margin: 0 0 60px 0;
	}
}

