/// <reference path="../framework.less" />


.product-hero {
    position: relative;
    height: 60vw;
    max-height: 700px;
    display: flex;
    align-items: center;

    @media @breakpointquery-2018-small-desktop {
        max-height: none;
        height: auto;
        overflow: hidden;
    }

    @media @breakpointquery-2018-tablet {
        max-height: none;
        height: auto;
        justify-content: center;
        align-content: center;
        display: flex;
        flex-direction: column;
        padding: 15px 0;
    }

    &--adjusted {

        @media @breakpointquery-2018-mobile {
            min-height: 490px !important;
        }
    
        @media @breakpointquery-2018-tablet {
            min-height: 660px;
        }

    }


    &__text-wrapper {
        @media @breakpointquery-2018-tablet {
            margin: 0 auto;
            margin-top: 26px;
        }
    }
    
    &__partner-logo {
        width: 140px;
        position: absolute;
        left: 50px;
        top: -1px;
        z-index: 1;
        -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
        filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));

        @media @breakpointquery-2018-small-desktop {
            left: 100px;
        }

        @media @breakpointquery-2018-tablet {
            left: 15px;
            width: 80px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: 100%;

        @media @breakpointquery-2018-small-desktop {
            padding: 100px 50px;
            flex-direction: row;
            flex-wrap: wrap;
            max-width: 660px;
        }

        @media @breakpointquery-2018-tablet {
            padding: 80px 0 30px;
            align-items: center;
            max-width: 100%;
            flex-direction: row;
            overflow-x: hidden;
        }

        @media @breakpointquery-2018-tablet {
            display: flex;
            flex-direction: column;
            padding: 0 0 35px;
        }

        &--adjusted {
            @media @breakpointquery-2018-mobile {
                min-height: 360px !important;
            }
    
            @media @breakpointquery-2018-tablet {
                min-height: 540px;
            }
        }
    }

    &__mask {
        position: absolute;
        bottom: -20%;
        right: -50%;
        width: 100%;
        border-radius: 50% 0 0 50%;
        overflow: hidden;

        @media @breakpointquery-2018-tablet {
            top: 55%;
            right: -20%;
            width: 140%;
            bottom: auto;
        }
    }

    &__media-mask {
        position: absolute;
        left: 50%;
        width: 50vw;
        top: 0;
        height: 100%;
        transform: translate(0%, -15%);

        @media @breakpointquery-2018-tablet {
            position: absolute;
            width: 100vw;
            height: 99vw;
            transform: none;
            left: 0;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            transform: translate3d(0, 0, 0);
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            border-radius: 0;
            overflow: hidden;
        }
    }

    &__media {
        @media @breakpointquery-2018-tablet {
            width: 120vw;
            border-radius: 50% 50% 0 0;
            left: -10vw;
            position: relative;
            overflow: hidden;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            transform: translate3d(0, 0, 0);
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
        }
    }

    &__media-inner {
        position: relative;
        transition: opacity @transition-2018-time-medium @transition-2018-cubic-bezier;
        opacity: 1;

        &--invisible {
            opacity: 0;
        }

        @media @breakpointquery-2018-tablet {
            margin-left: 0 !important;
        }
    }

    &__desktop-image {
        position: relative;
        border-radius: 50% 0 0 50%;
        display: block;
        overflow: hidden;

        img, source, video {
            width: 100%;
            height: auto;
            position: relative;
            display: block;
            transform: translateY(10%);
        }

        @media @breakpointquery-2018-tablet {
            display: none;
        }
    }

    &__mobile-image {
        position: relative;
        display: none;

        img, source, video {
            width: 100%;
            height: auto;
            position: relative;
            display: block;
            transform: translate(0, 43%);


            @media @breakpointquery-2018-tablet {
                transform: translate(0%, 0%);
            }
        }


        @media @breakpointquery-2018-tablet {
            display: block;
        }
    }

    &__header {
        font-family: @font-2018-black;
        color: @color-2018-white;
        width: 600px;
        max-width: 80vw;
        margin-bottom: 10px;
        text-transform: none;
        font-size: @font-2018-component-header;
        line-height: @font-2018-component-header-line-height;

        @media @breakpointquery-2018-small-desktop {
            flex-basis: 100%;
        }

        &--big {
            display: block;
           
            @media @breakpointquery-2018-tablet {
                display: none;
            }
        }

        &--small {
            display: none;
            
            @media @breakpointquery-2018-tablet {
                display: block;
            }  
        }

        @media @breakpointquery-2018-tablet {
            font-size: @font-2018-component-header-mobile;
            line-height: @font-2018-component-header-line-height-mobile;
            width: 100%;
            max-width: 100%;
            justify-content: center;
            align-content: center;
            text-align: center;
            order: 1;
        }

        &--enhanced {
            font-size: @font-2018-component-header-enhanced;
            line-height: @font-2018-component-header-line-height-enhanced;

            @media @breakpointquery-2018-tablet {
                font-size: @font-2018-component-header-enhanced-mobile;
                line-height: normal;
            }

            @media @breakpointquery-2018-mobile {
                font-size: @font-2018-component-header-mobile;
            }
        }
    }

    &__subheader {
        font-size: 27px;
        font-family: @font-2018-roman;
        color: @color-2018-white;
        width: 500px;
        max-width: 80vw;
        margin: 0;
        line-height: 1.4;
        text-transform: none;

        @media @breakpointquery-2018-small-desktop {
            flex-basis: 100%;
        }

        @media @breakpointquery-2018-tablet {
            font-size: 16px;
            line-height: 19px;
            width: 100%;
            text-align: center;
            align-content: center;
            justify-content: center;
            margin: auto;
            order: 2;
        }

        &--big {
            display: block;
           
            @media @breakpointquery-2018-tablet {
                display: none;
            }
        }

        &--small {
            display: none;
            
            @media @breakpointquery-2018-tablet {
                display: block;
            }  
        }

    }

    &__button-inner-wrapper {
        display: inline-flex;
        flex-direction: column;
        align-items: center;

        &--margin-right {
            margin-right: 20px;
        }
    }

    &__button-sub-text {
        margin-top: 10px;
        color: @color-2018-white;
        font-size: 14px;
        line-height: 19px;
        font-family: @font-2018-roman;
        text-align: center;
        display: block;

        @media @breakpointquery-2018-tablet {
            display: none;
        }

        &--mobile {
            display: none;

            @media @breakpointquery-2018-tablet {
                display: block;
            }
        }
    }

    &__textfield {
        font-size: 18px;
        line-height: 27px;
        font-family: @font-2018-roman;
        color: @color-2018-white;
        width: 500px;
        max-width: 80vw;
        margin: 0;
        text-transform: none;

        @media @breakpointquery-2018-small-desktop {
            flex-basis: 100%;
        }

        @media @breakpointquery-2018-tablet {
            font-size: 18px;
            line-height: 27px;
            width: 100%;
            text-align: center;
            align-content: center;
            justify-content: center;
            margin: auto;
            order: 2;
            padding-bottom: 20px;
        }
    }

    &__button {
        margin-top: 40px;
        align-self: flex-start;

        @media @breakpointquery-2018-small-desktop {
            flex: 0 1 auto;
        }

        @media @breakpointquery-2018-tablet {
            display: none;
            flex: auto;
        }

        &--got-secondary {
            min-width: 190px;
            padding: 20px 20px;
        }
    }

    &__mobile-button-wrapper {
        display: none;

        @media @breakpointquery-2018-tablet {
            display: flex;
            align-items: flex-end;
            // margin-top: 360px;
            // margin-bottom: 100px;
        }

        &--adjusted {
            @media @breakpointquery-2018-tablet {
                margin-top: 90px;
                margin-bottom: 110px;
            }

            @media @breakpointquery-2018-mobile {
                margin-top: 0;
                margin-bottom: 10px;
            }
        }
    }

    &__mobile-button {
        display: none;

        @media @breakpointquery-2018-tablet {
            display: block;
            width: auto;
            z-index: 1;
            margin: 20px auto 10px auto;
            bottom: 20px;
            position: sticky;
            position: -webkit-sticky;
        }
    }

    &__mobile-button-button {
        @media @breakpointquery-2018-tablet {
            width: 100%;
        }

        &--secondary {
            @media @breakpointquery-2018-tablet {
                margin-top: 20px;
            }
        }
    }

    &__price-bubble {
        border-radius: 50%;
        width: 180px;
        height: 180px;
        background: @color-2018-yellow;
        color: @color-2018-hallon-red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        align-self: flex-end;
        transform: translate(-25%, 100%);
        left: 0;
        bottom: 30%;
        z-index: 1;

        @media @breakpointquery-2018-small-desktop {
            display: none;
        }

        &--B2B {
            background: @color-2018-white;
            color: @color-2018-hallon-red;
        }
 
        &--mobile {
            display: none;

            @media @breakpointquery-2018-small-desktop {
                display: flex;
            }

            @media @breakpointquery-2018-tablet {
                bottom: 0;
            }
        }

        @media @breakpointquery-2018-small-desktop {
            flex: 0 1 auto;
            left: auto;
            position: relative;
            margin-left: 20px;
            align-self: auto;
            transform: none;
            right: auto;
        }

        @media @breakpointquery-2018-tablet {
            position: relative;
            transform: none;
            order: 3;
            right: auto;
            left: auto;
            margin-left: -100px;
            flex: 0 1 auto;
        }
    }

    &__main-price {
        font-size: 57px;
        line-height: 68px;
        font-family: @font-2018-black;
        color: @color-2018-hallon-red;
        text-align: center;
        padding: 0 5px;
        margin: -5px 0 0 0;
    }

    &__price-info {
        font-size: 14px;
        line-height: 18px;
        font-family: @font-2018-bold;
        text-align: center;
        padding: 0 5px;
    }

    &__price-span {
        position: relative;
        z-index: 1;
        display: block;
    }

    &__data-bubble {
        border-radius: 50%;
        background: @color-2018-yellow;
        width: 80px;
        height: 80px;
        font-family: @font-2018-bold;
        font-size: 17px;
        line-height: 20px;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        z-index: 0;
        flex-direction: column;
        justify-content: center;
        transform: translate(0%, 45%);

        &--B2B {
            background: @color-2018-white;
            color: @color-2018-hallon-red;
        }

        @media @breakpointquery-2018-tablet {
            transform: translate(75%, -40%);
        }

        @media @breakpointquery-2018-phablet {
            transform: translate(75%, -20%);
        }
    }
}
