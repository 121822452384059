/// <reference path="../framework.less" />

.checkout-summary {
    flex: 1;
    flex-basis: 40%;

    &__gdpr {
        padding: 5px 20px 0 20px;

        @media @breakpointquery-2018-phablet {
            padding: 5px 15px 0 15px;
        }

        &--first {
            padding-bottom: 15px;
        }
    }

    &__gdpr-title {
        font-family: @font-2018-bold;
        text-transform: none;
        font-size: 16px;
        margin: 0;
        padding-bottom: 10px;

        span {
            font-family: @font-2018-light;
            color: @color-2018-text-grey;
            margin-left: 5px;
        }
    }

    &__gdpr-text {
        font-family: @font-2018-roman;
        color: @color-2018-text-black;
        font-size: 14px;
        line-height: 19px;

        p {
            font-family: @font-2018-roman;
            color: @color-2018-text-black;
            font-size: 14px;
            line-height: 19px;
        }

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__cost-summary {
        &__wrapper {
            margin-top: 56px;
        }

        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 8px;
        }

        @media @breakpointquery-2018-phablet {
            padding-left: 15px;
            padding-right: 15px;
        }

        &__download {
            display: table;
            margin: 0;

            a {
                display: inline-block;
            }
        }
    }

    &__submit-button {
        font-size: 20px;
        display: block;
        text-transform: none;
        border-radius: 50px;
        padding: 10px 20px;

        &.hallon-btn--continue {
            width: 100%;
            text-transform: uppercase;
        }

        &.hallon-btn--disabled {
            color: @color-2018-white;
            text-transform: uppercase;
        }
    }

    &__order-error {
        color: @color-2018-error-red;
        font-size: 11px;
        margin-top: 10px;
        padding: 0 10px;
        text-align: center;
        display: none;
    }

    &__order-usp {
        margin-top: 10px;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        padding: 0 10px;
        color: #838383;
    }
}

