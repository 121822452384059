/// <reference path="../framework.less" />

.footer {

	&__logotype {
		flex: 0 0 auto;
		align-self: baseline;
		margin-bottom: 40px;
	}

	&__logotype-image {
		fill: @color-2018-hallon-red;
		width: 175px;
		height: 36px;
		margin-right: 40px;

		&--company {
			height: 55px;
		}
	}

	&__content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		background: @color-2018-white;
		padding: 50px 50px 50px 50px;

		@media @breakpointquery-2018-tablet {
			padding: 50px 15px 50px 15px;
		}

		&--overlay-padding{
			padding-top:100px;
		}

		&--overlay {
			display: none;
		}
	}


	&__column-wrapper {
		flex: 1 1 auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		@media @breakpointquery-2018-tablet {
			width: 100%;
		}
	}

	&__column {
		flex: 1 1 25%;
		min-width: 185px;
		align-self: baseline;
		margin-bottom: 40px;

		@media @breakpointquery-2018-tablet {
			flex: 1 1 50%;
		}

		@media @breakpointquery-2018-mobile {
			flex: 1 1 100%;
		}
	}

	&__column-header {
		font-size: 22px;
		font-family: @font-2018-bold;
		color: @color-2018-hallon-red;
		text-transform: none;
		margin-right: 40px;
		margin-top: 15px;
		white-space: nowrap;

		&--company {
			margin-top: 11px;
		}

		@media @breakpointquery-2018-tablet {
			font-size: 24px;
		}

		&::after {
			content: "";
			background: @color-2018-hallon-red;
			height: 1px;
			width: 10px;
			display: block;
			margin: 20px 0 25px 0;
		}
	}

	&__column-list {
		margin-right: 40px;
		display: inline-block;
	}

	&__column-list-item-link {
		font-size: 16px;
		font-family: @font-2018-roman;
		color: @color-2018-hallon-red;
		line-height: 28px;
		display: block;

		&:hover {
			text-decoration: underline;
		}
	}

	&__copyright {
		flex: 1 1 100%;
		font-family: @font-2018-light;
		font-size: 11px;
		color: @color-2018-hallon-red;
		margin-top: -40px;

		&::before {
			content: "";
			background: @color-2018-hallon-red;
			height: 1px;
			width: 10px;
			display: block;
			margin: 20px 0 10px 0;
		}
	}
}
