.horizontal-list {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;

    li:not(:last-child) {
        margin-right: 40px;
    }
}

.horizontal-list--centered {
    justify-content: center;
}
