/// <reference path="../framework.less" />

.section-content {
    display: block;
    width: 100%;
    background: @color-2018-background-grey;

    @media @breakpointquery-2018-tablet {
        overflow: visible;
        overflow-x: hidden;
    }

    &--no-overflow {
        position: relative;
        overflow: hidden;
    }

    &--no-overflow-desktop {
        overflow: hidden;

        @media @breakpointquery-2018-tablet {
            overflow: visible;
        }
    }

    &--white-background {
        background: @color-2018-white;
    }

    &--blue-background {
        background: @color-2018-linkblue;
    }


    &__content-wrapper {
        width: 100%;
        max-width: @breakpoint-2018-desktop;
        padding: @margins-2018-large;
        box-sizing: border-box;
        margin: auto;

        @media @breakpointquery-2018-tablet {
            padding: @margins-2018-medium @margins-2018-medium 0 @margins-2018-medium;
        }

        @media @breakpointquery-2018-phablet {
            padding: @margins-2018-small @margins-2018-small 0 @margins-2018-small;
        }

        &--top-bottom-padding-mobile {
            @media @breakpointquery-2018-tablet {
                padding: @margins-2018-medium;
            }

            @media @breakpointquery-2018-phablet {
                padding: @margins-2018-small;
            }
        }

        &--medium-padding {
            padding: @margins-2018-medium @margins-2018-large;

            @media @breakpointquery-2018-phablet {
                padding: @margins-2018-medium;
            }
        }

        &--small-padding {
            padding: @margins-2018-small @margins-2018-large;

            @media @breakpointquery-2018-phablet {
                padding: @margins-2018-small @margins-2018-medium;
            }
        }

        &--no-padding {
            padding: 0;
        }

        &--no-padding-sides {
            padding-left: 0;
            padding-right: 0;

            @media @breakpointquery-2018-phablet {
                padding-left: @margins-2018-small;
                padding-right: @margins-2018-small;
            }
        }

        &--no-padding-bottom {
            padding: @margins-2018-large @margins-2018-large 0 @margins-2018-large;

            @media @breakpointquery-2018-tablet {
                padding: @margins-2018-large @margins-2018-medium 0 @margins-2018-medium;
            }

            @media @breakpointquery-2018-phablet {
                padding: @margins-2018-large @margins-2018-small 0 @margins-2018-small;
            }
        }

        &--no-padding-bottom-tablet {

            @media @breakpointquery-2018-tablet {
                padding-bottom: 0;
            }

            @media @breakpointquery-2018-phablet {
                padding-bottom: 0;
            }
        }

        &--no-padding-top-tablet {

            @media @breakpointquery-2018-tablet {
                padding-top: 0;
            }

            @media @breakpointquery-2018-phablet {
                padding-top: 0;
            }
        }

        &--no-padding-top-bottom {
            padding: 0 @margins-2018-large;

            @media @breakpointquery-2018-tablet {
                padding: 0 @margins-2018-medium;
            }

            @media @breakpointquery-2018-phablet {
                padding: 0 @margins-2018-small;
            }
        }

        &--fullwidth {
            max-width: 100%;
        }

        &--banner-padding-top {
            padding-top: 150px;

            @media @breakpointquery-2018-small-desktop {
                padding-top: 90px;
            }
        }

        &--banner-padding-top-normal {
            padding-top: 50px;

            @media @breakpointquery-2018-tablet {
                padding-top: 25px;
            }
        }

        &--banner-padding-bottom {
            padding-bottom: 50px;

            @media @breakpointquery-2018-tablet {
                padding-bottom: 25px;
            }
        }

        &--banner-countdown {
            padding-bottom: 0;
        }

        &--always-at-hallon {
            padding-top: @margins-2018-xl;

            @media @breakpointquery-2018-tablet {
                padding-top: @margins-2018-large;
                padding-bottom: 0;
            }
        }

        &--circle-usp {
            padding-bottom: @margins-2018-large;

            @media @breakpointquery-2018-tablet {
                padding-top: @margins-2018-medium;
                padding-bottom: 0;
                overflow-x: hidden;
            }
        }

        &--productpicker {
            padding-top: @margins-2018-xl;
            padding-bottom: @margins-2018-large;

            @media @breakpointquery-2018-tablet {
                padding-top: @margins-2018-large;
                padding-bottom: 0;
                overflow-x: hidden;
            }
        }

        &--notification-center {
            padding: @margins-2018-small @margins-2018-large;

            @media @breakpointquery-2018-small-desktop {
                padding: @margins-2018-small 32px @margins-2018-small @margins-2018-small;
            }
        }

        &--generic-content {
            padding: @margins-2018-xl @margins-2018-large;

            @media @breakpointquery-2018-phablet {
                padding: @margins-2018-xl @margins-2018-small @margins-2018-medium @margins-2018-small;
                flex-direction: column;
            }
        }

        &--expandable {
            position: relative;
            max-width: 1250px;

            @media @breakpointquery-2018-tablet {
                padding-left: 0;
                padding-right: 0;
                height: auto;
            }
        }
    }

    &__products-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        width: calc(~"100% + 8px");
        margin-left: -4px;
        padding-top: 60px;
        overflow: hidden;

        &--padding {
            padding: @margins-2018-large;

            @media @breakpointquery-2018-tablet {
                padding: @margins-2018-medium @margins-2018-medium 0 @margins-2018-medium;
            }

            @media @breakpointquery-2018-phablet {
                padding: @margins-2018-small @margins-2018-small 0 @margins-2018-small;
            }
        }

        &--expandable {
            align-items: center;
            width: 100%;
            margin: 0;
            padding: 0 45px;
            height: auto;
            position: absolute;
            top: 50%;
            left: 0;
            transition: transform 0.2s linear, opacity 0.2s linear;
            transform: translateY(-70%);
            opacity: 0;
            pointer-events: none;
            overflow: visible;

            @media @breakpointquery-2018-tablet {
                height: auto;
                padding: 0;
                width: 100%;
                opacity: 0;
                top: 0;
                transform: translateY(0%);
                overflow: hidden;
                max-height: 0;
                position: relative;
                transform: none;
                transition: max-height 0.2s linear, padding 0.2s linear, opacity 0.2s linear;
            }

            &-active {
                transform: translateY(-50%);
                opacity: 1;
                pointer-events: auto;

                @media @breakpointquery-2018-tablet {
                    max-height: 1500px;
                    opacity: 1;
                    transform: translateY(0%);
                    padding: 15px 0 30px 0;
                }
            }
        }
    }

    &__product-wrapper-heading {
        display: flex;
        align-items: center;
        flex-direction: column;



        p:not(.campaign-banner) {
            font-size: 27px;
            line-height: 32px;
            font-family: @font-2018-roman;
            width: 100%;
            color: @color-2018-white;
            text-align: center;
            margin-bottom: 40px;

            @media @breakpointquery-2018-tablet {
                line-height: 24px;
                font-size: 20px;
                margin-bottom: 20px;
            }
        }

        a {
            color: @color-2018-linkblue;
            font-family: @font-2018-bold;
            font-size: @font-2018-section-subheader;

            @media @breakpointquery-2018-tablet {
                display: block;
                line-height: 24px;
                font-size: 20px;
            }
        }
    }

    &__product-wrapper-header {
        color: @color-2018-white;
        font-family: @font-2018-black;
        width: 100%;
        text-align: center;
        text-transform: none;
        font-size: @font-2018-component-header;
        line-height: @font-2018-component-header-line-height;

        @media @breakpointquery-2018-tablet {
            font-size: @font-2018-component-header-mobile;
            line-height: @font-2018-component-header-line-height-mobile;
        }

        &--enhanced {
            font-size: @font-2018-component-header-enhanced;
            line-height: @font-2018-component-header-line-height-enhanced;

            @media @breakpointquery-2018-tablet {
                font-size: @font-2018-component-header-enhanced-mobile;
                line-height: @font-2018-component-header-line-height-enhanced-mobile;
            }
        }
    }

    &--background {
        background-position: center;
        background-size: cover;
        background: none;
        position: relative;
        overflow: hidden;
    }

    &--background-color-hallon-red {
        background: @color-2018-hallon-red;
    }

    &--background-color-white {
        background: @color-2018-white;
    }

    &--background-color-green {
        background: @color-2018-moss-green;
    }

    &--dark-grey-background {
        background: @color-2018-background-dark-grey;
    }

    &--overlay-background {
        z-index: 1;
        position: relative;
        overflow: visible;
    }



    &__background-image {
        position: absolute;
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: cover;
        top: 0;
        left: 0;
        z-index: -1;
        background-repeat: no-repeat;
    }

    &__oval {
        overflow: hidden;
        position: absolute;
        height: 150px;
        z-index: -1;
        width: 100%;
        transform: translateY(-115px);
    }

    &__oval-border {
        border-radius: 100%;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        height: 150px;
        position: absolute;
        display: block;
        width: 120%;
        margin-top: 0;
        margin-left: -10%;
        background: transparent;
    }


    &--show-overflow {
        @media @breakpointquery-2018-tablet {
            overflow: visible;
            overflow-x: visible;
        }
    }
}
