.leftmenu-navigation{

  width: 100%;
  background: @hallon-white;
  height:100%;

  padding-top:60px;
  z-index: 9999;
  overflow: auto;
  -webkit-overflow-scrolling:touch;
  position: fixed;

  -webkit-box-shadow: inset 0 10px 10px -10px rgba(230,230,230,1);
  -moz-box-shadow: inset 0 10px 10px -10px rgba(230,230,230,1);
  box-shadow: inset 0 10px 10px -10px rgba(230,230,230,1);

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;


  left: -moz-calc(100% - 320px);
  left: -webkit-calc(100% - 320px);
  left: -o-calc(100% - 320px);
  left: calc(100% - 320px);

  @media only screen and (min-width: @phone-breakpoint-hallon) {
    width:320px;
    left: -320px;
  }

  a{
    font-family: @hallon-frutiger-55-roman;
    font-size: @ftsize-hallon-14;

    &:hover{
      cursor: pointer;
    }

  }


  .leftmenu-navigation__sublink{
    font-size: @ftsize-hallon-14;
    padding: 15px 20px;
    display: block;
    color: @hallon-dark-red;
    clear:both;

    &:hover{
      color: @hallon-red-hover;
    }

    i{
      float:right;
      margin-right:10px;
    }
  }

  .leftmenu-navigation__sublink--grey{
    color: @hallon-dark-grey;

    &:hover{
      color: @hallon-grey-hover;

    }
  }

  .leftmenu-navigation__link{
    font-size: @ftsize-hallon-14;
    padding: 15px 20px;
    display: block;
    color: @hallon-dark-grey;

    &:hover{
      color: @hallon-grey-hover;
    }

  }

  &.leftmenu-open {
    left: 0px;
  }

  .leftmenu-navigation__info-text{
    font-size: @ftsize-hallon-14;
    padding: 15px 20px;
    font-family: @hallon-frutiger-55-roman;
    display: block;
    color: @hallon-dark-grey;
    font-style:italic;
    border-top:1px solid @hallon-light-grey;
  }


  .leftmenu-navigation__sublist{
    display: none;
  }


  .leftmenu-navigation__header-link{
    width:50%;
    display: block;
    float:left;
    padding-top:20px;
    padding-bottom:20px;
    padding-left:20px;
  }
  .leftmenu-navigation__header-link--active{
    background: @hallon-medium-grey;
    color:@hallon-dark-red;

    &:hover{
      color: @hallon-red-hover;
    }

    i{
      padding-left:10px;
      vertical-align: middle;
    }

  }
  .leftmenu-navigation__header-link--disabled{
    color: @hallon-white;
    background: @hallon-light-red;
    background: -webkit-linear-gradient(@linear-gradient-company-red);
    background: -o-linear-gradient(@linear-gradient-company-red);
    background: -moz-linear-gradient(@linear-gradient-company-red);
    background: linear-gradient(@linear-gradient-company-red);
    i{
      padding-right:10px;
    }

  }

  .leftmenu-navigation__footer{
    border-top:1px solid @hallon-light-grey;
  }

  .leftmenu-navigation__logo{
    margin:20px;
  }

}