.MobilePackageOrderPage{

  .main-container__grid{
    background: @hallon-white;

    h1,h2{
      text-align: left;
      text-transform: none;
    }
  }

  .htmlContent{

    p{
      padding:0px;
    }

    margin-bottom:30px;
  }


  //MOBILE ORDER CONFIRM

  .hardwarepage__content{

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      height: -moz-calc(~"100vh - 140px");
      height: -webkit-calc(~"100vh - 140px");
      height: -o-calc(~"100vh - 140px");
      height: calc(~"100vh - 140px");
      //footer 80px + header 60px = 140px
      position: relative;
      overflow: hidden;
    }

    .red-circle{
      display: none;
      @media only screen and (min-width: @tablet-breakpoint-hallon) {
        display: block;
        margin-bottom:60px;
      }
    }

    .red-circle--left{
      float:left;
      max-width: 30%;
    }

    .hardware{
      width:100%;
      position: relative;
      display: block;

      @media only screen and (min-width: @tablet-breakpoint-hallon) {
        display: table;
        float: left;
        position: absolute;
        left:380px;
        height:100vh;
        top: 40%;
        margin-top:60px;
        -ms-transform: translate(0, -50%); /* IE 9 */
        -webkit-transform: translate(0, -50%); /* Safari */
        transform: translate(0, -50%);
      }

    }

    .red-circle--right{
      float:right;
      max-width: 30%;
    }


  }


  .hardwarepage__footer{

    position: relative;
    display: block;

    -webkit-box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.1);
    box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.1);


    background: @hallon-white;
    width:100%;
    z-index: 9999;

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      height:80px;
      display: block;
      bottom:0px;
    }

    #orderButton{
      display: none;
    }

  }

}

//THANK YOU PAGE
.MobilePackageOrderPage.klar {
    position: relative;

    .main-container {
        .main-container__grid {
            background: @body-background;
        }
    }

    img {
        width: 360px;
        height: 310px;
        position: absolute;
        bottom: 0;
        right: 50px;
        z-index: 2;
    }

    .hallon-logotype-bg {
        width: 360px;
        height: 260px;
        position: absolute;
        bottom: 0;
        right: 50px;
        z-index: 2;
        opacity:0.25;
    }
}