/*Mix for the below*/
.areaspaces {

	@vBoxpadding: 20;
	@hBoxpadding: @gutter-width; // 20
	padding: unit(@vBoxpadding, px) unit( @hBoxpadding/960*100, %);
	overflow: hidden;
	margin-bottom: @margin-M;

	@media only screen and (max-width: @hw-palm) {
		@hBoxpadding: @column-width - @gutter-width; // 60 - 20
		padding: unit(@vBoxpadding, px) unit( @hBoxpadding/960*100, %);
	}

	@media only screen and (max-width: @hw-mobile-L) {
		@hBoxpadding: @column-width; // 60
		padding: unit(@vBoxpadding, px) unit( @hBoxpadding/960*100, %);
	}
}


.whitearea {
	background-color: white;
	color: #000;
	.areaspaces();
}

.grayarea {
	background-color: @color-gray95;
	color: #000;
	.border-radius(4px);
	.areaspaces();
}

.brownarea {
	background-color: @tre-darkgray-color;
	// color: white;
	.headerparagraphColor(white);
	.border-radius(4px);
	.areaspaces();

	a {
		color: @color-violetred;
	}
}

[data-area~="style-brown"] .error-message-login {
	color: @color-violetred;
}

.blackarea {
	background-color: black;
	.headerparagraphColor(white);
	.border-radius(4px);
	.areaspaces();
}

.transparentarea {
	.areaspaces();
}

.highlightarea {
	.areaspaces();
	//this has already enough padding around it so no bottom margin is needed.
	margin-bottom: 0;
}


// Trying out a structure for boxes in the scenario in which they are either in a data-row="regular" structure
// ex: <div class="graybox" data-column="1/2">  // The box class is on the same node as the column attribute
// or in a data-row="equalheight"
// ex: 	<div data-column="1/2">
//			<div class="graybox">		// The box class is below the column attribute
[data-column] {

	&.whitebox,
	&.graybox,
	&.messagebox {
		margin-bottom: @margin-L;
	}
}

[data-column~="1/2"] {

	.whitearea,
	.grayarea,
	.brownarea,
	.blackarea,
	.transparentarea,
	.highlightarea {

		@vBoxpadding: 20;
		@hBoxpadding: @gutter-width; // 20
		padding: unit(@vBoxpadding, px) unit( @hBoxpadding/480*100, %);
		// half-width column becomes 100% wide. Reset padding to
		@media only screen and (max-width: @hw-tablet) {
			@hBoxpadding: @gutter-width; // 20
			padding: unit(@vBoxpadding, px) unit( @hBoxpadding/960*100, %);
		}

		@media only screen and (max-width: @hw-palm) {
			@hBoxpadding: @column-width - @gutter-width; // 60 - 20
			padding: unit(@vBoxpadding, px) unit( @hBoxpadding/960*100, %);
		}

		@media only screen and (max-width: @hw-mobile-L) {
			@hBoxpadding: @column-width; // 60
			padding: unit(@vBoxpadding, px) unit( @hBoxpadding/960*100, %);
		}
	}
}
