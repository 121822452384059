/// <reference path="../framework.less" />

.full-width-header-component {
	position: relative;
	height: 60vw;
	max-height: 400px;
	display: flex;
	align-items: center;

	@media @breakpointquery-2018-large-tablet {
		max-height: none;
		height: auto;
		display: flex;
		min-height: 400px;
		flex-direction: row-reverse;
	}

	@media @breakpointquery-2018-tablet {
		justify-content: center;
		flex-direction: column;
		min-height: 0px;
	}


	&__content {
		position: absolute;
		z-index: 1;
		left: 0;
		width: 50%;
		height: 100%;
		top: 0;
		display: flex;
		justify-content: flex-start;


		@media @breakpointquery-2018-tablet {
			position: relative;
			width: 100%;
		}

		@media @breakpointquery-2018-tablet {
			position: relative;
			display: flex;
			flex-direction: column;
			padding: 0;
			height: 100%;
			align-items: center;
			width: 100%;
		}
	}

	&__content-container {
		max-width: 600px;
		padding: 20px 50px;
		align-self: center;

		@media @breakpointquery-2018-tablet {
			display: flex;
			flex-direction: column;
			height: 100%;
			min-height: 0;
			padding: 0;
		}
	}

	&__header {
		font-family: @font-2018-black;
		color: @color-2018-white;
		margin-bottom: 0;
		text-transform: none;
		font-size: @font-2018-component-header;
		line-height: @font-2018-component-header-line-height;

		span {
			display: block;
		}

		@media @breakpointquery-2018-tablet {
			padding: 50px 0;
			width: 100%;
			max-width: 100%;
			text-align: center;
			order: 1;
			font-size: @font-2018-component-header-mobile;
			line-height: @font-2018-component-header-line-height-mobile;
		}

		&--enhanced {
			font-size: @font-2018-component-header-enhanced;
			line-height: @font-2018-component-header-line-height-enhanced;

			@media @breakpointquery-2018-tablet {
				font-size: @font-2018-component-header-enhanced-mobile;
				line-height: @font-2018-component-header-line-height-enhanced-mobile;
			}
		}
	}

	&__mask {
		position: absolute;
		right: 50%;
		top: 50%;
		width: 100%;
		max-width: 1189px;
		border-radius: 50%;
		overflow: hidden;
		transform: translate(100%, -50%);

		@media @breakpointquery-2018-tablet {
			display: none;
		}
	}

	&__image {
		position: relative;

		img {
			width: 100%;
			height: auto;
			position: relative;
			display: block;
			transform: translate(0);
		}

		@media @breakpointquery-2018-tablet {
			display: none;
		}
	}

	&__mask-mobile {
		position: relative;
		left: -50%;
		width: 200%;
		margin-bottom: -130%;
		border-radius: 50%;
		overflow: hidden;
		display: none;
		z-index: -1;
		order: 3;

		@media @breakpointquery-2018-tablet {
			display: block;
		}
	}

	&__mobile-image {
		position: relative;
		display: none;

		img {
			width: 100%;
			height: auto;
			position: relative;
			display: block;
			transform: translate(0);
		}


		@media @breakpointquery-2018-tablet {
			display: block;
		}
	}
}