.ProductPage, .ProductCompanyPage {
  clear: both;

  .fullsize-container{
    @media only screen and (min-width: @phone-breakpoint-hallon) {
      width:100%;
    }
  }

  .column-100.module-campaign {
    @media only screen and (min-width: @phone-breakpoint-hallon) {
      min-height: -moz-calc(~"100vh - 120px");
      min-height: -webkit-calc(~"100vh - 120px");
      min-height: -o-calc(~"100vh - 120px");
      min-height: calc(~"100vh - 120px");
    }
  }


  .product-content {
    @media only screen and (min-width: @phone-breakpoint-hallon) {
      min-height:70px;

    }
  }

  .product-content{
    width:100%;
    float:left;

    @media only screen and (min-width: @phone-breakpoint-hallon) {
      float:none;
    }
  }

}