/// <reference path="../framework.less" />

.login {
	width: 100%;
	margin: auto;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	position: relative;
	height: 100%;
	border-radius: 10px;
	overflow: hidden;

	&__header {
		display: none;
		padding: 10px;
		border-bottom: 1px solid #e1e1e1;

		@media @breakpointquery-2018-tablet {
			display: flex;
		}
	}

	&__logotype {
		margin-left: 6px;
		align-self: center;
		flex: 1 0 auto;
		height: 50px;
		display: none;

		@media @breakpointquery-2018-tablet {
			display: flex;
		}
	}

	&__logotype-image {
		fill: @color-2018-hallon-red;
		width: 111px;
		height: 23px;
		align-self: center;

		&--company {
			height: 40px;
			align-self: center;
		}
	}

	&__close-toggle {
		position: absolute;
		right: -20px;
		top: -20px;
		display: block;
		outline: none;
		background: none;
		border: 0;
		margin: 0;
		padding: 0;
		height: 43px;
		width: 43px;
		z-index: 10;
		display: flex;
		justify-content: center;
		cursor: pointer;

		@media @breakpointquery-2018-tablet {
			display: none;
		}
	}

	&__close-modal {
		height: 43px;
		width: 43px;
		-webkit-filter: drop-shadow( 0px 3px 3px rgba(0, 0, 0, .3));
		filter: drop-shadow( 0px 3px 3px rgba(0, 0, 0, .3));
		border-radius: 50px;
	}

	&__close-toggle-icon {
		width: 19px;
		pointer-events: all;
	}

	&__close-toggle-line {
		fill: none;
		stroke: @color-2018-white;
		stroke-linecap: round;
		stroke-miterlimit: 10;
		stroke-width: 2px;
		transform: rotate(0) translateY(0) translateX(0);
		opacity: 1;
		transform-origin: 20px 10px;
		transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;

		&:nth-of-type(1) {
			transform: rotate(-45deg) translateY(0) translateX(0);
			transform-origin: 35px 21px;
		}

		&:nth-of-type(2) {
			transform: rotate(45deg) translateY(0em) translateX(0em);
			transform-origin: 34px 29px;
		}
	}

	&__close-toggle-mobile {
		display: block;
		outline: none;
		background: none;
		border: 0;
		margin: 0;
		height: 50px;
		width: 50px;
		flex: 0 0 71px;
	}

	&__close-toggle-mobile-icon {
		width: 50px;
		height: 50px;
		cursor: pointer;
		pointer-events: all;
	}

	&__close-toggle-mobile-line {
		fill: none;
		stroke: @color-2018-hallon-red;
		stroke-linecap: round;
		stroke-miterlimit: 10;
		stroke-width: 2px;
		transform: rotate(0) translateY(0) translateX(0);
		opacity: 1;
		transform-origin: 20px 10px;
		transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;

		&:nth-of-type(1) {
			transform: rotate(-45deg) translateY(0) translateX(0);
			transform-origin: 35px 21px;
		}

		&:nth-of-type(2) {
			transform: rotate(45deg) translateY(0em) translateX(0em);
			transform-origin: 34px 29px;
		}
	}

	&__tab-wrapper {
		display: flex;
		flex: 0 0 auto;
	}

	&__tab {
		flex: 0 0 50%;
		max-width: 50%;
		padding: 20px 15px 10px 15px;
		text-align: center;
		font-family: @font-2018-roman;
		font-size: 24px;
		line-height: 34px;
		cursor: pointer;
		background: #f7f7f7;
		position: relative;
		color: @color-2018-text-black;
		transition: 0.2s linear color;

		&--b2b {
			box-shadow: inset 8px 0 10px -6px #e1e1e1;
		}

		&--b2c {
			box-shadow: inset -8px 0 10px -6px #e1e1e1;
		}

		&--active {
			background-color: @color-2018-white;
			z-index: 1;
			box-shadow: none;
		}

		&--inactive {
			pointer-events: none;
			color: #dfdfdf;
		}
	}

	&__form {
		width: 100%;
		position: relative;
		flex-direction: column;
		max-height: calc(~"100% - 64px");
		display: flex;
		flex: 1 1 auto;
		overflow: hidden;
		padding: 0;

		@media @breakpointquery-2018-tablet {
			max-height: calc(~"100% - 135px");
		}

		&--account-switcher {
			max-height: 100%;

			@media @breakpointquery-2018-tablet {
				max-height: calc(~"100% - 71px");
			}
		}
	}

	&__center-wrapper {
		flex: 1 0 auto;
		justify-content: flex-start;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px 35px 20px 35px;
		justify-content: center;

		&--without-header {
			justify-content: center;
			padding: 20px 35px 150px 35px;
		}

		&--account-switcher {
			flex: 1 0 auto;
			padding: 20px 35px 0 35px;
		}
	}

	&__button-wrapper {
		display: inline-block;
		width: 100%;
		max-width: 400px;
		padding-top: 20px;
	}

	&__button-wrapper-inner {
		display: flex;
		flex-direction: column;
	}

	&__forgotten-password-wrapper {
		display: block;
		text-align: center;
		margin-top: 10px;
	}
}

.login {
	&__group {
		position: relative;
		margin: 0 0 35px 0;
		padding: 0;
		display: inline-block;
		width: 100%;
		text-align: left;

		input {
			font-size: 14px;
			line-height: 21px;
			padding: 10px 10px 10px 10px;
			display: block;
			width: 100%;
			border: none;
			border-bottom: 1px solid @color-2018-light-grey;
			background: transparent;
			color: @color-2018-heavy-grey;
			font-family: @font-2018-roman;
			transition: background 0.2s linear;
			outline: none;
			-webkit-appearance: none;
			border-radius: 0;
			margin: 0;

			&::-ms-expand, &::-ms-reveal, &::-ms-clear {
				display: none;
			}

			&:focus {
				outline: none;
				border: none;
				border-bottom: 1px solid transparent;
			}

			&.valid {
				border-bottom-color: @color-2018-green;
				color: @color-2018-green;
			}

			&.error {
				border-bottom-color: @color-2018-error-red;
			}

			&.valid:not(.error) {
				color: @color-2018-green;

				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					color: @color-2018-green;
				}

				&::-moz-placeholder { /* Firefox 19+ */
					color: @color-2018-green;
				}

				&:-ms-input-placeholder { /* IE 10+ */
					color: @color-2018-green;
				}

				&:-moz-placeholder { /* Firefox 18- */
					color: @color-2018-green;
				}
			}
		}


		input:focus,
		input:disabled,
		input:not(.placeholder-shown):not(.error) {
			~ .login__animated-label {
				color: @color-2018-heavy-grey;
				opacity: 1;

				&:after {
					visibility: visible;
					width: 100%;
					left: -5px;
				}

				&.error {

					&:after {
						background-color: @color-2018-error-red;
					}
				}

				&.valid {
					color: @color-2018-green;

					&:after {
						background-color: @color-2018-green;
					}
				}
			}
		}

		.placeholder-shown.error {

			+ .login__animated-label.error {
				top: 20px;
				opacity: 0;
			}
		}

		input.valid:not(.error) {
			~ .login__animated-label {
				top: -5px;
				font-size: 11px;
				color: @color-2018-green;
				transition: 0.2s all;
				opacity: 1;

				&:after {
					background-color: @color-2018-green;
				}
			}

			&:focus {
				color: @color-2018-green;

				~ .login__animated-label {
					color: @color-2018-green;

					&:after {
						background-color: @color-2018-green;
						background: @color-2018-green;
					}
				}
			}
		}

		input:disabled {
			~ .login__animated-label {
				&:after {
					background-color: @color-2018-light-grey;
					background: @color-2018-light-grey;
				}
			}
		}

		input:focus {
			&:after {
				width: 100%;
			}
		}
	}

	&__animated-label {
		position: absolute;
		left: 5px;
		bottom: 0;
		z-index: 2;
		width: 100%;
		transition: 0.2s ease all;
		margin: 0;
		color: @color-2018-heavy-grey;
		pointer-events: none;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		top: -10px;
		font-size: 11px;
		opacity: 1;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			height: 1px;
			width: 10px;
			visibility: hidden;
			background-color: @color-2018-hallon-red;
			transition: 0.2s ease all;
		}
	}

	&__input-error-message {
		position: absolute;
		margin-top: 5px;
		width: 100%;
		padding-left: 10px;
		font-size: 11px;
		color: @color-2018-error-red;
	}
}

/*B2C*/

.login {

	&__title-wrapper {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: center;
		flex: 0 0 85px;
		min-height: 85px;
		align-items: center;
		position: relative;
		padding: 0 46px;

		&--ssn {
			flex: 0 0 85px;
		}

		&--forgotten-password {
			flex: 0 0 85px;
		}

		&--username {
			flex: 0 0 85px;
		}

		&--other {
			flex: 0 0 85px;
		}
	}

	&__bankid-logo {
		width: 40px;
		height: 40px;
	}

	&__bankid-title {
		margin: 0 10px 0 10px;
		padding: 0;
		color: @color-2018-hallon-red;
		font-size: 24px;
		line-height: 34px;
		font-family: @font-2018-bold;
		text-transform: none;
		height: 40px;
		top: 3px;
		position: relative;
	}

	&__bankid-button {
		width: 100%;
		max-width: 400px;
		padding: 11px 50px;
		font-size: 20px;
		line-height: 28px;
		font-family: @font-2018-bold;

		@media @breakpointquery-2018-tablet {
			min-width: 0;
			width: 100%;
			max-width: 400px;
			padding: 11px 30px;
		}

		&--username {
			margin-bottom: 15px;
		}

		&--other {
			background-color: #e6e6e6;
			color: @color-2018-text-black;
			font-family: @font-2018-roman;
			margin-top: 8px;

			&:hover {
				background-color: #b6b6b6;
				color: @color-2018-text-black;
			}
		}
	}

	&__back-button {
		width: 100%;
		padding: 11px 15px;
		font-size: 20px;
		line-height: 28px;
		font-family: @font-2018-bold;
		flex: 0 0 auto;
		margin-top: 20px;
		background-color: @color-2018-green;
		color: @color-2018-white;

		&:hover {
			background-color: @color-2018-green-hover;
		}

		&--alert {
			margin-top: 40px;
		}
	}

	&__bankid-readmore-link {
		color: #2EADE2;
		font-family: @font-2018-bold;
		font-size: 14px;
		line-height: 21px;
		display: block;
		margin-bottom: 40px;

		&:hover {
			color: @color-2018-text-black;
		}
	}

	&__bankid-cancel-link {
		color: @color-2018-hallon-red;
		font-family: @font-2018-roman;
		font-size: 14px;
		line-height: 21px;
		display: block;
		cursor: pointer;
	}

	&__bankid-return-link {
		color: @color-2018-text-grey;
		display: block;
		cursor: pointer;

		&:hover {
			color: @color-2018-text-black;
		}
	}

	&__bottom-link-wrapper {
		flex: 1 0 auto;
		display: flex;
		align-items: flex-end;
		padding: 0 35px;
	}

	&__bottom-link {
		color: @color-2018-hallon-red;
		font-family: @font-2018-roman;
		font-size: 14px;
		line-height: 21px;
		display: block;
		cursor: pointer;
		padding: 15px 35px;
		min-height: 30px;

		@media @breakpointquery-2018-tablet {
			padding: 0 0 50px 0;
		}

        &:hover{
            text-decoration:underline;
            cursor:pointer;
        }
		&--inside-wrapper {
			padding: 0;
			text-align: center;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&--username {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__bottom-link-bankid-logo {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}
}


.login {
	&__account-switcher-title-wrapper {
		display: flex;
		flex-direction: column;
		padding: 0 35px;
		width: 100%;
		flex: 0 0 auto;
		justify-content: center;
		height: 130px;
		position:relative;
		&--bottom-border {
			&::after {
				border-bottom: 1px solid #e2e2e2;
				content: "";
				height: 1px;
				width: calc(~"100% - 70px");
				bottom: 0;
				position: absolute;
			}
		}
	}

	&__slide-title {
		font-size: 24px;
		line-height: 34px;
		color: #707070;
		font-family: @font-2018-roman;
		text-transform: none;
		margin: 0;
		padding: 0;

		&--alert, &--loading {
			color: @color-2018-hallon-red;
			font-family: @font-2018-bold;
		}

		&--account-switcher-title {
			color: @color-2018-hallon-red;
			font-family: @font-2018-bold;
		}
	}

	&__slide-subtitle {
		font-size: 14px;
		line-height: 21px;
		color: #707070;
		font-family: @font-2018-roman;
		width: 100%;

		&--alert, &--loading {
			margin-top: 20px;
		}

		&--first {
			padding: 0 0 25px 0;
		}

		&--text-black {
			color: @color-2018-text-black;
		}

		&--account-switcher-subtitle {
			margin-top: 15px;
		}
	}

	&__slide-loader {
		margin-top: 40px;
	}
}


.login {

	&__username-title {
		margin: 0 0 0 0;
		padding: 0;
		color: @color-2018-hallon-red;
		font-size: 24px;
		line-height: 34px;
		font-family: @font-2018-bold;
		text-transform: none;
		height: 40px;
		top: 3px;
		position: relative;
	}

	&__username-remember-checkbox-label {
		font-size: 14px;
	}
}



.login {

    &__slide-content-wrapper {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow: auto;
        overflow-x: hidden;
        height: calc(~"100% - 25px");
        position: relative;
        align-items: center;

        @media @breakpointquery-2018-tablet {
            height: calc(~"100% - 25px");
        }

        &--no-top {
            padding-top: 100px;
        }
    }

    &__slide-content {
        text-align: center;
        flex: 1 0 auto;
        padding: 0 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    &__slide-bottom {
        flex: 0 0 25px;
        margin: 0 35px;

        @media @breakpointquery-2018-tablet {
            flex: 0 0 25px;
        }

        &--bottom-border {
            border-top: 1px solid #e2e2e2;
            margin: 0 25px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &__slide-animated-loader {
        margin-top: 20px;
    }
}


.login {

    &__checkbox {
        display: flex;
        align-content: center;
        transform: scale3d(1, 1, 1);
        padding: 0 0 0 5px;
        justify-content: center;

        &-label {
            display: flex;
            color: @color-2018-text-black;
            cursor: pointer;
            position: relative;
            font-size: 14px;
            line-height: 21px;
            align-items: center;
            margin: 5px 0 30px 0;

            &--rememberme {
                margin: 5px 0 20px 0;
            }

            &--rememberme-bankid {
                margin: 20px 0 20px 0;
            }

            .login__checkbox-box {
                position: relative;
                background-color: transparent;
                width: 22px;
                height: 22px;
                transform-origin: center;
                border: 0.2em solid @color-2018-linkblue;
                border-radius: 50%;
                margin-right: 10px;
                transition: background-color 150ms 200ms, transform 200ms cubic-bezier(.78,-1.22,.17,1.89);
                flex: 1 0 auto;
                pointer-events: none;
                font-size: 10px;

                &:before {
                    content: "";
                    width: 0px;
                    height: 0.2em;
                    border-radius: 0.2em;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    background: @color-2018-linkblue;
                    position: absolute;
                    transform: rotate(45deg);
                    top: 44%;
                    left: 30%;
                    transition: width 50ms ease 50ms;
                    transform-origin: 0% 0%;
                }

                &:after {
                    content: "";
                    width: 0;
                    height: 0.2em;
                    border-radius: 0.2em;
                    background: @color-2018-linkblue;
                    position: absolute;
                    transform: rotate(299deg);
                    top: 72%;
                    left: 44%;
                    transition: width 50ms ease;
                    transform-origin: 0% 0%;
                }
            }

            &:hover {
                input[type="checkbox"]:not([disabled]) + .login__checkbox-box {
                    &:before {
                        width: 0.7em;
                        top: 44%;
                        left: 30%;
                        transition: width 100ms ease;
                    }

                    &:after {
                        width: 1em;
                        top: 72%;
                        left: 44%;
                        transition: width 150ms ease 100ms;
                    }
                }
            }
        }

        input[type="checkbox"] {
            display: none;

            &:checked {
                + .login__checkbox-box {
                    background-color: @color-2018-linkblue;
                    transform: scale(1.15);

                    &:after {
                        width: 1em;
                        top: 72%;
                        left: 44%;
                        background: @color-2018-white;
                        transition: width 150ms ease 100ms;
                    }

                    &:before {
                        width: 0.7em;
                        top: 44%;
                        left: 30%;
                        background: @color-2018-white;
                        transition: width 150ms ease 100ms;
                    }
                }

                &:hover {
                    .login__checkbox-box {
                        background-color: @color-2018-linkblue;
                        transform: scale(1.15);

                        &:after {
                            width: 1em;
                            top: 72%;
                            left: 44%;
                            background: @color-2018-white;
                            transition: width 150ms ease 100ms;
                        }

                        &:before {
                            width: 0.7em;
                            top: 44%;
                            left: 30%;
                            background: @color-2018-white;
                            transition: width 150ms ease 100ms;
                        }
                    }
                }
            }

            &:disabled {
                + .login__checkbox-box {
                    opacity: 0.2;
                }
            }
        }
        //Fix for mobile hover
        @media (hover:none), (hover:on-demand) {
            .login__checkbox-label:hover input[type="checkbox"]:not([disabled]) + .login__checkbox-box:before {
                content: "";
                width: 0px;
                height: 0.2em;
                border-radius: 0.2em;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                background: @color-2018-linkblue;
                position: absolute;
                transform: rotate(45deg);
                top: 44%;
                left: 30%;
                transition: width 50ms ease 50ms;
                transform-origin: 0% 0%;
            }

            .login__checkbox-label:hover input[type="checkbox"]:not([disabled]) + .login__checkbox-box:after {
                content: "";
                width: 0;
                height: 0.2em;
                border-radius: 0.2em;
                background: @color-2018-linkblue;
                position: absolute;
                transform: rotate(299deg);
                top: 72%;
                left: 44%;
                transition: width 50ms ease;
                transform-origin: 0% 0%;
            }

            .login__checkbox-label:hover input[type="checkbox"]:checked:not([disabled]) + .login__checkbox-box:after {
                width: 1em;
                top: 72%;
                left: 44%;
                background: @color-2018-white;
                transition: width 150ms ease 100ms;
            }

            .login__checkbox-label:hover input[type="checkbox"]:checked:not([disabled]) + .login__checkbox-box:before {
                width: 0.7em;
                top: 44%;
                left: 30%;
                background: @color-2018-white;
                transition: width 150ms ease 100ms;
            }
        }
    }

    &__show-password {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: .85em;
        cursor: pointer;
        width: 40px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: all;
    }

    &__show-password-icon-show {
        display: block;
        width: 22px;
        height: 22px;
    }

    &__show-password-icon-hide {
        display: none;
        width: 22px;
        height: 22px;
    }

    &__show-password-icon-color {
        fill: #a3a3a3;
    }
}