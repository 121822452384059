/* campaign list */

.ProductLandingPageCampaign .boxes .priceplaceholder .campaign {
    top: 8%;
    color: #000;
    background: url(/UI/build/images/splash.png) no-repeat center;
    background-size: 30% 80%;
    padding: 10% 0;
}

.ProductLandingPageCampaign .boxes .priceplaceholder .old-campaign {
    top: 8%;
    color: #000;
    background: url(/UI/build/images/marker-small.png) no-repeat center;
    background-size: 30% 80%;
    padding: 10% 0;
}

/* from student.less 2014-04-10 fjo@cloudnine.se */

// ww-expect "mixin"
// ww-expect "variables"

@backgroundColor: #a8133e;

.campaignHeader {
    background: @backgroundColor;
    text-align: center;

    .topWrapper {
        background: @backgroundColor;
        padding-top: 86px;
        padding-bottom: 49px;

        h1,
        p {
            color: #fff;

            @media only screen and (max-width: @hw-tablet) {
                margin-bottom: 20px;
            }
        }

        h1 {
            overflow: hidden;
            margin-bottom: 43px;
            text-transform: none;
            .font-size(8.1);

            @media only screen and (max-width: @hw-palm) {
                .font-size(5.1);
                margin-bottom: 10px;
            }

            &.header {
                @media only screen and (max-width: @hw-palm) {
                    padding-left: 0;
                }
            }
        }

        h1 > span,
        h1 > img {
            line-height: 1em;
            //float: left;
            @media only screen and (max-width: @hw-palm) {
                float: none;
            }
        }

        h1 > img {
            margin-left: 0.14em;
            width: 352px;

            @media only screen and (max-width: @hw-palm) {
                width: 252px;
            }
        }

        p {
            .font-size(1.8);
        }
    }

    .fullWidthArea {
        padding: 15px 0;

        h2 {
            color: #fff;
            margin: 0;
            .font-size(2);
        }

        .campaignPartnerLogo {
            max-width: 300px;
            max-height: 25px;
        }

        background: #3e3a37;

        li {
            display: inline;
            margin-left: 0.5em;

            &:first-child {
                margin-left: 0;
            }
        }

        a {
            color: #fff;
        }
    }
}

.campaignBody {
    text-align: center;

    h2 {
        margin: 10px 0 15px !important;
        .font-size(4);
    }

    .Iconlisting {
        padding-right: 20px;

        ul {
            border: 1px solid #e1dcd9;
            border-width: 1px 0;
            margin: 28px 0 0 0;

            &:after {
                clear: both;
            }
        }

        li {
            display: inline-block;
            font-family: @tre-bold-font;
            text-transform: uppercase;
            vertical-align: top;
            color: #46423f;
            padding: 29px 0;
            .font-size(1.6);

            &:first-child {
                margin-left: 0;
            }

            width: 22.9%;

            @media only screen and (max-width: @hw-palm) {
                width: 49%;
            }

            @iconSize: 77px;
            @iconSizeHalf: (@iconSize/2) - @iconSize;

            .icon {
                position: relative;
                top: 0;
                left: 50%;
                display: block;
                width: @iconSize;
                height: @iconSize;
                margin-left: @iconSizeHalf;
                margin-bottom: 17px;
                background: url(/ui/build/images/sprites-start.png) no-repeat;
                background-size: 297px 286px;
                -ms-behavior: url(/UI/polyfill/backgroundsize.min.htc);
            }

            h3 {
                margin-bottom: 0;
            }

            p {
                text-transform: none;
                font-family: @tre-standard-font;
            }

            .hallon {
                font-family: @tre-bold-font;
                color: #AF1642;
            }

            &.bindingPeriod {


                .icon {
                    background-position: 0 -99px;
                }
            }

            &.periodOfNotice {
                /*.icon {
                    background-position: -100px 0;
                }

                &.active .icon {
                    background-position: 0 0;
                }*/
                .icon {
                    background-position: 0 0;
                }
            }

            &.bill {

                .icon {
                    background-position: -199px 0;
                }
            }

            &.fixedPrice {
                .icon {
                    background-position: 0 -199px;
                }
            }

            @media only screen and (max-width: @hw-desktop) {
                .icon {
                    margin-bottom: 0;
                }

                .text {
                    display: none;
                }
            }
        }
    }

    .iconRelatedTextList {
        position: relative;
    }
}

/* end from student.less */
