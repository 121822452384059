﻿// Mixins
// --------------------------

.ico-icon() {
  display: inline-block;
  // font: normal normal normal 14px/1 icomoon; // shortening font declaration //14px is the default size for fontawesome as Bootstrap's base font size is 14px.
  // font: normal normal normal 1.1875/1 icomoon; // shortening font declaration font-size: 19/16, Frutiger size/icomoon base size
  // font-size: inherit; // can't have font-size inherit on line above, so need to override
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: unit(19/16, em);
  font-family: icomoon;
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-icon-rotate(@degrees, @rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=@rotation);
  -webkit-transform: rotate(@degrees);
      -ms-transform: rotate(@degrees);
          transform: rotate(@degrees);
}

.ico-icon-flip(@horiz, @vert, @rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=@rotation, mirror=1);
  -webkit-transform: scale(@horiz, @vert);
      -ms-transform: scale(@horiz, @vert);
          transform: scale(@horiz, @vert);
}
