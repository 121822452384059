.header-profile__nav-list-item {
    cursor: pointer;
}

.header-profile__nav-list-item-link {
    -webkit-box-ordinal-group: 2;
    display: block;
}

.header-profile__nav-list-item-icon {
    -webkit-box-ordinal-group: 1;
}

.header-profile__nav-list-item-text,
.header-profile__nav-list-item-text-first,
.header-profile__nav-list-item-text-second {
    font-size: 12px;
    display: block;
}

.header-profile__nav-list-item-text {
    margin-top: 6px;
    color: black;
    white-space: nowrap;
}

.header-profile__nav-list-item-text-first {
    margin-top: -15px;
}

.header-profile__nav-list-item-text-first,
.header-profile__nav-list-item-text-second {
    margin-top: -12px;

    @media @breakpointquery-2018-small-desktop {
        margin-top: -15px;
        margin-left: 0;
    }
}

.header-profile__toggle-icon {
    width: 50px;
    height: 50px;
    cursor: pointer;
    pointer-events: none;
    margin-top: -8px;
    margin-left: -10px;
    
    &.active {
        z-index: 2000;
        @media @breakpointquery-2018-small-desktop {
            display: inline-block;
        }
        .header-profile__toggle-line:nth-of-type(1) {
            transform: rotate(-45deg) translateY(0) translateX(0);
        }

        .header-profile__toggle-line:nth-of-type(2) {
            opacity: 0;
        }

        .header-profile__toggle-line:nth-of-type(3) {
            transform: rotate(45deg) translateY(0em) translateX(0em);
        }
    }
}

.header-profile__toggle-background {
    fill: @color-2018-white;
}

.header-profile__toggle-line {
    fill: none;
    stroke: @color-2018-text-black;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-width: 3px;
    transform: rotate(0) translateY(0) translateX(0);
    opacity: 1;
    transform-origin: 20px 10px;
    transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;

    &:nth-of-type(1) {
        transform-origin: 35px 21px;
    }

    &:nth-of-type(3) {
        transform-origin: 34px 29px;
    }
}
