.bubble-slide{
  display: table;

  ul{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  li{
    line-height: 1;
    font-family: @hallon-frutiger-55-roman;
    font-size: @ftsize-hallon-12;
  }

  .bubble__price{
    line-height: 1;
    font-family: @hallon-frutiger-77-bc;
    font-size: @ftsize-hallon-31;
  }

  .bubble__regular-price{
    text-decoration: line-through;
    color:@hallon-white;
  }
}

.bubble-slide--original{
  background: url(/UI/build/images/icon-marker-soft-white-2.png) no-repeat center center;
  background-size: contain;
  height:130px;
  width:135px;

  li{
    color: @hallon-black;
  }
}
.bubble-slide--campaign{
  background: url(/UI/build/images/icon-marker-soft-red.png) no-repeat center center;
  background-size: contain;
  height:130px;
  width:135px;

  li{
    color: @hallon-yellow;
  }
}
.bubble-slide--news{
  background: url(/UI/build/images/icon-marker-soft-yellow-2.png) no-repeat center center;
  background-size: contain;
  height:130px;
  width:135px;

  .bubble__regular-price{
    color: @hallon-light-red;
  }

  li{
    color: @hallon-light-red;
  }
}

.bubble-slide--news-campaign{
  background: url(/UI/build/images/icon-marker-soft-yellow-2.png) no-repeat center center;
  background-size: contain;
  height:130px;
  width:135px;

  .bubble__regular-price{
    color: @hallon-light-red;
  }

  li{
    color: @hallon-light-red;
  }
}