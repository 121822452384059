/// <reference path="../framework.less" />

.expandable-product-picker {

    &__filter-wrapper {
        display: flex;
        flex-direction: column;

        @media @breakpointquery-2018-tablet {
            padding: 40px 20px 20px 20px;
        }
        
        // @media @breakpointquery-2018-mobile {
        //     padding-top: 50px !important;
        // }
        
    }

    &__filter-wrapper-inner {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
    }

    &__filter-wrapper-inner-inner {
        flex: 1 1 auto;
    }

    &__partner-logo {
        flex: 1 1 auto;
        align-self: flex-start;
        position: relative;
        order: 1;
        display: block;
        height: 0;

        @media @breakpointquery-2018-tablet {
            display: none;
            position: relative;
            order: 0;
            right: 0;
            top: -25px;
            margin: 0 auto 0 0;
            width: 80px;
            -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
            filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
            height: auto;
        }

        &--mobile {
            display: none;

            @media @breakpointquery-2018-tablet {
                display: block;
            }
        }

        @media @breakpointquery-2018-phablet {
            top: -16px;
        }

        picture {
            display: block;
            width: 100%;
            max-width: 220px;
            min-width: 180px;
            padding: 0 20px;
            margin: auto;

            @media @breakpointquery-2018-tablet {
                max-width: none;
                min-width: 0;
                padding: 0;
            }
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__usp-list {
        display: flex;
        background-color: @color-2018-white;
        border: 1px solid @color-2018-hallon-red;
        border-radius: 20px;
        justify-content: center;
        margin-top: 25px;

        &--big {
            border-radius: 50px;

            p, a {
                font-size: 24px;
                line-height: 32px;
                font-family: @font-2018-bold;

                @media @breakpointquery-2018-tablet {
                    font-size: 21px;
                    line-height: 24px;
                }
            }

            a {
                color: @color-2018-linkblue;

                &:hover {
                    color: @color-2018-linkblue;
                    text-decoration: underline;
                }
            }
        }

        @media @breakpointquery-2018-tablet {
            flex-direction: column;
            padding: 20px 0;
        }
    }

    &__usp-list-item {
        padding: 10px 20px;
        font-size: 14px;
        line-height: 21px;

        @media @breakpointquery-2018-tablet {
            padding: 5px 20px;
            font-size: 18px;
            line-height: 27px;
        }

        svg {
            path {
                fill: none;
                stroke: @color-2018-hallon-red;
                stroke-width: 2px;
            }
        }
    }

    &__product-list-wrapper {
        position: relative;
        height: 10px;
        opacity: 0;
        transition: height 0.2s linear, opacity 0.2s linear;
        margin-top: 50px;

        @media @breakpointquery-2018-tablet {
            height: auto;
            margin: 0;
            opacity: 1;
        }
    }

    &__summary {
        padding: 13px;
        width: 100%;
        position: relative;
        opacity: 0;
        transform: translateY(-20%);
        transition: all 0.2s linear;

        @media @breakpointquery-2018-tablet {
            display: block;
            width: auto;
            z-index: 1;
            margin: 0 auto;
            bottom: 0;
            padding: 0;
            transform: none;
            transition: opacity 0.4s linear;
            opacity: 0;
            position: relative;
            position: sticky;
            position: -webkit-sticky;
            pointer-events: none;
        }

        &--active {
            opacity: 1;
            transform: translateY(0);

            @media @breakpointquery-2018-tablet {
                opacity: 1;
            }
        }
    }

    &__summary-inner-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        background-color: @color-2018-white;
        border-radius: 25px;
        padding: 12px 10px 10px 10px;
        box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.75);

        @media @breakpointquery-2018-tablet {
            display: none;
        }
    }

    &__summary-amount-wrapper {
        flex: 1 1 33%;
        text-align: right;
    }

    &__summary-amount-inner-wrapper {
        display: inline-block;
        text-align: left;
        max-width: 150px;
    }

    &__summary-amount {
        font-family: @font-2018-bold;
        color: @color-2018-green;
        font-size: 14px;
        line-height: 21px;
        display: block;
    }

    &__summary-amount-info {
        font-family: @font-2018-roman;
        color: @color-2018-text-black;
        font-size: 12px;
        line-height: 16px;
        display: block;
    }

    &__summary-cta-wrapper {
        flex: 1 1 67%;
    }

    &__summary-cta-inline-wrapper {
        display: inline-block;
    }

    &__summary-cta-button {
        padding: 13px 80px;
        white-space: nowrap;
        margin: 0 50px 0 50px;
        display: inline-block;
        cursor: pointer;
    }

    &__summary-sub-title {
        text-align: center;
        font-family: @font-2018-roman;
        font-size: 12px;
        line-height: 16px;
        color: #5C5C5C;
        margin-top: 5px;
    }

    &__summary-mobile-reveal-wrapper {
        display: none;

        @media @breakpointquery-2018-tablet {
            border-radius: 25px 25px 0 0;
            display: block;
            pointer-events: all;
        }
    }

    &__summary-mobile-reveal-wrapper {
        width: 100%;
        display: none;
        transition: transform 0.4s linear;
        transform: translateY(100%) translateY(-70px);
        bottom: 100%;
        position: absolute;

        @media @breakpointquery-2018-tablet {
            display: block;
        }

        &--active {
            transform: translateY(0);

            .expandable-product-picker__summary-mobile-reveal-button {
                background: @color-2018-white;
                box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16);
                border-radius: 25px 25px 0 0;
                color: @color-2018-hallon-red;

                &:hover {
                    color: @color-2018-hallon-red;
                }
            }
        }
    }

    &__summary-mobile-reveal-button {
        display: flex;
        height: 70px;
        z-index: 1;
        cursor: pointer;
        background: @color-2018-green;
        color: @color-2018-white;
        padding: 0 35px;
        border-radius: 25px 25px 0 0;
        position: relative;
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
        transition: all 0.4s linear;

        &:hover {
            color: @color-2018-white;
        }
    }

    &__summary-mobile-reveal-button-title {
        flex: 1 1 auto;
        font-size: 22px;
        line-height: 31px;
        font-family: @font-2018-bold;
        align-self: center;
        text-align: left;
    }


    &__summary-mobile-reveal-button-amount {
        font-family: @font-2018-roman;
        font-size: 14px;
        line-height: 21px;
        flex: 1 1 auto;
        align-self: center;
        text-align: right;
    }

    &__summary-mobile-data {
        position: relative;
        width: 100%;
        top: 0;
        padding: 45px 35px 20px 35px;
        background-color: @color-2018-white;
    }

    &__summary-mobile-data-title {
        font-size: 14px;
        line-height: 21px;
        color: @color-2018-text-black;
        font-family: @font-2018-roman;
        text-decoration: none;
    }

    &__summary-mobile-data-list {
        margin-bottom: 20px;
    }

    &__summary-mobile-data-item {
        font-size: 22px;
        line-height: 31px;
        color: @color-2018-hallon-red;
        font-family: @font-2018-bold;
    }

    &__summary-mobile-cta-wrapper {
        text-align: center;
        padding: 20px 0;
    }


    &__summary-mobile-cta-button {
        padding: 13px 80px;
        white-space: nowrap;
        margin: 0;
        display: block;
        cursor: pointer;
    }

    &__summary-mobile-sub-title {
        text-align: center;
        font-family: @font-2018-roman;
        font-size: 12px;
        line-height: 16px;
        color: #5C5C5C;
        margin-top: 5px;
    }
}

.ProductLandingPage2-sub {

    .expandable-product-picker__text-wrapper {
        float: left;
        width: 60%;

        @media @breakpointquery-2018-tablet {
            float: none;
            width: 100%;
        }
    }

    .expandable-product-picker__usb-list-wrapper {
        float: right;
        width: 35%;
        margin-left: 5%;

        @media @breakpointquery-2018-tablet {
            float: none;
            width: 100%;
            margin-left: 0;
        }

        .expandable-product-picker__usp-list {
            display: block;
            padding: 5px 0;
            margin-top: 8px;
        }

        .expandable-product-picker__usp-list-item {
            padding: 5px 20px 5px;
        }
    }

    .expandable-product-picker__filter-mobile-wrapper {
        display: flex;
        flex-wrap: wrap;

        .product-filter__options-wrapper {
            flex: 1 1 100%;
        }

        @media @breakpointquery-2018-tablet {
            margin: 0 20px 20px;

            .product-filter__options-wrapper {
                margin-top: 0;
            }

            .product-filter__filter-option {
                margin-bottom: 20px;
            }
        }
    }
}




