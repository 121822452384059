﻿//HALLON FONTS

/*
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
Imaging with any questions regarding Web Fonts:  http://www.fonts.com

Added 2018
*/


@font-face {
	font-family: "Frutiger LT W01_45 Ligh1475730";
	src: url("/UI/build/css/2018/Fonts/81e3653a-53bd-49d9-a1f8-f924811b6f02.eot?#iefix");
	src: url("/UI/build/css/2018/Fonts/81e3653a-53bd-49d9-a1f8-f924811b6f02.eot?#iefix") format("eot"),url("/UI/build/css/2018/Fonts/087d8a95-3f25-4bae-a1d1-db9ddffd72f5.woff2") format("woff2"),url("/UI/build/css/2018/Fonts/ab1bcdf7-9570-4cba-a8e3-3e39ddd861db.woff") format("woff"),url("/UI/build/css/2018/Fonts/83c94878-06c8-442c-a7df-d520b0ced00b.ttf") format("truetype"),url("/UI/build/css/2018/Fonts/29f3ff8a-1719-4e25-a757-53ee1a1114a5.svg#29f3ff8a-1719-4e25-a757-53ee1a1114a5") format("svg");
}

@font-face {
	font-family: "FrutigerLTW01-55Roman";
	src: url("/UI/build/css/2018/Fonts/fe232554-5489-4874-bb45-bd966c8c5932.eot?#iefix");
	src: url("/UI/build/css/2018/Fonts/fe232554-5489-4874-bb45-bd966c8c5932.eot?#iefix") format("eot"),url("/UI/build/css/2018/Fonts/0811514e-6660-4043-92c1-23d0b8caaa2f.woff2") format("woff2"),url("/UI/build/css/2018/Fonts/aeae8cac-ba4d-40d2-bbcf-936f2c7dcfe6.woff") format("woff"),url("/UI/build/css/2018/Fonts/caedf2e6-fbef-4928-b08e-1f975981ae8d.ttf") format("truetype"),url("/UI/build/css/2018/Fonts/7def0e34-f28d-434f-b2ec-472bde847115.svg#7def0e34-f28d-434f-b2ec-472bde847115") format("svg");
}

@font-face {
	font-family: "Frutiger-LT-W01-65-Bold";
	src: url("/UI/build/css/2018/Fonts/eb06b4c3-c0fd-4cd0-a9d9-a2954f6839a7.eot?#iefix");
	src: url("/UI/build/css/2018/Fonts/eb06b4c3-c0fd-4cd0-a9d9-a2954f6839a7.eot?#iefix") format("eot"),url("/UI/build/css/2018/Fonts/8c92eb68-ce37-4962-a9f4-5b47d336bd1c.woff2") format("woff2"),url("/UI/build/css/2018/Fonts/9bdfdcac-bbd4-46a9-9280-056c9a4438a2.woff") format("woff"),url("/UI/build/css/2018/Fonts/5dae3a6e-9b07-4c2f-aed2-1f5b8951261b.ttf") format("truetype"),url("/UI/build/css/2018/Fonts/eae74276-dd78-47e4-9b27-dac81c3411ca.svg#eae74276-dd78-47e4-9b27-dac81c3411ca") format("svg");
}

@font-face {
	font-family: "Frutiger LT W01_75 Black";
	src: url("/UI/build/css/2018/Fonts/fb1e4ad2-83fa-4735-aa1b-900f4a36749f.eot?#iefix");
	src: url("/UI/build/css/2018/Fonts/fb1e4ad2-83fa-4735-aa1b-900f4a36749f.eot?#iefix") format("eot"),url("/UI/build/css/2018/Fonts/221b3252-186e-4a32-a90b-cc0c3a4817d7.woff2") format("woff2"),url("/UI/build/css/2018/Fonts/a061c7a6-3b14-451e-883c-0f1661ac3d76.woff") format("woff"),url("/UI/build/css/2018/Fonts/558394f3-8981-4be9-8a8a-c23488eb9b6d.ttf") format("truetype"),url("/UI/build/css/2018/Fonts/1c437efb-925e-4bc5-96d0-c706bb400696.svg#1c437efb-925e-4bc5-96d0-c706bb400696") format("svg");
}

@font-face {
	font-family: "FrutigerLTStd-BlackCn";
	src: url("/UI/build/css/2018/Fonts/7eee79cd-0bdf-42f5-934b-ea0719e8f972.eot?#iefix");
	src: url("/UI/build/css/2018/Fonts/7eee79cd-0bdf-42f5-934b-ea0719e8f972.eot?#iefix") format("eot"),url("/UI/build/css/2018/Fonts/6d87ec2c-6ff9-4959-ae4b-ee78bf6766d9.woff2") format("woff2"),url("/UI/build/css/2018/Fonts/64002fd1-78f6-4403-bbaa-af1af63a80d9.woff") format("woff"),url("/UI/build/css/2018/Fonts/350bf429-b295-4652-8bbd-c991c333406b.ttf") format("truetype"),url("/UI/build/css/2018/Fonts/ff49f3f1-422a-4c44-80e2-be8dc904d23d.svg#ff49f3f1-422a-4c44-80e2-be8dc904d23d") format("svg");
}

@font-face {
	font-family: "FrutigerLTW01-87ExtraBk";
	src: url("/UI/build/css/2018/Fonts/75ba6937-3bfa-44f1-92d6-20ac14078a5e.eot?#iefix");
	src: url("/UI/build/css/2018/Fonts/75ba6937-3bfa-44f1-92d6-20ac14078a5e.eot?#iefix") format("eot"),url("/UI/build/css/2018/Fonts/e6bb7878-ff84-4f3c-b940-1accdb0d3af5.woff2") format("woff2"),url("/UI/build/css/2018/Fonts/9a63260d-daef-47ce-a2a8-a0c578fece6e.woff") format("woff"),url("/UI/build/css/2018/Fonts/e2b05bef-eb99-443a-a22c-a35ce2d70098.ttf") format("truetype"),url("/UI/build/css/2018/Fonts/f9ce193a-3f49-47fc-bed2-21a9265ecc96.svg#f9ce193a-3f49-47fc-bed2-21a9265ecc96") format("svg");
}


// HALLON ICONS
@font-face {
	font-family: 'hallon';
	src:    url('/UI/build/fonts/hallon/hallon.eot?d1eqhp');
	src:    url('/UI/build/fonts/hallon/hallon.eot?d1eqhp#iefix') format('embedded-opentype'),
	url('/UI/build/fonts/hallon/hallon.ttf?d1eqhp') format('truetype'),
	url('/UI/build/fonts/hallon/hallon.woff?d1eqhp') format('woff'),
	url('/UI/build/fonts/hallon/hallon.svg?d1eqhp#hallon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="hallon-hallon"], [class*="hallon-hallon"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'hallon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.hallon-hallon-down-arrow:before {
	content: "\e921";
}
.hallon-hallon-left-arrow:before {
	content: "\e922";
}
.hallon-hallon-right-arrow:before {
	content: "\e923";
}
.hallon-hallon-up-arrow:before {
	content: "\e924";
}
.hallon-hallon_arrows_left_right-05:before {
	content: "\e91f";
}
.hallon-hallon_arrows_left_right-06:before {
	content: "\e920";
}
.hallon-hallon_icons_07-01:before {
	content: "\e90f";
}
.hallon-hallon_icons_08-01:before {
	content: "\e910";
}
.hallon-hallon_icons_12-01:before {
	content: "\e917";
}
.hallon-hallon_icons_13-01:before {
	content: "\e919";
}
.hallon-hallon_logo-01:before {
	content: "\e918";
}
.hallon-hallon_icons_plus_paket-01:before {
	content: "\e91e";
}
.hallon-hallon_logo:before {
	content: "\e91c";
}
.hallon-hallon_icons_01:before {
	content: "\e901";
}
.hallon-hallon_icons_02:before {
	content: "\e902";
}
.hallon-hallon_icons_03:before {
	content: "\e903";
}
.hallon-hallon_icons_04:before {
	content: "\e904";
}
.hallon-hallon_icons_05 .path1:before {
	content: "\e905";
	color: rgb(0, 0, 0);
}
.hallon-hallon_icons_05 .path2:before {
	content: "\e906";
	margin-left: -1.15234375em;
	color: rgb(0, 0, 0);
}
.hallon-hallon_icons_05 .path3:before {
	content: "\e907";
	margin-left: -1.15234375em;
	color: rgb(255, 255, 255);
}
.hallon-hallon_icons_06 .path1:before {
	content: "\e908";
	color: rgb(0, 0, 0);
}
.hallon-hallon_icons_06 .path2:before {
	content: "\e909";
	margin-left: -1.41796875em;
	color: rgb(0, 0, 0);
}
.hallon-hallon_icons_06 .path3:before {
	content: "\e90a";
	margin-left: -1.41796875em;
	color: rgb(255, 255, 255);
}
.hallon-hallon_icons_06 .path4:before {
	content: "\e90b";
	margin-left: -1.41796875em;
	color: rgb(255, 255, 255);
}
.hallon-hallon_icons_07 .path1:before {
	content: "\e90c";
	color: rgb(0, 0, 0);
}
.hallon-hallon_icons_07 .path2:before {
	content: "\e90d";
	margin-left: -0.970703125em;
	color: rgb(0, 0, 0);
}
.hallon-hallon_icons_07 .path3:before {
	content: "\e90e";
	margin-left: -0.970703125em;
	color: rgb(255, 255, 255);
}
.hallon-hallon_icons_10 .path1:before {
	content: "\e911";
	color: rgba(0, 0, 0, 0.5);
}
.hallon-hallon_icons_10 .path2:before {
	content: "\e912";
	margin-left: -6.0498046875em;
	color: rgb(0, 0, 0);
}
.hallon-hallon_icons_10 .path3:before {
	content: "\e913";
	margin-left: -6.0498046875em;
	color: rgba(0, 0, 0, 0.5);
}
.hallon-hallon_icons_11:before {
	content: "\e914";
}
.hallon-hallon_icons_12:before {
	content: "\e915";
}
.hallon-hallon_icons_13:before {
	content: "\e916";
}
.hallon-hallon-chevron-right:before {
	content: "\e900";
}
.hallon-hallon-chevron-up:before {
	content: "\e91a";
}
.hallon-hallon-chevron-down:before {
	content: "\e91b";
}
.hallon-hallon-chevron-left:before {
	content: "\e91d";
}