﻿// Bordered & Pulled
// -------------------------

.@{ico-css-prefix}-border {
  padding: .2em .25em .15em;
  border: solid .08em @ico-border-color;
  border-radius: .1em;
}

.pull-right { float: right; }
.pull-left { float: left; }

.@{ico-css-prefix} {
  &.pull-left { margin-right: .3em; }
  &.pull-right { margin-left: .3em; }
}
