/// <reference path="../framework.less" />

.cta-banner {
    display: flex;
    justify-content: center;
    align-items: center;

    @media @breakpointquery-2018-tablet {
        flex-direction: column;
    }

    &__title {
        font-size: 64px;
        line-height: 70px;
        margin-right: 70px;
        margin-bottom: 0;
        text-transform: none;
        color: @color-2018-hallon-red;
        font-family: @font-2018-black;
        &--no-margin{
            margin:0;
        }
        @media @breakpointquery-2018-tablet {
            font-size: 38px;
            line-height: 46px;
            margin: 0;
            text-align:center;
            margin-bottom: 20px;
        }
    }
}
