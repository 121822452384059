.module-btn{
  display: table;

  background: url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat  center center fixed;
  background: -webkit-linear-gradient(
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.2)
  ), url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat  center center fixed;

  background: -o-linear-gradient(
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.2)
  ), url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat  center center fixed;

  background: -moz-linear-gradient(
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.2)
  ), url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat  center center fixed;

  background: linear-gradient(
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.2)
  ), url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat  center center fixed;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  span{
    display: table-cell;
    vertical-align: middle;
    text-align:center;
  }

  h2{
    font-family: @hallon-frutiger-65-bold;
    font-size: @ftsize-hallon-24;
    text-transform: none;
    color: @hallon-white;
    margin-bottom: 0.7em;

    span{
      display: block;
    }

    @media only screen and (min-width: @phone-breakpoint-hallon){
      font-size: @ftsize-hallon-48;
    }
  }

  a{
    font-size: @ftsize-hallon-14;

    @media only screen and (min-width: @phone-breakpoint-hallon){
      font-size: @ftsize-hallon-24;
    }
  }

}

