.cancel-subscription {
    margin: 0px 30px;
    background: @hallon-white;
    width: auto;
    padding: 12px 16px 12px 16px;
    position: relative;
    border-radius: 2px;

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
        width: 860px;
        margin: 0 auto;
        padding: 40px 40px 10px 40px;
    }

    .cancel-subscription__headline {
        font-family: @hallon-frutiger-77-bc;
        font-size: 16px;
        margin-bottom: 10px;
        color: @hallon-dark-grey;
        text-transform: uppercase;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            margin-bottom: 45px;
        }
    }

    &__cancel-button {
        margin-left: auto;
    }

    &__cancel-button-final {
        display: block;
    }

    .cancel-subscription__wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .cancel-subscription__subscription-list {

        li {
            position: relative;
            padding-bottom: 20px;
            padding-top: 10px;

            &:first-child {
                padding-top: 0px;
            }

            &:not(:last-child) {
                border-bottom: 1px solid @hallon-dark-grey;
            }
        }
    }

    .cancel-subscription__number {
        font-family: @hallon-frutiger-77-bc;
        font-size: 32px;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            font-size: 60px;
        }
    }

    .cancel-subscription__subscription {
        text-transform: uppercase;
        color: @hallon-dark-grey;
        font-family: @hallon-frutiger-77-bc;
        font-size: 16px;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            font-size: 27px;
        }
    }

    .cancel-subscription__button {
        margin-left: auto;
        display: inline-block;
    }


    .cancel-subscription__form-list {
        font-family: @hallon-frutiger-55-roman;
        font-size: 16px;
        width: auto;
        padding-bottom: 20px;

        li:not(:last-child) {
            margin-bottom: 20px;
        }

        &-item:before {
            content: "\2022";
            color: @hallon-black;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-right: 12px;
        }

        &-item {
            display: flex;
        }

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            width: 390px;
        }

        &-label-text {
            display: inline-block;
            max-width: 360px;
            vertical-align: middle;
        }
    }

    .cancel-subscription__cancelation-notice {
        font-family: @hallon-frutiger-55-roman;
        color: @hallon-light-black;
        max-width: 400px;
        margin-bottom: 24px;
        text-transform: none;
        font-size: @ftsize-hallon-18;
    }


    .cancel-subscription__thank-you-info {
        max-width: 400px;
    }

    .cancel-subscription__thank-you-info-text {
        padding-bottom: 10px;
        font-family: @hallon-frutiger-55-roman;
        font-size: @ftsize-hallon-14;
    }

    .cancel-subscription__btn-container {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        float: none;
        margin-top: 20px;
        padding-bottom: 10px;
        clear: both;

        .cancel-subscription__button {
            font-size: 12px;

            &:last-child {
                margin-left: 10px;
                float: none;
                margin: 0;
                margin-top: 20px;
                display: block;
                margin-left: 20px;

                @media only screen and (min-width: @tablet-breakpoint-hallon) {
                    margin-top: 0px;
                    float: right;
                }
            }
        }
        // @media only screen and (min-width: @tablet-breakpoint-hallon) {
        //     margin-top: 0px;
        //     float: right;
        //     position: absolute;
        //     bottom: 20px;
        //     right: 20px;
        // }
    }

    .termination-regret-usps {
        display: none;
    }
}

.cancel-subscription--subscription-list{

  .cancel-subscription__btn-container {
      
    .cancel-subscription__button{
      &:last-child{
        margin-left: 0px;
        float: none;
      }
    }

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      right: 0;
    }
  }

}

.cancel-subscription__last-step {
    height: 400px;
}

.cancel-subscription--disabled{

  .hallon-btn{
    background-color: @hallon-medium-grey;
    pointer-events: none;

    &:hover{
      color: white;
    }
  }
}

.cancel-subscription--not-visible{
  display: none;
}

.cancel-subscription--regret{

  .termination-regret-usps{
    display: inline;
  }
}

.cancel-subscription__remaining-data-first-step {
    font-weight: bold;
    font-size: 32px;
}
