@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.HardwarePage {
		max-width: 100%;
		&-sub{
			overflow:visible;
		}
	}
}

.HardwarePage {
	width: 100vw;

	&-sub {
		overflow: visible;
	}

	.hardwarepage__content,
	.checkout__content {
		.hardware__inner,
		.checkout {
			min-height: ~"calc(100vh - 4.3em)";
		}

		@media only screen and (min-width: @desktop-special-breakpoint) {
			background: @hallon-darkish-red;

			.hardware,
			.checkout {
				background: @hallon-medium-grey;
			}
		}
	}

	.breadcrumbs {
		display: block;
		color: @hallon-dark-red;
		padding: 2em 1em;

		[itemprop="child"] {
			display: inline-block;

			&:last-child {
				font-family: @hallon-frutiger-65-bold;
			}
		}
	}

	.hardware__selling-points {
		overflow: hidden;

		ul {
			padding: 0;
			list-style: none;
			color: @hallon-dark-red;
			font-weight: 600;
			font-family: @hallon-frutiger-65-bold;
			.flex-display(flex);
			.flex-direction(column);

			li {
				height: 2em;
				.flex-display(flex);
				.align-items(center);

				&::before {
					content: '';
					width: .6em;
					height: .6em;
					background: #fff;
					margin: -.25em 1em -.35em .5em;
					display: inline-block;
					border-radius: 50%;
					border: 6px solid @hallon-dark-red;
				}

				a {
					color: currentColor;
					transition: .5s text-shadow;

					&:hover {
						text-shadow: 0 0 1px @hallon-lighter-red;
					}
				}
			}
		}

		@media only screen and (min-width: @tablet-breakpoint-hallon) {
			ul {
				.flex-direction(row);

				li {
					&:first-child {
						margin-left: 2.5em;
					}

					margin-right: 10%;
				}
			}

			.sim-card-bg();
		}
	}

	.hallon-btn--big {
		border-radius: 2em;
		padding: .5em 2em;
		width: auto;
		font-size: @ftsize-hallon-20;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-transform: capitalize;

		@media only screen and (max-width: @tablet-breakpoint-hallon) {
			width: 100%;
		}
	}
}
