//* ww-expect "variables.less"

//  _____________________________________________________________________________
//
//  ATTRIBUTE MODULES + SEMANTIC FUSED GRID SYSTEM
//  _____________________________________________________________________________
//


//  SEMANTIC.GS
//  _____________________________________________________________________________

// Defaults which you can freely override
@column-width: 60;
@gutter-width: 20;
@columns: 12;

// Utility variable — you should never need to modify this
@gridsystem-width: (@column-width*@columns) + (@gutter-width*@columns) * 1px;

// Set @total-width to 100% for a fluid layout
// @total-width: @gridsystem-width;
@total-width: 100%;

// Uncomment these two lines and the star-hack width/margin lines below to enable sub-pixel fix for IE6 & 7. See http://tylertate.com/blog/2012/01/05/subpixel-rounding.html
// @min-width: 960;
// @correction: 0.5 / @min-width * 100 * 1%;

.row(@columns:@columns) {

	@sidemargin: @total-width*(((@gutter-width*.5)/@gridsystem-width)*-1);

	display: block;
	width: @total-width*((@gutter-width + @gridsystem-width)/@gridsystem-width);
	margin-right: @sidemargin;
	margin-left: @sidemargin;

	// *width: @total-width*((@gutter-width + @gridsystem-width)/@gridsystem-width)-@correction;
	// *margin: 0 @total-width*(((@gutter-width*.5)/@gridsystem-width)*-1)-@correction;
	.clearfix();

}

.column( @x, @columns:@columns ) {

	@sidemargin: @total-width*( (@gutter-width*.5) / @gridsystem-width );

	display: inline-block;
	float: left;
	width: @total-width*((((@gutter-width+@column-width)*@x)-@gutter-width) / @gridsystem-width);

	margin-right: @sidemargin;
	margin-left: @sidemargin;

	height: auto;// possible fix for Chrome on iOS bug

	// margin: 0 @total-width*( (@gutter-width*.5) / @gridsystem-width );

	// *width: @total-width*((((@gutter-width+@column-width)*@x)-@gutter-width) / @gridsystem-width)-@correction;
	// *margin: 0 @total-width*((@gutter-width*.5)/@gridsystem-width)-@correction;

}

.push(@offset:1) {
	margin-left: @total-width*(((@gutter-width+@column-width)*@offset) / @gridsystem-width) + @total-width*((@gutter-width*.5)/@gridsystem-width);
}

.pull(@offset:1) {
	margin-right: @total-width*(((@gutter-width+@column-width)*@offset) / @gridsystem-width) + @total-width*((@gutter-width*.5)/@gridsystem-width);
}
.push-equalheight( @offset:1, @total-width: @total-width ) {
	padding-left: @total-width*(((@gutter-width+@column-width)*@offset) / @gridsystem-width) + @total-width*((@gutter-width*.5)/@gridsystem-width);
}

.pull-equalheight( @offset:1, @total-width: @total-width ) {
	padding-right: @total-width*(((@gutter-width+@column-width)*@offset) / @gridsystem-width) + @total-width*((@gutter-width*.5)/@gridsystem-width);
}

//  REGULAR ROWS AND COLUMNS
//  _____________________________________________________________________________
//

[data-row~="regular"] {

	.row();
	.clearfix();

	[data-column] {
		.column(12); // default width of a column if no specific value is given.
		float: left;
	}

	[data-column~="1/12"]		{ .column(1); }
	[data-column~="1/6"]		{ .column(2); }
	[data-column~="1/4"]		{ .column(3); }
	[data-column~="1/3"]		{ .column(4); }
	[data-column~="5/12"]		{ .column(5); }
	[data-column~="1/2"]		{ .column(6); }
	[data-column~="7/12"]		{ .column(7); }
	[data-column~="2/3"]		{ .column(8); }
	[data-column~="3/4"]		{ .column(9); }
	[data-column~="5/6"]		{ .column(10); }
	[data-column~="11/12"]		{ .column(11); }
	[data-column~="1/1"]		{ .column(12); }

	//  Push and pull parameters
	//  _____________________________________________________________________________


	// Push parameters expressed as a factor of columns

	[data-column~="push-1"]		{ .push(1); }
	[data-column~="push-2"]		{ .push(2); }
	[data-column~="push-3"]		{ .push(3); }
	[data-column~="push-4"]		{ .push(4); }
	[data-column~="push-5"]		{ .push(5); }
	[data-column~="push-6"]		{ .push(6); }
	[data-column~="push-7"]		{ .push(7); }
	[data-column~="push-8"]		{ .push(8); }
	[data-column~="push-9"]		{ .push(9); }
	[data-column~="push-10"]	{ .push(10); }
	[data-column~="push-11"]	{ .push(11); }
	[data-column~="push-12"]	{ .push(12); }

	// Push parameters expressed as fractions

	[data-column~="push-1/12"]	{ .push(1); }
	[data-column~="push-1/6"]	{ .push(2); }
	[data-column~="push-1/4"]	{ .push(3); }
	[data-column~="push-1/3"]	{ .push(4); }
	[data-column~="push-5/12"]	{ .push(5); }
	[data-column~="push-1/2"]	{ .push(6); }
	[data-column~="push-7/12"]	{ .push(7); }
	[data-column~="push-2/3"]	{ .push(8); }
	[data-column~="push-3/4"]	{ .push(9); }
	[data-column~="push-5/6"]	{ .push(10); }
	[data-column~="push-11/12"]	{ .push(11); }
	[data-column~="push-1/1"]	{ .push(12); }

	[data-column~="push-1/6 1/3"]	{
									.column(6);
									.push(2);
									background-color: orange;
								}
	// Pull parameters expressed as a factor of columns

	[data-column~="pull-1"]		{ .pull(1); }
	[data-column~="pull-2"]		{ .pull(2); }
	[data-column~="pull-3"]		{ .pull(3); }
	[data-column~="pull-4"]		{ .pull(4); }
	[data-column~="pull-5"]		{ .pull(5); }
	[data-column~="pull-6"]		{ .pull(6); }
	[data-column~="pull-7"]		{ .pull(7); }
	[data-column~="pull-8"]		{ .pull(8); }
	[data-column~="pull-9"]		{ .pull(9); }
	[data-column~="pull-10"]	{ .pull(10); }
	[data-column~="pull-11"]	{ .pull(11); }
	[data-column~="pull-12"]	{ .pull(12); }

	// Pull parameters expressed as fractions

	[data-column~="pull-1/12"]	{ .pull(1); }
	[data-column~="pull-1/6"]	{ .pull(2); }
	[data-column~="pull-1/4"]	{ .pull(3); }
	[data-column~="pull-1/3"]	{ .pull(4); }
	[data-column~="pull-5/12"]	{ .pull(5); }
	[data-column~="pull-1/2"]	{ .pull(6); }
	[data-column~="pull-7/12"]	{ .pull(7); }
	[data-column~="pull-2/3"]	{ .pull(8); }
	[data-column~="pull-3/4"]	{ .pull(9); }
	[data-column~="pull-5/6"]	{ .pull(10); }
	[data-column~="pull-11/12"]	{ .pull(11); }
	[data-column~="pull-1/1"]	{ .pull(12); }

}

//  Nested rows

[data-column] [data-row~="regular"] {

    margin-left: 0;
    margin-right: 0;
    width: 100%;
}

//  Equalheight columns
//  _____________________________________________________________________________


.column-equalheight( @x, @columns: @columns, @total-width: @total-width ) {

	// @half-gutter-width : @gutter-width * .5;
	//@col-width: @x/@columns * @gridsystem-width;
	// @col-width: @x/@columns * 960;
	// @padding-width : @total-width * ( @gutter-width*.5 / @col-width ); // width if the percentage was calculated of the containing box

	// @sidepadding: @total-width*( (@gutter-width*.5) / @gridsystem-width );

	display: table-cell;
	// width: @total-width * ( @x / @columns ) - @sidepadding*2;
	width: unit( @x/@columns*100, % ); // more straight forward division of columns as they are table cells.
	// padding-left: @sidepadding;
	// padding-right: @sidepadding;

}
// Uses padding instead of border
.column-equalheight-padding( @x, @columns: @columns, @total-width: @total-width ) {

	// @half-gutter-width : @gutter-width * .5;
	//@col-width: @x/@columns * @gridsystem-width;
	// @col-width: @x/@columns * 960;
	// @padding-width : @total-width * ( @gutter-width*.5 / @col-width ); // width if the percentage was calculated of the containing box

	@sidepadding: @total-width*( (@gutter-width*.5) / @gridsystem-width );

	display: table-cell;
	// width: @total-width * ( @x / @columns ) - @sidepadding*2;
	width: unit( @x/@columns*100, % ); // more straight forward division of columns as they are table cells.
	padding-left: @sidepadding;
	padding-right: @sidepadding;

}

[data-row~="equalheight"] {

	display: table;
	table-layout: fixed;
	// width: @total-width;
	width: @total-width*((@gutter-width + @gridsystem-width)/@gridsystem-width);
	margin: 0 @total-width*(((@gutter-width*.5)/@gridsystem-width)*-1);

	// height: 100%; // not 100% sure about this… // Doesn't work for Chrome on iOS
	height: auto; // Possible fix for Chrome on iOS
	// min-height: 1px; // IE fix where the background of a column doesn't fill 100% of the height. Works only in IE11 though as others interpret min-height as height.
	// .clearfix(); // kills price bubbles
	
	& > [data-column] {

		display: table-cell;
		width: 100%;
		height: 100%; // necessary for other browsers. // Causes rendering bugs in Chrome for iOS for elements which don't share a sibling like data-column 1/1
		vertical-align: top;

		// TODO: expand to calculate border width according to grid and gutter width
		border-right: 10px solid transparent;
		border-left: 10px solid transparent;
		.background-clip(padding-box);
	}

	& > [data-column~="1/12"]		{ .column-equalheight(1); }
	& > [data-column~="1/6"]		{ .column-equalheight(2); }
	& > [data-column~="1/4"]		{ .column-equalheight(3); }
	& > [data-column~="1/3"]		{ .column-equalheight(4); }
	& > [data-column~="5/12"]		{ .column-equalheight(5); }
	& > [data-column~="1/2"]		{ .column-equalheight(6); }
	& > [data-column~="7/12"]		{ .column-equalheight(7); }
	& > [data-column~="2/3"]		{ .column-equalheight(8); }
	& > [data-column~="3/4"]		{ .column-equalheight(9); }
	& > [data-column~="5/6"]		{ .column-equalheight(10); }
	& > [data-column~="11/12"]		{ .column-equalheight(11); }
	& > [data-column~="1/1"]		{ .column-equalheight(12); height:auto;}

	& > [data-column~="1/1"] {

		display: table-row;
		&:first-child {

			display: table-caption; // Doesn't work in mobile Safari
		}
	}


//  Push and pull parameters
//  _____________________________________________________________________________


	// Push parameters expressed as a factor of columns

	& > [data-column~="push-1"]		{ .push-equalheight(1); }
	& > [data-column~="push-2"]		{ .push-equalheight(2); }
	& > [data-column~="push-3"]		{ .push-equalheight(3); }
	& > [data-column~="push-4"]		{ .push-equalheight(4); }
	& > [data-column~="push-5"]		{ .push-equalheight(5); }
	& > [data-column~="push-6"]		{ .push-equalheight(6); }
	& > [data-column~="push-7"]		{ .push-equalheight(7); }
	& > [data-column~="push-8"]		{ .push-equalheight(8); }
	& > [data-column~="push-9"]		{ .push-equalheight(9); }
	& > [data-column~="push-10"]	{ .push-equalheight(10); }
	& > [data-column~="push-11"]	{ .push-equalheight(11); }
	& > [data-column~="push-12"]	{ .push-equalheight(12); }

	// Push parameters expressed as fractions

	& > [data-column~="push-1/12"]	{ .push-equalheight(1); }
	& > [data-column~="push-1/6"]	{
									&[data-column~="1/3"] {
										.column-equalheight(6);
										.push-equalheight(2);
									}
									.push-equalheight(2);
								}
	& > [data-column~="push-1/4"]	{ .push-equalheight(3); }
	& > [data-column~="push-1/3"]	{ .push-equalheight(4); }
	& > [data-column~="push-5/12"]	{ .push-equalheight(5); }
	& > [data-column~="push-1/2"]	{ .push-equalheight(6); }
	& > [data-column~="push-7/12"]	{ .push-equalheight(7); }
	& > [data-column~="push-2/3"]	{ .push-equalheight(8); }
	& > [data-column~="push-3/4"]	{ .push-equalheight(9); }
	& > [data-column~="push-5/6"]	{ .push-equalheight(10); }
	& > [data-column~="push-11/12"]	{ .push-equalheight(11); }
	& > [data-column~="push-1/1"]	{ .push-equalheight(12); }

	// Pull parameters expressed as a factor of columns

	& > [data-column~="pull-1"]		{ .pull-equalheight(1); }
	& > [data-column~="pull-2"]		{ .pull-equalheight(2); }
	& > [data-column~="pull-3"]		{ .pull-equalheight(3); }
	& > [data-column~="pull-4"]		{ .pull-equalheight(4); }
	& > [data-column~="pull-5"]		{ .pull-equalheight(5); }
	& > [data-column~="pull-6"]		{ .pull-equalheight(6); }
	& > [data-column~="pull-7"]		{ .pull-equalheight(7); }
	& > [data-column~="pull-8"]		{ .pull-equalheight(8); }
	& > [data-column~="pull-9"]		{ .pull-equalheight(9); }
	& > [data-column~="pull-10"]	{ .pull-equalheight(10); }
	& > [data-column~="pull-11"]	{ .pull-equalheight(11); }
	& > [data-column~="pull-12"]	{ .pull-equalheight(12); }

	// Pull parameters expressed as fractions

	& > [data-column~="pull-1/12"]	{ .pull-equalheight(1); }
	& > [data-column~="pull-1/6"]	{ .pull-equalheight(2); }
	& > [data-column~="pull-1/4"]	{ .pull-equalheight(3); }
	& > [data-column~="pull-1/3"]	{ .pull-equalheight(4); }
	& > [data-column~="pull-5/12"]	{ .pull-equalheight(5); }
	& > [data-column~="pull-1/2"]	{ .pull-equalheight(6); }
	& > [data-column~="pull-7/12"]	{ .pull-equalheight(7); }
	& > [data-column~="pull-2/3"]	{ .pull-equalheight(8); }
	& > [data-column~="pull-3/4"]	{ .pull-equalheight(9); }
	& > [data-column~="pull-5/6"]	{ .pull-equalheight(10); }
	& > [data-column~="pull-11/12"]	{ .pull-equalheight(11); }
	& > [data-column~="pull-1/1"]	{ .pull-equalheight(12); }

}

// Below didn't work because the container's text would align to the top in Chrome
// [data-column] {
	
// 	vertical-align: top; // If this doesn't work out then fix the page  /kundservice. This goes against .valign-
// }

//
// Utlity function to release equalheight cells to full width.
// Meant to be used with smaller screen sizes.

.column-equalheight-normalize() {

	.column(12);
	height: auto;
	padding-left: 0;
	padding-right: 0;
}


//  _____________________________________________________________________________
//
//  PREVIOUS GRID SYSTEM WHICH IS TO BE FADED OUT
//  _____________________________________________________________________________
//

.wrap-grid {padding:0 @grid-padding-horizontal;}

.grid
{
    //margin: 0 0 @grid-padding-vertical 0;
	margin: 0;

    &:after
    {
        /* Or @extend clearfix */
        content: "";
        display: table;
        clear: both;
    }
}

[class*='col-'] {
	float: left;
	padding-right: @grid-gutter-horizontal;
	.grid &:last-of-type {
		padding-right: 0;
	}
}

[class*='col-'] > [class*='col-'] {
    &:last-of-type {
		padding-right:0 !important;
    }
}
.col-1-1 {  width: 100%;}
.col-2-3 {	width: 66.6666%;}
.col-1-3 {	width: 33.3333%;}
.col-1-2 {	width: 50%;}
.col-1-4 {	width: 25%;}
.col-1-8 {	width: 12.5%;}
.col-1-12 {	width: 8.3333%;}

.module {
	padding: @grid-padding-vertical @grid-gutter-horizontal @grid-padding-vertical @grid-gutter-horizontal;
	overflow:hidden;
}

/* Opt-in outside padding */
.grid-pad {
	padding: @grid-padding-vertical 0 0 @grid-gutter-horizontal;
	[class*='col-']:last-of-type {
		padding-right: @grid-gutter-horizontal;
	}
	&:after
    {
        /* Or @extend clearfix */
        content: "";
        display: table;
        clear: both;
    }
}

/*
//nested support
[class*='col-'] .module [class*='col-']:last-of-type {
	padding-right:0;
}
*/



@media only screen and (max-width: 700px) {
	.grid-pad {padding:0 !important;}
	[class*="col-"] {padding-right:0 !important;}

	.wrap-grid {padding:0}
}

[data-row~="regular"] .flex-fix-button {
	display: flex;
	float: none;
	margin: auto;
}