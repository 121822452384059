/// <reference path="../framework.less" />


.hallon-dropdown {
    display: flex;
    position: relative;
    z-index: 1000;

    &--hidden {
        display: none;
        visibility: hidden;
    }

    &__toggle {
        width: 100%;
        border: none;
        border-bottom: 1px solid #e6e6e6;
        padding: 10px 10px 10px 5px;
        display: flex;
        background-color: transparent;
        cursor: pointer;
        font-size: 16px;
        font-size: 1.6rem;
        line-height: 1.7em;
        align-items: center;
        -webkit-appearance: none;
        transition: border 0.2s linear;

        &:focus, &:hover {
            border-bottom-color: @color-2018-background-dark-grey;
        }
    }

    &__toggle-value {
        flex: 1 1 auto;
        text-align: left;
    }

    &__toggle-icon {
        flex: 0 0 auto;
        margin-left: 2em;
        height: 20px;
        width: 20px;
        fill: @color-2018-hallon-red;

        &--grey {
            fill: #979797;
        }
    }

    &__list {
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        z-index: 1;
        background-color: @color-2018-white;
        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);
        overflow-y: auto;
        max-height: 300px;
    }

    &__list-item {
        background-color: @color-2018-white;
        transition: background 0.2s linear;

        &:nth-child(odd) {
            background-color: #F2F2F2;
        }

        &:hover {
            background-color: @color-2018-hallon-red;

            .hallon-dropdown__list-item-title {
                color: @color-2018-white;
            }

            .hallon-dropdown__list-item-info {
                color: @color-2018-white;
            }
        }
    }

    &__list-item-link {
        padding: 15px 30px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        cursor: pointer;
        background-color: transparent;
        border: none;
        -webkit-appearance: none;
        width: 100%;
    }

    &__list-item-title {
        font-family: @font-2018-bold;
        font-size: 16px;
        line-height: 22px;
        color: @color-2018-hallon-red;
        flex: 0 1 auto;
        margin: 0;
        padding: 0 40px 0 0;
        text-transform: none;
        transition: color 0.2s linear;
    }

    &__list-item-info {
        font-family: @font-2018-roman;
        font-size: 12px;
        line-height: 16px;
        color: @color-2018-text-black;
        flex: 1 0 auto;
        transition: color 0.2s linear;
        text-align: left;
    }
}
