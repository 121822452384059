.circle-picker {
    > input[type="radio"] {
        display: none;
    }

    input:checked + label {
        &::before {
            content: '';
            background: white;
            z-index: 1;
        }

        span {
            color: @hallon-black;
        }
    }

    input + label {
        margin-right: 15px;
        border: 2px solid transparent;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        position: relative;
        transition: .2s opacity;

        &::before {
            content: '';
            position: absolute;
            background: transparent;
            width: 50%;
            height: 50%;
            border-radius: 50%;
            top: 25%;
            left: 25%;
        }

        &:hover {
            cursor: pointer;
            opacity: .75;
        }
    }

    label {
        display: inline-block;
        font-weight: normal;
    }

    span {
        vertical-align: middle;
        text-align: center;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 0;
        background-image: none;
    }

    #product-color {
        // override some of the (insane) default colors for legibility reasons
        &__yellow {
            background-color: #fe2 !important;
        }

        &__green {
            background-color: #0d2 !important;
        }

        &__red {
            background-color: #d20 !important;
        }

        &__white {
            background-color: @hallon-medium-grey !important;
        }
    }

    .circle-picker__color {
        background: white;
        text-align: center;
        vertical-align: middle;
        font-size: 14px;
        line-height: 1.7em;
        color: @hallon-dark-grey;
    }
}
