.module-hallonslider{

  .module-hallonslider__list-product{
    display: none;
    @media only screen and (min-width: @tablet-breakpoint-hallon){
      display: table;
    }
  }

  .module-hallonslider__toggle-subscription{
    display: block;
    @media only screen and (min-width: @tablet-breakpoint-hallon){
      display: none;
    }
  }
}