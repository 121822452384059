﻿#floatingCirclesG{
position:relative;
width:64px;
height:64px;
-moz-transform:scale(0.6);
-webkit-transform:scale(0.6);
-ms-transform:scale(0.6);
-o-transform:scale(0.6);
transform:scale(0.6);
}

.f_circleG{
position:absolute;
background-color:#FFFFFF;
height:12px;
width:12px;
-moz-border-radius:6px;
-moz-animation-name:f_fadeG;
-moz-animation-duration:1.52s;
-moz-animation-iteration-count:infinite;
-moz-animation-direction:linear;
-webkit-border-radius:6px;
-webkit-animation-name:f_fadeG;
-webkit-animation-duration:1.52s;
-webkit-animation-iteration-count:infinite;
-webkit-animation-direction:linear;
-ms-border-radius:6px;
-ms-animation-name:f_fadeG;
-ms-animation-duration:1.52s;
-ms-animation-iteration-count:infinite;
-ms-animation-direction:linear;
-o-border-radius:6px;
-o-animation-name:f_fadeG;
-o-animation-duration:1.52s;
-o-animation-iteration-count:infinite;
-o-animation-direction:linear;
border-radius:6px;
animation-name:f_fadeG;
animation-duration:1.52s;
animation-iteration-count:infinite;
animation-direction:linear;
}

#frotateG_01{
left:0;
top:26px;
-moz-animation-delay:0.57s;
-webkit-animation-delay:0.57s;
-ms-animation-delay:0.57s;
-o-animation-delay:0.57s;
animation-delay:0.57s;
}

#frotateG_02{
left:8px;
top:8px;
-moz-animation-delay:0.76s;
-webkit-animation-delay:0.76s;
-ms-animation-delay:0.76s;
-o-animation-delay:0.76s;
animation-delay:0.76s;
}

#frotateG_03{
left:26px;
top:0;
-moz-animation-delay:0.95s;
-webkit-animation-delay:0.95s;
-ms-animation-delay:0.95s;
-o-animation-delay:0.95s;
animation-delay:0.95s;
}

#frotateG_04{
right:8px;
top:8px;
-moz-animation-delay:1.14s;
-webkit-animation-delay:1.14s;
-ms-animation-delay:1.14s;
-o-animation-delay:1.14s;
animation-delay:1.14s;
}

#frotateG_05{
right:0;
top:26px;
-moz-animation-delay:1.33s;
-webkit-animation-delay:1.33s;
-ms-animation-delay:1.33s;
-o-animation-delay:1.33s;
animation-delay:1.33s;
}

#frotateG_06{
right:8px;
bottom:8px;
-moz-animation-delay:1.52s;
-webkit-animation-delay:1.52s;
-ms-animation-delay:1.52s;
-o-animation-delay:1.52s;
animation-delay:1.52s;
}

#frotateG_07{
left:26px;
bottom:0;
-moz-animation-delay:1.71s;
-webkit-animation-delay:1.71s;
-ms-animation-delay:1.71s;
-o-animation-delay:1.71s;
animation-delay:1.71s;
}

#frotateG_08{
left:8px;
bottom:8px;
-moz-animation-delay:1.9s;
-webkit-animation-delay:1.9s;
-ms-animation-delay:1.9s;
-o-animation-delay:1.9s;
animation-delay:1.9s;
}

@-moz-keyframes f_fadeG{
0%{
background-color:#3e3a37}

100%{
background-color:#FFFFFF}

}

@-webkit-keyframes f_fadeG{
0%{
background-color:#3e3a37}

100%{
background-color:#FFFFFF}

}

@-ms-keyframes f_fadeG{
0%{
background-color:#3e3a37}

100%{
background-color:#FFFFFF}

}

@-o-keyframes f_fadeG{
0%{
background-color:#3e3a37}

100%{
background-color:#FFFFFF}

}

@keyframes f_fadeG{
0%{
background-color:#3e3a37}

100%{
background-color:#FFFFFF}

}
