/// <reference path="../framework.less" />

.topmenu {
    display: block;
    background-color: #F6EBEF;
    top: 0;
    width: 100%;
    height: auto;

    @media @breakpointquery-2018-small-desktop {
        display: none;
    }
}

.topmenu--visible {
    display: none;
}

.topmenu__list {
    width: 100%;
    max-width: 1300px;
    box-sizing: border-box;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    list-style: none;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 14px;
}

.topmenu__list-item {
    display: inline-block;
    margin-left: 8px;
}

.topmenu__list-item:first-child {
    margin-left: 28px; 
}

.topmenu__list-item-link {
    text-decoration: none;
    color: #000;
    padding: 7px 14px 7px 14px;
    font-size: 16px;
}

.topmenu__list-item-link:hover {
    background: #8F1840;
    border-radius: 50px;
    padding: 7px 14px 7px 14px;
    width: 62px;
    height: 25px;
    color: #fff;
}

.topmenu__list-item-link--active {
    background: #8F1840;
    border-radius: 50px;
    padding: 7px 14px 7px 14px;
    width: 62px;
    height: 25px;
    color: #fff;
}
