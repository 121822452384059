// @hw-mobile: 320px;
// @hw-mobile-max: (@hw-mobile)-1;
// @hw-palm: 480px;
// @hw-palm-max: (@hw-palm)-1;
// @hw-tablet: 768px;
// @hw-tablet-max: (@hw-tablet)-1;
// @hw-laptop: 880px;
// @hw-laptop-max: (@hw-laptop)-1;
// @hw-desktop: 940px;
// @hw-desktop-max: (@hw-desktop)-1;
// @hw-tv: 1170px;
// @hw-tv-max: (@hw-tv)-1;

@media only screen and (min-width: 960px ) and ( max-width: 1080px ) {

	// The footer is placed within a 1080px grid and needs seperate adjustments
	footer {

		.content-container {

			@side-padding: 100/12 * 3/4; // one column width minus gutter
			padding-left: unit( @side-padding, %);
			padding-right: unit( @side-padding, %);
		}
	}
}

@media only screen and ( max-width: 960px ) {

	// .content-container {

	// 	@side-padding: 100/12; // one column width
	// 	padding-left: unit( @side-padding , %);
	// 	padding-right: unit( @side-padding , %);
	// }

	

	[data-row~="regular"] {

		[data-column~="1/3 push-1/3"] {
			.column(8);
			.push(2);
		}
		// Embedded columns
		[data-column] {

			[data-hlayout]:not([data-hlayout~="keeplayout"]) {

				[data-column~="1/2"]:not([data-column~="push-1/2"]) {
					.column(12);
				}
			}
		}

		[data-hlayout~="leftright"] {
			[data-column~="1/2"] {
				.column(6); // Keep the half width
			}
		}
	}

	[data-row~="constant"] {

		[data-column~="push-1/6"] {
			&[data-column~="1/3"] {
				.column(5);
				.push(1);
			}
		}
	}

	[data-row~="equalheight"] {

		[data-column~="push-1/6"] {
			&[data-column~="1/3"] {
				.push-equalheight(1);
			}
		}
	}

	// Deprecated after the introduction of fit-constrained
	// [data-column~="1/2"] {

	// 	> [data-button~="fit-fullwidth"] {

	// 		max-width: unit( @gridsystem-width/2, px );
	// 		margin-left: auto;
	// 		margin-right: auto;
	// 	}
	// }
}

@media only screen and ( min-width: @phone-barrier ) and ( max-width: 960px ) {

	[data-column~="1/2"] {

		[data-button~="type-selection"] {

			&[data-button~="size-L"] {
				.font-size(1.6);
			}
		}
	}

	[data-row~="equalheight"] {

		> [data-column] {
			border-right-width: 8px;
			border-left-width: 8px;
		}
	}
}

//
// @hw-laptop: 880px;
//
@media only screen and ( max-width: @hw-laptop ) {




	[data-row~="constant"] {

		[data-column~="push-1/6"] {
			&[data-column~="1/3"] {
				.column(6); // 50%
				.push(0);
			}
		}
	}

	[data-row~="equalheight"] {

		[data-column~="push-1/6"] {
			&[data-column~="1/3"] {
				.push-equalheight(0);
			}
		}
	}
}

//
// @hw-tablet: 768px
//
@media only screen and (max-width: @hw-tablet) {

	body {
		-webkit-text-size-adjust: none; // no default text-size adjustments on iOS.
	}

	[data-row~="regular"] {

		[data-column~="1/3"]:not( [data-column~="static"] ) {


			&[data-column~="push-2/3"] {
				// Centered layout. Implemented in Confirm.cshtml for selfservice data topup.
				.column(8); // 2/3
				.push(2);
			}
			// &[data-hlayout~="center"],
			// &[data-hlayout~="right"]
			// {
			// 	float: none;
			// 	text-align: left;
			// 	> * {
			// 		margin-left: 0;
			// 		text-align: left;
			// 		float: left;
			// 	}
			// }
		}

		[data-column~="2/3"].not('[data-column~="1/3"]') {
			.column(12); // 100%
		}


		[data-column="1/4"] { //omitting possible push properties
			.column(6); // 1/2
			&:nth-child(3) {
				clear: left;
			}
		}

		[data-column~="3/4"] {
			.column(6);
		}

		[data-column~="push-1/3"] {
			.push(1);
		}
	}

	[data-row~="equalheight"] {

		> [data-column] {
			border-right-width: 7px;
			border-left-width: 7px;
		}
	}

	// General nested columns
	//
	[data-column~="1/2"] {

		[data-column~="2/3"] {
			.column(12); // Full width. Ex. .MobilePackageOrderPage .personuppgifter
			&[data-column~="pull-1/3"] {
				.pull(0);
			}
		}
	}

	// Keep the 1/3 grid width for special layouts
	[data-hlayout~="leftcenterright"] {

		[data-column~="1/3"] {
			.column(4);
		}

		[data-column~="2/3"] {
			.column(12);
			text-align: center;

			> * {
				float: none;
				margin-left: auto;
				margin-right: auto;
				text-align: center;
			}
		}
	}

	[data-hlayout~="leftright"] {

		[data-column~="1/3"] {
			.column(6);
		}

		[data-column~="push-1/3"] {
			.push(0);
		}
	}
}

@media only screen and (max-width: 680px) {

	[data-row~="equalheight"] {

		> [data-column] {
			border-right-width: 6px;
			border-left-width: 6px;
		}
	}
}
//
// 600px
//
@media only screen and (max-width: @phone-barrier) {

	[data-row~="regular"] {

		[data-column~="1/2"]:not([data-column~="static"]) {
			.column(12);
		}
		// All 2/3 columns including ones with pull- properties
		[data-column~="2/3"]:not([data-column~="static"]) {
			.column(12); // 100%
		}

		[data-column~="1/4"][data-column~="push-3/4"] { //omitting possible push properties
			.column(6); // 1/2
			.push(6);
		}

		[data-column~="5/6"]:not([data-column~="keep-size"]) {
			.column(12);

			&[data-column~="push-1/12"] {
				.push(0);
			}
		}

		[data-hlayout~="leftright"] {

			[data-column~="1/2"] {
				.column(12);
			}

			&[data-hlayout~="weight-left"] {

				[data-hlayout="right"] {
					float: left;
					text-align: left;

					> * {
						text-align: left;
					}

					[data-button] { // Add more components here when necessary
						float: left;
					}
				}
			}

			&[data-hlayout~="weight-right"] {

				[data-hlayout="right"] {
					float: left;
					text-align: left;

					> * {
						float: right;
						text-align: right;
					}
				}
			}

			&[data-hlayout~="keeplayout"] {

				[data-column~="1/2"] {
					.column(6);
				}
			}
		}
	}

	[data-row~="equalheight"] {
		display: block; // required for iOS
		height: auto; // possible fix for Chrome on iOS
		.clearfix();

		[data-column]:not( [data-column~="static"] ) {
			border-right-width: 0;
			border-left-width: 0;
		}
		// The below is for mobile safari which renders table-caption incorrectly.
		[data-column~="1/1"]:first-child {
			display: block;
		}

		[data-column~="1/2"] {
			.column(12);
			// padding-right: 0;
			// padding-left: 0;
			height: auto;
		}

		[data-column~="1/3"] {
			.column(12);
			// padding-right: 0;
			// padding-left: 0;
		}

		[data-column~="push-1/12"] {
			// .push-equalheight(0);
			padding-left: 0;
		}

		[data-column~="pull-1/12"] {
			// .pull-equalheight(0);
			padding-right: 0;
		}

		[data-column~="pull-1/6"] {
			// .pull-equalheight(0);
			padding-right: 0;
		}
	}

	// General nested columns
	//
	[data-row] {


		[data-column~="1/2"] {

			[data-column~="2/3"] {
				.column(8); // Retain original width again. Ex. .MobilePackageOrderPage .personuppgifter
				&[data-column~="pull-1/3"] {
					.pull(4);
				}
			}
		}

		[data-column~="5/12"] {
			.column(12);
			padding-right: 0;
			padding-left: 0;

			&[data-column~="push-1/12"] {
				.push(0);
			}

			&[data-column~="pull-1/12"] {
				.pull(0);
			}

			&[data-column~="pull-1/6"] {
				.pull(0);
			}
		}

		[data-column~="7/12"] {
			.column(12);
		}
	}
}
// 480px
@media only screen and (max-width: @hw-palm) {


	[data-row~="regular"]:not([data-hlayout~="keeplayout"]) {

		[data-column~="1/4"] {
			.column(12);

			&[data-column~="push-3/4"] {
				.column(9); // 1/2
				.push(3);
			}
		}

		[data-column~="3/4"] {
			.column(12);
		}

		[data-column~="1/3"] {
			.column(12);
		}
		// 100%
		[data-column~="push-1/3"] {
			.push(0);
		}

		&[data-hlayout~="leftright"] {

			[data-hlayout~="left"] {
				margin-bottom: @margin-M;

				&:last-child {
					margin-bottom: 0;
				}
			}

			&[data-hlayout~="weight-left"] {

				[data-hlayout~="right"] {
					float: left;
					text-align: left;

					* > {
						text-align: left;
					}
				}
			}
		}
	}

	[data-hlayout~="leftcenterright"] {

		[data-column~="1/3"] {
			.column(12);

			> * {
				text-align: left;
				float: none;
			}
		}

		&[data-hlayout~="weight-center"] {

			[data-column~="1/3"] {
				text-align: center;

				> * {
					float: none;
					margin-left: auto;
					margin-right: auto;
					text-align: center;
				}
			}
		}

		&[data-hlayout~="weight-left"] {

			[data-hlayout~="center"] {
				text-align: left;
			}
		}

		&[data-hlayout~="keeplayout"] {

			[data-hlayout="left"] {
			}

			[data-hlayout="center"] {
			}

			[data-hlayout="right"] {
				text-align: right;

				> * {
					float: right;
					text-align: right;
				}
			}
		}
	}

	[data-hlayout~="leftright"] {

		[data-hlayout~="left"] {
			margin-bottom: @margin-M;

			[data-button] {
				margin-bottom: @margin-M;
			}
		}
	}

	[data-hlayout~="weight-center"] {
		text-align: center;

		[data-hlayout] {
			float: none;
			text-align: center;

			> * {
				float: none;
				text-align: center;
			}
		}
	}
}

// @hw-mobile-L being 360px
@media only screen and (max-width: @hw-mobile-L) {

	[data-row~="regular"] {

		[data-column~="1/4"] {
			// .column(12);
			&[data-column~="push-3/4"] {
				.column(12);
			}
		}

		[data-column~="5/6"] {
			.column(12);
		}

		[data-column~="1/2"] {

			[data-column~="2/3"] {
				.column(12); // Full width again. Ex. .MobilePackageOrderPage .personuppgifter
				&[data-column~="pull-1/3"] {
					.pull(0);
				}
			}
		}
	}

	[data-row~="equalheight"] {

		[data-column~="1/2"] {

			[data-column~="2/3"] {
				.column(12); // Full width again. Ex. .MobilePackageOrderPage .personuppgifter
				&[data-column~="pull-1/3"] {
					.pull-equalheight(0);
				}
			}
		}

		[data-column~="5/12"] {

			&[data-column~="pull-1/6"] {
				.pull-equalheight(0);
			}
		}
	}
}


