/// <reference path="../framework.less" />

.banner-component {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: column;

	&--countdown {
		flex-direction: column;
	}

	&__center-wrapper {
		position: absolute;
		padding: 0 50px;
		width: 100%;
		max-width: 1000px;
		margin: auto;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@media @breakpointquery-2018-tablet {
			padding: 0 15px;
			max-width: 100%;
		}

		&--padding-top {
			padding-top: 150px;

			@media @breakpointquery-2018-tablet {
				padding-top: 90px;
			}
		}

		&--none {
			position: relative;
		}
	}

	&__title {
		font-family: @font-2018-black;
		text-transform: none;
		color: @color-2018-white;
		text-align: center;
		width: 100%;
		position: relative;
		font-size: @font-2018-component-header;
		line-height: @font-2018-component-header-line-height;
		white-space: pre-line;
		word-break: break-word;
		overflow-wrap: break-word;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		hyphens: auto;
		max-width: 100%;

		@media @breakpointquery-2018-tablet {
			font-size: @font-2018-component-header-mobile;
			line-height: @font-2018-component-header-line-height-mobile;
		}

		&--enhanced {
			font-size: @font-2018-component-header-enhanced;
			line-height: @font-2018-component-header-line-height-enhanced;

			@media @breakpointquery-2018-tablet {
				font-size: @font-2018-component-header-enhanced-mobile;
				line-height: @font-2018-component-header-line-height-enhanced-mobile;
			}
		}

		&--relative {
			position: relative;
		}
	}

	&__sub-text {
		font-family: @font-2018-roman;
		margin-top: 20px;
		font-size: 27px;
		line-height: 1.4;
		text-transform: none;
		text-align: center;
		white-space: pre-line;
		word-break: break-word;
		overflow-wrap: break-word;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		hyphens: auto;
		max-width: 100%;

		@media @breakpointquery-2018-tablet {
			font-size: 20px;
			line-height: 24px;
		}
        a{
            color:@color-2018-linkblue;
            &:hover{
                text-decoration:underline;
            }
        }
	}

	&__image-wrapper {
		width: 100%;
		height: 100%;
		display: block;
	}

	&__image {
		width: 100%;
		height: 100%;
		display: block;
	}

	&__countdown-title {
		font-family: @font-2018-black;
		text-transform: none;
		color: @color-2018-white;
		text-align: center;
		width: 100%;
		margin: 0 auto;
		padding: 0 50px;
		max-width: 1000px;
		font-size: @font-2018-component-header;
		line-height: @font-2018-component-header-line-height;

		@media @breakpointquery-2018-tablet {
			font-size: @font-2018-component-header-mobile;
			line-height: @font-2018-component-header-line-height-mobile;
			padding: 0 15px;
			max-width: none;
		}

		&--enhanced {
			font-size: @font-2018-component-header-enhanced;
			line-height: @font-2018-component-header-line-height-enhanced;

			@media @breakpointquery-2018-tablet {
				font-size: @font-2018-component-header-enhanced-mobile;
				line-height: @font-2018-component-header-line-height-enhanced-mobile;
			}
		}
	}

	&__countdown-sub-text {
		font-family: @font-2018-roman;
		margin-top: 20px;
		font-size: 27px;
		line-height: 1.4;
		text-transform: none;
		text-align: center;
		white-space: pre-line;
		word-break: break-word;
		overflow-wrap: break-word;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		hyphens: auto;
		max-width: 1000px;
		margin-bottom: 50px;

		@media @breakpointquery-2018-tablet {
			font-size: 20px;
			line-height: 24px;
			margin-bottom: 20px;
			max-width: 100%;
		}
	}

	&__countdown-wrapper {
		color: @color-2018-white;
		text-align: center;
		padding: 0 0 60px 0;


		@media @breakpointquery-2018-tablet {
			width: 100%;
			text-align: center;
			align-content: center;
			justify-content: center;
			margin: auto;
			padding: 20px 0 40px 0;
		}
	}

	&__countdown-button {
		margin-bottom: 50px;

		@media @breakpointquery-2018-tablet {
			margin-bottom: 25px;
			max-width: 100%;
		}
	}

	&__countdown-container {
		display: inline-block;
	}

	&__countdown-time-container {
		display: flex;
	}

	&__countdown-time-time {
		font-size: @font-2018-component-header-enhanced;
		line-height: @font-2018-component-header-line-height-enhanced;
		font-family: @font-2018-black;

		@media @breakpointquery-2018-tablet {
			font-size: @font-2018-component-header-enhanced-mobile;
			line-height: @font-2018-component-header-line-height-enhanced-mobile;
		}
	}

	&__countdown-counter-text {
		font-size: 18px;
		line-height: 21px;
		font-family: @font-2018-light;

		@media @breakpointquery-2018-tablet {
			font-size: 16px;
			line-height: 19px;
		}
	}

	&__countdown-time-text {
		font-size: 27px;
		line-height: 1.4;
		font-family: @font-2018-light;

		@media @breakpointquery-2018-tablet {
			font-size: 16px;
			line-height: 19px;
		}
	}

	&__countdown-time-wrapper {
		margin: 0;
		padding: 0;
		float: none;

		&--seperator {
			align-self: flex-end;
			font-size: @font-2018-component-header-enhanced;
			line-height: @font-2018-component-header-line-height-enhanced;
			font-family: @font-2018-black;

			@media @breakpointquery-2018-tablet {
				font-size: @font-2018-component-header-enhanced-mobile;
				line-height: @font-2018-component-header-line-height-enhanced-mobile;
			}

			padding: 0 5px;
		}

		span {
			margin: 0;
			padding: 0;
			display: block;
			text-align: center;
		}
	}
}

