/// <reference path="../framework.less" />

//This is old colors for myPages before 2018
@color-grey-mypages-old: @color-gray50;
@font-blackCondensed-mypages-old: @hallon-frutiger-77-bc;

.hallon-checkbox {
  display: flex;
  align-content: center;
  transform: scale3d(1, 1, 1);

  &--gdpr:last-of-type {
    margin-bottom: 2em;
  }

  &__label {
    display: flex;
    color: @color-2018-hallon-red;
    cursor: pointer;
    position: relative;
    font-size: 16px;
    align-items: center;
    margin: 5px 0 10px 0;

    &--gdpr {
      color: @color-2018-text-black;
      font-size: 14px;
      align-items: flex-start;

      strong {
        font-family: @font-2018-bold;
        margin: 0 0 5px 0;
        display: block;
      }
    }

    &--gdpr-mail {
      color: @color-2018-text-black;
      font-size: 14px;
      line-height: 21px;
      align-items: flex-start;
      font-family: @font-2018-light;

      strong {
        font-family: @font-2018-bold;
        color: @color-2018-text-black;
        margin: 0 0 5px 0;
        display: block;
      }
    }
    //This is a specialcase for my pages older than 2018 TODO
    &--gdpr-my-pages {
      color: @color-grey-mypages-old;
      font-size: 14px;
      align-items: flex-start;
      margin: 0 0 5px 0;

      strong {
        font-family: @font-blackCondensed-mypages-old;
        text-transform: uppercase;
        font-weight: normal;
        margin: 0 0 5px 0;
        display: block;
      }
    }

    .hallon-checkbox__ordinary-filter-label,
    .hallon-checkbox__sub-filter-label {
      display: block;
      text-align: left;
    }

    .hallon-checkbox__ordinary-filter-label {
      font-size: 26px;
      padding-bottom: 10px;

      
		@media @breakpointquery-2018-tablet {
            font-size:18px;
		}
    }

    .hallon-checkbox__sub-filter-label {
        font-size: 14px;
        font-family:@font-2018-roman;
        font-weight:400;
		@media @breakpointquery-2018-tablet {
            font-family:@font-2018-roman;
            font-weight:400;
		}
    }

    .hallon-checkbox__box {
      position: relative;
      background-color: transparent;
      width: 2.5em;
      height: 2.5em;
      transform-origin: center;
      border: 0.2em solid @color-2018-linkblue;
      border-radius: 50%;
      margin-right: 10px;
      transition: background-color 150ms 200ms,
        transform 200ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
      flex: 1 0 auto;
      pointer-events: none;
      font-size: 10px;

      &:before {
        content: "";
        width: 0px;
        height: 0.2em;
        border-radius: 0.2em;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background: @color-2018-linkblue;
        position: absolute;
        transform: rotate(45deg);
        top: 44%;
        left: 30%;
        transition: width 50ms ease 50ms;
        transform-origin: 0% 0%;
      }

      &:after {
        content: "";
        width: 0;
        height: 0.2em;
        border-radius: 0.2em;
        background: @color-2018-linkblue;
        position: absolute;
        transform: rotate(299deg);
        top: 72%;
        left: 44%;
        transition: width 50ms ease;
        transform-origin: 0% 0%;
      }
    }

    &:hover {
      input[type="checkbox"]:not([disabled]) + .hallon-checkbox__box {
        &:before {
          width: 0.7em;
          top: 44%;
          left: 30%;
          transition: width 100ms ease;
        }

        &:after {
          width: 1em;
          top: 72%;
          left: 44%;
          transition: width 150ms ease 100ms;
        }
      }
    }

    .hallon-checkbox__text {
      font-family: @font-2018-roman;
      font-size: 14px;
      line-height: 21px;
      pointer-events: none;
      display: block;
    }
  }

  input[type="checkbox"] {
    display: none;

    &:checked {
      + .hallon-checkbox__box {
        background-color: @color-2018-linkblue;
        transform: scale(1.15);

        &:after {
          width: 1em;
          top: 72%;
          left: 44%;
          background: @color-2018-white;
          transition: width 150ms ease 100ms;
        }

        &:before {
          width: 0.7em;
          top: 44%;
          left: 30%;
          background: @color-2018-white;
          transition: width 150ms ease 100ms;
        }
      }

      &:hover {
        .hallon-checkbox__box {
          background-color: @color-2018-linkblue;
          transform: scale(1.15);

          &:after {
            width: 1em;
            top: 72%;
            left: 44%;
            background: @color-2018-white;
            transition: width 150ms ease 100ms;
          }

          &:before {
            width: 0.7em;
            top: 44%;
            left: 30%;
            background: @color-2018-white;
            transition: width 150ms ease 100ms;
          }
        }
      }
    }

    &:disabled {
      + .hallon-checkbox__box {
        opacity: 0.2;
      }
    }
  }
  //Fix for mobile hover
  @media (hover: none), (hover: on-demand) {
    .hallon-checkbox__label:hover
      input[type="checkbox"]:not([disabled])
      + .hallon-checkbox__box:before {
      content: "";
      width: 0px;
      height: 0.2em;
      border-radius: 0.2em;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background: @color-2018-linkblue;
      position: absolute;
      transform: rotate(45deg);
      top: 44%;
      left: 30%;
      transition: width 50ms ease 50ms;
      transform-origin: 0% 0%;
    }

    .hallon-checkbox__label:hover
      input[type="checkbox"]:not([disabled])
      + .hallon-checkbox__box:after {
      content: "";
      width: 0;
      height: 0.2em;
      border-radius: 0.2em;
      background: @color-2018-linkblue;
      position: absolute;
      transform: rotate(299deg);
      top: 72%;
      left: 44%;
      transition: width 50ms ease;
      transform-origin: 0% 0%;
    }

    .hallon-checkbox__label:hover
      input[type="checkbox"]:checked:not([disabled])
      + .hallon-checkbox__box:after {
      width: 1em;
      top: 72%;
      left: 44%;
      background: @color-2018-white;
      transition: width 150ms ease 100ms;
    }

    .hallon-checkbox__label:hover
      input[type="checkbox"]:checked:not([disabled])
      + .hallon-checkbox__box:before {
      width: 0.7em;
      top: 44%;
      left: 30%;
      background: @color-2018-white;
      transition: width 150ms ease 100ms;
    }
  }

  &__readmore-modal-link {
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }

  &__text-raw {
    margin-top: 4px;

    a {
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__group-title {
    margin-top: 20px;
  }
}
