/// <reference path="../framework.less" />

.full-width-component {
  position: relative;
  display: flex;
  align-items: stretch;
  height: 60vw;
  max-height: 580px;

  &--left {
    flex-direction: row-reverse;
  }

  &--right {
    flex-direction: row;
  }

  &--fluid-height {
    height: auto;
    max-height: none;
  }

  @media @breakpointquery-2018-large-tablet {
    max-height: none;
    height: auto;
    display: flex;
    min-height: 550px;
    flex-direction: row-reverse;
  }

  @media @breakpointquery-2018-phablet {
    min-height: 0;
  }

  @media @breakpointquery-2018-tablet {
    justify-content: center;
    flex-direction: column;
  }

  &__content {
    position: relative;
    z-index: 1;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    &--left {
      left: 0;
      right: unset;
      order: 0;
      justify-content: flex-start;

      @media @breakpointquery-2018-tablet {
        order: 1;
        justify-content: center;
      }
    }

    &--right {
      left: unset;
      right: 0;
      order: 1;
      justify-content: flex-end;

      @media @breakpointquery-2018-tablet {
        order: 0;
        justify-content: center;
      }
    }

    &--right-bubble {
      left: auto;
      right: 0;
      order: 1;
      justify-content: flex-start;

      @media @breakpointquery-2018-tablet {
        order: 0;
        justify-content: center;
      }
    }

    &--left-bubble {
      left: 0;
      right: auto;
      order: 1;
      justify-content: flex-start;

      @media @breakpointquery-2018-tablet {
        order: 0;
        justify-content: center;
      }
    }

    &--small {
      width: 35%;

      @media @breakpointquery-2018-tablet {
        width: 100%;
      }
    }

    &--large {
      width: 65%;
      justify-content: left;

      @media @breakpointquery-2018-tablet {
        width: 100%;
      }
    }

    @media @breakpointquery-2018-tablet {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 0;
      height: 100%;
      align-items: center;
      width: 100%;
      order: 1;
    }
  }

  &__content-container {
    max-width: 410px;
    padding: 20px;
    align-self: center;

    &--large-bubble {
      max-width: 550px;
    }

    &--large-content {
      max-width: 776px;
    }

    @media @breakpointquery-2018-tablet {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 550px;
      padding: 60px 0 10px 0;
      width: 100%;

      &--large-bubble {
        min-height: 0;
        padding: 20px 0 20px 0;
      }
    }
  }

  &__header {
    font-family: @font-2018-black;
    color: @color-2018-white;
    margin-bottom: 40px;
    text-transform: none;
    font-size: @font-2018-component-header;
    line-height: @font-2018-component-header-line-height;

    span {
      display: block;
    }

    @media @breakpointquery-2018-tablet {
      padding: 0;
      width: 100%;
      max-width: 100%;
      text-align: center;
      order: 1;
      font-size: @font-2018-component-header-mobile;
      line-height: @font-2018-component-header-line-height-mobile;
    }

    &--enhanced {
      font-size: @font-2018-component-header-enhanced;
      line-height: @font-2018-component-header-line-height-enhanced;

      @media @breakpointquery-2018-tablet {
        font-size: @font-2018-component-header-enhanced-mobile;
        line-height: @font-2018-component-header-line-height-enhanced-mobile;
      }
    }
  }

  &__text {

    @media @breakpointquery-2018-tablet {
      order: 2;
      flex: 1 0 auto;
      margin-bottom: 40px;
      text-align: center;

      &--large-bubble {
        flex: 0 1 auto;
        margin-bottom: 0;
      }
    }

    h2, h3, h4, h5 {
      color: @color-2018-white;
      font-family: @font-2018-bold;
      text-transform: none;
      margin-bottom: 20px;
    }

    p {
      color: @color-2018-white;
      font-size: 18px;
      line-height: 27px;
      font-family: @font-2018-roman;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }

      @media @breakpointquery-2018-tablet {
        font-size: 18px;
        line-height: 27px;
        padding: 0;
      }
    }
  }

  &__button-wrapper {
    margin-top: 40px;

    @media @breakpointquery-2018-tablet {
      order: 4;
      align-self: center;
    }
  }

  &__mask {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    max-width: 1189px;
    border-radius: 50%;
    overflow: hidden;
    transform: translate(-100%, -50%);

    &--right {
      transform: translate(0%, -50%);
    }

    @media @breakpointquery-2018-tablet {
      display: none;
    }
  }

  &__image {
    position: relative;

    img, source {
      width: 100%;
      height: auto;
      position: relative;
      display: block;
      transform: translate(0);
    }

    @media @breakpointquery-2018-tablet {
      display: none;
    }
  }

  &__mask-mobile {
    position: relative;
    left: -50%;
    width: 200%;
    margin-bottom: -130%;
    border-radius: 50%;
    overflow: hidden;
    display: none;
    z-index: -1;
    order: 3;

    @media @breakpointquery-2018-tablet {
      display: block;
    }
  }

  &__mobile-image {
    position: relative;
    display: none;

    img, source {
      width: 100%;
      height: auto;
      position: relative;
      display: block;
      transform: translate(0);
    }


    @media @breakpointquery-2018-tablet {
      display: block;
    }
  }

  &__large-bubble {
    background: @color-2018-white;
    color: @color-2018-hallon-red;
    padding: 20px;
    z-index: 1;
    position: relative;
    border-radius: 50%;
    margin: auto 0;
    opacity: 0;
    transition: opacity 0.2s linear;

    &--multiline {
      padding: 50px;
    }

    &--B2B {
      background: @color-2018-white;
      color: @color-2018-hallon-red;
    }
  }

  &__large-bubble-inner-wrapper {
    height: 100%;
    width: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }

  canvas {
    display: block;
    width: 100%;
    visibility: hidden;
  }

  &__large-bubble-picture {
    width: 80%;
    height: 80%;
    display: flex;
  }

  &__large-bubble-image {
    width: 100%;
    height: auto;
  }

  &__large-bubble-text-wrapper {
    position: relative;
    width: 100%;
    margin: 0.2em 0;
  }

  &__large-bubble-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    text {
      fill: @color-2018-hallon-red;
      font-family: @font-2018-roman;
      white-space: pre-wrap;
    }

    &--black {
      text {
        font-family: @font-2018-black;
      }
    }

    &--bold {
      text {
        font-family: @font-2018-bold;
      }
    }
  }


  &__large-bubble-content {
    display: block;
    height: 100%;
  }

  &__price-bubble {
    border-radius: 50%;
    width: 180px;
    height: 180px;
    background: @color-2018-yellow;
    color: @color-2018-hallon-red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: calc(~"50% - 90px");
    top: calc(~"50% - 90px");
    z-index: 1;

    &--B2B {
      background: @color-2018-white;
      color: @color-2018-hallon-red;
    }


    @media @breakpointquery-2018-small-desktop {
      left: calc(~"25% - 90px");
    }

    &--right {
      left: auto;
      right: calc(~"50% - 90px");

      @media @breakpointquery-2018-small-desktop {
        right: calc(~"25% - 90px");
        left: auto;
      }
    }


    @media @breakpointquery-2018-tablet {
      display: none;
    }

    &--mobile {
      display: none;

      @media @breakpointquery-2018-tablet {
        position: relative;
        display: flex;
        margin: 0 auto -70px auto;
        order: 3;
        top: 0;
        flex: 0 0 auto;
        z-index: -1;
        right: initial;
        left: initial;
      }
    }
  }

  &__main-price {
    font-size: 57px;
    line-height: 68px;
    font-family: @font-2018-black;
    color: @color-2018-hallon-red;
    text-align: center;
    padding: 0 5px;
    margin: -5px 0 5px 0;
  }

  &__price-info {
    font-size: 14px;
    line-height: 18px;
    font-family: @font-2018-bold;
    text-align: center;
    padding: 0 5px;
  }

  &__price-span {
    position: relative;
    z-index: 1;
    display: block;
  }

  &__data-bubble {
    border-radius: 50%;
    background: @color-2018-yellow;
    width: 80px;
    height: 80px;
    font-family: @font-2018-bold;
    font-size: 17px;
    line-height: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 0;
    flex-direction: column;
    justify-content: center;
    transform: translate(32%, 32%);

    &--B2B {
      background: @color-2018-white;
      color: @color-2018-hallon-red;
    }

    @media @breakpointquery-2018-tablet {
      transform: translate(70%, -80%);
    }
  }
}
