@fontsizeButtonSizeL: 2;

[data-button] {
	.inlineBlock();
	// max-width: 215px; // replaced with specific property fit-standard
	text-align: center;
	text-transform: uppercase;
	font-family: @tre-black-font;

	* > {
		display: inline;
	}

	&:hover {
		text-decoration: none;
		cursor: pointer;
	}
}

[data-button~="size-L"] {
	.font-size( @fontsizeButtonSizeL );
	padding: 0.8em 3%; // horizontal padding is an arbitrary value as iOS doesn't let text flow over to the sides equally, therefore creating an unbalanced looking button if the text is too long. -LR
	// margin-bottom: @margin-M;
}

[data-button~="size-M"] {
	.font-size(1.8);
	padding: 0.8em 1em;
	// margin-bottom: @margin-M;
	.border-radius( 4px );

	&:before {
		content: "";
		font-size: 1.2em;
		background-color: red;
		.inline-block();
	}
}

[data-button~="size-MS"] {
	.font-size(1.6);
	padding: 0.4em 2em;
	// margin-bottom: @margin-S;
	.border-radius( 4px );
}

[data-button~="size-S"] {
	.font-size(1.6);
	padding: 0.2em 1em;
	// margin-bottom: @margin-XS;
	.border-radius( 2px );
}

[data-button] {


	&[data-button~="style-raspberry"] {
		background-color: transparent;
		color: @tre-standard-color;
		padding: 0;
		// padding-left: 0;
		// padding-right: 0;
		&:hover {
			color: @tre-purple-color;
		}
	}

	&[data-button~="style-white"] {
		background-color: transparent;
		color: white;
		padding: 0;
		// padding-left: 0;
		// padding-right: 0;
		&:hover,
		&:focus {
			color: @tre-lightgrey-color;
		}
	}

	&[data-button~="style-green"] {
		background-color: transparent;
		color: @tre-success-color;
		padding: 0;
		// padding-left: 0;
		// padding-right: 0;
		&:hover {
			color: @color-olivegreen3;
		}
	}

	&[data-button~="style-blue"] {
		background-color: transparent;
		color: @color-darkblue;
		padding: 0;
		// padding-left: 0;
		// padding-right: 0;
		&:hover {
			color: darken( @color-darkblue, 10% );
		}
	}

	&[data-button~="style-filled-raspberry"] {
		background-color: @tre-standard-color;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		color: white; // stronger selector
		&:hover {
			background-color: @tre-purple-color;
			color: white;
		}
	}

	&[data-button~="style-filled-blue"] {
		background-color: @color-darkblue;
		color: white;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		color: white; // stronger selector
		&:hover {
			background-color: darken(@color-darkblue, 10%);
			color: white;
		}
	}

	&[data-button~="style-filled-green-gradient"] {
		#gradient.vertical( @tre-success-color, @color-green);
		border: @buttonBorderWidth solid @color-darkgreen;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		color: white;

		&:hover,
		&:focus {
			color: white;
			#gradient.vertical( @color-green, @tre-success-color);
		}

		&:active,
		&:selected,
		&:focus {
			color: white;
		}

		&.disabled,
		&.disabled:hover {
			cursor: default;
			background-color: @color-darkgreen;
			background-image: none;
			filter: none;
			color: @color-gray83;
			text-shaddow: none;
			-moz-appearance: none;
			-webkit-appearance: none;
		}
	}

	&[data-button~="style-filled-raspberry-gradient"] {
		#gradient.vertical( @tre-standard-color, @tre-standard-color-darker);
		border: @buttonBorderWidth solid @tre-standard-color-darker;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		color: white;

		&:hover,
		&:focus {
			color: white;
			#gradient.vertical( @tre-standard-color-darker, @tre-standard-color);
		}

		&:active,
		&:selected,
		&:focus {
			color: white;
		}

		&.disabled,
		&.disabled:hover {
			cursor: default;
			background-color: @tre-standard-color-darker;
			background-image: none;
			filter: none;
			color: @color-gray83;
			text-shaddow: none;
			// background-color: transparent;
		}
	}

	&[data-button~="style-filled-blue-gradient"] {
		#gradient.vertical( lighten(@color-darkblue, 10%), @color-darkblue);
		border: @buttonBorderWidth solid @color-darkblue;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		color: white;

		&:hover,
		&:focus {
			color: white;
			#gradient.vertical( @color-darkblue, lighten(@color-darkblue, 10%) );
		}

		&:active,
		&:selected,
		&:focus {
			color: white;
		}

		&.disabled,
		&.disabled:hover {
			cursor: default;
			background-color: @color-darkblue;
			background-image: none;
			filter: none;
			color: @color-gray83;
			text-shaddow: none;
			// background-color: transparent;
		}
	}

	&[data-button~="style-outline-white"] {
		background-color: transparent;
		border: 2px solid white;
		color: white;

		&:hover {
			background-color: white;
			color: @tre-standard-color;
		}
	}
}



[data-button~="type-selection"] {
	position: relative; // for possible absolutely positioned labels
	text-align: left;
	text-transform: none;
	@iconmargintop: 1em;
	@buttonSizeLargePadding: 1.25em;

	&.selected {

		&:hover {
			cursor: default;
		}
	}

	&[data-button~="size-L"] {
		padding-right: @buttonSizeLargePadding;
		padding-left: @buttonSizeLargePadding;

		> i[class^="icon-"]:first-child {
			margin-top: unit( -1/@fontsizeButtonSizeL, em);
		}

		&[data-button~="icon-left"] {
			padding-left: @buttonSizeLargePadding + 2em; // space for the icon to reside in.
			padding-right: unit( (@gutter-width/2)/332*100, % ); // 332 is the width
		}
	}

	* > {
		text-transform: none;
	}

	> i[class^="icon-"]:first-child {
		position: absolute;
		top: 50%;
		left: 1em;
		// margin-top: -0.5em;
	}

	&[data-button~="style-filled-light-gray"] {
		background-color: @color-gray95;
		border: @buttonBorderWidth solid @color-gray95;
		color: black;

		> * {
			color: black;
		}

		> i {
			color: @color-gray83;
		}

		&:hover,
		&:focus {
			background-color: @color-gray85;
			border-color: @color-gray59;

			> i {
				color: @color-gray76;
			}
		}

		&.active,
		&.selected {
			background-color: @color-olivegreen3;
			border-color: @color-darkolive;
			color: white;

			> * {
				color: white;
			}

			i {
				color: white;
			}
		}
	}


	&[data-button~="size-L"] {

		.productname {
			font-size: unit( 2.4/@fontsizeButtonSizeL, em );
		}

		label {
			font-size: unit( 2/@fontsizeButtonSizeL, em );
		}

		@media only screen and ( max-width: @hw-palm ) {
			.font-size(1.8);
		}

		@media only screen and ( max-width: @hw-mobile-L ) {
			.font-size(1.6);
		}
	}
}

// Use the jquery.submitbutton plugin to remove the default input[type="subit"] styling which IE 9< places upon this element
[data-button~="type-submit"] {
	border: none;
	-webkit-appearance: none;

	* > {
		text-transform: none;
	}

	> i[class^="icon-"]:first-child {
		visibility: hidden;
		position: relative;
		// top: 50%;
		left: -1.25em;
		// margin-top: -0.5em;
	}

	&.loading,
	&.loading:focus,
	&.disabled,
	&.disabled:focus {
		color: @color-gray83;
		text-shadow: none;

		&[data-button~="style-filled-raspberry"],
		&[data-button~="style-filled-raspberry"] {
			background-color: @tre-purple-color;
		}

		&[data-button~="style-filled-green-gradient"] {
			background-color: @color-darkgreen;
			background-image: none;
			border-color: transparent;

			&:hover {
				color: @color-gray83;
				cursor: default;
			}
		}

		&:hover {
			cursor: pointer;
		}

		> i[class^="icon-"] {
			visibility: visible;
		}
	}

	&:focus {
		color: white;
		text-shadow: 0 1px 2px rgba(255, 255, 255, 0.3);
	}

	&:active {
		color: @color-gray91;
		text-shadow: none;
	}
}

[data-button~="fit-auto"] {
	width: auto;
	max-width: none;
}

[data-button~="fit-fullwidth"] {
	display: block;
	width: 100%; // relying on the grid container
	max-width: none;
}

[data-button~="fit-standard"] {
	max-width: 225px; // The size of a button embeded twice in data-column="1/2"
	width: 100%;
	// margin-right: 0.5em;
	// margin-left: 0.5em;
}
// fit-constrained fits the container but never larger than it when the window is in full width
//
[data-column~="1/2"] {

	[data-button~="fit-constrained"] {
		width: 100%;
		max-width: unit( (@gridsystem-width - @gutter-width) / 2, px);
	}

	[data-column~="1/2"] {

		[data-button~="fit-constrained"] {
			max-width: unit( (@gridsystem-width - @gutter-width) / 4, px);
		}
	}
}

[data-column~="1/3"] {

	[data-button~="fit-constrained"] {
		width: 100%;
		max-width: unit( (@gridsystem-width - @gutter-width) / 3, px);
	}
}

// Button has a side label
[data-button~="sidelabel-right"] {
	padding-right: 0;
	// This complex structure has to do with adjusting the layout ot fit the IE bug of calculating padding percentages based on the container, not the element itself.
	> div {
		width: 100%;
		// padding-right: 33.333%;	// Had to move this down to .productname as the padding didn't work in iOS
		.border-radius( inherit );
	}

	.productname {
		padding-right: 33.333%;
	}

	[data-sidelabel] {
		width: 33.333%;

		&:hover {
			cursor: pointer;
		}
	}
}

[data-button~="type-goback"] {
	margin-bottom: @margin-M;

	> i[class^="icon-"]:first-child {
		font-size: 0.8em;
		margin-right: @margin-S;
	}
}
// HALLON REDESIGN

/* standard */
.hallon-btn {
	font-size: @hallon-btn-standard-ftsize;
	font-family: @hallon-frutiger-65-bold;
	outline: 0;
	background: none;
	border: 0;
	cursor: pointer;
	line-height: normal;
	padding-top: 16px;
	padding-bottom: 16px;
	text-transform: uppercase;
	text-align: center;
	-webkit-user-select: none; /* for button */
	-moz-user-select: none;
	-ms-user-select: none;
}

.hallon-btn-hamburger {
	background: none;
	cursor: pointer;
	line-height: 1;
	padding: 3px 5px;
	font-size: @hallon-login-ftsize;
}

.hallon-btn-login {
	font-size: @hallon-login-ftsize;
	cursor: pointer;
	padding: 3px 5px;
	line-height: 1;
}

.hallon-btn-logout {
	font-size: @hallon-login-ftsize;
	cursor: pointer;
	line-height: 1;
	padding: 3px 5px;
}

.hallon-btn-logo {
	font-size: @hallon-logo-ftsize;
	padding-top: 7px;

	&:before {
		font-family: 'hallon';
		content: '\e91c';
	}
}

.hallon-btn-link {
	color: @hallon-dark-red;
	text-decoration: underline;
	font-family: @hallon-frutiger-55-roman;
	font-size: @ftsize-hallon-14;
	
	&.hallon-btn--disabled {
		color: @hallon-dark-grey;
	}

	&--last-step {
		margin-left: 10px;
	}
}


.hallon-btn-transparent {
	background-color: transparent;
	color: @hallon-white;
	font-family: @hallon-frutiger-55-roman;
	border: 4px solid @hallon-white;
	padding: 0.5em 1em;
	cursor: pointer;

	&:hover {
		background: @hallon-white;
		color: @hallon-light-grey;
	}
}

.hallon-btn-tooltip {
	background: transparent;
	color: @hallon-white;
	cursor: pointer;
	line-height: normal;
	font-family: @hallon-frutiger-55-roman;
	font-size: @ftsize-hallon-14;

	&:hover {
		color: @hallon-white;
	}

	i {
		font-family: 'hallon';
		font-size: @ftsize-hallon-31;
		content: '\e902';
		display: block;
		margin-bottom: 5px;
	}
}

.hallon-btn-close {
	font-size: 1.6em;
	width: 1.6em;
	text-align: center;
	line-height: 1.4em;
	background: @hallon-white;
	border-radius: 0.8em;
	outline: 0;
	border: 0;
	cursor: pointer;


	&:before {
		font-size: @ftsize-hallon-20;
		font-family: 'hallon';
		content: "\e901";
		color: @hallon-light-red;
	}
}


.link-right-arrow {
	display: block;
	font-size: @ftsize-hallon-14;
	font-family: @hallon-frutiger-55-roman;
	line-height: 1.8rem;

	&:after {
		font-family: "hallon";
		content: "\e900";
		padding-left: 10px;
		vertical-align: middle;
	}
}

.link-down-arrow {
	display: block;
	font-size: @ftsize-hallon-14;
	font-family: @hallon-frutiger-55-roman;
	line-height: 1.8rem;
	color: @hallon-dark-grey;
	cursor: pointer;

	i {
		padding-left: 10px;
	}

	&:hover {
		color: @hallon-light-blue;
	}
}

.link-left-arrow {
	cursor: pointer;
	display: block;
	font-size: @ftsize-hallon-14;
	font-family: @hallon-frutiger-55-roman;
	line-height: 1;
	color: @hallon-dark-red;

	i {
		padding-right: 10px;
		vertical-align: middle;
	}
}


// BUTTON MODIFIERS
.hallon-btn-small {
	font-family: @hallon-frutiger-55-roman;
	font-size: @ftsize-hallon-14;
	/* use !important to prevent issues with browser extensions that change fonts */
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: normal;
	padding: 6px 20px;
	text-align: center;
}

.hallon-btn--big {
	padding: 0.5em 4em;
}

.hallon-btn--medium {
	padding: 0.5em 1em;
}

.hallon-btn--small {
	font-size: 24px;
	width: 120px;
}


/* colors */
.hallon-btn--red {
	background-color: @hallon-white;
	color: @hallon-dark-red;
	border: 2px solid @hallon-dark-red;
	font-size: @ftsize-hallon-14;

	&:hover {
		color: @hallon-white;
		background-color: @hallon-dark-red;
		border: 2px solid @hallon-dark-red;
	}
}

.hallon-btn--red-background {
	background-color: @hallon-dark-red;
	color: @hallon-white;
	border: 2px solid @hallon-dark-red;
	font-size: @ftsize-hallon-14;

	&:hover {
		color: @hallon-dark-red;
		background-color: @hallon-white;
		border: 2px solid @hallon-dark-red;
	}
}

.hallon-btn--green {
	background-color: @hallon-green;
	color: @hallon-white;

	&:hover {
		background-color: @hallon-dark-green;
		color: @hallon-white;
	}
}

.hallon-btn--disabled {
	pointer-events: none;
}

.hallon-btn--no-stock {
	padding: 0.5em 0.5em;
	pointer-events: none;
	background: @hallon-yellow;
	color: @hallon-white;
}


.hallon-btn--cancel {
	color: @hallon-white;
	background: @hallon-dark-red;
	width: 140px;
	display: block;

	&:hover {
		color: @hallon-white;
		background: @hallon-wine-red;
	}
}

.hallon-btn--continue {
	color: @hallon-white;
	background: @hallon-green;
	width: 140px;
	display: block;
	font-size: 14px;

	&:hover {
		color: @hallon-white;
		background: @hallon-dark-green;
	}
}

.hallon-btn--continue-big {
	width: 100%;
	color: @hallon-white;
	background: @hallon-green;
	display: block;

	@media only screen and (min-width: @tablet-breakpoint-hallon) {
		width: 345px;
	}

	&:hover {
		color: @hallon-white;
		background: @hallon-dark-green;
	}
}

.hallon-btn--telemarketing-submit {
	width: 280px;
}

.hallon-btn--continue.hallon-btn--disabled, .hallon-btn--cancel.hallon-btn--disabled, .hallon-btn.hallon-btn--disabled {
	background-color: @hallon-dark-grey;
	pointer-events: none;
	cursor: default;

	&:hover {
		color: @hallon-white;
	}
}

.hallon-btn--telemarketing {
	font-size: 17px;
	width: 160px;
	padding-top: 14px;
	padding-bottom: 14px;
}

.hallon-btn--loading {
	position: relative;
	padding-right: 2.25em;
	transition: .4s all;

	&::after {
		content: '';
		position: absolute;
		border-radius: 100%;
		right: 0;
		top: 50%;
		width: 0;
		height: 0;
		border: 2px solid rgba(255, 255, 255, .5);
		border-left-color: @hallon-white;
		border-top-color: @hallon-white;
		animation: spin .6s infinite linear, grow .3s forwards ease-out;
	}
}

@keyframes spin {
	to {
		transform: rotate(359deg);
	}
}

@keyframes grow {
	to {
		width: 14px;
		height: 14px;
		margin-top: -8px;
		right: 13px;
	}
}
