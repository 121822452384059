/// <reference path="../framework.less" />

.circle-usp {

	&__column-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding: @margins-2018-large 0 0 0;
		justify-content: space-between;

		&--space-evenly {
			justify-content: space-evenly;
		}

		@media @breakpointquery-2018-tablet {
			width: 100%;
			padding: @margins-2018-medium 0 0 0;
		}
	}

	&__column {
		flex: 0 1 24%;
		margin-bottom: 50px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@media @breakpointquery-2018-large-tablet {
			flex: 0 1 40%;
		}

		@media @breakpointquery-2018-tablet {
			flex: 1 1 100%;
		}
	}

	&__circle-icon-wrapper {
		background: @color-2018-yellow;
		width: 180px;
		height: 180px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto 20px auto;
		overflow: hidden;

		&--empty {
			background: none;

			@media @breakpointquery-2018-tablet {
				display: none;
			}
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	&__circle-icon {
		max-height: 111px;
		max-width: 111px;
		height: auto;
		width: 100%;
	}

	&__circle-header {
		font-size: 22px;
		font-family: @font-2018-bold;
		color: @color-2018-white;
		line-height: 26px;
		height: 52px;
		text-transform: none;
		margin-bottom: 0;

		&--quote {
			font-size: 22px;
			font-family: @font-2018-bold;
			color: @color-2018-white;
			line-height: 26px;
			margin-top: 25px;
			display: block;
			height: auto;
		}
	}

	&__circle-text {
		font-size: 18px;
		font-family: @font-2018-light;
		color: @color-2018-white;
		line-height: 27px;
		display: flex;
		min-height: 80px;
		max-height: 80px;
		overflow: hidden;

		&--quote {
			min-height: 0;
			max-height: none;
			overflow: visible;
			display: block;
			position: relative;
			margin-top: 20px;
			flex: 1 1 auto;


			&::before, &::after {
				display: none;
			}
		}
	}

	&__quote-icon {
		position: absolute;
		width: 50px;
		fill: #FFF;
		opacity: 0.2;
		top: 15px;
		left: 0px;
	}

	&__circle-link {
		font-size: 22px;
		font-family: @font-2018-bold;
		color: @color-2018-linkblue;
		line-height: 26px;
		margin-top: 25px;
		display: block;

		&--quote {
			margin-top: 0;
		}

		&:hover {
			color: @color-2018-linkblue;
			text-decoration: underline;
		}
	}

	&__text-wrapper {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		position:relative;
	}
}