// CMS based content
.htmlContent {

  p{
    padding: 10px 0;
  }

  h2,h1{
    text-align: left;
  }


  div {

    border-top: 1px solid @tre-lightgrey-color;
    padding: 2em 0 2em;
    overflow: hidden;

    h1, h2{
      text-align:left;
    }
  }

  div:first-child {

    border: none;
    padding: 1em 0;

    h2, h1 {
      text-align: left;
      margin-bottom: @margin-M;
    }
  }

  div.info {

    div.infotext {

      border: none;
      padding: 0;
    }
  }


    ul:not([class^="checklist-"]) {

      list-style: disc;
      padding-left: 18px;
      margin-bottom: @margin-M;
    }
    ul[class^="checklist-"] {

      margin-bottom: 1.2em;
    }




  // TODO: replace with icons instead.
  span.info,
  span.bindningstid,
  span.uppsagningstid,
  span.fastpris,
  span.fakturor {

    float: left;
    margin-right: 40px;
    vertical-align: text-top;
    .ico-icon();
    .font-size(18);
    color: @tre-darkgray-color;

    // Visually place the icons in place
    text-align: center;
    line-height: 0.77em;
    margin-left: 0.17em;


    @media only screen and ( max-width: @hw-palm) {

      display: block;
      font-size: 10em;
      float: none;
      text-align: center;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 0.1em;
      padding-left: 0;
    }
  }

  span.info {
    // background-position: 0 0;
    &:before { content: @ico-var-info-o; }
  }

  span.bindningstid {
    // background-position: 0 -180px;
    &:before { content: @ico-var-clock-circle; }
  }

  span.uppsagningstid {
    // background-position: 0 -365px;
    &:before { content: @ico-var-comment; }
  }

  span.fastpris {
    // background-position: 0 -549px;
    &:before { content: @ico-var-pricetag-circle; }
  }

  span.fakturor {
    // background-position: 0 -733px;
    &:before { content: @ico-var-file-text-circle; }
  }

}