.red-circle{

  height: 100vh;
  width: 430px;
  margin-top:-60px;


  .red-circle__container{

    width: 100%;
    height: 100%;
    background: @hallon-light-red;
    background: -webkit-linear-gradient(@linear-gradient-pink);
    background: -o-linear-gradient(@linear-gradient-pink);
    background: -moz-linear-gradient(@linear-gradient-pink);
    background: linear-gradient(@linear-gradient-pink);
    -webkit-transition: all 0.5s; /* Safari */
    transition: all 0.5s;
    transition-timing-function: ease-in;
    display: table;
    position: relative;

  }

  .red-circle__content{
    position: absolute;
    top: 45%;
    left: 50%;
    margin-right: -50%;
    -ms-transform: translate(-50%, -55%); /* IE 9 */
    -webkit-transform: translate(-50%, -55%); /* Safari */
    transform: translate(-50%, -55%);
    display: table-cell;
    text-align: center;
    vertical-align: middle;


    h1{
      font-family: @hallon-frutiger-77-bc;
      font-size: @ftsize-hallon-48;
      text-transform: uppercase;
      color: @hallon-white;
      margin-bottom:0;

      span{
        font-family: @hallon-frutiger-55-roman;
        font-size: @ftsize-hallon-20;
        color: @hallon-white;
        margin-bottom:0;
        text-transform: none;
        line-height: 1;
        display: block;
      }

    }

    .red-circle__uspcontainer{
      margin-top:1em;

      a{
        display: inline-block;
        margin-left:3px;
        margin-right:3px;
      }

    }

    //TODO: REFACTOR LATER
    .bubble{
      margin-top:1em;
      display: none;
    }
  }

  .red-circle__slider-container{
    position: relative;
    padding-top:30px;
    display: block;



    @media only screen and (min-width: @tablet-breakpoint-hallon){
      padding-top:60px;
      width:200px;
    }

  }
}

.red-circle--left{
  .red-circle__container{
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
  }
}

.red-circle--right{
  .red-circle__container{
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
  }
}

.red-circle--right.red-circle--small{

  .red-circle__container{
    width:200px;
    margin-left:230px;
    -webkit-transition: all 0.5s; /* Safari */
    transition: all 0.5s;

    .red-circle__arrow{

      display: table-cell;
      top:50%;
      -ms-transform: translate(-5%, 50%); /* IE 9 */
      -webkit-transform: translate(-5%, 50%); /* Safari */
      transform: translate(-5%, 50%);

      i{
        font-size: @ftsize-hallon-24;
        color:@hallon-white;
        background: @hallon-light-red;
        background: -webkit-linear-gradient(@linear-gradient-pink);
        background: -o-linear-gradient(@linear-gradient-pink);
        background: -moz-linear-gradient(@linear-gradient-pink);
        background: linear-gradient(@linear-gradient-pink);
        border-radius:50%;
        border:2px solid @hallon-white;
      }
    }

  }

  &:hover{
    .red-circle__container{
      cursor: pointer;
      width:220px;
      margin-left:210px;
    }
  }

}
.red-circle--left.red-circle--small{



  .red-circle__container{
    width:200px;

    .red-circle__arrow{

      display: table-cell;
      top:50%;
      -ms-transform: translate(94%, 50%); /* IE 9 */
      -webkit-transform: translate(94%, 50%); /* Safari */
      transform: translate(94%, 50%);

      i{
        font-size: @ftsize-hallon-24;
        color:@hallon-white;
        background: @hallon-light-red;
        background: -webkit-linear-gradient(@linear-gradient-pink);
        background: -o-linear-gradient(@linear-gradient-pink);
        background: -moz-linear-gradient(@linear-gradient-pink);
        background: linear-gradient(@linear-gradient-pink);
        border-radius:50%;
        border:2px solid @hallon-white;
      }
    }
  }

  &:hover{
    .red-circle__container{
      cursor: pointer;
      width:220px !important;
      -webkit-transition: all 0.5s; /* Safari */
      transition: all 0.5s;
    }
  }

}

.red-circle--small{



  .red-circle__content{

    h1{
      span{
        display: none;
      }
    }

    p, .bubble{
      display: none;
    }


    .red-circle__uspcontainer{


      margin-top:2em;


      a{
        pointer-events: none;
        display: block;
        left: 50%;
        margin-left: 50%;
        margin-right: -50%;
        top: 50%;
        margin-bottom:0.5em;
        -ms-transform: translate(-50%, -50%); /* IE 9 */
        -webkit-transform: translate(-50%, -50%); /* Safari */
        transform: translate(-50%, -50%);
      }
    }

  }

  .red-circle__slider-container{
    display: none;
  }


}
