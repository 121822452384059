@grid-padding-horizontal: 5%;
@grid-padding-vertical: 20px;
@grid-gutter-horizontal:20px;

@width-standard-button: 216;


@tre-standard-color:			#a8133e;
@tre-purple-color:				#860f32;

@tre-standard-color-lighter:	#c3466a;
@tre-standard-color-darker:		#58011b;
@tre-standard-color-darkeer: 	#460116;
@tre-standard-color-darkeeer: 	#2c000d;
@tre-white-color: 				#ffffff;

@tre-success-color: 			#76b158; // Green
@tre-error-color:				#c72100;
@tre-alert-color:				#aa0c0c;
@tre-message-color:				#f3bb60;

@tre-bg-lightgray:				#faf8f7;
@tre-inputborder-color:			#d8d3d0;
@tre-specialborder-color:		#c9e1bd;

@tre-gray-color:				#b1a8a2;
@tre-darkgray-color:			#3e3a37;
@tre-lightgrey-color:			#e7e5e3;
@tre-lightergrey-color:			#F9F5F2;
@tre-lightblue-color:			#f1f7ee;
@tre-secondary-tone-light: 		#FBEEEF;

@color-green:					#569537; // darker green than success-color
@color-darkgreen:				#266A04;
@color-darkblue:				#233367;
@color-olivegreen3: 			#71AB32;
@color-darkolive: 				#092D06;
@color-violetred:				#ef235e;
@color-yellow:                  #ffda46;
@color-orange:					#F7A525;
@color-orange-light:			#F8C46E;
@color-raspberry-dark:			#630A25;
@color-blue:                    #29ABE2;
@color-gray50:					#808080;
@color-gray59:					#979797;
@color-gray68:					#afafaf;
@color-gray76:					#C2C2C2;
@color-gray83: 					#D5D5D5;
@color-gray85: 					#D9D9D9;
@color-gray89:					#E4E4E4;
@color-gray91:					#E8E8E8;
@color-gray95:					#F2F2F2;


@tre-standard-font: "FrutigerLTW01-55Roman";
@tre-bold-font: "Frutiger-LT-W01-65-Bold";
@tre-black-font: "FrutigerLTW01-87ExtraBk";

@sprite: url('/ui/build/images/sprite.png?v=1');
@spriteUrl: url('/ui/build/images/sprite.png?v=1');
@spriteWidth: 400px;
@spriteHeight: 400px;
@buttonBorderWidth: 1px;

//  FONT SIZES
//  ___________________
// This should be breaken into regular font sizes and title sizes
@fontsize-XXXL: 180;
@fontsize-XXL:	98;
@fontsize-XL:	72;
@fontsize-L:	48;
@fontsize-ML:	40;
@fontsize-M:	32;
@fontsize-MS:	24;
@fontsize-S:	20;
@fontsize-XS:	14;
@fontsize-XXS:	12;
@fontsize-XXXS:	10;

@font-size-h1: 5;
@font-size-h2: 2.4;
@font-size-h3: 1.6;
@font-size-h4: 1.6;
@font-size-h5: 1.6;
@font-size-h6: 1.4;

@font-size-h1-phone: 2.4;
@font-size-h2-phone: 1.8;
@font-size-h3-phone: 1.6;
@font-size-h4-phone: 1.6;
@font-size-h5-phone: 1.6;
@font-size-h6-phone: 1.4;


// .fsize-XXXL {	.font-size( 180 ) };
// .fsize-XXL {	.font-size( 98 ) };
// .fsize-XL {		.font-size( 72 ) };
// .fsize-L {		.font-size( 48 ) };
// .fsize-ML {		.font-size( 40 ) };
// .fsize-M {		.font-size( 32 ) };
// .fsize-MS {		.font-size( 24 ) };
// .fsize-S {		.font-size( 20 ) };
// .fsize-XS {		.font-size( 14 ) };
// .fsize-XXS {	.font-size( 12 ) };
// .fsize-XXXS {	.font-size( 10 ) };

//  SIZES FOR PRICETAG
//  ___________________

@pricetag-size-L: 2.4;
@pricetag-size-M: 1.6;
@pricetag-size-S: 1.4;
@pricetag-size-XS: 1.2;
@pricetag-size-XXS: 1.1;
@pricetag-size-XXXS: 1;
@pricetag-size-XXXXS: 0.8;


//  DISTANCES
//  ___________________

@lineheight-XXL: 1.6;
@lineheight-XL: 1.4;
@lineheight-L: 1.2;
@lineheight-M: 1;
@lineheight-S: 0.8;
@lineheight-XS: 0.6;
@lineheight-XS: 0.4;

// margin intended to steer the VERTICAL flow. Don't use for horizontal distances.
@margin-XXL:	5em;
@margin-XL:		3em;
@margin-L:		2.4em;
@margin-ML:		1.8em;
@margin-M:		1em;
@margin-S:		0.8em;
@margin-XS:		0.6em;
@margin-XXS:	0.4em;
@margin-XXXS:	0.2em;



//  _____________________________________________________________________________
//
//  HARDWARE VARIABLES
//  _____________________________________________________________________________


//  MEDIA QUERIES
//  ___________________

@gridwidth: 960px; // This is declared in the grid less file, but can't be used 8ight now as a constant.

@hw-mobile: 320px;
@hw-mobile-max: (@hw-mobile)
-1;
@hw-mobile-L: 360px;
@hw-mobile-L-max: (@hw-mobile-L)-1;
@hw-palm: 480px;
@hw-palm-max: 479px;
@hw-tablet-S: 600px;
@hw-tablet: 768px;
@hw-tablet-max: 767px; // this just doesn't work: @hw-tablet - 1
@hw-laptop: 880px;
@hw-laptop-max: (@hw-laptop)-1;
@hw-desktop: 940px;
@hw-desktop-max: (@hw-desktop)-1;
@hw-tv: 1170px;
@hw-tv-max: (@hw-tv)-1;

@hw-max-mobile: ~ "(max-width: @{hw-mobile-max})";
@hw-min-mobile: ~ "(min-width: @{hw-mobile})";
@hw-max-mobile-L: ~ "(max-width: @{hw-mobile-L-max})";
@hw-min-mobile-L: ~ "(min-width: @{hw-mobile-L})";
@hw-max-palm: ~ "(max-width: @{hw-palm-max})";
@hw-min-palm: ~ "(min-width: @{hw-palm})";
@hw-max-tablet: ~ "(max-width: @{hw-tablet-max})";
@hw-min-tablet: ~ "(min-width: @{hw-tablet})";
@hw-max-laptop: ~ "(max-width: @{hw-laptop-max})";
@hw-min-laptop: ~ "(min-width: @{hw-laptop})";
@hw-max-desktop: ~ "(max-width: @{hw-desktop-max})";
@hw-min-desktop: ~ "(min-width: @{hw-desktop})";
@hw-max-tv: ~ "(max-width: @{hw-tv-max})";

@phone-barrier: @hw-tablet-S; // Not specified where this variable has come from.
//  GRID
//  ___________________
@hw-margin: 20px;
//  HARDWARW COLORS
//  ___________________
@hw-bg: #f4f4f4;
@hw-bg-alt: #e4e5e4;
@hw-bg-dark: @tre-darkgray-color;
@hw-bg-highlight: #ee7202;
@hw-bg-notice: #ffd146;

@hw-font: #000000;
@hw-font-alt: #666666;
@hw-font-link: @color-darkblue;
@hw-font-highlight: #ee7202;
@hw-font-disabled: #b7b7b7;

@hw-check-blue: #1576B3;
//  HALLON LINEAR GRADIENT BACKGROUND
//  ___________________
@linear-gradient-pink: 60deg, rgba(137,24,69,0.9), rgba(238,35,94,0.9);


@linear-gradient-red: 60deg, rgba(95,0,43,0.9), rgba(137,24,69,0.9);

@linear-gradient-company-red: 60deg, @hallon-dark-red, @hallon-light-red;
//linear gradient left to right 891845 to EE235E, 60 deg
//  HALLON COLORS
//  ___________________
@hallon-black: #000000;
@hallon-light-black:#3E3A37;
@hallon-heavy-grey: #666666;
@hallon-dark-grey: #B0A7A1;
@hallon-medium-grey:#F2F2F2;
@hallon-light-grey: #E6E6E6;
@hallon-white: #FFFFFF;
@hallon-wine-red: #5F002B;
@hallon-dark-red: #8F1840;
@hallon-darkish-red:#8d214c;
@hallon-light-red: #EE235E;
@hallon-lighter-red:#FFCCCC;
@hallon-yellow: #F4CF23;
@hallon-light-blue: #29ABE2;
@hallon-lighter-blue:#99ccff;
@hallon-dark-blue: #43609C;
@hallon-green: #76B058;
@hallon-dark-green: #619447;
@hallon-light-green:#47B700;
@hallon-new-green: #E6F7DA;

@hallon-red-hover: #680423;
@hallon-grey-hover: #928B87;

@body-background: #F2F2F2;
//  HALLON FONTS
//  ___________________
@hallon-frutiger-55-roman: 'FrutigerLTW01-55Roman';
@hallon-frutiger-65-bold: 'Frutiger-LT-W01-65-Bold';
@hallon-frutiger-77-bc: 'FrutigerLTStd-BlackCn';
//  HALLON FONTSIZES
//  ___________________
@ftsize-hallon-12: 12px;
@ftsize-hallon-14: 14px;
@ftsize-hallon-16: 16px;
@ftsize-hallon-18: 18px;
@ftsize-hallon-20: 20px; //Special for lead in campaign
@ftsize-hallon-24: 24px;
@ftsize-hallon-31: 31px;
@ftsize-hallon-36: 36px;
@ftsize-hallon-48: 48px;
@ftsize-hallon-60: 60px;
@ftsize-hallon-77: 77px;
@ftsize-hallon-90: 90px;


@hallon-btn-ftsize: 18px;
@hallon-login-ftsize: 53px;
@hallon-btn-standard-ftsize: 24px;
@hallon-logout-ftsize: 25px;
@hallon-logo-ftsize: 42px;
//  HALLON BREAKPOINTS
//  ___________________
@phone-breakpoint-hallon: 768px;
@tablet-breakpoint-hallon: 1025px;
@desktop-special-breakpoint: 1260px;
//  MISC
//  ___________________
.placeholder-color() {
	*::-webkit-input-placeholder {
		color: @hallon-dark-grey;
		opacity: 1;
	}

	*:-moz-placeholder {
		/* FF 4-18 */
		color: @hallon-dark-grey;
		opacity: 1;
	}

	*::-moz-placeholder {
		/* FF 19+ */
		color: @hallon-dark-grey;
		opacity: 1;
	}

	*:-ms-input-placeholder {
		/* IE 10+ */
		color: @hallon-dark-grey;
		opacity: 1;
	}
}