.column-25{
  float: left;
  width: 50%;
  height: 175px;


  @media only screen and (min-width: @tablet-breakpoint-hallon){
    width:  25%;
    height: 320px;
  }

}
.column-25.module-campaign{
  width:100%;
  height:320px;
  @media only screen and (min-width: @tablet-breakpoint-hallon){
    width:  25%;
  }
}

.column-50{
  float: left;
  width: 100%;
  height: 320px;

  @media only screen and (min-width: @tablet-breakpoint-hallon){
    width: 50%;
  }
}

.column-100.module-campaign{
  height: 550px;
  background-attachment: scroll;
  @media only screen and (min-width: @tablet-breakpoint-hallon){
    background-attachment: fixed!important;
    height: -moz-calc(~"100vh - 150px");
    height: -webkit-calc(~"100vh - 150px");
    height: -o-calc(~"100vh - 150px");
    height: calc(~"100vh - 150px");
  }
}

.column-50.module-campaign, .column-25.module-campaign{
  .module-campaign__uspcontainer, .link-right-arrow{
    display: none;
  }
}

.column-50.module-listproduct{
  height: 550px;
}

.column-100{
  float: left;
  width: 100%;
  height: 320px;
  @media only screen and (min-width: @tablet-breakpoint-hallon){

    height: -moz-calc(~"100vh - 150px");
    height: -webkit-calc(~"100vh - 150px");
    height: -o-calc(~"100vh - 150px");
    height: calc(~"100vh - 150px");
  }
}
.column-100.module-btn{
  background-attachment: scroll;
  @media only screen and (min-width: @tablet-breakpoint-hallon){
    background-attachment: fixed!important;
  }
}

.column-100.module-usp{
  float: left;
  width: 100%;
  height:200px;

  @media only screen and (min-width: @tablet-breakpoint-hallon){
    height:100px;
  }
}

.column-50.module-hardware{
  float:left;
  width:100%;
  height:650px;

  @media only screen and (min-width: @tablet-breakpoint-hallon){
    width:50%;
  }
}


.column-half{
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  padding-top:20px;
  float: left;
  width: 100%;
  background:@hallon-white;
  margin-bottom:2em;

  div{
    margin-bottom:1.5em;
  }
  p, ul, li{
    color: @hallon-light-black;
    font-family: @hallon-frutiger-55-roman;
    font-size: @ftsize-hallon-14;
  }

  .hallon-btn{
    display: table;
    margin: 0 auto;
    margin-top:1.5em;
  }

  @media only screen and (min-width: @tablet-breakpoint-hallon){
    float:left;
    width:45%;
  }
}
.column-half--left{
  @media only screen and (min-width: @tablet-breakpoint-hallon){
    margin-right:5%;
  }
}
.column-half--right{
  @media only screen and (min-width: @tablet-breakpoint-hallon){
    margin-left:5%;
  }
}