/// <reference path="../framework.less" />

.gdprConsentPage {
	&__wrapper {
		&--hidden {
			display: none;
		}
	}

	&__header {
		margin-bottom: 50px;
		font-size: 48px;
		line-height: 57px;
		color: @color-2018-hallon-red;
		text-transform: none;
		font-family: @font-2018-black;

		@media @breakpointquery-2018-phablet {
			margin-bottom: 20px;
			font-size: 24px;
			line-height: 28px;
		}
	}

	&__subheader {
		margin-bottom: 20px;
		font-size: 24px;
		line-height: 28px;
		color: @color-2018-hallon-red;
		text-transform: none;
		font-family: @font-2018-bold;
		width: 100%;
		max-width: 590px;

		@media @breakpointquery-2018-phablet {
			font-size: 18px;
			line-height: 21px;
		}
	}

	&__subheader-text {
		margin-bottom: 40px;
		font-size: 14px;
		line-height: 21px;
		font-family: @font-2018-light;

		@media @breakpointquery-2018-phablet {
			margin-bottom: 20px;
		}
	}

	&__wysiwyg {
		width: 100%;
		max-width: 590px;
	}

	&__form-wrapper {
		width: 100%;
		max-width: 590px;
	}

	&__form-gdpr {
		padding: 1.5em 0 0em 0;
	}

	&__form-submit {
		background: @color-2018-green;
		color: @color-2018-white;
		font-family: @font-2018-bold;
		font-weight: normal;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		border-radius: 50px;
		outline: none;
		border: none;
		cursor: pointer;
		display: inline-block;
		margin: 40px auto 0 auto;
		padding: 15px 55px;
		z-index: 1;
		position: relative;
		transition: all 0.2s linear;
		outline: none;

		&:hover, &.active {
			color: @color-2018-white;
			background: @color-2018-green-hover;
		}

		&:disabled {
			color: @color-2018-white;
			background: @color-2018-text-grey;
			pointer-events: none;
			cursor: default;
		}

		@media @breakpointquery-2018-phablet {
			font-size: 14px;
			padding: 15px 55px;
			width: 100%;
			display: block;
		}
	}

	&__form-error {
		color: @color-2018-hallon-red;
		font-size: 0.8em;
		margin-top: 1em;
		padding: 0 1em;
		display: none;
	}
}
