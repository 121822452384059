/// <reference path="../../framework.less" />

.checkout-summary-multi {
    margin: -14px 0 0 0;

    &__subscription {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin-top: 20px;
    }

    &__subscription-name-wrapper {
        flex: 1 1 auto;
    }

    &__subscription-name {
        font-size: 14px;
        line-height: 21px;
        text-transform: none;
        font-family: @font-2018-bold;
        color: @color-2018-text-black;
        margin: 0;
        padding: 0;
    }

    &__subscription-name-amount {
        font-family: @font-2018-roman;
    }



    &__subscription-voucher-wrapper {
        font-size: 12px;
        font-family: @font-2018-roman;
        line-height: 16px;
        color: @color-2018-hallon-red;
        display: flex;
        flex-wrap: wrap;
    }

    &__subscription-voucher-code {
        flex: 1 1 100%;
    }

    &__subscription-voucher-code2 {
        margin-right: 5px;
    }

    &__subscription-voucher-delete-icon {
        cursor: pointer;
        width: 11px;
        height: 11px;

        path {
            fill: none;
            stroke: #832440;
            stroke-linecap: round;
            stroke-width: 1px;
        }
    }

    &__subscription-price-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex: 1 0 auto;
    }

    &__subscription-price {
        color: @color-2018-hallon-red;
        font-size: 18px;
        line-height: 18px;
        font-family: @font-2018-bold;
    }

    &__subscription-price-strikethrough {
        color: @color-2018-hallon-red;
        font-size: 16px;
        line-height: 18px;
        font-family: @font-2018-bold;
        text-decoration: line-through;
    }

    &__subscription-campaign-wrapper {
        font-size: 12px;
        line-height: 16px;
        color: @color-2018-text-black;
        font-family: @font-2018-bold;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

