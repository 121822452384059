﻿.ContentPage {

	.main-container__grid{
		background-color: @hallon-white;

		.htmlContent{
			h1, h2{
				text-align: left;
			}
		}
	}

	.wrap-grid {
		padding: 0;
	}

	.grid-pad {
		padding: 0;
	}

	.col-1-1 {
		padding-right: 0!important;
	}

	.imagecontainer {

		padding: 0;
		margin: 0;
		.bgsize(cover);
		background-position: center center;
		background-repeat: no-repeat;
		min-height: 370px;

		@media only screen and (max-width: @phone-breakpoint-hallon) {
			min-height:145px;
		}

		@media only screen and (min-width: @phone-breakpoint-hallon) and (max-width: 1250px) {
			min-height:270px;
		}
	}

	.tophead {
	
		margin: 0;
		padding-top: 6%;
	}

	.main-menu-top {
		.grid-pad {
			padding: 0;
		}
	}

	.original {

			.tophead {

				padding-top: 0;
				margin-top: 0;
				margin-bottom: @margin-M;

				span {
					color: @tre-darkgray-color;
				}
			}

	}
}
