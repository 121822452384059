.SubscriptionTerminationPage {

    .fullsize-container {
        width: auto;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            width: 860px;
            margin: 0 auto;
        }
    }

    .SubscriptionTerminationPage__headline {
        font-family: @hallon-frutiger-77-bc;
        text-align: center;
        font-size: 24px;
        margin-bottom: 20px;
        margin-top: 20px;
        text-transform: none;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            font-size: 48px;
            margin-bottom: 20px;
            margin-top: 50px;
        }
    }

    .SubscriptionTerminationPage__first-link {
        font-family: @hallon-frutiger-77-bc;
        font-size: 14px;
        display: block;
        margin-bottom: 12px;
        margin-left: 30px;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            margin-left: 0px;
        }
    }

    .SubscriptionTerminationPage__second-link {
        font-family: @hallon-frutiger-77-bc;
        text-transform: uppercase;
        font-size: 14px;
        display: block;
        margin-bottom: 30px;
        margin-left: 30px;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            margin-left: 0px;
        }

        i {
            font-size: 1.1875em;
            margin-right: 0.4em;
            display: inline-block;
            vertical-align: bottom;
        }
    }

    .subscriptionterminationpage__container {
        margin-bottom: 30px;
        box-shadow: 0 1px 3px rgba(0,0,0,.3);
        border-radius: 4px;
    }
}
