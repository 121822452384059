// This hasn't matured to a full component so it's placed here for the time being. -LR
/** Select box **/
[data-dropdown] {


	.listitem {
		position: relative;
		display:none;
		padding-top: 4px;
		padding-bottom: 4px;
		padding-left: 1%;

		&--no-padding {
			padding-right: 0;
		}
		&:hover {

			background-color: @tre-purple-color;
			color: #fff;

			@media only screen and (max-width: @hw-laptop) {
				background-color: #fff;
				color: #000;
			}
		}

		i[class^="icon-"] {

			display: none;
			color: @color-gray50;
		}
		&.not(.listitem--no-padding) i[class^="icon-"] {
			position: absolute;
			right: 0;
			bottom: 0;

			// @media only screen and (max-width: @hw-mobile-L) {
			// 	.font-size(5);
			// 	top: 37px;
			// }
		}

		&.selected,
		&.default {

			display: block;
			// .font-size(4);
			font-weight: bold;
			text-transform: uppercase;

			// @media only screen and (max-width: @hw-mobile-L) {
			// 	.font-size(2.5);
			// }

			i[class^="icon-"] {

				display: block;
			}

			&:hover {
				background-color: #fff;
				color: #000;
			}
		}
	}
}

// Types define functionality and state, minimal amount of styling
/**
* Select llst
*/
[data-dropdown~="type-select"] {

	cursor: pointer;

	&[data-dropdown~="single"] {
		cursor: default;
	}

}
/**
* Select list with a label
* As found on self-service pages
*/
[data-dropdown~="type-select--label"] {

	cursor: pointer;

	&[data-dropdown~="single"] {
		cursor: default;
	}

}

/**
* A dropdown with content when expanded
* optional labelling options
**/
[data-dropdown~="type-content"] {

	position: relative;
	
	.dropdown-labelcontainer {

		cursor: pointer;
		.whitebox();

	}
	&.disabled{


		.dropdown-labelcontainer {

			.infobox();
			cursor: default;
			
			.dropdown-label {

				cursor: default;
				color: @tre-darkgray-color;

				i[class^="icon-"] {

					.hidden();
				}
			}
		}
		&.error{

			.dropdown-labelcontainer {
				.errorbox();
			}
		}

	}
	&.error{

		.dropdown-labelcontainer {
			.errorbox();
			a[href].dropdown-label:hover {

				color: #FFF;
				pointer: default;
			}
		}
	}
	
	.dropdown-label {

		position: relative;

		i[class^="icon-"] {

			position: absolute;
			top: 50%;
			right: 0;
			margin: 0;

			&:before {

				position:relative;
				// top: -half of the glyph
			}
		}

		.indicator-open {
			
			display: none;
		}

		&.open {

			.indicator-closed {

				.hidden();
			}

			.indicator-open {
			
				display: block;
			}
		}

	}

	.dropdown-label--text {

	}

	.dropdown-content {

		.whitebox(); // The order here is imperative.
		.box-shadow( 0px 2px 3px rgba(0, 0, 0, 0.3) ); // covers visual deficiency
		display: none;
		position: absolute;
		width: 100%;
		padding-top: 0;
		overflow: visible; // for the path cover below
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		z-index: 999;
		
		&.open {

			display: block;
		}
		// An artificial cover for the gap between these two sibling elements
		&:before {

			content: "";
			display: block;
			position: absolute;
			top: -2px;
			left: 0;
			height: 3px;
			width: 100%;
			background-color: white;
		}

	}
}

[data-dropdown~="container-type-box"] {

	.dropdown-content {

		position: relative;
	}
}

[data-dropdown~="size-M"] {

	margin-bottom: @margin-M;
	
	.dropdown-label {

		display: block;
		padding-right: 2em;

		&.campaign {

			font-family: @tre-bold-font;
			color: @tre-message-color;
		}

		.dropdown-label--text {

			.font-size(1.6);
		}

		i[class^="icon-"] {

			font-size: 2em;
			color: @tre-darkgray-color;

			&:before {

				top: -0.5em;
			}
		}
	}
}

[data-dropdown~="size-L"] {

	@basefontsize: 16;
	@iconsize: 56;

	.font-size( @basefontsize/10 );

	.listitem:not(.listitem--no-padding) {

		padding-right: unit( @iconsize/@basefontsize, em ); // space for the icon
		margin: 0;
	}

	//TODO: move to selfservice
	.phonenumber,
	.instruction {
		font-family: @tre-bold-font;
		font-size: unit( 50/@basefontsize, em );
		color: @hw-bg-dark;
	}

	.default {
		.instruction {
			&:hover {
				color: @hw-bg-dark;
			}
		}
	}

	//TODO: move to selfservice
	.packagename {
		font-size: unit( 22/@basefontsize, em );
	}

	@media only screen and (max-width: @hw-palm) {

		.font-size( 1.4 );

		.listitem {

			.instruction {
				font-size: 1.8em;
			}
			.no-break {
				white-space: normal;
			}
		}
	}
}

[data-dropdown~="style-gray"] {

	.dropdown-labelcontainer {

		.graybox();
	}
}

[data-dropdown~="fit-fullwith"] {

	display: block;
	width: 100%;
	max-width: none;
}