/// <reference path="../framework.less" />

.order-done {
    height: -moz-calc(~"100vh - 60px");
    height: -webkit-calc(~"100vh - 60px");
    height: -o-calc(~"100vh - 60px");
    height: calc(~"100vh - 60px");
    margin: 0px auto;
    display: table;
    position: relative;

    .order-done__container {
        padding: 0;
        vertical-align: middle;
        z-index: 3;
        margin: 0 auto;
        display: table-cell;
        max-width: 680px;
        width: auto;

        @media @breakpointquery-2018-tablet {
            max-width: none;
            margin-bottom: 300px;
            vertical-align: top;
            padding: 20px;
        }


        p {
            font-size: 14px;
            font-family: @font-2018-roman;
            color: @color-2018-text-black;

            b, bold {
                font-family: @font-2018-bold;
            }

            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        h2 {
            font-size: 48px;
            color: @color-2018-hallon-red;
            text-transform: none;
        }

        h3 {
            font-size: 14px;
            font-family: @font-2018-bold;
            color: @color-2018-background-dark-grey;
            text-transform: none;
        }

        ul {
            list-style: none;
            font-size: 14px;
            font-family: @font-2018-roman;
            color: @color-2018-background-dark-grey;
        }

        .link-right-arrow {
            margin-top: 2em;
        }
    }

    &__line-item {
        display: flex;
        flex-direction: column;
        margin-bottom:10px;
    }

    &__line-item-extra {
        font-family: @font-2018-bold;
        font-size: 12px;
        margin-top: 3px;
    }
}
