﻿.LoginPage {
	margin: 0px auto;
	background:@hallon-white;
	padding: 20px;

		.background-overlay{
			background: @hallon-white;
			height:100%;

			background: url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat  center center fixed;
			background: -webkit-linear-gradient(
							@linear-gradient-pink
			), url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat  center center fixed;

			background: -o-linear-gradient(
							@linear-gradient-pink
			), url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat  center center fixed;

			background: -moz-linear-gradient(
							@linear-gradient-pink
			), url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat  center center fixed;

			background: linear-gradient(
							@linear-gradient-pink
			), url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat  center center fixed;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
		}
}
