/// <reference path="../framework.less" />

.wysiwyg-section, .cke_editable, .wysiwyg-section .htmlContent {
    width: 660px;

    &--max-width {
        width: 100%;
    }

    &--max-width-600 {
        max-width: 660px;
        width: 100%;

        @media @breakpointquery-2018-tablet {
            max-width: none;
        }
    }

    &--text-indent {
        ul {
            text-indent: -20px;
        }

        li {
            margin-bottom: 10px;
        }
    }

    @media @breakpointquery-2018-tablet {
        width: 100%;
    }

    &__extra-large-font-size {
        margin-bottom: 50px;
        font-size: 80px;
        line-height: 93px;
        text-transform: none;
        font-family: @font-2018-black;

        @media @breakpointquery-2018-tablet {
            font-size: 38px;
            line-height: 46px;
        }
    }

    &__large-font-size {
        margin-bottom: 50px;
        font-size: 48px;
        line-height: 57px;
        text-transform: none;
        font-family: @font-2018-black;

        @media @breakpointquery-2018-tablet {
            font-size: 24px;
            line-height: 28px;
        }
    }

    &__medium-font-size {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 28px;
        text-transform: none;
        font-family: @font-2018-bold;

        @media @breakpointquery-2018-tablet {
            font-size: 21px;
            line-height: 24px;
        }
    }

    &__normal-font-size {
        font-family: @font-2018-light;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 20px;
    }

    h1 {
        margin-bottom: 50px;
        font-size: 48px;
        line-height: 57px;
        text-transform: none;
        font-family: @font-2018-black;

        @media @breakpointquery-2018-tablet {
            font-size: 24px;
            line-height: 28px;
        }
    }

    h2 {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 28px;
        text-transform: none;
        font-family: @font-2018-bold;

        @media @breakpointquery-2018-tablet {
            font-size: 21px;
            line-height: 24px;
        }
    }

    h3 {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 22px;
        text-transform: none;
        font-family: @font-2018-bold;

        @media @breakpointquery-2018-tablet {
            font-size: 18px;
            line-height: 22px;
        }
    }

    p {
        font-family: @font-2018-light;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 20px;
    }

    strong, b {
        font-family: @font-2018-bold;
        font-weight: normal;
    }

    ul {
        list-style-type: disc;
        list-style-position: inside;
        margin-bottom: 20px;
        margin-left: 40px;
    }

    ol {
        list-style-type: decimal;
        list-style-position: inside;
        margin-bottom: 20px;
        margin-left: 40px;
    }

    ul ul, ol ul {
        list-style-type: circle;
        list-style-position: inside;
        margin-left: 15px;
    }

    ol ol, ul ol {
        list-style-type: lower-latin;
        list-style-position: inside;
        margin-left: 15px;
    }

    li {
        font-family: @font-2018-light;
        font-size: 14px;
        line-height: 21px;
    }

    &__button-wrapper {
        margin: 30px 0;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        border: none;
        padding: 0;
        overflow: auto;
    }

    &__button {
        color: @color-2018-white;
        background: @color-2018-green;
        border: 2px solid @color-2018-green;
        font-size: 16px;
        line-height: 18px;
        font-family: @font-2018-bold;
        border-radius: 50px;
        transition: all 0.2s linear;
        text-transform: none;
        padding: 12px 35px;
        display: flex;
        justify-content: center;
        cursor: pointer;

        @media @breakpointquery-2018-tablet {
            font-size: 14px;
            line-height: 17px;
            padding: 10px 15px;
        }

        &:hover {
            background: @color-2018-green-hover;
            border: 2px solid @color-2018-green-hover;
            color: @color-2018-white;
        }

        &--confirm {
            color: @color-2018-white;
            background: @color-2018-hallon-red;
            border: 2px solid @color-2018-hallon-red;

            &:hover {
                background: @color-2018-white;
                border: 2px solid @color-2018-hallon-red;
                color: @color-2018-hallon-red;
            }
        }

        &--decline {
            color: @color-2018-hallon-red;
            background: @color-2018-white;
            border: 2px solid @color-2018-hallon-red;

            &:hover {
                background: @color-2018-hallon-red;
                border: 2px solid @color-2018-hallon-red;
                color: @color-2018-white;
            }
        }

        &--full-width-mobile {
            @media @breakpointquery-2018-tablet {
                flex-basis: 100%;
                margin-top: 20px;

                &.wysiwyg-section__button--margin-right, &.wysiwyg-section__button--margin-left {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }

        &--margin-left {
            margin-left: 10px;
        }

        &--margin-right {
            margin-right: 10px;
        }
    }

    blockquote {
        position: relative;
        width: 100%;
        max-width: 350px;

        p {
            position: relative;
            z-index: 1;
            font-size: 18px;
            font-family: @font-2018-light;
            line-height: 27px;
        }

        &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.24 20.34'%3E%3Cpath fill='%23ccc' d='M10.19,25.53H.89L8,5.19h7.31Zm15.61,0H16.66l7-20.34h7.47Z' transform='translate(-0.89 -5.19)'/%3E%3C/svg%3E");
            content: "";
            width: 50px;
            height: 50px;
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            z-index: 0;
            top: -5px;
            left: -5px;
            opacity: 0.5;
        }
    }
}
