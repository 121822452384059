.hallon-input {
    width: 100%;
    display: block;
    padding: 1em;
    font-family: @hallon-frutiger-55-roman;
    font-size: @ftsize-hallon-14;
    background-color: transparent;
    border: 2px solid @hallon-dark-grey;
    border-radius: 0;
    color: @hallon-dark-grey;

    &:focus {
        border: 2px solid @color-blue;
    }

    &:disabled {
        border: 2px solid @hallon-medium-grey;
    }
}

.hallon-select {
    width: 100%;
    display: block;
    position: relative;
    font-family: @hallon-frutiger-55-roman;
    font-size: @ftsize-hallon-14;
    background-color: transparent;
    border: 2px solid @hallon-dark-grey;
    border-radius: 0;
    color: @hallon-dark-grey;

    &:focus,
    &:active {
        border: 2px solid @color-blue;
    }

    &:disabled {
        border: 2px solid @hallon-medium-grey;
        background-color: @hallon-light-grey;
        color: red;
        pointer-events: none;
    }

    li {
        padding: .3em 1em;
        cursor: pointer;
        font-family: @hallon-frutiger-55-roman;
        font-size: @ftsize-hallon-14;

        &:focus,
        &:active {
            color: @hallon-light-blue;
        }
    }

    li:nth-child(2) {
        padding-top: 1em;
    }

    li:last-child {
        padding-bottom: 1em;
    }

    .hallon-select__label-inside {
        padding: 1em;
    }

    li:not(:first-child) {
        display: none;
    }

    &:not(.hallon-select--expanded) {
        li {
            padding: 1em;
        }
    }

    i {
        position: absolute;
        right: 1em;
        top: .8em;
        color: @hallon-dark-grey;
        cursor: pointer;
    }

    select {
        width: 100%;
        padding: 1em;
        display: none; 
    }

    ul,
    i {
        display: block;
    }
}

.hallon-select--expanded {
    max-height: 140px;
    overflow-y: auto;

    &:hover {
        border: 2px solid @hallon-light-blue;
    }
}

.hallon-select__item--selected {
    color: @hallon-light-blue;
}

.js-hallon-select.hallon-select--selected {
    display: block;

    .hallon-select__label-inside {
        display: none;
    }

    .hallon-select__item--selected {
        display: block;
    }
}

.hallon-password {
    position: relative;

    .hallon-password__btn {
        position: absolute;
        right: 4em;
        bottom: 16px;
        color: @hallon-dark-grey;
        cursor: pointer;
    }
}

.hallon-label {
    color: @hallon-dark-grey;
    font-family: @hallon-frutiger-55-roman;
    font-size: @ftsize-hallon-12;
    padding-bottom: .5em;
    margin-left: 0;
    opacity: 0;
    margin-bottom: .1em;
}

/* CHECKBOX / RADIO */

.label-checkbox {
    color: @hallon-dark-grey;

    > input {
        display: none;
    }

    > input + span {
        cursor: pointer;
        display: inline-block;
        margin-right: 1.5em;
        width: 32px;
        height: 32px;
        background: url("/UI/build/images/hallonsvg/checkbox_unactive.svg");
        background-size: 22px 22px;
        vertical-align: sub;
    }

    > input:checked + span {
        cursor: pointer;
        display: inline-block;
        margin-right: 1.5em;
        width: 32px;
        height: 32px;
        background: url("/UI/build/images/hallonsvg/checkbox_active.svg");
        background-size: 22px 22px;
        vertical-align: sub;
    }
}

.label-checkbox-inactive {
    color: @hallon-dark-grey;

    span {
        cursor: default;
        display: inline-block;
        margin-right: 1.5em;
        width: 15px;
        height: 15px;
        background: url("/UI/build/images/hallonsvg/checkbox_unactive.svg");
        background-size: 15px 15px;
        vertical-align: sub;
    }
}

.label-radio {
    color: @hallon-dark-grey;

    > input {
        display: none;
    }

    > input + span {
        cursor: pointer;
        display: inline-block;
        margin-right: 1.5em;
        width: 18px;
        height: 15px;
        background: url("/UI/build/images/hallonsvg/radio_unactive.svg");
        background-repeat: no-repeat;
        background-size: 15px 15px;
        vertical-align: sub;
    }

    > input:checked + span {
        cursor: pointer;
        display: inline-block;
        margin-right: 1.5em;
        width: 18px;
        height: 15px;
        background: url("/UI/build/images/hallonsvg/radio_active.svg");
        background-size: 15px 15px;
        background-repeat: no-repeat;
        vertical-align: sub;
    }
}

.label-radio.label-radio--cancel {
    color: @hallon-light-black;
    display: flex;

    > input {
        display: none;
    }

    > input + span {
        margin-right: 10px;
    }

    > input:checked + span {
        margin-right: 10px;
    }
}

.label-radio--right {
    > input + span {
        margin-right: 0;
        margin-left: 1.5em;
    }

    > input:checked + span {
        margin-right: 0;
        margin-left: 1.5em;
    }
}

.label-checkbox--right {
    > input + span {
        margin-right: 0;
        margin-left: 5px;
    }

    > input:checked + span {
        margin-right: 0;
        margin-left: 5px;
    }
}

/* VALIDATION MESSAGES */

input.valid-input {
    border: 2px solid @hallon-light-blue;
}

input.invalid-input {
    border: 2px solid @hallon-light-red;
}

div.valid-input {
    position: relative;

    &::before {
        color: @hallon-light-blue;
        font-family: "hallon";
        font-size: @ftsize-hallon-20;
        content: "\e914";
        padding-left: 1em;
        position: absolute;
        right: 14px;
        bottom: 13px;
    }
}

div.invalid-input {
    position: relative;

    &::before {
        color: @hallon-light-red;
        font-family: "hallon";
        font-size: @ftsize-hallon-20;
        content: "\e915";
        padding-left: 1em;
        position: absolute;
        right: 14px;
        bottom: 15px;
    }
}

// ERROR DISPLAY

.mobile-subscription__error {
    display: inline-block;
    float: right;
    margin-bottom: 0;
    padding-bottom: .5em;
    font-size: @ftsize-hallon-12;
    margin-block-end: 0;
    margin-block-start: 0;
    color: @hallon-light-red;

    p {
        display: inline-block;
        margin-block-end: 0;
        margin-block-start: 0;
        float: right;
        margin: 0;
        font-size: @ftsize-hallon-12;
        color: @hallon-light-red;
    }

    &.override--valid p {
        color: @hallon-light-blue;
    }
}

:-moz-ui-invalid:not(output) {
    box-shadow: none;
}

input[type="submit"] {
    -webkit-appearance: none;
    border-radius: 0;
}

.hallon-textarea {
    resize: none;
    margin-bottom: 1em;
    width: 100%;
}
