.product-content{

  @media only screen and (min-width: @phone-breakpoint-hallon) {
    background: @hallon-light-red;
    background: -webkit-linear-gradient(
            @linear-gradient-pink
    );
    background: -o-linear-gradient(
            @linear-gradient-pink
    );
    background: -moz-linear-gradient(
            @linear-gradient-pink
    );
    background: linear-gradient(
            @linear-gradient-pink
    );
    display: block;
  }

  ul{


    @media only screen and (min-width: @phone-breakpoint-hallon) {

      display: block;
      margin:0 auto;
      text-align: center;
    }
  }

  li{

    @media only screen and (min-width: @phone-breakpoint-hallon) {
      text-align: center;
      background: transparent;
      width:auto;
      display: inline-block;
    }

  }

  .product-content__expand-link{
    cursor: pointer;
    font-family: @hallon-frutiger-55-roman;
    font-size: @ftsize-hallon-14;
    color: @hallon-dark-grey;
    background: @hallon-white;
    width:100%;
    padding: 1.5em;
    border-top: 1px solid @hallon-light-grey;
    display: block;


    @media only screen and (min-width: @phone-breakpoint-hallon) {
      color: @hallon-white;
      padding: 25px 35px;
      background: transparent;
      border:0;

      &:hover{
        color: @hallon-light-grey;
      }

    }
  }


  i{
    float:right;

    @media only screen and (min-width: @phone-breakpoint-hallon){
      display: none;
    }
  }

  .product-content__expanding-area{
    display: none;
    background: @hallon-white;
    padding: 0 1.5em 1.5em;
    h1{
      text-transform: none;
      font-size: @ftsize-hallon-24;
    }
    p{
      padding-bottom:1em;
    }


    ul{

      @media only screen and (min-width: @phone-breakpoint-hallon) {
        text-align: left;
      }
    }

    li{

      @media only screen and (min-width: @phone-breakpoint-hallon) {
        text-align: left;
      }

    }


  }
  .hallon-btn-close{
    display: none;
  }

}