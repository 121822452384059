/// <reference path="../framework.less" />

.topUpPage {
    &__wrapper {
        display: flex;
        overflow: hidden;
    }

    &__step-wrapper {
        width: calc(~"100% + 50px");
        flex: 1 0 auto;
        transition: transform 0.6s ease-in-out;
        transform: translateX(0);
        margin-bottom: 50px;

        &--step2 {
            transform: translateX(-100%);
        }

        &--step3 {
            transform: translateX(-200%);
        }

        @media @breakpointquery-2018-tablet {
            width: calc(~"100% + 15px");
        }
    }

    &__step-back {
        cursor: pointer;
    }

    &__step-number {
        font-family: @font-2018-roman;
        color: @color-2018-hallon-red;
        font-size: 16px;
        line-height: 70px;
        max-width: 580px;

        &--margin-top {
            margin-top: 17px;
        }

        @media @breakpointquery-2018-tablet {
            padding-right: 15px;
            max-width: none;

            &--margin-top {
                margin-top: 0;
            }
        }
    }

    &__title {
        font-size: 48px;
        line-height: 70px;
        color: @color-2018-text-black;
        margin-bottom: 20px;
        padding: 0;
        font-family: @font-2018-black;
        text-transform: none;
        max-width: 580px;

        @media @breakpointquery-2018-tablet {
            font-size: 40px;
            line-height: 50px;
            padding-right: 15px;
            max-width: none;
        }
    }

    &__subtitle {
        font-family: @font-2018-bold;
        line-height: 36px;
        font-size: 24px;
        color: @color-2018-text-black;
        text-transform: none;
        margin-bottom: 20px;
        max-width: 580px;

        @media @breakpointquery-2018-tablet {
            padding-right: 15px;
            max-width: none;
        }
    }

    &__info-text {
        font-size: 16px;
        line-height: 26px;
        font-family: @font-2018-roman;
        color: @color-2018-text-black;
        max-width: 580px;

        @media @breakpointquery-2018-tablet {
            padding-right: 15px;
            max-width: none;
        }
    }

    &__form-wrapper {
        margin-top: 70px;
        max-width: 580px;
        width: 100%;
        max-width: 580px;

        @media @breakpointquery-2018-tablet {
            padding-right: 15px;
            margin-top: 40px;
            max-width: none;
        }
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
        max-width: 900px;
        margin-bottom: 40px;

        @media @breakpointquery-2018-tablet {
            padding-right: 15px;
            margin-top: 10px;
            max-width: none;
            justify-content: space-between;
        }
    }

    &__list-header {
        margin-top: 60px;

        @media @breakpointquery-2018-tablet {
            margin-top: 30px;
        }
    }


    &__list-item {
        margin: 0 10px 10px 0;
        position: relative;

        @media @breakpointquery-2018-tablet {
            flex: 0 0 48%;
            max-width: 48%;
            margin-right: 0;
        }
    }

    &__list-item-wrapper {
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 158px;
        cursor: pointer;

        @media @breakpointquery-2018-tablet {
            width: 100%;
        }
    }

    &__list-item-title {
        color: @color-2018-hallon-red;
        font-family: @font-2018-black;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        background-color: @color-2018-yellow;
        padding-top: 25px;
        padding-bottom: 5px;
    }


    &__list-item-price {
        color: @color-2018-hallon-red;
        font-family: @font-2018-roman;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        background-color: @color-2018-yellow;
        padding-bottom: 25px;
    }

    &__list-item-lowest-price {
        background-color: @color-2018-hallon-red;
        color: @color-2018-yellow;
        font-size: 12px;
        line-height: 16px;
        font-family: @font-2018-roman;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 5px 0;

        &--gb {
            font-family: @font-2018-bold;
        }
    }


    &__list-item-choosen {
        position: absolute;
        z-index: 1;
        right: -5px;
        top: -5px;
        width: 40px;
        height: 40px;
        opacity: 0;
        margin: 0;
        transform: scale3d(0,0,0);
        transition: all 0.2s ease-in-out;

        &--active {
            opacity: 1;
            transform: scale3d(1,1,1);
        }
    }

    &__list-item-tick-icon {
        margin-right: 7px;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
    }

    &__list-item-tick-icon-background {
        fill: @color-2018-hallon-red;
    }
    &__list-item-tick-icon-background-internationel {
        fill: @color-2018-yellow;
    }

    &__list-item-tick-color {
        fill: none;
        stroke: @color-2018-white;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
    }

    &__button-wrapper{
        display:inline-block;
        padding-bottom:40px;
    }

    &__input-error-message {
        position: absolute;
        margin-top: 5px;
        width: 100%;
        padding-left: 10px;
        font-size: 11px;
        color: @color-2018-error-red;
    }
}
.topUpPage__list-item-wrapper-international-wrapper {
    display: none;
}

.topUpPage__list-item-wrapper-international {
    width: 327px;
    margin-bottom: 50px;
    position: relative;
    overflow: visible;    

    @media @breakpointquery-2018-tablet {
        width: calc(~"100% - 15px");        
    }
    
    
    .topUpPage__list-item-price,
    .topUpPage__list-item-title {
        color: @color-2018-white;
        background-color: @color-2018-hallon-red;        
    }

    .topUpPage__list-item-title {
        border-radius: 10px 10px 0 0;
    }

    .topUpPage__list-item-price {
        border-radius: 0 0 10px 10px;
        padding: 0 10px 15px;
    }
}
