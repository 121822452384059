.imageheader{

  padding: 0;
  margin: 0;
  .bgsize(cover);
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 160px;
  position: relative;

  @media only screen and (min-width: @phone-breakpoint-hallon) {
    min-height: 640px;
  }

  h1{
    position: absolute;
    margin:auto;
    text-align:center;
    color:@hallon-white;
    top:40%;
    width:100%;

    p{
      font-size: inherit;
    }

    span{
      display: block;
    }


  }
}

