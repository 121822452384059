.header-menu{

  -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1);



  text-align: center;
  height:60px;
  position: fixed;
  top:0;
  width:100%;
  z-index: 9999;
  .clearfix;

  @media only screen and (min-width: @phone-breakpoint-hallon) {
    text-align: left;
  }

  .hallon-btn-hamburger{
    float:left;

    @media only screen and (min-width: @phone-breakpoint-hallon) {

      display: inline-block;
    }
  }

  .header-menu__popover-container{
    float:right;
    display: inline;
    position: relative;

    .popover-menu{
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -24px);
      margin-top: 20px;
    }

  }

  .hallon-btn-login{
    float:right;
  }
  .hallon-btn-logout{
    float:right;
  }

  .hallon-btn-logo{
    display: inline-block;
    position: relative;
    margin-left:0;

    @media only screen and (min-width: @phone-breakpoint-hallon) {
      margin-left:50px;
    }

    img{
      display: none;
      @media only screen and (min-width: @phone-breakpoint-hallon) {
        position: absolute;
        top:60px;
        left:0px;
        display: block;

      }
    }

  }
}

.header-menu--private{
  background-color: @hallon-white;

  .hallon-btn-hamburger{
    i{
      color: @hallon-dark-red;
    }
  }

  .hallon-btn-logo{
    &:before{
      color: @hallon-dark-red;
    }
  }

  .hallon-btn-logout{
    i{
      color: @hallon-dark-red;
    }
  }

  .hallon-btn-login{
    i{
      color: @hallon-dark-red;
    }
  }

}

.header-menu--company{

  background: @hallon-light-red;
  background: -webkit-linear-gradient(@linear-gradient-company-red);
  background: -o-linear-gradient(@linear-gradient-company-red);
  background: -moz-linear-gradient(@linear-gradient-company-red);
  background: linear-gradient(@linear-gradient-company-red);

  .hallon-btn-hamburger{
    i{
      color: @hallon-white;
    }
  }

  .hallon-btn-logo{
    &:before{
      color: @hallon-white;
    }
  }

  .hallon-btn-logout{
    i{
      color: @hallon-white;
    }
  }

  .hallon-btn-login{
    i{
      color: @hallon-white;
    }
  }

}