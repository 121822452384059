.chat {

	&.available{

		.chat-open {

			height: auto;
		}
		.chat-closed {

			height: 0;
		}
	}

	.chat-open {

		height: 0;
	}
	.chat-closed {

		height: auto;
	}

	.chat-open,
	.chat-closed {

		overflow: hidden;
		.transition(height 450ms);
	}
}