.module-campaign {
    display: table;
    background: url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat center center fixed;
    background: -webkit-linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat center center fixed;
    background: -o-linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat center center fixed;
    background: -moz-linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat center center fixed;
    background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url("/UI/build/images/bg/MbbKkLiten.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .module-campaign__container {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    .module-campaign__slider-container {
        position: relative;
        padding-top: 50px;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            padding-top: 60px;
        }
    }

    .module-campaign__hallon-slider {
        width: 286px;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, 0%);
    }

    .module-campaign__btncontainer {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 1em;
    }

    .module-campaign__btn {
        position: relative;
        z-index: 1;
    }

    .module-campaign__uspcontainer {

        a {
            display: inline-block;
            margin-left: 3px;
            margin-right: 3px;
        }
    }

    .bubble {
        z-index: 2;
        position: relative;
    }

    .module-campaign__pink-offer {
        position: relative;
        z-index: 3;
        transform: translate(-93px, 150%);
        margin-top: -95px;
    }

    h1, h2 {
        font-family: @hallon-frutiger-77-bc;
        font-size: @ftsize-hallon-77;
        text-transform: uppercase;
        color: @hallon-white;
        margin-bottom: 0;

        span {
            font-family: @hallon-frutiger-55-roman;
            font-size: @ftsize-hallon-20;
            color: @hallon-white;
            margin-bottom: 0;
            text-transform: none;
            display: block;
            line-height: 1;
        }


        @media only screen and (min-width: @phone-breakpoint-hallon) {
            font-size: @ftsize-hallon-90;
        }
    }

    .link-right-arrow {
        margin-top: 2em;
        text-align: center;
        color: @hallon-white;
    }

    .module-campaign__clock {
        display: none;
    }
}

.module-campaign.column-50, .module-campaign.column-25 {

    h2 {
        font-size: @ftsize-hallon-60;
        line-height: 1;

        span {
            font-size: @ftsize-hallon-16;
        }
    }

    .module-campaign__btncontainer {
        margin-top: 0em;
    }
}

.module-campaign.module-campaign--countdown {

    .module-campaign__clock {
        position: relative;
        display: block;
        font-family: @hallon-frutiger-55-roman;
        color: @hallon-white;
        font-size: @ftsize-hallon-14;
        line-height: 18px;

        .module-campaign__time-container {
            text-align: center;
            display: inline-block;
            margin-bottom: 30px;

            &:not(:last-child) {
                margin-right: 10px;
            }

            span:nth-child(-n+2) {
                font-family: @hallon-frutiger-65-bold;
                font-size: 18px;
                display: inline-block;
                background: @hallon-dark-red;
                width: 22px;
                height: 35px;
                line-height: 35px;
            }

            span:nth-child(3n+3) {
                display: block;
                margin-top: 3px;
                font-family: @hallon-frutiger-55-roman;
                font-size: 14px;
            }
        }


        p {
            display: block;
            margin-bottom: 10px;
            font-family: @hallon-frutiger-55-roman;
            font-size: 14px;
        }
    }
}
