.telemarketing {
    width: 100%;
    background: @hallon-white;
    border-top: 1px solid @hallon-light-grey;
    float: left;
    padding-bottom: 50px;

    @media only screen and (min-width: @phone-breakpoint-hallon) {
        float: none;
        max-width: 960px;
        margin: 0 auto;
        border-top: 0;
        background: @hallon-white;
    }

    form {
        padding-left: 20px;
        padding-right: 20px;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            padding-left: 230px;
            padding-right: 230px;
        }

        div {
            margin-bottom: 15px;
        }
    }

    .telemarketing__headline {
        text-transform: none;
        font-family: @hallon-frutiger-77-bc;
        font-size: 48px;
        color: @hallon-light-black;
        text-align: center;
        padding-top: 40px;
    }

    .telemarketing__sub-headline {
        font-family: @hallon-frutiger-55-roman;
        font-size: 14px;
        color: @hallon-light-black;
        text-transform: none;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .telemarketing__sub-headline--first {
        margin-top: 10px;
    }

    .telemarketing__name {
        width: 100%;
        display: block;

        @media only screen and (min-width: @phone-breakpoint-hallon) {
            display: inline-block;
            width: 235px;

            &:nth-of-type(1) {
                margin-right: 20px;
            }
        }
    }

    .telemarketing__checkbox {
        display: block;
        margin-bottom: 15px;
    }

    .telemarketing__verify-input {
        display: block;
        width: 100%;

        @media only screen and (min-width: @phone-breakpoint-hallon) {
            display: inline-block;
            width: 330px;
        }

        .hallon-input:disabled {
            color: @hallon-black;
            background-color: @hallon-medium-grey;
            border-color: @hallon-light-grey;
        }
    }

    .telemarketing__verify-button {
        display: block;
        float: none;
        margin-top: 23px;

        @media only screen and (min-width: @phone-breakpoint-hallon) {
            float: right;
        }
    }

    .telemarketing__paragraph {
        font-family: @hallon-frutiger-55-roman;
        color: @hallon-dark-grey;
        margin-top: 10px;
        margin-bottom: 40px;
        font-size: 14px;
    }

    .telemarketing__submit {
        margin: 0 auto;
        margin-top: 10px;
        display: block;
    }

    .mobile-subscription-list {
        padding: 2em 20px;
        margin-top: 24px;
        margin-bottom: 1em;
    }

    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="number"],
    input[type="tel"] {
        display: block;
        .placeholder-color;
    }

    label {
        font-size: @ftsize-hallon-14;
        font-family: @hallon-frutiger-55-roman;
        display: inline-block;
        margin-bottom: 5px;
    }

    .label-radio {
        display: block;
    }

    .mobile-subscription__error {
        margin-bottom: 0;
    }

    .mobile-subscription__confirm-text {
        padding-left: 20px;
        padding-right: 20px;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            padding-left: 230px;
            padding-right: 230px;
        }

        p {
            text-align: left;
        }
    }

    .hallon-label {
        margin-left: 0;
        font-size: @ftsize-hallon-12;
        opacity: 0;
        margin-bottom: .1em;
    }

    svg path,
    svg rect {
        fill: @hallon-white;
    }

    .telemarketing__parent-subscription {
        margin-top: 26px;
        margin-bottom: 40px;

        p {
            font-family: @hallon-frutiger-55-roman;
            color: @hallon-dark-grey;
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 14px;
        }
    }

    .telemarketing__spinner-section {
        width: 100%;
        margin-top: 20px;
        height: 49px;
        text-align: center;
        line-height: 45px;
        color: white;
        font-size: @ftsize-hallon-20;
        background-color: #9cf;
    }
}

.TelemarketingSalePage {
    .footer-presection {
        display: none;
    }
}
