.cancel-subscription__info {
    margin: 0 auto;
}

.cancel-subscription__info,
.cancel-subscription__info-following-step {
    background: @hallon-yellow;
    padding: 20px;
}

.cancel-subscription__info-following-step {
    position: inherit;
    width: 280px;

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
        position: absolute;
        top: 80px;
        right: 40px;
        width: 280px;
        margin-left: auto;
    }
}

.cancel-subscription__info-headline {
    font-size: @ftsize-hallon-20;

    &--first {
        font-weight: bold;
        font-size: 22px;
    }

    @media @breakpointquery-2018-mobile {
        font-size: 18px;
    }
}

.cancel-subscription__info-headline,
.cancel-subscription__info-remaining-data,
.cancel-subscription__info-text,
.cancel-subscription__info-following-step-info-headline,
.cancel-subscription__info-following-step-info,
.cancel-subscription__info-last-step-info-headline {
    color: @hallon-wine-red;
}

.cancel-subscription__info-following-step-info,
.cancel-subscription__info-following-step-info-headline,
.cancel-subscription__info-last-step-info-headline {
    text-align: center;
    font-size: @ftsize-hallon-14;
}

.cancel-subscription__info-following-step-info-headline {
    font-weight: bold;
}

.cancel-subscription__info-last-step-info-headline {
    text-decoration: underline;
}

.cancel-subscription__info-following-step-info-headline {
    margin-bottom: 14px;  
}

.cancel-subscription__info-headline,
.cancel-subscription__info-remaining-data,
.cancel-subscription__info-text {
    text-align: center;
    padding: 6px;
}

.cancel-subscription__info-our-phones {
    font-size: @ftsize-hallon-16;
    @media @breakpointquery-2018-mobile {
        margin-bottom: 14px;
    } 
}

.cancel-subscription__info-remaining-data {
    font-size: 22px;
    
    @media @breakpointquery-2018-mobile {
        font-size: 18px;
    }
}

.cancel-subscription__info-icon {
    height: 40px;
    width: 40px;
    display: block;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 14px;
}
