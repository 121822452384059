.module-usp {
  background: @hallon-white;
  position: relative;
  display: block;
  -ms-overflow-y: hidden;

  &:hover{
    background: @hallon-light-grey;
  }

  ul{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -ms-transform: translate(-50%, -50%); /* IE 9 */
    -webkit-transform: translate(-50%, -50%); /* Safari */
    transform: translate(-50%, -50%);


    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      display: block;
      position: relative;
      width:100%;
      left:0;
      -ms-transform: translate(-0, -50%); /* IE 9 */
      -webkit-transform: translate(-0, -50%); /* Safari */
      transform: translate(-0, -50%);
    }
  }

  li{
    display: block;
    width:100%;
    text-align: left;
    vertical-align: middle;
    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      text-align: right;
      display: inline-block;
    }
  }

  .module-usp__text-item {
    font-family: @hallon-frutiger-65-bold;
    font-size: @ftsize-hallon-18;
    color: @hallon-dark-grey;
    padding: 0.5em 0;


    i {
      color: @hallon-light-red;
      font-family: "hallon";
      content: "\e914";
      padding-right: 1em;
    }

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      color: @hallon-white;
      width: 20%;
      padding:0;
      i {
        color: @hallon-white;
      }
    }
  }

  .module-usp__logo-item{
    display: none;

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      color: @hallon-white;
      width: 10%;
      font-size: @ftsize-hallon-48;
      display: inline-block;
    }

  }

  &:after{
    .clearfix
  }
}

.module-usp.module-usp--red{
  @media only screen and (min-width: @tablet-breakpoint-hallon) {
    background: @hallon-light-red;
    background: -webkit-linear-gradient(@linear-gradient-pink);
    background: -o-linear-gradient(@linear-gradient-pink);
    background: -moz-linear-gradient(@linear-gradient-pink);
    background: linear-gradient(@linear-gradient-pink);

    &:hover{
      background: @hallon-dark-red;
      background: -webkit-linear-gradient(@linear-gradient-red);
      background: -o-linear-gradient(@linear-gradient-red);
      background: -moz-linear-gradient(@linear-gradient-red);
      background: linear-gradient(@linear-gradient-red);
    }
  }
}


.module-usp.module-usp--white {
  background: @hallon-white;

  @media only screen and (min-width: @tablet-breakpoint-hallon) {
    background: @hallon-white;

    &:hover{
      background: @hallon-light-grey;
    }
  }

  &:hover{
    background: @hallon-light-grey;
  }


  .module-usp__text-item {
    color: @hallon-light-black;

    i{
      color:@hallon-dark-red;
    }
  }

  .module-usp__logo-item {

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      color: @hallon-dark-red;
    }
  }
}