/// <reference path="../framework.less" />

.registerNumber {
    max-height: 0;
    height: auto;

    &__wrapper {
        display: flex;
        overflow: hidden;
    }

    &__step-wrapper {
        width: 100%;
        flex: 1 0 auto;
        padding-right: 50px;
        transition: transform 0.6s ease-in-out;
        transform: translateX(0);
        padding-bottom: 50px;
        height: 100%;
        justify-self: flex-start;

        &--step2 {
            transform: translateX(-100%);
        }

        &--step3 {
            transform: translateX(-200%);
        }

        @media @breakpointquery-2018-tablet {
            width: calc(~"100% + 20px");
            padding-right: 0;
            padding-bottom: 30px;
        }
    }

    &__step-back {
        cursor: pointer;
    }

    &__step-number {
        font-family: @font-2018-roman;
        color: @color-2018-hallon-red;
        font-size: 16px;
        line-height: 70px;
        max-width: 580px;

        &--margin-top {
            margin-top: 17px;
        }

        @media @breakpointquery-2018-tablet {
            padding-right: 15px;
            max-width: none;

            &--margin-top {
                margin-top: 0;
            }
        }
    }

    &__bankId-wrapper {
        display: none;

        &--first {
            display: block;
        }
    }

    &__title {
        font-size: 24px;
        line-height: 36px;
        color: @color-2018-text-black;
        margin-bottom: 20px;
        padding: 0;
        font-family: @font-2018-black;
        text-transform: none;
        max-width: 580px;

        @media @breakpointquery-2018-tablet {
            padding-right: 15px;
            max-width: none;
        }
    }

    &__info-text {
        font-size: 16px;
        line-height: 26px;
        font-family: @font-2018-roman;
        color: @color-2018-text-black;
        max-width: 580px;

        @media @breakpointquery-2018-tablet {
            padding-right: 15px;
            max-width: none;
        }
    }

    &__form-wrapper {
        margin-top: 70px;
        max-width: 580px;
        width: 100%;

        &--inline-block {
            display: inline-flex;
            width: auto;
            flex-direction: column;

            @media @breakpointquery-2018-tablet {
                display: flex;
            }
        }

        @media @breakpointquery-2018-tablet {
            padding-right: 15px;
            margin-top: 40px;
            max-width: none;
        }
    }


    &__button-wrapper {
        display: inline-block;
        padding-bottom: 40px;
    }

    &__device-button {
        margin-bottom: 20px;
        cursor: pointer;

        &--desktop {
            background-color: #dddddd;
            color: #000;

            &:hover {
                color: #000;
                background-color: #f2f2f2;
            }
        }

        @media @breakpointquery-2018-tablet {
            margin-bottom: 20px;
            cursor: pointer;
            font-size: 16px;
            padding: 15px 20px;
            width: 100%;
        }
    }

    &__missing-bankid {
        display: block;
        cursor: pointer;
        font-size: 16px;
        line-height: 32px;
        margin-top: 20px;

        &:hover {
            text-decoration: underline;
        }


        @media @breakpointquery-2018-tablet {
            text-align: center;
            margin-top: 0;
        }
    }

    &__input-error-message {
        position: absolute;
        margin-top: 5px;
        width: 100%;
        padding-left: 10px;
        font-size: 11px;
        color: @color-2018-error-red;
    }


    &__return-to-start {
        display: none;
        margin-bottom: 20px;
    }

    &__bankid-cancel {
        display: none;
        cursor: pointer;
        font-size: 16px;
        line-height: 32px;
        margin-top: 20px;

        &:hover {
            text-decoration: underline;
        }
    }

    &__bankid-back {
        display: block;
        cursor: pointer;
        font-size: 18px;
        line-height: 32px;
        margin-top: 20px;

        &:hover {
            text-decoration: underline;
        }
    }

    &__submit-button {
        align-self: flex-start;

        @media @breakpointquery-2018-tablet {
            cursor: pointer;
            font-size: 16px;
            padding: 15px 20px;
            width: 100%;
            align-self: center;
        }
    }

    &__submit-button-error-message {
        text-align: center;

        @media @breakpointquery-2018-tablet {
            text-align: center;
        }
    }
}
