/// <reference path="2018/framework.less" />


.SelfServicePage {
    input[type="text"], input[type="tel"], input[type="password"], input[type="email"], input[type="tel"], select, textarea {
        background-color: #f8f9fa;
        border: 1px solid #d8d3d0;
        border-radius: 4px;
        font-family: "FrutigerLTW01-55Roman";
        font-size: 1.6rem;
        padding: 6px;
    }


    input:focus, textarea:focus {
        border-color: #3e3a37;
        color: #3e3a37;
    }

    .ajax-loader, .cancel-success {
        margin-bottom: 38px;
    }

    .aliasName {
        margin-top: 10px;
        display: block;
    }

    .aliasInput {
        display: block;
        padding-top: 20px;
    }
}