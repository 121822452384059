* {	.box-sizing(border-box); }

body,
html {

	height: 100%;
}

body {
	overflow-x: hidden;
	background: @body-background;
}

html {

	font-size: 62.5%;
	font-family:"FrutigerLTW01-55Roman", Arial;
}

body,
p,
h1 {

	margin:0;
	padding:0;
}

ol,
ul,
dl,
figure {

	padding:0;
	margin:0;
	list-style:none;
}

fieldset {

	margin: 0;
	padding: 0;
	border: none;
}

.fullsize-container{
	/*overflow: hidden;*/
	.clearfix
}
.wrapper{
	padding-top:60px;
	display:block;
	.clearfix;
	-ms-overflow-y: hidden;
}

/*@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
	html{
		height: 100%;
	}
	body{
		overflow: auto;
		height: 100%;
	}
}
.lte-IE9{
	overflow: hidden;
	height: 100%;

	body{
		overflow: auto;
		height: 100%;
	}
}*/

.zoneItem:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}