/// <reference path="../framework.less" />

.overlay-menu-open {
	width: 100vw;
	// height: 100vh;
	height: 100%;
	background: @color-2018-white;
}

.overlay-open {
	display: none;
}

.wrapper {
	padding: 0;
	min-height: 0;
	overflow: visible;
	z-index: 0;
}

.header + main {
	padding-top: 0;
}

.header--white-header + main {
	padding-top: 95px;

	@media @breakpointquery-2018-small-desktop {
		padding-top: 70px;
	}
}

.main-container .main-container__grid {
	padding: 50px 50px;
	margin: 0 auto;

	@media @breakpointquery-2018-tablet {
		padding: 50px 20px 50px 20px;
		margin: 0 auto;
	}

	&:empty {
		display: none;
	}
}


.main-container {
	flex: 1;
	display: flex;
	align-items: center;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
	/* IE10+ specific styles go here */
	.main-container {
		flex: 1 0 auto;
	}
}

main {
	flex: 1 0 auto;
	width: 100%;
	display: flex;
	flex-direction: column;
}

body {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

.main-container__grid:empty {
	display:none;
}

.flexwrapper {
	display: flex;
	flex-direction: column;
	flex: auto;
}
