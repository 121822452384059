﻿.hardware-footer {
    padding-bottom: 2em;

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
        padding-bottom: 0;
    }

    .hardware-footer__mobile-bubble {
        position: absolute;
        width: 156px;
        height: 122px;
        right: 10%;


        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            display: none;
        }
    }


    .hardware-footer__mobile-img {
        position: absolute;
        display: block;
        top: -121px;
        z-index: 6;
    }

    .hardware-footer__mobile-total-price {
        left: 51%;
        margin-right: -50%;
        -ms-transform: translate(-50%, -50%); /* IE 9 */
        -webkit-transform: translate(-50%, -50%); /* Safari */
        transform: translate(-50%, -50%);
        position: absolute;
        display: block;
        top: -38px;
        z-index: 6;
        color: @hallon-light-black;


        span:first-child {
            font-size: @ftsize-hallon-48;
            font-family: @hallon-frutiger-77-bc;
        }

        span {
            font-family: @hallon-frutiger-55-roman;
            display: block;
            line-height: 1;
            text-align: center;
        }
    }


    ul {
        width: 100%;
        display: block;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            display: table;
            height: 100%;
        }

        ul {
            display: table-cell;

            li {
                width: 100%;
                text-align: left;
            }
        }
    }

    li {
        font-family: @hallon-frutiger-55-roman;
        font-size: 14px;
    }

    .hardware-footer__list-item--sum {
        width: 200px;
    }

    .hardware-footer__list-headline {
        font-size: @ftsize-hallon-24;
        font-family: @hallon-frutiger-77-bc;
        padding-bottom: 1em;
        padding-top: 1em;
        display: block;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            display: table-cell;
            width: 370px;
            vertical-align: middle;
            text-align: center;
        }
    }

    .hardware-footer__list-item {
        display: block;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 1em;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            display: table-cell;
            width: 200px;
            vertical-align: middle;
            text-align: center;
            height: 100%;
            padding: 0;
        }
    }

    i {
        font-size: @ftsize-hallon-31;
        color: @hallon-dark-grey;
    }

    .hardware-footer__button-container {
        display: block;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;

        @media only screen and (min-width: @tablet-breakpoint-hallon) {
            position: relative;
            width: 420px;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            height: 100%;
            padding: 0;
        }

        .hallon-btn {
            margin: 0 auto;
            display: table;
        }

        .hardware-footer__desktop-img {
            display: none;

            @media only screen and (min-width: @tablet-breakpoint-hallon) {
                position: absolute;
                bottom: 80px;
                display: block;
                z-index: 5;
                margin-right: -50%;
                left: 50%;
                -ms-transform: translate(-50%, 0%); /* IE 9 */
                -webkit-transform: translate(-50%, 0%); /* Safari */
                transform: translate(-50%, 0%);
            }
        }


        .hardware-footer__total-price {
            display: none;

            @media only screen and (min-width: @tablet-breakpoint-hallon) {
                position: absolute;
                display: block;
                left: 51%;
                z-index: 6;
                bottom: 50px;
                margin-right: -50%;
                -ms-transform: translate(-50%, -50%); /* IE 9 */
                -webkit-transform: translate(-50%, -50%); /* Safari */
                transform: translate(-50%, -50%);
                color: @hallon-light-black;


                span:first-child {
                    font-size: @ftsize-hallon-48;
                    font-family: @hallon-frutiger-77-bc;
                }

                span {
                    font-family: @hallon-frutiger-55-roman;
                    display: block;
                    line-height: 1;
                }
            }
        }
    }
}
