.toggle-subscription {
    background: @hallon-light-red;
    background: -webkit-linear-gradient(@linear-gradient-pink);
    background: -o-linear-gradient(@linear-gradient-pink);
    background: -moz-linear-gradient(@linear-gradient-pink);
    background: linear-gradient(@linear-gradient-pink);
    width: 100%;
    //padding-left:15px;
    //padding-right:15px;
    padding-bottom: 40px;
    padding-top: 20px;
    float: left;

    .toggle-subscription__slider {
        .hallon-slider__labels {
            display: none;
        }
    }


    .toggle-subscription__container {
        margin: 0 auto;
        text-align: center;
        vertical-align: middle;
        position: relative;

        h2:first-child {
            margin-right: 8px;
            color: @hallon-white;
        }

        h2:last-child {
            margin-left: 8px;
            color: @hallon-white;
        }

        h2 {
            text-transform: uppercase;
            font-family: @hallon-frutiger-77-bc;
            font-size: @ftsize-hallon-24;
            display: inline-block;
            vertical-align: bottom;
            text-align: center;
            margin-bottom: 0px;
            line-height: 104px;
        }
    }


    .toggle-subscription__selector {
        display: inline-block;
        width: 150px;

        @media only screen and (min-width: @phone-breakpoint-hallon) {
            //width:300px;
            display: inline-block;
        }
    }

    .toggle-subscription__info {
        position: relative;
        clear: both;

        .toggle-subscription__line {
            display: block;
            width: 2px;
            height: 265px;
            position: absolute;
            top: 0%;
            left: 50%;
            margin-right: -50%;
            -ms-transform: translate(-50%, 0%);
            -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
            background-color: #fff;
            /*
      height:265px;

      border:1px solid @hallon-white;
      width:1px;
      position: absolute;
      z-index: 1;
      top:145px;
      left: 50%;
      margin-right: -50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); */
        }


        .toggle-subscription__uspcontainer {


            a {
                margin: 0 auto;
                display: block;
                margin-bottom: 1em;
                margin-top: 1em;
            }
        }

        .toggle-subscription__info-container {
            width: 50%;
            float: left;
            //display: inline-block;
            text-align: center;
        }
    }


    .hallon-btn {
        display: inline-block;
        margin-bottom: 1em;
    }

    .toggle-subscription__data-slider {
        display: none;
    }

    .link-right-arrow {
        color: @hallon-white;
    }

    .toggle-subscription__btn {
        margin-top: -0.5em;
        display: block;
    }

    .bubble {
        display: block;
    }
}

.toggle-subscription.toggle-subscription--first {

    .toggle-subscription__container {
        h2:first-child {
            color: @hallon-white;
        }

        h2:last-child {
            color: @hallon-light-grey;
        }
    }

    .toggle-subscription__uspcontainer {
        a {
            display: inline-block;
            margin-right: 0.5em;
            margin-left: 0.5em;
        }
    }

    .toggle-subscription__info-container--first {
        display: block;
        width: 100%;
    }

    .toggle-subscription__info-container--second {
        display: none;
    }

    .toggle-subscription__btn {
        margin-top: 0em;
        display: block;
    }



    .toggle-subscription__info {
        .toggle-subscription__line {
            display: none;
        }
    }

    .toggle-subscription__data-slider {
        display: block;
        margin-top: 60px;
        padding-left: 2em;
        padding-right: 2em;
    }

    .bubble {
        display: none;
    }
}

.toggle-subscription.toggle-subscription--first.toggle-subscription--single {

    .bubble {
        display: block;
    }

    .toggle-subscription__btn {
        margin-top: -0.5em;
    }
}

.toggle-subscription.toggle-subscription--second {

    .toggle-subscription__container {
        h2:first-child {
            color: @hallon-light-grey;
        }

        h2:last-child {
            color: @hallon-white;
        }
    }

    .toggle-subscription__uspcontainer {
        a {
            display: inline-block;
            margin-right: 0.5em;
            margin-left: 0.5em;
        }
    }

    .toggle-subscription__info-container--second {
        display: block;
        width: 100%;
    }

    .toggle-subscription__info-container--first {
        display: none;
    }

    .toggle-subscription__btn {
        display: block;
    }

    .toggle-subscription__info {
        .toggle-subscription__line {
            display: none;
        }
    }

    .bubble {
        display: block;
    }
}

.toggle-subscription.toggle-subscription--hw {

    .hallon-slider__bubble-container {
        display: none;
    }
}
