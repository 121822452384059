/// <reference path="../../framework.less" />

.checkout {
    padding: 50px 50px;

    @media @breakpointquery-2018-tablet {
        padding: 50px 20px;
    }

    &--b2b {
        padding: 0 50px 50px 50px;

        @media @breakpointquery-2018-tablet {
            padding: 0 20px 50px 20px;
        }
    }

    &__whitebox-header-above {
        font-family: @font-2018-bold;
        font-size: 22px;
        line-height: 31px;
        color: @color-2018-text-black;
        text-transform: none;
        margin: 40px 20px 20px 40px;

        @media @breakpointquery-2018-tablet {
            margin: 40px 20px 20px 20px;
        }

        &--sidebar {
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    &__whitebox-step {
        font-family: @font-2018-roman;
        line-height: 22px;
        font-size: 16px;
        color: #646464;
        margin-left: 30px;
    }

    &__whitebox-wrapper {
        padding: 20px;
        background: @color-2018-white;
        margin: 0 20px 20px;
        border-radius: 8px;

        @media @breakpointquery-2018-tablet {
            padding: 20px 20px 0 20px;
            margin: 0 0 20px 0;
        }

        &--user-info {
            @media @breakpointquery-2018-tablet {
                padding: 20px 20px 20px 20px;
            }
        }
    }

    &__whitebox-inner-header {
        font-family: @font-2018-bold;
        color: @color-2018-text-black;
        margin: 0 20px 0 0;
        text-transform: none;

        &--red {
            color: @color-2018-hallon-red;
        }

        @media @breakpointquery-2018-tablet {
            margin: 0;
        }
    }

    &__content {
        width: 100%;
        max-width: calc(@breakpoint-2018-desktop - 80px);
        margin: 0 auto;
    }

    &__container {
        &:not(.checkout__container-noflex) {
            display: flex;
            flex-direction: row;
        }

        @media @breakpointquery-2018-tablet {
            &:not(.checkout__container-noflex) {
                display: block;
                width: 100%;
            }
        }
    }

    &__customer-wrapper {
        flex: 1;
        align-self: flex-start;
        flex-basis: 60%;

        @media @breakpointquery-2018-tablet {
            flex-basis: 100%;
        }
    }


    &__subscription-information {
        h2,
        h3,
        a {
            color: @color-2018-hallon-red;
            text-transform: none;
        }

        h2 {
            color: @color-2018-hallon-red;
        }

        h2, h3 {
            font-family: @font-2018-bold;
        }

        .checkout__customize-title {
            color: @color-2018-text-black;
            margin-bottom: 30px;
        }
    }

    &__customer-payment {
        padding-bottom: 20px;
        margin: 0 0 15px 0;
    }

    &__already-customer-header-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;

        &--b2b {
            display: none;

            @media @breakpointquery-2018-tablet {
                display: flex;
            }
        }
    }

    &__already-customer-b2b {
        width: 100%;
        text-align: right;
    }

    &__already-customer-link {
        font-family: @font-2018-bold;
        font-size: 16px;

        &--b2b-above {
            @media @breakpointquery-2018-tablet {
                display: none;
            }
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__form-error {
        color: @color-2018-error-red;
        margin-bottom: 20px;
    }

    &__form-personnumber.authed {
        margin-top: 56px;
    }

    &__radio-wrapper-header {
        margin: 0 8px;
        display: inline-block;
        margin-bottom: 10px;
    }

    &__radio-inner-wrapper {
        position: relative;
        margin: 14px 7px;

        > .checkout__input-error-message {
            position: absolute;
            bottom: -30px;
            font-size: 11px;
            left: 0px;
            height: 22px;
            line-height: 11px;
            color: @color-2018-error-red;
            padding: 5px 5px 5px 5px;
            border-top: 1px solid @color-2018-error-red;
            width: 100%;
        }
    }

    &__radio-wrapper {
        margin: 48px -8px -24px -8px;

        &--porting-type {
            margin: 21px -7px -21px -7px;
        }

        &-li {
            line-height: 3;
            margin-bottom: 16px;

            &--error {
                line-height: 1;
            }

            &--disabled {
                opacity: 0.3;
            }
        }

        .checkout__radio-wrapper-input {
            display: none;
        }

        .checkout__radio-wrapper-input:checked + label::before {
            border-color: @color-2018-linkblue;
        }

        .checkout__radio-wrapper-input + label {
            display: flex;
            cursor: pointer;
            line-height: 27px;

            > span {
                top: -1.5px;
                position: relative;
            }
        }

        .checkout__radio-wrapper-number {
            > span {
                display: flex;
                flex-wrap: wrap;

                span {
                    padding-right: 10px;

                    @media @breakpointquery-2018-tablet {
                        padding: 0;
                    }
                }

                @media @breakpointquery-2018-tablet {
                    display: inline-block;
                }
            }
        }

        .checkout__radio-wrapper-input--inner + label {
            margin: 0 -8px;
        }

        .checkout__radio-wrapper-number-owner-b2b {
            display: flex;
            flex-direction: row;

            @media @breakpointquery-2018-mobile {
                flex-direction: column;
            }
        }

        .checkout__radio-wrapper-input + label::before {
            content: '';
            width: 8px;
            height: 8px;
            background: @color-2018-white;
            margin: 2px 8px 0 8px;
            display: inline-block;
            border-radius: 50%;
            border: 6px solid @color-2018-light-grey;
            flex: 0 0 auto;
        }
    }

    &__porting-time-header {
        margin: 28px 0 0 0;
    }

    &__porting-time-text-wrapper {
        margin: 0 7px;
        padding: 14px 0 28px 0;
        display: flex;
        align-items: center;

        @media @breakpointquery-2018-tablet {
            padding-bottom: 20px;
        }
    }

    &__porting-time-icon {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        position: absolute;
        left: 0;
        top: -3px;
        transform: translateY(50%);
        pointer-events: none;
    }

    &__porting-time-icon-red {
        fill: @color-2018-hallon-red;
    }

    &__porting-time-icon-white {
        stroke: @color-2018-white;
        stroke-miterlimit: 10;
    }

    &__porting-time-text {
        margin-left: 10px;
        font-size: 15px;
    }

    &__porting-info-text {
        padding: 0;
        font-size: 16px;
        line-height: 19px;
        max-width: 500px;
        margin: 0;
    }

    &__porting-read-more-link {
        cursor: pointer;
        left: 10px;
        position: relative;
        font-size: 14px;

        @media @breakpointquery-2018-tablet {
            width: 100%;
            padding: 0;
            margin-top: 5px;
            top: 0;
            display: block;
        }

        &:hover {
            text-decoration: underline;
        }
    }
    /*&__choose-payment-method-sub-title {
        margin-top: 2em;
    }*/
    &__payment-method-ul {
        margin: 16px 0 32px 0;

        &--error {
            margin: 16px 0 32px 0;
        }
    }

    &__choose-payment-method {
        margin: 0 -7px 0 -7px;
    }

    &__choose-payment-method-error-text {
        font-size: 11px;
        display: block;
        width: 100%;
        color: @color-2018-error-red;
        font-family: @font-2018-roman;
    }


    &__choose-payment-method-small-text {
        font-family: @font-2018-roman;
        font-size: 12px;
        line-height: 16px;
    }


    &__choose-payment-method-text-separator {
        height: 1px;
        background: #f2f2f2;
        margin: 28px -28px;
        position: relative;
    }

    &__customer-name-wrapper {
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
    }

    &__customer-name {

    }

    &__choose-phonenumber {
        position: relative;

        i {
            position: absolute;
            bottom: 14px;
            right: 7px;
            color: @color-2018-heavy-grey;
            z-index: 0;
        }

        h3 {
            font-family: @font-2018-bold;
            text-transform: none;
        }
    }

    &__account-switcher {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        position: relative;

        @media @breakpointquery-2018-phablet {
            top: -10px;
        }
    }

    &__account-switcher-icon {
        width: 19px;
        height: 19px;
        margin-right: 10px;

        @media @breakpointquery-2018-phablet {
            width: 16px;
            height: 16px;
        }
    }

    &__account-switcher-icon-path {
        fill: @color-2018-hallon-red;
    }

    &__account-switcher-text {
        font-family: @font-2018-bold;
        font-size: 16px;
        line-height: 18px;
    }

    &__show-password {
        position: absolute;
        top: 14px;
        right: 5px;
        font-size: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: @color-2018-heavy-grey;

        @media @breakpointquery-2018-phablet {
            top: -18px;
            font-size: 11px;
        }
    }

    &__plus-separator {
        height: 1px;
        background: @color-2018-background-grey;
        margin: 42px -28px;
        position: relative;

        &::before {
            content: '+';
            color: @color-2018-white;
            font-size: 38px;
            line-height: 1.85;
            top: -38px;
            left: 35px;
            z-index: 2;
            position: absolute;
        }

        &::after {
            content: '';
            background: @color-2018-background-grey;
            width: 36px;
            height: 36px;
            font-size: 42px;
            border-radius: 50%;
            top: -18px;
            left: 28px;
            z-index: 1;
            position: absolute;
        }
    }

    &__price-title-wrapper, &__parent-sub-title-wrapper {
        margin: 20px;
        margin-left: 0;
        flex: 1;

        @media @breakpointquery-2018-small-desktop {
            flex-basis: 100%;
        }

        @media @breakpointquery-2018-large-tablet {
            flex-basis: auto;
        }

        @media @breakpointquery-2018-phablet {
            flex-basis: 100%;
        }

        &--b2b {
            margin: 0 20px 0 0;
        }
    }

    &__price-title-span {
        display: inline-block;
    }

    &__price-title-wrapper-header {
        color: @color-2018-white;
    }

    &__price-unit-strikethrough {
        font-size: 26px;
        font-family: @hallon-frutiger-55-roman;
        text-decoration: line-through;
    }

    &__price-unit {
        font-size: 35px;
        font-family: @font-2018-bold;
    }

    &__price-unit-multi {
        font-size: 19px;
        font-family: @font-2018-bold;
        color: #832440;
    }

    &__price-unit-wrapper {
        display: flex;
        flex-direction: column;
        text-align: right;
        align-items: stretch;
        justify-content: center;


        @media @breakpointquery-2018-small-desktop {
            flex-basis: 100%;
            text-align: left;
        }

        @media @breakpointquery-2018-large-tablet {
            flex-basis: auto;
            text-align: left;
        }

        @media @breakpointquery-2018-tablet {
            flex-basis: auto;
            text-align: right;
        }

        @media @breakpointquery-2018-phablet {
            flex-basis: 100%;
            text-align: left;
        }
    }

    &__price-unit-campaign-info-wrapper {
        font-size: 12px;
        font-family: @font-2018-bold;
        line-height: 16px;
    }

    &__price-unit-campaign-info {
        display: block;
    }

    &__summary-separator {
        height: 1px;
        background: @color-2018-background-grey;
        margin: 28px -28px;
        position: relative;
    }

    &__box-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 28px 0;

        &--contrasted {
            background: @color-2018-hallon-red;
            margin: 20px -28px -28px -28px;
            color: @color-2018-white;
            padding: 35px 28px 28px 28px;

            h3 {
                color: @color-2018-white;
            }

            .checkout__box-wrapper-item {
                h3 {
                    color: @color-2018-white;
                }
            }
        }
    }

    &__box-wrapper-item {
        flex: 1;

        @media @breakpointquery-2018-phablet {
            flex-basis: 100%;
            margin-bottom: 20px;
        }
    }

    &__group {
        position: relative;
        margin: 40px 0 15px 0;
        padding: 0;
        display: inline-block;
        width: 100%;
        font-size: 14px;

        &--inside-radio {
            margin: 40px 0 15px 0;
            font-size: 14px;
        }

        &--inner {
            position: relative;
        }

        @media @breakpointquery-2018-phablet {
            margin: 40px 0 20px 0;
        }

        &--no-margin-top {
            margin: 0 0 14px 0;

            @media @breakpointquery-2018-phablet {
                margin: 0 0 20px 0;
            }
        }

        &--submit {
            margin: 0;

            @media @breakpointquery-2018-phablet {
                margin: 0;
            }
        }

        &--gift-code {
            margin: 14px 0;

            @media @breakpointquery-2018-phablet {
                margin: 14px 0;
            }
        }

        &--calendar {
            margin: 14px 0 14px 0;
        }

        &.hallon-btn--loading::after {
            border-right-color: rgba(0, 0, 0, .5);
            border-bottom-color: rgba(0, 0, 0, .5);
        }

        input,
        select {
            font-size: 16px;
            padding: 10px 10px 10px 5px;
            display: block;
            width: 100%;
            border: none;
            border-bottom: 1px solid @color-2018-light-grey;
            background: transparent;
            color: @color-2018-heavy-grey;
            font-family: @font-2018-roman;
            transition: background 0.2s linear;
            outline: none;
            -webkit-appearance: none;
            border-radius: 0;
            margin: 0;

            @media @breakpointquery-2018-phablet {
                font-size: 14px;
            }

            &.checkout__porting-time-input {
                padding-left: 30px;
            }

            &::-ms-expand, &::-ms-reveal, &::-ms-clear {
                display: none;
            }

            &:focus {
                outline: none;
                border: none;
                border-bottom: 1px solid transparent;
            }

            &.valid:not(.placeholder-shown) {
                border-bottom-color: @color-2018-green;
                color: @color-2018-green;
            }

            &.error {
                border-bottom-color: @color-2018-error-red;
            }

            &.valid:not(.error):not(.placeholder-shown) {
                color: @color-2018-green;

                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: @color-2018-green;
                }

                &::-moz-placeholder { /* Firefox 19+ */
                    color: @color-2018-green;
                }

                &:-ms-input-placeholder { /* IE 10+ */
                    color: @color-2018-green;
                }

                &:-moz-placeholder { /* Firefox 18- */
                    color: @color-2018-green;
                }
            }
        }


        select {
            position: relative;
            z-index: 1;
            transition: border 0.2s linear;
            cursor: pointer;

            &:focus, &:hover {
                outline: none;
                border: none;
                border-bottom: 1px solid @color-2018-heavy-grey;
            }

            &.valid:not(.error) {
                color: @color-2018-heavy-grey;

                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: @color-2018-heavy-grey;
                }

                &::-moz-placeholder { /* Firefox 19+ */
                    color: @color-2018-heavy-grey;
                }

                &:-ms-input-placeholder { /* IE 10+ */
                    color: @color-2018-heavy-grey;
                }

                &:-moz-placeholder { /* Firefox 18- */
                    color: @color-2018-heavy-grey;
                }
            }
        }


        input:focus,
        input:disabled,
        input:not(.placeholder-shown):not(.error) {
            ~ .checkout__animated-label {
                top: -18px;
                font-size: 11px;
                color: @color-2018-heavy-grey;
                transition: 0.2s all;
                opacity: 1;

                &:after {
                    visibility: visible;
                    width: 100%;
                    left: -5px;
                }

                &.error {

                    &:after {
                        background-color: @color-2018-error-red;
                    }
                }

                &.valid:not(.placeholder-shown) {
                    color: @color-2018-green;

                    &:after {
                        background-color: @color-2018-green;
                    }
                }
            }
        }

        .placeholder-shown.error {

            + .checkout__animated-label.error {
                top: 20px;
                opacity: 0;
            }
        }

        input.valid:not(.error):not(.placeholder-shown) {
            ~ .checkout__animated-label {
                top: -18px;
                font-size: 11px;
                color: @color-2018-green;
                transition: 0.2s all;
                opacity: 1;

                &:after {
                    background-color: @color-2018-green;
                }
            }

            &:focus {
                color: @color-2018-green;

                ~ .checkout__animated-label {
                    color: @color-2018-green;

                    &:after {
                        background-color: @color-2018-green;
                        background: @color-2018-green;
                    }
                }
            }
        }

        input:disabled {
            ~ .checkout__animated-label {
                &:after {
                    background-color: @color-2018-light-grey;
                    background: @color-2018-light-grey;
                }
            }
        }

        input:focus,
        select:focus {
            &:after {
                width: 100%;
            }
        }

        .checkout__animated-label {
            position: absolute;
            top: 20px;
            left: 5px;
            bottom: 0;
            z-index: 2;
            width: 100%;
            transition: 0.2s ease all;
            margin: 0;
            color: @color-2018-heavy-grey;
            font-size: 14px;
            pointer-events: none;
            opacity: 0;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            &.error {
                top: -18px;
                font-size: 11px;
                transition: 0.2s all;
                opacity: 1;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                height: 1px;
                width: 10px;
                visibility: hidden;
                background-color: @color-2018-hallon-red;
                transition: 0.2s ease all;
            }
        }

        .checkout__input-error-message {
            position: absolute;
            bottom: -26px;
            font-size: 11px;
            left: 5px;
            height: 22px;
            line-height: 11px;
            color: @color-2018-error-red;

            a {
                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    &__group-title {
        color: @color-2018-text-black;
        font-size: 16px;
        font-family: @font-2018-bold;
        padding: 0;
        line-height: 19px;
        margin: 0 0 10px 0;
        font-weight: normal;
        text-transform: none;

        &--flex {
            display: flex;
            align-items: center;

            @media @breakpointquery-2018-phablet {
                display: block;
            }
        }
    }

    &__group-small-title {
        color: #707070;
        font-size: 12px;
        font-family: @font-2018-roman;
        padding: 0;
        line-height: 14px;
        margin: 0 0 10px 0;
        font-weight: normal;
        text-transform: none;
    }

    &__purchase-information {
        background: @color-2018-white;
        color: @color-2018-text-black;
        overflow: hidden;
        padding: 20px 28px;
        margin-bottom: 20px;
        border-radius: 8px;

        @media @breakpointquery-2018-phablet {
            padding: 20px;
            margin-top: 20px;
        }

        h2,
        h3,
        h2 {
            font-family: @font-2018-bold;
            text-transform: none;
        }
    }

    &__purchase-information-header3 {
        margin: 0 0 5px;
    }

    &__purchase-information-header {
        color: @color-2018-text-black;
        font-family: @font-2018-bold;
        text-transform: none;
        flex: 1 1 auto;
    }

    &__purchase-information-text {
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        flex: 1 1 auto;
    }


    &__giftcode-text {
        p {
            text-align: left;
        }

        h3 {
            text-align: left;
        }
    }

    &__giftcode-error {
        display: inline-block;
        float: right;
        margin-bottom: 0;
        font-size: 12px;

        p {
            display: inline-block;
            float: right;
            margin: 0;
            font-size: 12px;
            color: @color-2018-hallon-red;
        }
    }

    &__giftcode {
        border-bottom: 1px solid @color-2018-background-grey;

        &:focus {
            border-bottom: 1px solid transparent;
        }
    }

    &__giftcode-submit, &__giftcode-link {
        border-radius: 32px;
        padding: 8px 16px;
        font-size: 16px;
        display: block;
        text-transform: none;
        width: auto;
        border: 2px solid@color-2018-hallon-red;
        background: @color-2018-hallon-red;
        color: @color-2018-white;
        -webkit-appearance: none;
        outline: none;
        transition: 0.2s all linear;

        &:hover, &:focus {
            color: @color-2018-hallon-red;
            background: @color-2018-white;
        }

        &.hallon-btn--disabled {
            background-color: #B0A7A1;
            transition: 0.2s all linear;
            pointer-events: none;
            color: @color-2018-white;
            cursor: default;
            border: 2px solid #B0A7A1;
        }
    }


    &__giftcard-field-error {
        margin-top: 28px;
        font-family: @font-2018-bold;
        color: @color-2018-hallon-red;
        display: block;
    }

    &__pay-now-toggle {
        color: @color-2018-white;
        margin-top: 28px;
        display: flex;
        align-items: baseline;
        flex-basis: 100%;
        cursor: pointer;

        &:Hover {
            color: @color-2018-white;
        }

        svg {
            width: 20px;
            height: 20px;
            align-self: flex-start;
            margin-top: -2px;
            flex: 0 0 auto;
        }

        span {
            padding: 0 14px;

            @media @breakpointquery-2018-phablet {
                padding: 0 8px;
            }
        }

        i {
            padding: 0;
        }

        img {
            height: 1.75em;
            width: 1.75em;
            margin-right: 1em;
            margin-top: -.1em;
        }
    }

    &__pay-now-info {
        display: none;

        p {
            font-size: 14px;
            margin-top: 14px;
        }
    }

    &__notice-text {
        font-size: 14px;
    }

    &__add-subscription-information {
        background: @color-2018-white;
        margin: 0 20px 20px;
        border-radius: 8px;
        font-size: 16px;

        @media @breakpointquery-2018-tablet {
            margin: 40px 0 20px 0;
            padding: 0 20px;
        }
    }

    &__add-subscription {
        display: flex;
        cursor: pointer;
        align-items: center;
        outline: none;
        -webkit-appearance: none;
        background: transparent;
        border-radius: 0;
        border: 0;
        width: 100%;
        margin: 0;
        padding: 0;


        &.hallon-dropdown-toggle--active {
            .checkout__add-subscription-plus-icon {
                transform: rotate(180deg);
            }
        }

        @media @breakpointquery-2018-tablet {
            width: calc(~"100% + 40px");
            position: relative;
            left: -20px;
            align-items: stretch;
        }
    }

    &__add-subscription-title {
        margin: 0;
        flex: 1 1 auto;
        padding: 0 20px;
        text-align: left;
        color: @color-2018-hallon-red;
        line-height: 31px;
        font-size: 22px;
        font-family: @font-2018-bold;
        text-transform: none;
        display: flex;
        align-items: center;

        @media @breakpointquery-2018-tablet {
            line-height: 24px;
            font-size: 20px;
            padding: 26px 20px;
        }
    }

    &__add-subscription-icon-wrapper {
        flex: 0 0 auto;
        height: 69px;
        width: 69px;
        background-color: @color-2018-hallon-red;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 8px 8px 0;

        @media @breakpointquery-2018-tablet {
            height: auto;
            width: 60px;
        }
    }

    &__add-subscription-plus-icon {
        width: 18px;
        height: 18px;
        position: relative;
        transition: 0.1s linear transform;
        transform: rotate(0);

        path {
            stroke: @color-2018-white;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 3px;
            fill: none;
        }
    }

    &__add-subscription-header {
        margin: 0;
        padding: 0;
    }

    &__add-subscription-max-ten-notification {
        display: none;
        font-family: @font-2018-roman;
        font-size: 14px;
        line-height: 21px;
        padding: 0 0 14px 20px;

        &--show {
            display: block;
        }
    }

    &__add-subscription-elements {

        &--disabled {
            color: #D1D1D1;

            path {
                fill: none;
                stroke: #D1D1D1;
                stroke-width: 3px;
            }
        }
    }

    &__subscription-title-wrapper {
        display: flex;
        align-items: center;

        @media @breakpointquery-2018-tablet {
            align-items: flex-start;
        }
    }

    &__subscription-title {
        flex: 0 0 auto;
        padding-right: 40px;
        margin: 0;
        color: @color-2018-hallon-red;
        text-transform: none;
        font-family: @font-2018-bold;
        flex: 0 1 auto;

        @media @breakpointquery-2018-tablet {
            word-break: break-word;
            padding-right: 10px;
            font-size: 20px;
            line-height: 24px;
            margin-top: -4px;
        }
    }


    &__subscription-title-number {
        background-color: @color-2018-hallon-red;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        font-size: 18px;
        color: @color-2018-white;
        line-height: 30px;
        font-family: @font-2018-bold;
        margin-right: 20px;
        flex: 0 0 auto;

        @media @breakpointquery-2018-tablet {
            width: 26px;
            height: 26px;
            line-height: 26px;
            margin-right: 12px;
            font-size: 16px;
        }
    }

    &__change-subscription-wrapper {
        display: inline-flex;
        margin: 20px 0;
        flex: 1 1 auto;

        @media @breakpointquery-2018-tablet {
            display: none;
        }

        &--mobile {
            display: none;

            @media @breakpointquery-2018-tablet {
                display: flex;
                width: calc(~"100% + 40px");
                margin: 20px 0 0 -20px;
                border-radius: 0 0 10px 10px;
                overflow: hidden;
                position: relative;
            }
        }
    }

    &__remove-subscription {
        cursor: pointer;

        @media @breakpointquery-2018-tablet {
            display: none !important;
        }
    }

    &__change-subscription-link {
        cursor: pointer;
        color: @color-2018-hallon-red;
        font-size: 14px;
        line-height: 21px;
        display: block;

        &:hover {
            text-decoration: underline;
        }

        @media @breakpointquery-2018-tablet {
            display: none;
        }
    }

    &__change-subscription-button {
        cursor: pointer;
        color: @color-2018-white;
        background-color: @color-2018-linkblue;
        font-size: 14px;
        line-height: 21px;
        font-family: @font-2018-roman;
        flex: 1 1 auto;
        outline: none;
        -webkit-appearance: none;
        border: none;
        padding: 14px;
        border-radius: 50px;
        min-width: 200px;
        margin: 0;

        @media @breakpointquery-2018-tablet {
            min-width: 0;
            border-radius: 0;
            flex-basis: 50%;
            font-size: 16px;
            margin: 0;
        }

        &--change {
            display: none;

            @media @breakpointquery-2018-tablet {
                display: block;
            }
        }

        &:hover {
            text-decoration: none;
        }

        &--remove {
            display: none;

            @media @breakpointquery-2018-tablet {

                &::before {
                    content: "";
                    width: 1px;
                    background-color: @color-2018-white;
                    height: 60%;
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 20%;
                    opacity: 0.3;
                }
            }
        }
    }

    &__delete-voucher-code-icon {
        cursor: pointer;
        width: 11px;
        height: 11px;

        path {
            fill: none;
            stroke: #832440;
            stroke-linecap: round;
            stroke-width: 1px;
        }
    }

    &__your-order-header-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
    }

    &__price-summary-box {
        width: 100%;
    }

    &__price-text-paragraf {
        display: flex;
        align-items: baseline;
    }

    &__price-text-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
    }

    &__price-vat-text {
        flex-basis: 100%;
        text-align: right;
    }

    &__price-small-text {
        font-size: 12px;
        font-family: @font-2018-roman;
        line-height: 16px;
    }

    &__price-delimiter {
        border: 1px solid white;
        margin-top: 35px;
        margin-bottom: 35px;
    }

    &__price-big-amount {
        font-size: 28px;
        font-family: @font-2018-bold;
        line-height: 40px;
    }

    &__price-medium-amount {
        font-size: 14px;
        font-family: @font-2018-bold;
        line-height: 16px;
    }

    &__price-cost-during-campaign {
        flex: 1 1 auto;
    }

    &__price-campaign-price {
        margin-bottom: 23px;
    }

    &__price-pay-little-text {
    }

    &__price-voucher-code-wrapper {
    }

    &__price-voucher-code {
        font-size: 12px;
        font-family: @font-2018-roman;
        line-height: 16px;
        color: @color-2018-hallon-red;

        &--breakline {
            display: block;
        }
    }
}



.checkout {
    &__fixed-number {
        background: @color-2018-white;
        margin: 0 20px 20px 20px;
        border-radius: 8px;
        font-size: 16px;

        @media @breakpointquery-2018-tablet {
            margin: 32px 0 16px 0;
            padding: 0 20px;
        }
    }

    &__fixed-number-toggle {
        display: flex;
        cursor: pointer;
        align-items: center;
        outline: none;
        -webkit-appearance: none;
        background: transparent;
        border-radius: 0;
        border: 0;
        width: 100%;
        margin: 0;
        padding: 0;

        &--disabled {
            .checkout__fixed-number-toggle-title {
                color: @color-2018-A5-grey;
            }

            .checkout__fixed-number-toggle-icon-wrapper {
                opacity: 0.3;
            }
        }

        @media @breakpointquery-2018-tablet {
            width: calc(~"100% + 40px");
            position: relative;
            left: -20px;
            align-items: stretch;
        }
    }
    &__fixed-number-toggle-title-wrapper{
        display:flex;
        align-items:center;
    }

    &__fixed-number-toggle-title {
        margin: 0;
        flex: 1 1 auto;
        padding: 0 24px;
        text-align: left;
        color: @color-2018-hallon-red;
        line-height: 31px;
        font-size: 22px;
        font-family: @font-2018-bold;
        text-transform: none;
        display: flex;
        align-items: center;

        @media @breakpointquery-2018-tablet {
            line-height: 24px;
            font-size: 20px;
            padding: 26px 20px;
        }
    }

    &__fixed-number-toggle-icon-wrapper {
        flex: 0 0 auto;
        height: 69px;
        width: 69px;
        background-color: @color-2018-hallon-red;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 8px 8px 0;

        @media @breakpointquery-2018-tablet {
            height: auto;
            width: 60px;
        }
    }

    &__fixed-number-toggle-icon {
        width: 18px;
        height: 18px;
        position: relative;
        transition: 0.1s linear transform;
        transform: rotate(0);

        path {
            stroke: @color-2018-white;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 3px;
            fill: none;
        }
    }

    &__fixed-number-card {
        padding: 20px;
        background: @color-2018-white;
        border-radius: 8px;

        @media @breakpointquery-2018-tablet {
            padding: 24px 0 0 0;
            position: relative;
        }
    }

    &__fixed-number-card-subscription-title-wrapper {
        display: flex;
        align-items: center;
        min-height: 53px;

        @media @breakpointquery-2018-tablet {
            align-items: flex-start;
            min-height: 0;
        }
    }

    &__fixed-number-card-subscription-title {
        padding-right: 40px;
        margin: 0;
        color: @color-2018-hallon-red;
        text-transform: none;
        font-family: @font-2018-bold;
        flex: 1 1 auto;
        font-size: 22px;
        line-height: 31px;
        display: flex;
        align-items: center;

        @media @breakpointquery-2018-tablet {
            word-break: break-word;
            padding-right: 0;
            font-size: 20px;
            line-height: 24px;
            margin-top: -4px;
            display: block;
        }
    }

    &__fixed-number-card-remove-subscription {
        background-color: transparent;
        -webkit-appearance: none;
        border: none;
        cursor: pointer;

        @media @breakpointquery-2018-tablet {
            display: none !important;
        }
    }

    &__fixed-number-dropdown-toggle {
        font-size: 16px;
        padding: 10px 10px 10px 5px;
        display: block;
        width: 100%;
        border: none;
        border-bottom: 1px solid @color-2018-light-grey;
        background: transparent;
        color: @color-2018-heavy-grey;
        font-family: @font-2018-roman;
        transition: border 0.2s linear;
        outline: none;
        -webkit-appearance: none;
        border-radius: 0;
        margin: 0;
        text-align: left;
        cursor: pointer;

        &--valid {
            border-bottom-color: @color-2018-green;
            color: @color-2018-green;
        }

        &:focus, &:hover {
            border-bottom-color: @color-2018-background-dark-grey;
        }
    }

    &__fixed-number-subscription-dropdown {
        .hallon-dropdown__list-item-link {
            @media @breakpointquery-2018-tablet {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: flex-start;
            }
        }

        .checkout__subscription-dropdown-title-wrapper {
            @media @breakpointquery-2018-tablet {
                display: flex;
                flex-direction: column;
                text-align: left;
            }
        }

        .hallon-dropdown__list-item-title {
            @media @breakpointquery-2018-tablet {
                padding: 0 0 0 0;
            }
        }
    }
}


.mobile-subscription__response .checkout__summary-gdpr--first {
	padding-bottom: 7px;
	padding-top: 14px;
}


.checkout {
    &__white-box-header {
        margin: 44px 0 22px 14px;
    }

    &__white-box-wrapper {
        padding: 20px 20px;
        background: @color-2018-white;
        margin: 0 20px 20px;
        border-radius: 8px;
    }

    &__white-box-link-wrapper {
        display: flex;
        align-items: baseline;
        padding: 0 20px 0 0;
    }
}
