﻿//  _____________________________________________________________________________
//
//  FONT SIZES
//  _____________________________________________________________________________

body { .font-size(1.4); }

h1, h2, h3, h4, h5, h6 {

	color: @tre-darkgray-color;
	line-height: 1.22em;
	margin: 0 0 @margin-XXS;
}



h1 {
	.fontsize-h1();
}

h2 {
	.fontsize-h2();
}

h3 {
	.fontsize-h3();
}

h4 {
	font-family: @tre-bold-font;
	.fontsize-h4();
	font-weight:normal;
}

h5 {
	font-family: @tre-bold-font;
	.fontsize-h5();
	font-weight:normal;
}

h6 {
	font-family: @tre-bold-font;
	.fontsize-h6();
	font-weight:normal;
}


p, input, label, ul, ol, dl {
	
	.font-size(1.6);
	
	// Debatable if changing all font-sizes adds any value. -LR
	// @media screen and ( max-width: @hw-palm) {

	// 	.font-size( 1.4 );
	// }
}


p.intro {

	color: @tre-darkgray-color;
	margin: 0 0 @margin-XXXS 0;
}

p.intro{
	
	.font-size(1.8);
	line-height: 24px;
}
// Header-type paragraphs which are used for prices
p.large {

	.font-size(6.5);
	color: @tre-darkgray-color;
	font-family: @tre-black-font;
	text-transform: uppercase;
	letter-spacing: -1px;
	
	@media only screen and (max-width: 900px) {
	
		.font-size(5.5);
	}
}

header, article a.box { font-size: 20px; font-size: 2rem;}

// Responsive box headers
//
h2.boxheader {

	.font-size( 3.2 ); 
	
	@media screen and ( max-width: @hw-desktop) {

		.font-size( 2.8 ); 
	}

	@media screen and ( max-width: @hw-tablet) {

		.font-size( 2.2 ); 
	}

	@media screen and ( max-width: @hw-palm) {

		.font-size( 2 );
	}
}

h3.boxheader {

	.font-size( 2.4 ); 
	
	@media screen and ( max-width: @hw-desktop) {

		.font-size( 2.2 ); 
	}

	@media screen and ( max-width: @hw-tablet) {

		.font-size( 2.0 ); 
	}

	@media screen and ( max-width: @hw-palm) {

		.font-size( 1.8 );
	}
}


//HALLON REDESIGN

p{
	font-family: @hallon-frutiger-55-roman;
}

h1, h2, h3, h4 {

	font-family: @hallon-frutiger-77-bc;
	text-transform:uppercase;
	font-weight:normal; // Prevents extra bold rendering
}

