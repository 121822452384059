/// <reference path="../framework.less" />

.product-card {
	padding: 60px 4px 20px 4px;
	width: 100%;
	max-width: 241.6px;
	flex: auto;
	margin-bottom: 40px;

	&--enhanced {
		padding: 0px 4px 20px 4px;

		@media @breakpointquery-2018-mobile {
			margin: 60px 0 40px 0;
		}
	}

	@media @breakpointquery-2018-mobile {
		max-width: 100%;
	}

	&__item {
		border-radius: 20px;
		background: @color-2018-white;
		padding: 20px 20px 30px 20px;
		text-align: center;
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&__price-wrapper {
		background: @color-2018-yellow;
		color: @color-2018-hallon-red;
		border-radius: 50%;
		width: 180px;
		height: 180px;
		margin: -50px auto 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);

		&--campaign {
			background: @color-2018-yellow;
			color: @color-2018-hallon-red;
		}

		&--B2B {
			background: @color-2018-white;
			color: @color-2018-hallon-red;
			border: 1px solid @color-2018-hallon-red
		}
	}

	&__amount {
		font-size: 44px;
		font-family: @font-2018-black;
		z-index: 1;
		margin-top: 13px;
	}

	&__price {
		font-size: 20px;
		font-family: @font-2018-black;
		margin-bottom: 5px;
	}

	&__price-info-wrapper {
		min-height: 26px;
	}

	&__price-info {
		font-size: 11px;
		font-family: @font-2018-bold;
		min-height: 12px;
		display: block;

		&--under {
			max-width: 150px;
			margin: auto;
		}
	}

	&__info-bubble {
		position: absolute;
		top: -15%;
		right: -15%;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		font-size: 17px;
		line-height: 20px;
		font-family: @font-2018-bold;
		color: @color-2018-hallon-red;
		background: @color-2018-yellow;
		display: flex;
		justify-content: center;
		align-items: center;

		&--promo {
			color: @color-2018-white;
			background: @color-2018-hallon-red;
		}

		&--strikethrough {
			text-decoration: line-through;
		}

		&--B2B {
			background: @color-2018-hallon-red;
			color: @color-2018-white;
			border: 1px solid @color-2018-white;
		}
	}


	&__usp-list {
		padding: 0;
		margin: 150px 0 0 0;
		list-style-type: none;
		flex: 1 1 auto;
		overflow: hidden;
	}

	&__usp-list-item {
		display: block;
		font-size: 14px;
		font-family: @font-2018-bold;

		&:first-child::before { // first item should not have a separator
			content: none;
		}

		&::before {
			content: "";
			border-bottom: 1px solid @color-2018-background-grey;
			width: 137px;
			display: block;
			margin: 0 auto 13px auto;
			padding: 14px 0 0 0;
		}
	}

	&__purchase-button {
		margin: 25px auto 5px auto;
		z-index: 1;
		position: relative;
		align-self: center;
	}

	&__bottom-icon-wrapper {
		background: @color-2018-white;
		width: 91px;
		height: 91px;
		border-radius: 50%;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -20px;
		z-index: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
	}

	&__bottom-icon {
		width: 33px;
		padding-bottom: 10px;
		flex-basis: 33px;
	}

	&__bottom-icon-icon {
		fill: @color-2018-hallon-red;
	}
}
	/*Special cases for when it's in modulemode*/
.product-card-standalone-module {

	.product-card {
		max-width: 340px;

		@media @breakpointquery-2018-phablet {
			max-width: 100%;
		}
	}

	.product-card__usp-list {
		margin-top: 210px;

		@media @breakpointquery-2018-phablet {
			margin-top: 150px;
		}
	}

	.product-card__price-wrapper {
		width: 240px;
		height: 240px;
		margin: -60px auto 0 auto;

		@media @breakpointquery-2018-phablet {
			width: 180px;
			height: 180px;
		}
	}

	.product-card__info-bubble {
		right: -40px;
		top: 6px;

		@media @breakpointquery-2018-phablet {
			right: -45px;
			top: 6px;
			width: 67px;
			height: 67px;
			font-size: 12px;
			line-height: 14px;
		}
	}

	.product-card__amount {
		font-size: 57px;
		line-height: 68px;
		z-index: 1;

		@media @breakpointquery-2018-phablet {
			font-size: 44px;
			line-height: 52px;
		}
	}

	.product-card__price {
		font-size: 35px;
		line-height: 42px;

		@media @breakpointquery-2018-phablet {
			font-size: 20px;
			line-height: 24px;
		}
	}

	&__price-info-wrapper {
		min-height: 26px;
	}

	.product-card__price-info {
		font-size: 12px;

		&--under {
			max-width: 200px;
			margin: auto;

			@media @breakpointquery-2018-phablet {
				max-width: 150px;
			}
		}

		@media @breakpointquery-2018-phablet {
			font-size: 11px;
		}
	}

	.product-card--enhanced {
		.product-card__price-wrapper {
			width: 250px;
			height: 250px;
			margin: -70px auto 0 auto;

			@media @breakpointquery-2018-phablet {
				width: 180px;
				height: 180px;
				margin: -60px auto 0 auto;
			}
		}
	}
}