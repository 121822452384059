﻿/********* IE-specific styles (ie8 mainly) ***************************************/

html.lte-IE8 {
	// @override: defining total widths as pixel value.
	// IE8 doesn't compute padding percentages in table cells but only strict pixel values

	[data-row~="equalheight"] {

		[data-column] {

			border-right: 0;
			border-left: 0;
		}

		> [data-column~="1/12"] {
			.column-equalheight-padding(1, @columns, @gridsystem-width);
		}
		> [data-column~="1/6"] {
			.column-equalheight-padding(2, @columns, @gridsystem-width);
		}
		> [data-column~="1/4"] {
			.column-equalheight-padding(3, @columns, @gridsystem-width);
		}
		> [data-column~="1/3"] {
			.column-equalheight-padding(4, @columns, @gridsystem-width);
		}
		> [data-column~="5/12"] {
			.column-equalheight-padding(5, @columns, @gridsystem-width);
		}
		> [data-column~="1/2"] {
			.column-equalheight-padding(6, @columns, @gridsystem-width);
			&[data-area~="padded"] {

				// @sidemargin: @total-width*( @gutter-width / @gridsystem-width ); // Double up side pading.

				// width: 50% - 2 * @sidemargin;
				// padding-right: @sidemargin;
				// padding-left: @sidemargin;
				// TODO: fix so that procentage margins work in IE8
				padding-right: 20px;
				padding-left: 20px;
			}
		}
		> [data-column~="7/12"] {
			.column-equalheight-padding(7, @columns, @gridsystem-width);
		}
		> [data-column~="2/3"] {
			.column-equalheight-padding(8, @columns, @gridsystem-width);
		}
		> [data-column~="3/4"] {
			.column-equalheight-padding(9, @columns, @gridsystem-width);
		}
		> [data-column~="5/6"] {
			.column-equalheight-padding(10, @columns, @gridsystem-width);
		}
		> [data-column~="11/12"] {
			.column-equalheight-padding(11, @columns, @gridsystem-width);
		}
		> [data-column~="1/1"] {
			.column-equalheight-padding(12, @columns, @gridsystem-width);
		}
		> [data-column~="1/1"]:first-child {
			display: table-caption;
		}
		// Pull parameters expressed as a factor of columns
		> [data-column~="push-1"] {
			.push-equalheight(1, @gridsystem-width);
		}
		> [data-column~="push-2"] {
			.push-equalheight(2, @gridsystem-width);
		}
		> [data-column~="push-3"] {
			.push-equalheight(3, @gridsystem-width);
		}
		> [data-column~="push-4"] {
			.push-equalheight(4, @gridsystem-width);
		}
		> [data-column~="push-5"] {
			.push-equalheight(5, @gridsystem-width);
		}
		> [data-column~="push-6"] {
			.push-equalheight(6, @gridsystem-width);
		}
		> [data-column~="push-7"] {
			.push-equalheight(7, @gridsystem-width);
		}
		> [data-column~="push-8"] {
			.push-equalheight(8, @gridsystem-width);
		}
		> [data-column~="push-9"] {
			.push-equalheight(9, @gridsystem-width);
		}
		> [data-column~="push-10"] {
			.push-equalheight(10, @gridsystem-width);
		}
		> [data-column~="push-11"] {
			.push-equalheight(11, @gridsystem-width);
		}
		> [data-column~="push-12"] {
			.push-equalheight(12, @gridsystem-width);
		}
		// Push parameters expressed as fractions
		> [data-column~="push-1/12"] {
			.push-equalheight(1, @gridsystem-width);
		}
		> [data-column~="push-1/6"] {
			.push-equalheight(2, @gridsystem-width);
		}
		> [data-column~="push-1/4"] {
			.push-equalheight(3, @gridsystem-width);
		}
		> [data-column~="push-1/3"] {
			.push-equalheight(4, @gridsystem-width);
		}
		> [data-column~="push-5/12"] {
			.push-equalheight(5, @gridsystem-width);
		}
		> [data-column~="push-1/2"] {
			.push-equalheight(6, @gridsystem-width);
		}
		> [data-column~="push-7/12"] {
			.push-equalheight(7, @gridsystem-width);
		}
		> [data-column~="push-2/3"] {
			.push-equalheight(8, @gridsystem-width);
		}
		> [data-column~="push-3/4"] {
			.push-equalheight(9, @gridsystem-width);
		}
		> [data-column~="push-5/6"] {
			.push-equalheight(10, @gridsystem-width);
		}
		> [data-column~="push-11/12"] {
			.push-equalheight(11, @gridsystem-width);
		}
		> [data-column~="push-1/1"] {
			.push-equalheight(12, @gridsystem-width);
		}
		// Pull parameters expressed as a factor of columns
		//
		> [data-column~="pull-1"] {
			.pull-equalheight(1, @gridsystem-width);
		}
		> [data-column~="pull-2"] {
			.pull-equalheight(2, @gridsystem-width);
		}
		> [data-column~="pull-3"] {
			.pull-equalheight(3, @gridsystem-width);
		}
		> [data-column~="pull-4"] {
			.pull-equalheight(4, @gridsystem-width);
		}
		> [data-column~="pull-5"] {
			.pull-equalheight(5, @gridsystem-width);
		}
		> [data-column~="pull-6"] {
			.pull-equalheight(6, @gridsystem-width);
		}
		> [data-column~="pull-7"] {
			.pull-equalheight(7, @gridsystem-width);
		}
		> [data-column~="pull-8"] {
			.pull-equalheight(8, @gridsystem-width);
		}
		> [data-column~="pull-9"] {
			.pull-equalheight(9, @gridsystem-width);
		}
		> [data-column~="pull-10"] {
			.pull-equalheight(10, @gridsystem-width);
		}
		> [data-column~="pull-11"] {
			.pull-equalheight(11, @gridsystem-width);
		}
		> [data-column~="pull-12"] {
			.pull-equalheight(12, @gridsystem-width);
		}
		// Pull parameters expressed as fractions
		> [data-column~="pull-1/12"] {
			.pull-equalheight(1, @gridsystem-width);
		}
		> [data-column~="pull-1/6"] {
			.pull-equalheight(2, @gridsystem-width);
		}
		> [data-column~="pull-1/4"] {
			.pull-equalheight(3, @gridsystem-width);
		}
		> [data-column~="pull-1/3"] {
			.pull-equalheight(4, @gridsystem-width);
		}
		> [data-column~="pull-5/12"] {
			.pull-equalheight(5, @gridsystem-width);
		}
		> [data-column~="pull-1/2"] {
			.pull-equalheight(6, @gridsystem-width);
		}
		> [data-column~="pull-7/12"] {
			.pull-equalheight(7, @gridsystem-width);
		}
		> [data-column~="pull-2/3"] {
			.pull-equalheight(8, @gridsystem-width);
		}
		> [data-column~="pull-3/4"] {
			.pull-equalheight(9, @gridsystem-width);
		}
		> [data-column~="pull-5/6"] {
			.pull-equalheight(10, @gridsystem-width);
		}
		> [data-column~="pull-11/12"] {
			.pull-equalheight(11, @gridsystem-width);
		}
		> [data-column~="pull-1/1"] {
			.pull-equalheight(12, @gridsystem-width);
		}

		[data-column][data-area~="style-white"]{

			background-color: transparent;
		
			> * {
				background-color: white;
			}
		}
		[data-column][data-area~="style-gray"]{

			background-color: transparent;
		
			> * {
				background-color: @color-gray95;
			}
		}
		[data-column][data-area~="style-brown"]{

			background-color: transparent;
		
			> * {
				background-color: @tre-darkgray-color;
			}
		}
		[data-column][data-area~="style-red"]{

			background-color: transparent;
		
			> * {
				background-color: @tre-error-color;
			}
		}
		[data-column][data-area~="style-raspberry"]{

			background-color: transparent;
		
			> * {
				background-color: @color-raspberry-dark;
			}
		}

	}

	.navigation__sublist {
		z-index: 9999;
	}
	footer {

		> .content-container {
			width: 1080px;
		}
	}
	select {
		// Some perverse bug in IE8 drop downs
		padding: 0;
		.border-radius( 0);
	}
	.productimagecontainer {

		-ms-behavior: url('/UI/polyfill/backgroundsize.min.htc');

	}
	.campaignhero_background {
		-ms-behavior: url('/UI/polyfill/backgroundsize.min.htc');
	}
	.icon-marker-soft-yellow {

		-ms-behavior: url('/UI/polyfill/backgroundsize.min.htc');
	}

	[data-moduleblock~='imagearea'] .content {
		-ms-behavior: url('/UI/polyfill/backgroundsize.min.htc');
	}
	
	.startpage {
		.startpage__section {
			-ms-behavior: url('/UI/polyfill/backgroundsize.min.htc');
		}
	}
	
	.offersdisplay .offersdisplay__list[data-row~="equalheight"] .offersdisplay__box {

		.hw-box {

			min-height:440px;
			padding-bottom: 0;
		}
	}

	.pricetag[data-pricetag] {

		font-size: 14px;
	}
	[class*='Hardware'] {

		.main-header + section {
			-ms-behavior: url('/UI/polyfill/backgroundsize.min.htc');
		}
	}
	.boxes {
		.campaign {
			-ms-behavior: url('/UI/polyfill/backgroundsize.min.htc');
		}
	}
	.bgIE {
		display: block;
		position: absolute !important;
		top: 0;
		left: 0;
		height: 100% !important;
		z-index: -1;
	}
	.bgIEwidth {
		width: 100% !important;
		height: auto !important;
	}
	.bgIEheight {
		height: 100% !important;
	}
	.module.push-item h1.tophead {
		margin-top: 8%;
	}
	.grid.menu .col-1-1 {
		background-color: @tre-standard-color !important;
	}
	// workaround for background-image cover
	.module.push-item {
		min-height: 350px;
		&.noImg {
			min-height: 0;
		}
		//background-image:none !important;
		overflow:hidden;
		position:relative;
		* {
			position: relative;
		}
	}
	.StartPage article .sloganLayer {
		display: none;
		span {
			display: none;
		}
	}

	.surroundProduct .productimagetitle {

		-ms-behavior: url('/UI/polyfill/backgroundsize.min.htc');
		height: 510px;
	}

	div.menu nav a {
		float: left;
		margin-bottom: 0;
	}
	div.menu nav ul li a {
		border-bottom: none;
	}
	.top-pane {
		display: none;
	}
	.main-menu {
		background-color: #a8133e;
		nav {
			display: inline-block !important;
			position: relative;
			max-width: 1200px;
		}
		.login-area {
			display: none !important;
		}
	}
	/*.login-area.loginpage {form {width:50%;}}*/

	.top-pane {
		display: none;
	}
	.grid.menu .col-1-1 {
		background-color: #515151;
	}	

	.ContentPage {
		.imagecontainer {
			background-image: url(/UI/build/images/omoss_bg_fade.png)!important;
			background-position: center;
			background-repeat: no-repeat;
		}
	}
	.ui-widget-overlay {
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	}
}
@media only screen and (min-width: 850px) {
	html.lte-IE9 {
		.module.push-item {
			min-height: 400px;
		}
	}
}
html.lte-IE9 {
	.campaignhero_background {
		// background-size: 100% auto;
		background-repeat: no-repeat;
	}
	[data-moduleblock~='imagearea'] .content {
		height: 465px;
	}
	.campaignhero__area .campaignhero__box a.button-link, .green.button-link {
		.border-radius(0);
	}
}
//ie fix
* a > img {
	border: none;
}
* input[type*="checkbox"] {
	padding: 0 !important;
	border: none !important;
}