.cancel-info{

  margin:0px 30px;
  width: auto;
  position: relative;
  .clearfix;

  @media only screen and (min-width: @tablet-breakpoint-hallon) {
    width: 860px;
    margin: 0 auto;
  }

  .cancel-info__headline{
    font-family: @hallon-frutiger-77-bc;
    font-size: 16px;
    text-transform: none;
    color: @hallon-dark-grey;
    margin-bottom:12px;
  }

  .cancel-info__button{
    float: none;
    margin:0 auto;
    margin-top: 20px;

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      display: block;
      float: left;
      margin-top: 0px;
    }

  }

  .cancel-info__link{
    font-family: @hallon-frutiger-77-bc;
    font-size: 14px;
    position: relative;
    display: block;

    @media only screen and (min-width: @tablet-breakpoint-hallon) {
      position: absolute;
      right:0;
      bottom:0;
    }

  }

}

.cancel-info--not-visible{
  display: none;
}
