.ellip {
	display: block;
	align-self:flex-start;
}

.ellip-line {
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-wrap: normal;
	max-width: 100%;
}

.ellip,
.ellip-line {
	position: relative;
	overflow: hidden;
}
