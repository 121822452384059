.boxspaces {
	@vBoxpadding: 20;
	@hBoxpadding: @gutter-width; // 20
	padding: unit(@vBoxpadding, px) unit(@hBoxpadding/960 * 100, %);
	overflow: hidden;

	@media only screen and (max-width: @hw-palm) {
		@hBoxpadding: @column-width - @gutter-width; // 60 - 20
		padding: unit(@vBoxpadding, px) unit(@hBoxpadding/960 * 100, %);
	}

	@media only screen and (max-width: @hw-mobile-L) {
		@hBoxpadding: @column-width; // 60
		padding: unit(@vBoxpadding, px) unit(@hBoxpadding/960 * 100, %);
	}
}

.variant-campaign {
	font-family: @tre-bold-font;
	color: @tre-message-color;

	a {
		color: @tre-message-color;
	}
}

.variant-size-M {
	.font-size(1.6);
}

.whitebox {
    display: block;
    overflow: hidden;
    background-color: @tre-white-color;
    .border-radius(4px);
    .box-shadow();
    .boxspaces();

    &.campaign {
        .variant-campaign();
    }

    &.size-M {
        .variant-size-M();
    }

    &--no-padding-top-bottom{
        padding-top:0;
        padding-bottom:0;
    }
    // Remove after cleaning up areas where the white box is used in this manner.
    section {
		h3 {
			font-size: 1.8rem;
			color: #afafaf;
		}
	}

	p {
		&.center {
			padding-left: 40px;
			padding-right: 40px;
		}

		&.space {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
}

.graybox {
	display: block;
	overflow: hidden;
	color: @tre-white-color;
	background-color: @tre-darkgray-color;
	.headerparagraphColor( @tre-white-color );
	.border-radius(4px);
	.boxspaces();

	a {
		color: @color-violetred;
	}

	img {
		display: block;
		max-width: 100%;
		height: auto;
		width: auto;
	}
}

.messagebox {
	position:relative;
	display: block;
	overflow: hidden;
	text-align: center;
	color: #000;
	background-color: @tre-message-color;
	.border-radius(4px);
	.box-shadow();
	.boxspaces();

	h2 {
		margin-bottom: .5em;
		text-align: center;
		text-transform: uppercase;
		.font-size(3);

		@media only screen and (max-width: @hw-mobile-L) {
			.font-size(2.5);
		}
	}

	p {
		text-align: left;
	}

	&.alert {
		background-color: @tre-alert-color;

		h2,
		p,
		a {
			color: #fff;
		}
		// Where is this to be found? Looks like legacy. -LR
		[data-button] {
			color: #000;
			background: #fff;
		}
	}

	.messagebody {
		width: 70%;
		margin: @margin-M auto @margin-M auto;
		text-align: left;

		@media only screen and (max-width: @hw-mobile-L) {
			width: 100%;
		}
	}
}

.errorbox {
	.messagebox();
	background-color: @tre-alert-color;

	h2,
	p,
	a {
		color: #fff;
	}
	// Where is this to be found? Looks like legacy. -LR
	[data-button] {
		color: #000;
		background: #fff;
	}

	a[href]:hover {
		color: @tre-inputborder-color;
	}
}

.infobox {
	display: block;
	overflow: hidden;
	background-color: @tre-lightergrey-color;
	.border-radius(4px);
	.box-shadow( 0px 0px 3px rgba(109, 109, 109, 0.1) );
	.boxspaces();

	&.campaign {
		.variant-campaign();
	}

	&.size-M {
		.variant-size-M();

		&.icon-left {
			.infobox-content {
				.inline-block();
				padding-left: 3em;
			}

			.infobox-icon {
				//TODO: rethink this part as it's not sustainable if there would be a different size icon
				margin-left: -46px;
				margin-right: 13px;
			}
		}
	}
}

//Alertinformation such as Errorpage, Canceled
.alertbox {
	display: block;
	margin-bottom: @margin-L;
}

[data-column~="1/2"] {
	.whitebox,
	.graybox,
	.messagebox,
	.errorbox {
		@vBoxpadding: 20;
		@hBoxpadding: @gutter-width; // 20
		padding: unit(@vBoxpadding, px) unit(@hBoxpadding/480 * 100, %);
		// half-width column becomes 100% wide. Reset padding to
		@media only screen and (max-width: @hw-tablet) {
			@hBoxpadding: @gutter-width; // 20
			padding: unit(@vBoxpadding, px) unit(@hBoxpadding/960 * 100, %);
		}

		@media only screen and (max-width: @hw-palm) {
			@hBoxpadding: @column-width - @gutter-width; // 60 - 20
			padding: unit(@vBoxpadding, px) unit(@hBoxpadding/960 * 100, %);
		}

		@media only screen and (max-width: @hw-mobile-L) {
			@hBoxpadding: @column-width; // 60
			padding: unit(@vBoxpadding, px) unit(@hBoxpadding/960 * 100, %);
		}
	}
}

// Added for new HW page, 2017
.hardware__white-box,
.checkout__white-box {
	background: #fff;
	margin: 0 1em;
	padding: 1em;
	border-radius: 8px;

	@media @breakpointquery-2018-phablet {
		margin: 0;
	}

	h1,
	h2,
	li,
	a,
	p {
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: grayscale;
	}
}
