.icon-block {
    display: block;
    margin: 6px;
    height: 28px;
    margin: 0 auto;
    
    @media @breakpointquery-2018-small-desktop {
        margin-bottom: 6px;
    }

    &.active {
        display: none;
    }
}

.icon-block__hamburger {
    margin-top: 4px;
}
