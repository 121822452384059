.ForeignPriceCountryPage {

	// .is-campaign {
	// 	margin-top: 750px;
	// }

	.campaignhero__area h2 {
		   margin: 0 0 0 -5px !important;

	}

	// .campaignhero_background {
	// 	position: absolute;
	// 	top: 120px;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 700px;
	// 	background-size: cover;
	// }

	.foreign-prices-description {
		margin-bottom: 20px;
	}


	.module {
		padding-bottom: 40px;

		.details {
			tr {
				td {
					span {
						font-weight: bold;
					}
				}
			}
		}
	}

	.ForeignBox {
	}

	.boxContainer {

		.flags {

			display: block;
			text-align: center;
			color: #000;
			padding: @margin-M unit( @gutter-width/@gridsystem-width*100, %);
			// background-color: #fff;

			img {
				width: auto;
				vertical-align: middle;
				margin: 0 0.6em;
				border: none;
			}
			i:first-child {
				margin-right: 0;
			}
		}
	}

	.getpackagelink {
		margin-bottom: @margin-L;
	}
}

.ForeignPriceListPage,
.ForeignPriceCountryPage {

	.top-rubrik {
		text-align: center;

		.listing {
			li{
			display: inline-block;
			margin: 0px 10px;
			@media only screen and (max-width: 700px){ padding-left: 40px; text-align: left; display:block;}
			}
		margin-bottom: 40px;
	}
	}

	.select-country-container {
		margin-bottom: 50px;
		margin-top: 50px;
	}

	.additional-info {
		// p {
		// 	text-align: left;
		// }
	 //  	@media only screen and (min-width: 700px){
		// 	width: 600px;
		// 	margin-left: auto;
		// 	margin-right: auto;
		// }
		p {
			margin-bottom: @margin-ML;
		}
	}

	.linkarea {
			// margin-bottom: 60px;
			padding-top: 2em;
			padding-bottom: 2em;
		}
	.col-1-2{
		h2.viewarea{
			padding: 20px 10px;
		}
	}

	.packages-top-rubrik{
		// margin-bottom: 50px;
		h2{
			// margin-bottom: 10px;
			text-align: center;
		}
	}
}

.ForeignPriceCountryPage{
	>div{
		>div{
			>div {
				 >.linkarea {
			margin-bottom: 50px;
			padding-top: 0px;
			padding-bottom: 0px;
		}
			}
		}
	}
}


.linkarea {

	background: #fff;
	margin-bottom: 0px;
	padding-top: 1em;
	text-align: center;

}

.select2-chosen {
	.langOptionElement {
		float: left;
	}
}
.langOptionElement {
	display:block;
	white-space: nowrap;

	img {
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
		margin-top:-2px;
		border: none;
		width: 32px;
	}

}

@media only screen and (max-width: @phone-barrier) {
	.ForeignPriceCountryPage {
		.is-campaign {
			margin-top: 0;
		}

		// .campaignhero_background {
		//     position: static;
		//     height: 600px;
		//     background-size: auto 100%;
		// }

		.campaignhero__usps.campaignhero__box {
			display: none;
		}
	}
}

.choseCountry {
	display: none;
	color: #ccc;
}
