﻿// Stacked Icons
// -------------------------

.@{ico-css-prefix}-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.@{ico-css-prefix}-stack-1x, .@{ico-css-prefix}-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.@{ico-css-prefix}-stack-1x { line-height: inherit; }
.@{ico-css-prefix}-stack-2x { font-size: 2em; }
.@{ico-css-prefix}-inverse { color: @ico-inverse; }
