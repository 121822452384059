/// <reference path="../framework.less" />


.hallon-2018-input {
    &__group {
        position: relative;
        margin: 0 0 35px 0;
        padding: 0;
        display: inline-block;
        width: 100%;
        text-align: left;

        input {
            font-size: 14px;
            line-height: 21px;
            padding: 10px 10px 10px 5px;
            display: block;
            width: 100%;
            border: none;
            border-bottom: 1px solid @color-2018-light-grey;
            background: transparent;
            color: @color-2018-heavy-grey;
            font-family: @font-2018-roman;
            transition: background 0.2s linear;
            outline: none;
            -webkit-appearance: none;
            border-radius: 0;
            margin: 0;

            &::-ms-expand, &::-ms-reveal, &::-ms-clear {
                display: none;
            }

            &:focus {
                outline: none;
                border: none;
                border-bottom: 1px solid transparent;
            }

            &.valid {
                border-bottom-color: @color-2018-green;
                color: @color-2018-green;
            }

            &.error {
                border-bottom-color: @color-2018-error-red;
            }

            &.valid:not(.error) {
                color: @color-2018-green;

                &::-webkit-input-placeholder {
                    color: @color-2018-green;
                }

                &::-moz-placeholder {
                    color: @color-2018-green;
                }

                &:-ms-input-placeholder {
                    color: @color-2018-green;
                }

                &:-moz-placeholder {
                    color: @color-2018-green;
                }
            }
        }


        input:focus,
        input:disabled,
        input:not(.placeholder-shown):not(.error) {
            ~ .hallon-2018-input__animated-label {
                color: @color-2018-heavy-grey;
                opacity: 1;
                top: -18px;
                font-size: .75em;
                transition: 0.2s all;

                &:after {
                    visibility: visible;
                    width: 100%;
                    left: -5px;
                }

                &.error {

                    &:after {
                        background-color: @color-2018-error-red;
                    }
                }

                &.valid {
                    color: @color-2018-green;

                    &:after {
                        background-color: @color-2018-green;
                    }
                }
            }
        }

        .placeholder-shown.error {

            + .hallon-2018-input__animated-label.error {
                top: 20px;
                opacity: 0;
            }
        }

        input.valid:not(.error) {
            ~ .hallon-2018-input__animated-label {
                top: 5px;
                font-size: 11px;
                color: @color-2018-green;
                transition: 0.2s all;
                opacity: 1;

                &:after {
                    background-color: @color-2018-green;
                }
            }

            &:focus {
                color: @color-2018-green;

                ~ .hallon-2018-input__animated-label {
                    color: @color-2018-green;

                    &:after {
                        background-color: @color-2018-green;
                        background: @color-2018-green;
                    }
                }
            }
        }

        input:disabled {
            ~ .hallon-2018-input__animated-label {
                &:after {
                    background-color: @color-2018-light-grey;
                    background: @color-2018-light-grey;
                }
            }
        }

        input:focus {
            &:after {
                width: 100%;
            }
        }
    }

    &__animated-label {
        position: absolute;
        top: 20px;
        left: 5px;
        bottom: 0;
        z-index: 2;
        width: 100%;
        transition: 0.2s ease all;
        margin: 0;
        color: @color-2018-heavy-grey;
        pointer-events: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1em;
        opacity: 0;


        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            height: 1px;
            width: 10px;
            visibility: hidden;
            background-color: @color-2018-hallon-red;
            transition: 0.2s ease all;
        }
    }

    &__input-error-message {
        position: absolute;
        margin-top: 5px;
        width: 100%;
        padding-left: 10px;
        font-size: 11px;
        color: @color-2018-error-red;
    }
}
