/******** Chrome on iOS bug *****************************************/ 
/* …or more correctly the Apple WebUiView bug
*
* When specifying a container's height either explicitly (height:100%) or implictly the height get calculated from
* the window height, not logically as the container or height of its children.
* 
*/

.html {
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
}

/******** Reusable modules *****************************************/
/* I'm sorry to say but there is absolutly no modules here
 * If there were the styles would be contained in their own files.
 */

h1.top-header,
h2.top-header,
h3.top-header,
h4.top-header,
h5.top-header,
h6.top-header {

	margin-bottom: @margin-S;
}

a {
	text-decoration: none;
	color: @tre-standard-color;
	&:hover {
		color: @tre-purple-color;
	}
}
a.back-link, a.abort {
	text-decoration: none;
	color: @tre-standard-color;
	.font-size(2.4);
	float: left;
	font-family: @tre-black-font;
	text-transform: uppercase;
	span {
		display: inline-block;
		.background(-168px, 0px);
		width: 16px;
		height: 16px;
		margin-right: 5px;
	}
}
// TODO: remove when this class is phased out.
.link-button {
	cursor: pointer;
}
iframe[name=google_conversion_frame] {
	display: none;
}

[data-button].center {
	
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.module.transparent {
	background-color: transparent;
	box-shadow: none !important;
}
div.module > .purchase-history {
	margin-bottom: 30px;
}



p.paragraph {
	margin-bottom: 10px;
}
.clear {
	clear: both;
}
.lte-IE8 .campain-ribbon, .lt-ie8 .campain-ribbon {
	display: none !important;
}
.login-message {
	margin-bottom: 20px;
	h2 {
		margin-top: 0;
	}
}
section {
	&.original {
		// padding-top: @margin-M;
		padding-bottom: @margin-L;
	}
}


.topcontentcontainer {
	margin-bottom: @margin-L;
}

body > img {
	height: 0 !important;
	border: 0 !important;
	padding: 0;
	display: inline;
	display: block;
	visibility: hidden;
}
.advertisement-functions {
	position: fixed;
	bottom: -3000px;
	left: -3000px;
}

.productimagecontainer {
	position: relative;
	overflow: hidden;
	.campain-ribbon {
		background: #ffda46;
		height: 87px;
		text-align: center;
		width: 650px;
		transform: rotate(-45deg);
		-ms-transform: rotate(-40deg);
		-webkit-transform: rotate(-40deg);
		position: absolute;
		.box-shadow(2px 0px 3px rgba(0, 0, 0, 0.4));
		-webkit-backface-visibility: hidden;
		top: 120px;
		left: -150px;
		p {
			position: absolute;
			vertical-align: middle;
			color: black;
			margin: auto 120px;
			font-size: 20px;
			color: #3e3a37;
		}
		@media only screen and (max-width: @phone-barrier) {
			display: none;
		}
	}
}

.surroundProduct {

	margin-bottom: @margin-ML;
	
	@media only screen and ( max-width: @phone-barrier ) {

 		margin-top: @margin-L;
		
		[data-area],
		[data-box] {

			margin-bottom: @margin-ML;
		}
	}

	h2 {
		
		top: 15%;

	}

	.productimagetitle {

		// height: 474px; // Height without h2 title
		height: 513px;
		background-size: cover;
		background-repeat: no-repeat;

		h2 {
			.font-size(4);
			margin-top: 2em;
			text-align: center;
			color: white;
			
			@media only screen and (max-width: @hw-laptop) {
				.font-size(3);
			}
			@media only screen and (max-width: @hw-tablet) {
				.font-size(2.8);
			}
			@media only screen and (max-width: @phone-barrier) {
				.font-size(2.9);
			}
		}
		
		@media only screen and (max-width: @phone-barrier) {

			display: none;
		}
	}

}

.nextbutton {

	margin-bottom: @margin-S;
}

//try to fix visual errors in mobile ios7-8 chrome -JE
.campaignhero__area.campaignhero-upfront {

    text-align: center;

    @media only screen and (min-width: 401px) {
        .desktoponly {display: block;}
        .mobileonly {display: none;}
    }
    @media only screen and (max-width: 400px) {
        .desktoponly {display: none;}
        .mobileonly {
            display: inline-block;
            float: none;
            margin: 30px auto;
            /*
            .column(6);
            .push(3);
            */
            width: unit( @width-standard-button, px );
            height: auto;
        }
    }
}

.campaignhero__area {
    height:auto !important;
    h1 {
        height:auto !important;
    }
}

.campaign-container {
	max-width: 900px;
	padding-right: 20px;
	margin: auto;
	@media only screen and (max-width: @phone-barrier) {
		padding-right: 0px;
	}
}
.lte-IE8 .mobile-campain-ribbon, .lt-ie8 .mobile-campain-ribbon {
	display: block !important;
	height: auto;
	max-width: 900px;
	margin: 10px auto;
	p {
		@media only screen and (min-width: @phone-barrier) {
			margin: 20px;
		}
	}
}
.mobile-campain-ribbon {
		.border-radius(4px);
		display: none;
		background: #ffda46;
		text-align: center;
	.box-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));
		overflow: hidden;
		p {
			color: black;
			font-size: 22px;
			margin: 20px;
			color: #3e3a37;
		}
		margin: 10px 5% 20px 5%;
		@media only screen and (max-width: @phone-barrier) {
			display: block;
			height: auto;
		}
		@media only screen and (max-width: 420px) {
			p {
				font-size: 20px;
			}
		}
}
.faqBox {

	padding-top: @margin-S;
	padding-bottom: @margin-M;
	.font-size( 1.6);
	color: #fff;
	border-top: 1px solid @tre-gray-color;
	// .surround {
	// 	overflow:hidden;
	// 	height:135px;
	// }
	.htmlContent {
		border-top: none !important;
	}
	ul {
		list-style: disc;
		padding-left: 18px;
		margin-bottom: 20px;
		li {
			margin-bottom: 5px;
		}
	}
	h2 {
		.font-size(1.6);
		font-family: @tre-standard-font;
	}

	a.readmore {

		display:none;
	}
}

.toFaqUrl {
	text-align: right;
}

.noJavascriptLayer, .oldBrowserLayer {
	padding: 20px;
	color: @tre-darkgray-color;
	background-color: #fff;
	border: 1px solid @tre-darkgray-color;
	max-width: 250px;
	position: fixed;
	top: 80px;
	left: 20px;
	@media only screen and (max-width: @phone-barrier) {
		position: fixed;
		width: 100%;
		max-width: none;
		bottom: 0;
		top: auto;
		left: 0;
	}
}
.oldBrowserLayer {
	display: none;
	z-index: 30;
}
.lt-ie8 .oldBrowserLayer {
	display: block;
}
.noJavascriptLayer {
	display: none;
	z-index: 40;
}
.no-js .noJavascriptLayer {
	display: block;
}
.additional-text-with-image {
	img {
		width: 100%;
		height: auto;
		display: block;
	}
}

//Questionform on FAQ and Contact page
.whitebox.contactForm {
	.comment-vote-actions {
		margin-right: 20px;
	}
	label {
		display: block;
		margin-bottom: 10px;
		color: @tre-darkgray-color;
	}
	.field-validation-error {
		margin-top: 5px;
	}
	.button-link {
		display: inline-block;
		margin-top: 20px;
		border: none;
		float: left;
	}
	.col-1-2 {
		input, select {
			width: 90%;
}
	}
	.col-1-1 {
		margin-top: 20px;
		textarea {
			width: 100%;
}
	}
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="tel"],
select,
textarea {
	//Prevents operating system styling
	-webkit-appearance: none;
	-moz-appearance:    none;
	appearance:         none;
}

input, textarea {

	color: @tre-gray-color;
}

input:focus,
textarea:focus {

	border-color: @tre-darkgray-color;
	color: @tre-darkgray-color;
}

.field-valid {	
	
	@iconheight: 35px;

	display: block;
	float: right;
	top: -44px;
	margin-bottom: @iconheight * -1; // remove the height of the element with negative margin
	height: @iconheight;
	// background: url(/UI/build/images/form_valid.png);
	.ico-icon();
	.font-size(3.4);
	color: @tre-success-color;
	&:before { content: @ico-var-check; }
}

input[type="checkbox"] + .field-valid,
input[type="radio"] + .field-valid{

	top: -9px;
}

.field {
	position: relative;
	clear: both;
	// overflow: hidden;
}
.show-is-valid {
	float: left;
}
.field-validation-error {
	clear: both;
	float: none;
}
.campaign-code {
	
	border-bottom: 1px solid #eee;
	padding-bottom: 20px;
	margin-bottom: 20px;
	overflow: hidden;

	// This seems like legacy. -LR
	&.results {
		h3 {
			font-size: 20px;
			margin-bottom: 5px;
			margin-top: 0;
		}
		.code {
			font-size: 20px;
			display: inline-block;
			padding-right: 40px;
			background: url(/UI/build/images/form_valid.png) top right no-repeat;
			line-height: 32px;
		}
	}
	.campaign-code-panel {
		display: none;
	}
	.campaign-code-panel.error {
		display: block;
	}
	.campaign-code-link {
		text-decoration: none;
		color: #a8133e;
		font-size: 24px;
		font-size: 2.4rem;
		font-family: "FrutigerLTW01-87ExtraBk";
		text-transform: uppercase;
		cursor: pointer;
	}
	.campaign-code-panel {
		position: relative;
		top: 5px;
	}
	input[type=text] {
		float: left;
	}
	.button-link {
		float: left;
		padding: 5px 10px;
		width: auto;
		font-size: 1.4rem;
		margin-left: 15px;
		margin-top: 4px;
		border: 0px;
	}
}
.campaign-total {
	overflow: hidden;
	.left {
		width: 50%;
		@media only screen and (max-width: @phone-barrier) {
			float: none !important;
			width: auto;
			margin-bottom: 20px;
		}
	}
	.middle {
		width: 25%;
		@media only screen and (max-width: @phone-barrier) {
			float: left !important;
			width: 50% !important;
		}
	}
	.right {
		width: 25%;
		@media only screen and (max-width: @phone-barrier) {
			float: right !important;
			width: 20% !important;
		}
	}
	.marked-small-old {
		background: url(/UI/Images/marker-small.png) no-repeat;
		background-size: 120px 110px;
		padding-left: 30px;
		height: 120px;
		margin-bottom: -10px;
	}
	.marked-small {
		background: url(/UI/Images/splash.png) no-repeat;
		background-size: 120px 110px;
		padding-left: 30px;
		height: 120px;
		margin-bottom: -10px;
	}
	.marked-big-old {
		background: url(/UI/build/images/marker-big.png) no-repeat;
		padding-left: 20px;
		height: 90px;
		padding-top: 7px;
	}
	.marked-big {
		background: url(/UI/build/images/kludd-kop.png) no-repeat left center;
		background-size: 60% 80%;
		padding-left: 20px;
		height: 90px;
		padding-top: 7px;
	}
	.under {
		font-size: 24px;
		color: #3e3a37;
		font-family: "FrutigerLTW01-87ExtraBk";
		text-transform: uppercase;
		letter-spacing: -1px;
	}
}
@media only screen and (max-width: @phone-barrier) {
	#mobile-broadband .productimagecontainer h2 span + span {
		font-size: 34px !important;
		line-height: 50px !important;
	}
}
@media only screen and (max-width: @hw-palm) {

	.confirm-terms {

		margin-top: @margin-M;
		margin-bottom: @margin-M;
	}
}
.field-validation-error {

	display: block;
	color: @tre-error-color;
	font-weight: bold;

	&.placeorder span {
		float: right;
		width: 90%;
	}

	&:before {
		content: @ico-var-times-circle;
		margin-right: @margin-XXS;

		.ico-icon();

	}

}
.input-validation-error {
	color: #c72100!important;
	border: 2px solid #c72100!important;
}
[data-area~="style-brown"] {

	.input-validation-error {

		border-color: @color-violetred!important;
	}
}

.wrap-grid {
	background-image: url('/UI/build/images/bg3.png'); // TODO: phase this out as a grid-related class shouldn't be assigned a background.
}
.decor-bg-weave {
	background-image: url('/UI/build/images/bg3.png');
}
.decor-bg-rain {
    background: @hw-bg url("/static/telefoner/bg-hardware-samsung.jpg") no-repeat top center;
    background-size: 100% auto;
}

.progressbar {

	&[data-used] {
		position: relative;

		.bar {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 0;
			min-height: 100%;
			background-color: #d2d2d2;
			.border-left-radius(4px);
		}

		.barwidth(@width) {
			.bar {
				width: @width;
			}
		}

		@color-alert: #f00;

		&[data-used~="5"] { .barwidth(5%) };
		&[data-used~="10"] { .barwidth(10%) };
		&[data-used~="15"] { .barwidth(15%) };
		&[data-used~="20"] { .barwidth(20%) };
		&[data-used~="25"] { .barwidth(25%) };
		&[data-used~="30"] { .barwidth(30%) };
		&[data-used~="35"] { .barwidth(35%) };
		&[data-used~="40"] { .barwidth(40%) };
		&[data-used~="45"] { .barwidth(45%) };
		&[data-used~="50"] { .barwidth(50%) };
		&[data-used~="55"] { .barwidth(55%) };
		&[data-used~="60"] { .barwidth(60%) };
		&[data-used~="65"] { .barwidth(65%) };
		&[data-used~="70"] { .barwidth(70%) };
		&[data-used~="75"] { .barwidth(75%) };
		&[data-used~="80"] { .barwidth(80%) };
		&[data-used~="85"] { background-color: @color-alert; .barwidth(85%) };
		&[data-used~="90"] { background-color: @color-alert; .barwidth(90%) };
		&[data-used~="95"] { background-color: @color-alert; .barwidth(95%) };
		&[data-used~="100"] { .barwidth(100%); .bar { .border-right-radius(4px); }};

		.content {
			position: relative;
			z-index: 10;
		}
	}
}



.continuetext {

	font-style: italic;
	display: block;
	text-align: center;
}

/* FAQ voting */

.button-link {
	&.voted {
		background-color: #cccccc;
		font-weight: bold;
		color: black;
	}
}
.comment-vote-actions {
	padding-bottom: 20px;
	display: block;
	border-bottom: 1px solid #e7e5e3;
	overflow: hidden;
	.button-link {
		padding: 5px 0;
		width: 54px;
		margin-right: 10px;
		text-align: center;
	}
}
.confirm-price-box {
	.pricetag {
		// TODO: remove this when embedding the bubble icon into the code.
		// width: unit( 13/1.4, em ); // Original proportions but changed for upfront campaign
		// width: unit( 13.5/1.4, em); // changed once again due to subjective opinions.
		// height: unit( 14/1.4, em);
		width: 130px;
		height: 140px;
	}
}

.confirm-price-box,
.genericBox {

	.campaign {
		font-family: "FrutigerLTW01-87ExtraBk";
		text-transform: uppercase;
		font-weight: normal;
		font-size: 1.8rem;
	}

}
.all-boxes-visible .col-1-2.genericBox.credit-card {
	clear: both;
}
/* Box that can be clicked and slides down to show more information */
// TODO: replace this with the dropdown component
// .col-1-2.toggle-box {
.toggle-box {
	// margin-top:20px;
	.module {

		position: relative;
		overflow: hidden;
		padding: 0;
		
		&.whitebox{

			padding: 0;
		}

		.content {
			display: none;
			position: relative;
			background-color: #FFF;
			padding: @margin-M;
			.clearfix();
		}
		
		@fs-toggle-it: 1.8;
		
		.toggle-it {

			.font-size( @fs-toggle-it );
			position: relative;
			height: 4em;
			overflow: hidden;
			cursor: pointer;
		}
		h3 {

			display: table;
			margin: 0;
			height: 100%;
			width: 84%;
			// padding: 20px;
			padding: 0 16% 0 20px;
			float: left;
			background-color: #fff;
			border-right: 1px solid @tre-inputborder-color;

			span {
				
				display: table-cell;
				vertical-align: middle;
			}

			// NB! min-width from phone barrier. Otherwise use normal h3
			@media screen and ( min-width: @hw-tablet ) {

				.font-size( @fs-toggle-it );
			}
		}
		.togglebutton {

			display: inline-block;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: 16%;
			color: @tre-gray-color;
			background-color: @color-gray95;

			i[class^="icon-"] {

				@basefontsize: 1.8;
				.font-size(@basefontsize);
				position: absolute;
				top: 50%;
				left: 50%;
				margin-top: unit( @basefontsize/-2/@basefontsize, em );
				margin-left: -0.2em;

			}
		}
	}
	@media only screen and ( max-width: @hw-tablet) {
		margin-bottom: @margin-M;
	}
}
	.module.push-item {
	min-height: 700px;

	background-position: top center;
	background-repeat: no-repeat;
	&.noImg {
		min-height: 0;
		background-size: auto;
		background-repeat: repeat;
		background-color: transparent;
		background-image: url('/UI/build/images/bg3.png');
		h2.tophead {
			margin-top: 20px;
	}
		h2 span, p {
			color: @tre-darkgray-color;
		}
		a.nav {
			display: none;
		}
	}
	.bgsize(cover);
	header {
		margin-top: 30px;
	}
	h2.tophead, h1.tophead {
		margin-bottom: 20px;
		margin-top: 12%;
	}
	p {
		font-family: @tre-standard-font;
		.font-size(1.8);
		color: #fff;
		text-align: center;
	}
	a.nav {
		width: 49px;
		display: block;
		margin: 0 auto;
		margin-top: 20px;
	}
}
.SelfServicePage .grid-pad {
	max-width: 900px;
	max-width: 90rem;
	margin: 0 auto;
}
.pricelist {
	display: none;
}
// div.shadowbox {
// 	background-color: #fff;
// 	padding: 15px 15px 25px 15px;
// 	.box-shadow();
// 	.border-radius(2px);
// 	margin-right: 20px;
// }
.top-rubrik {
	padding: 0 0 @margin-M 0;
}
.bottom-rubrik {
	margin-top: 30px;
	.clearfix();
	.whitebox {
		margin-right: 0px;
	}
}
.middle-rubrik {
	clear: both;
	 padding-top: 30px;
	h2 {
		margin-top: 0!important;
	 }
 }

.boxContainer {

	.clearfix();
	margin-bottom: @margin-L;

	@media only screen and ( max-width: @phone-barrier ) {

		margin-bottom: @margin-M;
	}
}

.product-category-description,
.packages-top-rubrik,
.additional-info {

	margin-bottom: @margin-L;
}

.boxes {

	margin-bottom: @margin-M;
}

@media only screen and ( max-width: @phone-barrier ) {

	@sidepadding: unit( @gutter-width/@gridsystem-width*100, %);

	.product-category-description,
	.packages-top-rubrik,
	.additional-info {

		padding-right: @sidepadding;
		padding-left: @sidepadding;
	}

	.boxes {

		margin-bottom: @margin-M;
	}

}


.error-message-login {

	color: @tre-error-color;
	margin-bottom: @margin-XXXS;
	font-weight: normal;
}

.cl {
	overflow: hidden;
}

article {
	.box {
		padding: 10px;
		background-color: transparent;
		text-align: center;
		display: inline-block;
		width: 100%;
		text-decoration: none;
		color: #000;
	}
}
.MobilePackageOrderPage {
	.nextbutton {
		margin-bottom: @margin-S;
	}
}

.wrapper {
	overflow-x: hidden;
}
body > .wrapper {

	min-height: 100%;
}

.boximage {
	height: auto !important;
	width: 100%;
	display: block;
}
.col-1-2.boxes .module {
	margin-bottom: 20px;
}

.titlebox {

	display: table;
	width: 100%;
	text-align: center;

	&[data-area] {

		padding-top: 1.5em;
		padding-bottom: 1.5em;
	}

	> * {
		display: table-cell;
		vertical-align: middle;
		line-height: 1.1em;
	}

	// TODO: take a look at kundservice pages headers as well.
	h2 {
		.font-size(3);
		padding-right: 0.3em;
		padding-left: 0.3em; 
	}
	h3 {
		.font-size(2.8);
		padding-right: 0.3em;
		padding-left: 0.3em; 
	}
	@media only screen and ( max-width: @hw-desktop ) {

		h2 {
			.font-size(2.4);
		}

		h3 {

			.font-size(2.4);
		}
	}
	@media only screen and ( max-width: @hw-palm-max ) {

		h2 {
			.font-size(1.8);
		}

		h3 {

			.font-size(1.8);
		}
	}

}

// TODO: omve to context specific page.
.surroundBox {

	height: auto; // for Chrome on iOS bug*
	.titlebox {
		height: 80px;
	}
}
.boxContainer {

	height: auto; // for Chrome on iOS bug*
	.surroundBox {

		background-color: white;
	}
}

//design prodboxes
.boxes {
	.surroundBox {
		.box-shadow();
	}
	h2.viewarea {
		width: 100%;
		display: block;
		text-align: center;
		color: #fff;
		background-color: @tre-darkgray-color;
		text-transform: uppercase;
		margin: 0;
		padding: 20px 0;
		@media only screen and (min-width: 1150px) {
			.font-size(3.1);
		}
	}

	.surroundimg {
		position: relative;
		img {
			border: none;
		}
		// The price tags which are positioned in an image with varying widths.
		//
		.priceplaceholder {
			// @media only screen and (max-width: @hw-laptop) {
			// 	.font-size(2.6);
			// }
			@media only screen and (max-width: @hw-tablet) {
				.font-size(2.9);
			}
			@media only screen and (max-width: 700px) {
				.font-size(2.6);
			}
			@media only screen and (max-width: 600px) {
				.font-size(2.1);
			}
			@media only screen and (max-width: 500px) {
				.font-size(2);
			}
			@media only screen and (max-width: @hw-palm) {
				.font-size(1.7);
			}
			@media only screen and (max-width: 400px) {
				.font-size(1.5);
			}
		}
	}
	.priceplaceholder.campaign {
			// padding: 9% 0 0;
			color: #111;
			background: url(/UI/build/images/splash.png) no-repeat center 36%;
			background-size: 30% 70%;
	}
	.priceplaceholder.old-campaign {
			padding: 10% 0 0;
			color: #111;
			background: url(/UI/build/images/marker-small.png) no-repeat center 36%;
			background-size: 30% 70%;
	}
}
.selfserv-header {
	margin-bottom: 20px;
}
.module.headtext {
	text-align: center;
}
.mobilecoverage {
	> p {
		margin-bottom: 20px;
}
}
.mobilecoverage iframe {} .ui-dialog {
	background-color: #fff;
	@media only screen and (min-width: @phone-barrier) {
		width: 600px !important;
	}
}
.ui-dialog .ui-dialog-titlebar-close {
	padding: 12px;
	margin-top: -8px;
}
.ui-dialog .ui-dialog-content {
	padding: 0;
	}
.ui-widget-overlay {
	background-color: #000;
	opacity: .6;
}
ul.ui-autocomplete {
	background-color: #fff;
	width: 450px !important;
	border-radius: 0 0 4px 4px;
	border: 1px solid @tre-gray-color;
	li {
		&.selected {
			.font-size(2.2);
		}
		cursor:pointer;
		padding:0;
		font-family:@tre-standard-font!important;
		font-size:16px !important;
		color:#000 !important;
		a {
			color: @tre-standard-color !important;
			cursor: pointer;
			display: block;
			padding: 10px;
			border: none!important;
			border-radius: 0!important;
			&:hover {
				background: @tre-lightgrey-color;
			}
		}
	}
}
.button-link {

	padding: 17px 15px;
	background-color: @tre-standard-color;
	text-decoration: none;
	color: #fff;
	font-family: @tre-black-font;
	text-transform: uppercase;
	cursor: pointer;
	margin: 0 auto;
	text-align: center;
	display: block;
	width: 215px;
	border: 0;
	.border-radius();
	.font-size(1.6);
	&.disabled {
		background-color: @tre-gray-color;
		cursor: default;
	}
	&.disabled:hover {
		background-color: @tre-gray-color;
		cursor: default;
	}
	&.done-success {
		background-color: @tre-success-color;
		cursor: default;
	}
	&.done-success:hover {
		background-color: @tre-success-color;
		cursor: default;
	}
	&:hover, &:active {
		background-color: @tre-purple-color;
		color: #fff;
	}
	@media only screen and (min-width: 1250px) {
		.font-size(1.9);
		width: 215px;
	}
	&.get-package {

		width: 240px;
		@media only screen and (min-width: 1250px) {
			width: 280px;
		}
	}

	&.green {

		font-family: "FrutigerLTW01-87ExtraBk";
		color: white;

		#gradient.vertical( @tre-success-color, #569537);

		border: 1px solid #266a04 !important;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
		text-transform: uppercase;
		.border-radius(4px);
		padding: 17 15px;
		margin: 0 auto;
	}
}

input.button-link {
	-webkit-appearance: none;
}
/**** Isotope Filtering ****/

.isotope-item {
  z-index: 2;
}
.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}
/**** Isotope CSS3 transitions ****/

.isotope, .isotope .isotope-item {
  -webkit-transition-duration: 0.8s;
	 -moz-transition-duration: 0.8s;
	  -ms-transition-duration: 0.8s;
	   -o-transition-duration: 0.8s;
		  transition-duration: 0.8s;
}
.isotope {
  -webkit-transition-property: height, width;
	 -moz-transition-property: height, width;
	  -ms-transition-property: height, width;
	   -o-transition-property: height, width;
		  transition-property: height, width;
}
.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform, opacity;
	-ms-transition-property: -ms-transform, opacity;
	-o-transition-property: -o-transform, opacity;
	transition-property: transform, opacity;
}
/**** disabling Isotope CSS3 transitions ****/

.isotope.no-transition, .isotope.no-transition .isotope-item, .isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
	 -moz-transition-duration: 0s;
	  -ms-transition-duration: 0s;
	   -o-transition-duration: 0s;
		  transition-duration: 0s;
}
/* End: Recommended Isotope styles */
/* disable CSS transitions for containers with infinite scrolling*/

.isotope.infinite-scrolling {
  -webkit-transition: none;
	 -moz-transition: none;
	  -ms-transition: none;
	   -o-transition: none;
		  transition: none;
}

// TODO: find out what this is. -LR
.bgIE {
	
	display: none;
}

.coveragemap {

	p {

		margin-bottom: @margin-L;
	}

	.coveragemap-container {

		width: 100%;
		height: 960px;
		border: none;
		background-color: @color-gray95;
		// .transition(height 350ms);


		// The inconsistant sizes are a mirror of the heights the service gives. 
		&[data-mapsize="1"] {
			
			// height: 618px; // medium
			height: 468px; // seemingly getting the smaller size here as
		}
		&[data-mapsize="2"] {
			
			height: 733px; // large
		}
		&[data-mapsize="3"] {

			height: 468px; //small
		}


	}

	.coveragemap-link {
		
		display:none;
	}

	@media only screen and (min-width: @hw-tablet) and ( max-width: 800px ) {

		.coveragemap-container {

			width: 101%; // because the static map is slightly larger
			// height: 1024px;
		}
	}
	@media only screen and ( max-width: @hw-tablet-max ) {

		.coveragemap-container {

			display: none; // a link will be displayed instead.
		}

		.coveragemap-link {

			.inlineBlock();
		}
	}
}
.hasHttps {

	.coveragemap {

		.coveragemap-container {

			display: none; // a link will be displayed instead.
		}

		.coveragemap-link {

			.inlineBlock();
		}
	}
}

//fixes correct placement for android
.boxes .priceplaceholder p span {
	line-height: 50px;
}
/********* N2-specific styles ***************************************/

.controlPanel .plugins a {
	.box-sizing(content-box);
}
a {
   outline: 0;
}
/********* Right-to-left languages *********/

.rtl {
	direction: rtl;
	p, h1, h2, h3, h4 {
		padding-right: 20px;
	}
}
ul.rtl {
	list-style: square url(/UI/build/images/list_arrow.png);
	li {
		h4 {
			.check {
				float: right;
			margin-left: 10px;
			margin-right: 0px;
			}
		}
	}
}
.align-right {
	text-align: right !important;
}

